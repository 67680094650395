import Vue from 'vue';
// import { SnackbarManager } from './snackbar-manager';
// const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();

const initialState = {
    user: [],
    isAuthenticated: null,
    loading: false,
    sessionKey: 'token',
};

export class AdminManager {
    state;
    constructor() {
        this.state = Vue.observable(initialState);
    }

    async ping() {

      try {

          this.state.loading = true;
          // console.log("create", pFormData)

          axios.interceptors.request.use(
            (config) => {
              const today = new Date();
              console.log(config);
              console.log(`${config.method.toUpperCase()} request sent to ${config.url} at ${today.getHours()}:${today.getMinutes()}`);
              return config;
            },
            (error) => {
              console.log(error);
            }
          );

          return await axios.get(`/ping`).catch(function (error) { 
            console.log('Analisando erros...');
            console.warn('error');
            console.log(error);
            console.log(JSON.stringify(error));
            console.warn('error.config');
            console.log(error.config);
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              return error.response.status +': '+error.response.data;
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
              return 'Request: ' + error.request;
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              return 'Message: '+ error.message;
            }
          });

      } catch (error) {

          // globalManager.printErrors(error);

          return globalManager.getErrorsMessage(error);
      } finally {
          this.state.loading = false;
      }
  }

}
