<template>
  <div class="pa-4">
    <!-- TITULO -->
    <v-row>
      <div>
        <span class="text-h5 text-sm-h4"> Drives </span>
        <div
          v-if="!showInputSearch"
          class="d-flex flex-column col-12 pa-0 breadcrumb-drives"
        >
          <v-breadcrumbs class="pa-0">
            <template v-slot>
              <v-breadcrumbs-item
                :to="`/drives`"
                :disabled="getDisableBreadcrumbs(true)"
                exact
              >
                Meu Drive
              </v-breadcrumbs-item>

              <v-breadcrumbs-item
                v-for="item in breadcrumbs"
                :key="item.id"
                :to="`/drives/${item.id}`"
                exact
                :disabled="getDisableBreadcrumbs(item)"
              >
                <span style="color: #ccc; cursor: default" class="pr-2">/</span>
                {{ item.title }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>
      </div>

      <v-spacer></v-spacer>

      <div class="mr-2">
        <v-text-field
          v-model="searchInput"
          filled
          clearable
          flat
          rounded
          dense
          placeholder="Pesquisar"
          :append-icon="
            !searchInput?.length > 0
              ? 'mdi-magnify'
              : '' || !searchInput == null
              ? 'mdi-magnify'
              : ''
          "
          @keyup.enter="callSearch()"
          @click:clear="callClearSearch()"
        ></v-text-field>
      </div>

      <v-btn
        type="button"
        v-show="showButtonCreateNote"
        color="blue"
        icon
        @click="openNotesForm"
      >
        <v-icon>mdi-file-plus</v-icon>
      </v-btn>

      <v-btn
        class="mr-2"
        type="button"
        color="blue"
        icon
        @click="openDriveForm"
      >
        <v-icon>mdi-folder-plus</v-icon>
      </v-btn>
    </v-row>

    <slot v-if="!showInputSearch">
      <!-- DRIVES -->
      <v-row class="mt-6">
        <v-col cols="12" class="pa-0">
          <v-row justify="start">
            <div
              v-for="(drive, index) in drives"
              :key="index"
              class="arquivos"
              @dblclick="enterDriver(drive)"
              @contextmenu="controlContextMenuDrive"
              @click.right="callEditItem(drive)"
            >
              <template>
                <div class="arquivos">
                  <v-icon
                    size="80"
                    style="cursor: pointer"
                    :color="drive.color"
                  >
                    mdi-folder-outline
                  </v-icon>
                  <br />
                  <div style="font-size: 1em; cursor: pointer" class="itens">
                    {{ drive.title }}
                  </div>
                </div>
              </template>
            </div>
            <!-- <DrivesMessage v-if="driveIsEmpty" /> -->
          </v-row>
        </v-col>
      </v-row>

      <!-- NOTES  -->
      <v-row class="mt-6">
        <v-col cols="12" class="pa-0">
          <v-row justify="start" @click.right="menuDrive = false">
            <Notes
              v-show="showNotes"
              :notes="notes"
              @emitOpenContextMenuNote="controlContextMenuNote"
            />
          </v-row>
        </v-col>
      </v-row>

      <v-menu
        top
        v-model="showContextMenu"
        :position-x="x"
        :position-y="y"
        absolute
      >
        <v-list>
          <v-list-item
            @click="selectorMenu(item.title)"
            v-for="(item, index) in dropDownItems"
            :key="index"
          >
            <v-list-item-title>
              <v-icon size="20" class="mr-2">{{ item.icon }}</v-icon
              >{{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </slot>

    <AlertMessage
      v-if="items.length == 0 && showInputSearch"
      title="Nenhum resultado encontrado"
      styleTitle="font-size: 12px !important;"
      sizeIcon="150"
      styleIcon="color: #e6e6e6"
    />

    <slot v-if="showInputSearch">
      <v-row class="mt-6">
        <v-col cols="12" class="pa-0">
          <v-row justify="center">
            <v-list width="100%">
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="item in items"
                  @click="openItemListSearch(item)"
                >
                  <v-list-item-avatar>
                    <v-icon>{{
                      item.attribute.includes("drive")
                        ? "mdi-folder"
                        : "mdi-note"
                    }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle
                      v-if="item.attribute.includes('content')"
                      >{{ item.content }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-row>
        </v-col>
      </v-row>
    </slot>

    <DriveForm
      :formData="drive"
      :showForm="showDriveForm"
      @emitClose="closeDriveForm"
    />

    <DrivesMoveForm
      :formData="drive"
      :showForm="showDriveMoveForm"
      @emitClose="closeDriveMoveForm"
    />

    <NotesForm
      :formData="note"
      :showForm="showNoteForm"
      @emitClose="closeNoteForm"
    />
    
    <NotesMoveForm
      :formData="note"
      :showForm="showNoteMoveForm"
      @emitClose="closeNoteMoveForm"
    />

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteDrive"
      :loading="loading"
      @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteDrive"
    />

    <ConfirmDialog
      :title="deleteTextNote"
      :dialog="toggleDialogDeleteNote"
      :loading="loadingNotes"
      @confirm="deleteItemConfirmNote"
      @cancel="closeDialogConfirmDeleteNote"
    />
  </div>
</template>

<script>
import DriveForm from "./DriveForm.vue";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";
import Notes from "@/views/Notes/Notes.vue";
import NotesForm from "@/views/Notes/NotesForm.vue";
import NotesMoveForm from "@/views/Notes/NotesMoveForm.vue";
import DrivesMoveForm from "@/views/Drives/DrivesMoveForm.vue";

import AlertMessage from "@/components/Alert/Message.vue";

import { DrivesManager } from "@/manager/drives-manager";
const drivesManager = new DrivesManager();
import { NotesManager } from "@/manager/notes-manager";
const notesManager = new NotesManager();

import { DocumentsManager } from "@/manager/document-manager";
const documentsManager = new DocumentsManager();

export default {
  name: "Drives",

  components: {
    DriveForm,
    DrivesMoveForm,
    ConfirmDialog,
    Notes,
    NotesForm,
    NotesMoveForm,
    AlertMessage,
    // DrivesMessage,
  },

  data() {
    return {
      // VARIABLES DRIVES DATA AND FLAGS
      drives: [],
      drive: {},
      showDriveForm: false,
      showDriveMoveForm: false,
      menuDrive: true,

      // NOTES DATA AND FLAGS
      notes: [],
      note: {},
      showNoteForm: false,
      showNoteMoveForm: false,
      showButtonCreateNote: false,
      showNotes: false,

      // UTILS
      deleteText: "",
      deleteTextNote: "",
      toggleDialogDeleteDrive: false,
      toggleDialogDeleteNote: false,
      driveIsEmpty: false,

      // SEARCH COMPONENT
      showInputSearch: false,
      searchInput: "",
      items: [],

      // CONTEXT MENU DATA AND FLAGS
      dropDownItems: [],
      showContextMenu: false,
      showMenu: false,
      x: 0,
      y: 0,

      // BREADCRUMBS
      breadcrumbs: [],
    };
  },

  computed: {
    loading: () => drivesManager.state.loading,
    loadingNotes: () => notesManager.state.loading,
  },

  watch: {
    $route(to) {
      this.showButtonCreateNote = false;
      this.refreshData();

      if (this.$route.path != "/drives") {
        this.showButtonCreateNote = true;
        this.callListBreadcrumbs();
        this.callListNotes();
      } else {
        this.breadcrumbs = [];
        this.showNotes = false;
        this.showButtonCreateNote = false;
      }

      // this.verifyDriveIsEmpty();
    },
  },

  created() {
    this.refreshData();
    if (this.$route.path != "/drives") {
      this.showButtonCreateNote = true;
      this.callListBreadcrumbs();
      this.callListNotes();
    } else {
      this.breadcrumbs = [];
      this.showNotes = false;
      this.showButtonCreateNote = false;
    }
    // this.verifyDriveIsEmpty();
  },

  methods: {
    // DRIVES
    async callList() {
      const data = await drivesManager.list(this.$route.params.id);
      // console.log(data);
      this.drives = data;
    },

    async callListBreadcrumbs() {
      const data = await drivesManager.breadcrumbs(this.$route.params.id);
      // console.log(data);

      this.breadcrumbs = data;
    },

    callEditItem(pItem) {
      // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
      this.drive = Object.assign({}, pItem);
      this.menuDrive = true;
    },

    // SEARCH
    async callSearch() {
      const data = await drivesManager.search({ filter: this.searchInput });

      this.showInputSearch = true;

      this.items = data;
    },

    async callClearSearch() {
      this.searchInput = null;
      this.showInputSearch = false;
    },

    // ABRE FORMULARIO DE INCLUSÃO DRIVE
    openDriveForm() {
      this.drive = { drive_id: this.$route.params.id };
      this.showDriveForm = true;
    },

    // FECHA FORMULARIO INCLUSAO DRIVE
    closeDriveForm(pHasUpdate) {
      if (pHasUpdate) {
        this.refreshData();
      }
      this.drive = {};
      this.showDriveForm = false;
    },

    // ABRE DIALOG PARA DELETAR O DRIVE
    callDeleteItem(pItem) {
      this.drive = pItem;
      this.deleteText =
        "Tem certeza de deseja excluir o item: " + pItem.title + " ?";
      this.toggleDialogDeleteDrive = true;
    },

    // CONFIRMA DELEÇÃO DA DRIVE
    async deleteItemConfirm() {
      const response = await drivesManager.delete(this.drive);
      if (response.ok) {
        this.closeDialogConfirmDeleteDrive();
        this.refreshData();
      }
    },

    // FECHA DIALOG DELETE DRIVE
    closeDialogConfirmDeleteDrive() {
      this.drive = {};
      this.toggleDialogDeleteDrive = false;
    },

    // NAVEGAR ENTRE UM DRIVE
    enterDriver(pItem) {
      this.showNotes = false;
      this.$router.push("/drives/" + pItem.id).catch(() => {});
      this.editedItem = this.defaultItem;
    },

    // NOTES
    async callListNotes() {
      const data = await notesManager.list(this.$route.params.id);
      // console.log(data);

      this.notes = data;
      this.showNotes = true;
      this.callClearSearch();
    },

    // ABRE FORMULARIO DE INCLUSÃO NOTA
    openNotesForm() {
      this.note = { drive_id: this.$route.params.id };
      this.showNoteForm = true;
    },

    // FECHA FORMULARIO INCLUSAO NOTA
    closeNoteForm(pHasUpdate) {
      if (pHasUpdate) {
        this.callListNotes();
      }
      this.note = {};
      this.showNoteForm = false;
    },

    // FECHA FORMULARIO MOVER NOTA
    closeNoteMoveForm(pHasUpdate) {
      if (pHasUpdate) {
        this.callListNotes();
      }
      this.note = {};
      this.showNoteMoveForm = false;
    },
  
  // FECHA FORMULARIO MOVER DRIVE
    closeDriveMoveForm(pHasUpdate) {
      if (pHasUpdate) {
        this.callList();
      }
      this.drive = {};
      this.showDriveMoveForm = false;
    },

    // ABRE DIALOG PARA DELETAR O NOTA
    callDeleteItemNote(pItem) {
      this.deleteTextNote =
        "Tem certeza de deseja excluir o item: " + pItem.title + " ?";
      this.toggleDialogDeleteNote = true;
    },

    // CONFIRMA DELEÇÃO DA NOTA
    async deleteItemConfirmNote() {
      const response = await notesManager.delete(this.note);
      if (response.ok) {
        this.closeDialogConfirmDeleteNote();
        this.callListNotes();
      }
    },

    // FECHA DIALOG PARA DELETAR NOTA
    closeDialogConfirmDeleteNote() {
      this.note = {};
      this.toggleDialogDeleteNote = false;
    },

    // UTILS
    refreshData() {
      this.callList();
    },

    // BREADCRUMB DISABLED
    getDisableBreadcrumbs(pItem) {
      return pItem.id === this.$route.params.id ? true : false;
    },

    // CONTROLE DO MENU DE CONTEXTO DAS NOTAS
    controlContextMenuNote(pNoteSelected, x, y) {
      this.note = pNoteSelected;
      this.dropDownItems = [
        { title: "Renomear", icon: "mdi-pencil" },
        { title: "Mover", icon: "mdi-file-move-outline" },
        { title: "Excluir", icon: "mdi-close" },
      ];
      this.x = x;
      this.y = y;
      this.menuDrive = false;
      this.showContextMenu = false;
      this.$nextTick(() => {
        this.showContextMenu = true;
      });
    },

    // CONTROLE DO MENU DE CONTEXTO DOS DRIVES
    controlContextMenuDrive(e) {
      e.preventDefault();
      (this.dropDownItems = [
        { title: "Editar", icon: "mdi-pencil" },
        { title: "Mover", icon: "mdi-file-move-outline" },
        { title: "Excluir", icon: "mdi-close" },
        { title: "Criar Documento", icon: "mdi-text-box" },
      ]),
        (this.showContextMenu = false);
      this.menuDrive = true;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showContextMenu = true;
      });
    },

    async callCreateDocument(pItem) {
      const formData = { title: pItem.title, drive: pItem.id };
      await documentsManager.create(formData);
    },

    // SELETOR DO MENU DOS DRIVES
    selectorMenu(value) {
      const options = value;

      switch (options) {
        case "Excluir":
          if (this.menuDrive) {
            this.callDeleteItem(this.drive);
          } else {
            this.callDeleteItemNote(this.note);
          }
          break;

        case "Editar":
          if (this.menuDrive) {
            this.showDriveForm = true;
          }
          break;

        case "Renomear":
          this.showNoteForm = true;
          break;

        case "Mover":
          if (this.menuDrive) {
            this.showDriveMoveForm = true;
          } else {
            this.showNoteMoveForm = true;
          }
          break;

        case "Compartilhar":
          if (this.menuDrive) {
            this.showShareForm = true;
            // console.log("compartilhar");
          } else {
            // console.log("aqui etou vindo do notes");
          }

          break;
        case "Criar Documento":
          this.callCreateDocument(this.drive);
          break;

        default:
        // console.log(`Não foi reconhecido como opção ${options}.`);
      }
    },

    // VERIFICA SE O DRIVE ESTA VAZIO
    verifyDriveIsEmpty() {
      if (
        drivesManager.state.drives.length == 0 &&
        notesManager.state.notes.length == 0
      ) {
        this.driveIsEmpty = true;
      } else {
        this.driveIsEmpty = false;
      }
    },

    // DIRECIONA PARA ROTA DO ITEM SELECIONADO NA PESQUISA
    openItemListSearch(pItemSearch) {
      console.log(pItemSearch);
      if (pItemSearch.attribute.includes("note")) {
        this.$router.push("/edit-note/" + pItemSearch.id).catch(() => {});
      } else {
        this.$router.replace("/drives/" + pItemSearch.id).catch(() => {});
      }
    },
  },
};
</script>

<style scoped>
.arquivos {
  float: left;
  height: auto;
  width: 140px;
  text-align: center;
  padding: 2px;
  border-radius: 6px;
  max-width: 140px;
}
.itens {
  text-align: center;
  padding: 2px;
  border-radius: 6px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.breadcrumb-drives {
  padding-left: 2px !important;
}
</style>
