<template>
  <div>
    <div>
      <h1>Validação do email</h1>
      <v-card>
        <v-container class="pa-6 mt-4">
          Para validar seu cadastro é preciso enviar um link com um novo código
          para seu email.<br />
          <br />
          <v-form
            ref="formNewCode"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-row>
              <!-- CAMPO EMAIL -->
              <v-col cols="12">
                <v-text-field
                  v-model="formData.email"
                  :rules="rules.email"
                  label="E-mail"
                  required
                ></v-text-field>
                <v-btn @click="submit()" color="success">Envie um novo código</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { RegisterManager } from "@/manager/register-manager";
const registerManager = new RegisterManager();

export default {
  name: "NewCode",
  data: () => ({
    email: "",
    valid: false,
    rules: {
      email: [
        (v) => !!v || "Campo obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail precisa ser válido",
      ],
    },

    formData: {
      email: "",
    },
  }),
  computed: {
    ...mapGetters({
      // carregar o módulo de autenticação e o usuário logado
      user: "auth/user",
      isAuthenticated: "auth/isAuthenticated",
    }),
  },
  created() {
    this.formData.email = this.$route.params.email;
  },
  methods: {
    async submit() {
      if (!this.$refs.formNewCode.validate()) return;
      console.log("after", this.formData);
      let response = await registerManager.createNewCode(this.formData);
      console.log("after", response);
    },
  },
};
</script>
