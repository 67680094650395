<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4 ml-2">Parcelamentos</div>
      <v-spacer></v-spacer>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" sm="7">
        <v-card :loading="loading" style="z-index: 0">
          <div>
            <v-card-title>
              Lista de parcelamentos
              <v-spacer></v-spacer>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="toggleSearch()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="callList()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <!-- <v-btn
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="openFinancialRecurrenceForm()"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn> -->
            </v-card-title>

            <div
              class="d-flex justify-space-between align-center"
              v-if="showSearch"
            >
              <v-col cols="9">
                <v-text-field
                  v-model="searchFinancialRecurrence"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  dense
                  single-line
                  autofocus
                  hide-details
                />
              </v-col>

              <v-col cols="3" class="px-4">
                <v-row justify="end" class="mr-2">
                  <v-btn icon @click="clearSearch()">
                    <v-icon color="grey"> mdi-broom </v-icon>
                  </v-btn>
                  <v-btn icon @click="toggleSearch()">
                    <v-icon color="grey"> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </div>

            <v-data-table
              :headers="headers"
              :search="searchFinancialMovement"
              :items="listFinancialRecurrence"
              loading-text="Carregando..."
              progress
              class="elevation-1"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <!-- <v-icon small class="mr-2" @click="callEditItem(item)">
                  mdi-pencil
                </v-icon> -->
                <!-- <v-icon small class="mr-2" @click="callDeleteItem(item)">
                  mdi-delete
                </v-icon> -->
                <v-icon small class="mr-2" @click="callListMovements(item)">
                  mdi-list-box-outline
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" sm="5">
        <v-card :loading="loadingMovement" style="z-index: 0">
          <div>
            <v-card-title>
              {{ titleMovements }}
              <v-spacer></v-spacer>
              <v-btn
                v-if="!loadingMovement"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="toggleSearch()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                v-if="!loadingMovement && this.financialMovement!=null"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="callListMovements()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <!-- <v-btn
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="openFinancialRecurrenceForm()"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn> -->
            </v-card-title>

            <div
              class="d-flex justify-space-between align-center"
              v-if="showSearch"
            >
              <v-col cols="9">
                <v-text-field
                  v-model="searchFinancialRecurrence"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  dense
                  single-line
                  autofocus
                  hide-details
                />
              </v-col>

              <v-col cols="3" class="px-4">
                <v-row justify="end" class="mr-2">
                  <v-btn icon @click="clearSearch()">
                    <v-icon color="grey"> mdi-broom </v-icon>
                  </v-btn>
                  <v-btn icon @click="toggleSearch()">
                    <v-icon color="grey"> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </div>

            <v-data-table
              :headers="headersMovement"
              :search="searchFinancialMovement"
              :items="listFinancialMovement"
              loading-text="Carregando..."
              progress
              class="elevation-1"
            >
              <template v-slot:[`item.situation`]="{ item }">
                {{ (item.situation=='O') ? 'Aberto':'Realizado' }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon v-if="item.situation=='O'" small class="mr-2" @click="situationChange(item)">
                  mdi-invoice-text-check-outline
                </v-icon>
                <v-icon v-if="item.situation=='R'" small class="mr-2" @click="situationChange(item)">
                  mdi-invoice-text-clock-outline
                </v-icon>
                <!--                 
                <v-icon small class="mr-2" @click="callEditItem(item)">
                  mdi-pencil
                </v-icon>
                -->
                <v-icon small class="mr-2" @click="callDeleteItemMovement(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- <FinancialRecurrenceForm
      :formData="financialRecurrence"
      :showForm="showFinancialRecurrenceForm"
      @emitClose="closeFinancialRecurrenceForm"
    /> -->

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteFinancialMovement"
      @confirm="deleteItemMovementConfirm"
      @cancel="closeDialogConfirmDeleteFinancialMovement"
    />

  </div>
</template>
  
  <script>

import { FinancialMovementManager } from "@/manager/financial-movement-list-manager";
const financialMovementManager = new FinancialMovementManager();

import { FinancialRecurrenceManager } from "@/manager/financial-recurrence-manager";
const financialRecurrenceManager = new FinancialRecurrenceManager();
  
// import FinancialRecurrenceForm from "./FinancialRecurrenceForm.vue";

import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";

export default {
  name: "FinancialRecurrence",
  components: {
    // FinancialRecurrenceForm,
    ConfirmDialog,
  },

  created() {
    this.refreshData();
  },

  computed: {
    loadingMovement: () => financialMovementManager.state.loading,
    loading: () => financialRecurrenceManager.state.loading,
  },

  methods: {
    // TARGETS
    async callList() {
      const data = await financialRecurrenceManager.list();
      // console.log(data);
      this.listFinancialRecurrence = data;
    },
    async callListMovements(pItem) {
      this.financialRecurrence = pItem;
      this.titleMovements = 'Lista de movimentos - '+ pItem.title;
      var filterData = { 'account_id': pItem.account_id, 'origin_movement': pItem.origin_movement};
      const data = await financialMovementManager.listTransferFilter(filterData);
      // console.log(data);
      this.listFinancialMovement = data;
    },
    async situationChange(pItem) {
      // console.log(pItem);
      const data = await financialMovementManager.situationChange(pItem);
      // console.log(data);
      this.callListMovements(this.financialRecurrence);
    },

    // ABRE FORMULARIO DE INCLUSÃO
    // openFinancialRecurrenceForm() {
    //   this.financialRecurrence = {};
    //   this.showFinancialRecurrenceForm = true;
    // },

    // FECHAR MODAL INCLUSAO
    // closeFinancialRecurrenceForm(pHasUpdate) {
    //   if (pHasUpdate) {
    //     this.refreshData();
    //   }
    //   this.financialRecurrence = {};
    //   this.showFinancialRecurrenceForm = false;
    // },

    // EDITAR DOCUMENTO
    // callEditItem(pItem) {
    //   // console.log(pItem)
    //   // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
    //   this.financialRecurrence = Object.assign({}, pItem);
    //   this.showFinancialRecurrenceForm = true;
    // },

    // async deleteItemConfirm() {
    //   const ret = await financialRecurrenceManager.delete(this.financialRecurrence);
    //   if (ret.ok) {
    //     this.closeDialogConfirmDeleteFinancialRecurrence();
    //     this.refreshData();
    //   }
    // },

    async deleteItemMovementConfirm() {
      const ret = await financialMovementManager.delete(this.financialMovement);
      if (ret.ok) {
        this.callListMovements(this.financialRecurrence);
        this.closeDialogConfirmDeleteFinancialMovement();
      }
    },

    callDeleteItemMovement(pItem) {
      // console.log(pItem);
      this.financialMovement = pItem;
      this.deleteText =
        "Tem certeza de deseja excluir o item: " + pItem.note + " ?";
      this.toggleDialogDeleteFinancialMovement = true;
    },

    closeDialogConfirmDeleteFinancialMovement() {
      this.financialMovement = {};
      this.toggleDialogDeleteFinancialMovement = false;
    },

    // UTILS
    refreshData() {
      this.callList();
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

    clearSearch() {
      this.searchFinancialRecurrence = " ";
    },
  },
  data() {
    return {
      // TARGETS
      listFinancialRecurrence: [],
      financialRecurrence: {},
      listFinancialMovement: [],
      titleMovements: "Lista de movimentos",
      financialMovement: {},
      // showFinancialRecurrenceForm: false,

      // UTILS
      deleteText: "",
      toggleDialogDeleteFinancialMovement: false,

      // DEFINIÇÕES DA TABELA
      showSearch: false,
      searchFinancialRecurrence: "",
      searchFinancialMovement: "",
      headers: [
        {
          text: "Título",
          align: "start",
          value: "title",
          sortable: true,
        },
        {
          text: "Data de início",
          align: "start",
          sortable: true,
          value: "date_start",
        },
        {
          text: "Data de término",
          align: "start",
          sortable: true,
          value: "date_end",
        },
        {
          text: "Valor fixo",
          align: "start",
          sortable: true,
          value: "fixed_amount",
        },
        {
          text: "Quantidade",
          align: "start",
          sortable: true,
          value: "quantity",
        },
        {
          text: "Valor",
          align: "start",
          sortable: true,
          value: "amount",
        },
        {
          text: "Encerrado",
          align: "start",
          sortable: true,
          value: "closed",
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
      headersMovement: [
        {
          text: "Situação",
          align: "start",
          value: "situation",
          sortable: true,
        },
        {
          text: "Data da compra",
          align: "start",
          sortable: true,
          value: "posting",
        },
        {
          text: "Data do movimento",
          align: "start",
          sortable: true,
          value: "movement",
        },
        {
          text: "Referência",
          align: "start",
          sortable: true,
          value: "reference",
        },
        {
          text: "Valor",
          align: "start",
          sortable: true,
          value: "amount",
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
};
</script>
  
  <style scoped>
</style>
  