<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      overlay-opacity="0.8"
      style="width: 100%; z-index: 5000"
      persistent
      max-width="400px"
      open-delay="5000"
    >
      <v-card :loading="loading || loadingFinancialCategory">
        <v-card-text>
          <!-- TOPO FORMULARIO  -->
          <div class="d-flex justify-end"></div>
          <v-card-actions>
            <v-row class="my-4 d-flex justify-space-between align-center">
              <div class="d-flex display-1 align-center">
                <span
                  class="text-h6 sm text-h5"
                  v-if="this.formData.id == undefined"
                >
                  Cadastrar metas financeiras
                </span>
                <span v-if="this.formData.id != undefined">Editar metas financeiras</span>
              </div>
              <v-btn fab rounded text tile x-small @click="toggleFinancialMonthlyGoal(false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>

          <v-form
            ref="refFinancialMonthlyGoalForm"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-row>

              <!-- SELECT - CATEGORY  -->
              <v-col cols="12">
                <v-row class="pa-2">
                  <v-checkbox
                    v-model="filterCategory"
                    label="Filtrar categorias lançadas"
                    @click="refreshData()"
                  ></v-checkbox>
                  <SelectFinancialCategory
                    v-if="showForm"
                    :list="listFinancialCategory"
                    :formData="formData"
                    itemText="title"
                    itemValue="id"
                    label="Categoria"
                    nodeName="category"
                    :itemSelected.sync="formData.category"
                    :loading="loadingFinancialCategory"
                  />
                </v-row>
              </v-col>

              <!-- INPUT - YEAR -->
              <v-col cols="6">
                <v-row class="pa-2">
                  <v-text-field
                    v-model="formData.year"
                    label="Ano"
                    maxlength="4"
                  />
                </v-row>
              </v-col>

              <!-- INPUT - PAID_BY -->
              <v-col cols="6">
                <v-row class="pa-2">
                  <v-text-field
                    v-model="formData.paid_by"
                    label="Pago até mês"
                    maxlength="2"
                  />
                </v-row>
              </v-col>

              <!-- INPUT - MONTH_START -->
              <v-col cols="6">
                <v-row class="pa-2">
                  <v-text-field
                    v-model="formData.month_start"
                    label="Mês de início"
                    maxlength="2"
                  />
                </v-row>
              </v-col>

              <!-- INPUT - MONTH_END -->
              <v-col cols="6">
                <v-row class="pa-2">
                  <v-text-field
                    v-model="formData.month_end"
                    label="Mês de término"
                    maxlength="2"
                  />
                </v-row>
              </v-col>

              <!-- INPUT - DAY -->
              <v-col cols="6">
                <v-row class="pa-2">
                  <v-text-field
                    v-model="formData.day"
                    label="Dia de pagamento"
                    maxlength="2"
                  />
                </v-row>
              </v-col>

              <!-- INPUT - AMOUNT -->
              <v-col cols="6">
                <v-row class="pa-2">
                  <v-text-field
                    v-model="formData.amount"
                    label="Valor"
                    maxlength="10"
                    @keyup="formData.amount = formatNumber(formData.amount)"
                  />
                </v-row>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12">
                <v-row justify="end">
                  <v-btn
                    v-if="this.formData.id == undefined"
                    :disabled="!valid"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callCreate()"
                  >
                    Salvar
                  </v-btn>
                  <v-btn
                    v-if="this.formData.id != undefined"
                    :disabled="!valid"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callUpdate()"
                  >
                    Editar
                  </v-btn>
                </v-row>
              </v-col>

              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  
<script>
// BIBLIOTECAS
import moment from "moment";
// COMPONENTES
import SelectFinancialCategory from "@/components/Selects/Select.vue";
//MANAGERS
import { FinancialMonthlyGoalManager } from "../../manager/financial-monthly-goal-manager";
export const financialMonthlyGoalManager = new FinancialMonthlyGoalManager();
import { FinancialCategoryManager } from "../../manager/financial-category-manager";
export const financialCategoryManager = new FinancialCategoryManager();

export default {
  name: "FinancialMonthlyGoal",

  components: {
    SelectFinancialCategory,
  },

  props: {
    formData: {
      type: Object,
      require: false,
    },

    showForm: {
      type: Boolean,
    },
  },

  created() {
    
  },

  computed: {
     loading: () => financialMonthlyGoalManager.state.loading,
     loadingFinancialCategory: () => financialCategoryManager.state.loading,
  },

  watch: {
    showForm: function () {
      if(this.showForm){
        console.log(moment().month());
        this.formData.year = moment().year();
        this.formData.month_start = 1;
        this.formData.month_end = 12;
        this.formData.paid_by = moment().month(); // retorna mês de 0 a 11
        this.refreshData();
      }
    }
  },

  methods: {
    refreshData() {
      // this.callListFinancialCategory();
      this.callListCategory(this.filterCategory);
    },
    // CREATE
    // CRIA UM NOVO ITEM
    async callCreate() {
      if (!this.$refs.refFinancialMonthlyGoalForm.validate()) return;
      this.formData.type = this.selectTypeFinancialMonthlyGoal;
      var response = await financialMonthlyGoalManager.create(this.formData);
      if (response.ok) {
        this.toggleFinancialMonthlyGoal(true);
      }
    },
    // LIST
    // async callListFinancialCategory() {
    //   const data = await financialCategoryManager.list();
    //   // console.log(data);
    //   this.listFinancialCategory = data.map((objeto) => objeto);
    // },
    async callListCategory(pFilterCategory) {
      var filterData = { filter: pFilterCategory, year : this.formData.year };
      // console.log(filterData);
      const data = await financialMonthlyGoalManager.listCategory(filterData);
      // console.log(data);
      this.listFinancialCategory = data.map((objeto) => objeto);
    },
    // UPDATE
    // ATUALIZA UM ITEM
    async callUpdate() {
      if (!this.$refs.refFinancialMonthlyGoalForm.validate()) return;
      this.formData.type = this.selectTypeFinancialMonthlyGoal;
      var response = await financialMonthlyGoalManager.update(this.formData);
      if (response.ok) {
        this.toggleFinancialMonthlyGoal(true);
      }
    },

    // SERVICES
    // UTILIZADA PARA FECHAR O FORMULARIO. ELA RECEBE UM PARAMETRO
    // QUE VALIDA SE EXITE NESCESSIDADE DE ATUALIZAR A TABELA OU NAO
    toggleFinancialMonthlyGoal(pHasUpdate) {
      this.$refs.refFinancialMonthlyGoalForm.resetValidation();
      this.$emit("emitClose", pHasUpdate);
    },

    // USADO PARA LIMPAR O FORMULARIO
    callClearForm() {
      this.$refs.refFinancialMonthlyGoalForm.reset();
    },

    formatNumber(pValue) {
      var vValue = String(pValue);
      if (vValue.indexOf('.') >= 0 && vValue.indexOf(',') >= 0) {
          vValue = vValue.replace('.', '');
      }
      vValue = vValue.replace(',', '.');
      return vValue;
    },

  },
  data() {
    return {
      valid: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },
      filterCategory: true,
      listFinancialCategory: [],
    };
  },
};
</script>