/* eslint-disable */
import Vue from 'vue';
import axios from 'axios'
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();


const initialState = {
    timeline: [],
    loading: false
}

export class TimelineManager {
    state;
    constructor() {
        this.state = Vue.observable(initialState);
    }

    // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
    extractData(pResponse) {
        const { data } = pResponse.data;

        // VERIFICA SE É UM OBJETO
        if (!!data?.attributes) {
            return globalManager._reduceResponse(data);
        }

        const list = data.map(item => (globalManager._reduceResponse(item)));
        return (list);
    }

    async list(pFilter) {

        try {
            this.state.loading = true;
            let response = await axios.post(`/timeline/list`, {...pFilter});
            //  console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }


}