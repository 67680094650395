<template>
  <!-- CAMPO TRADE  -->

  <v-combobox
    dense
    :outlined="outlined"
    v-model="selected"
    :items="list"
    input
    @change="verifyIsNewItem"
    :search-input.sync="search"
    :label="label"
    :item-text="itemText"
    :item-value="itemValue"
    clearable
  >
    <template v-slot:append-outer>
      <v-icon
        v-if="includedItem"
        @click="callIncludedItem"
        color="success"
        class=""
      >
        mdi-link-plus
      </v-icon>
      <!-- <v-icon
        v-if="newItem"
        @click="callCreateItem"
        color="success"
        class="mt-1"
      >
        mdi-content-save-plus
      </v-icon> -->
    </template>
  </v-combobox>
</template>
  
  <script>
export default {
  name: "SelectAutoCompleted",
  props: {
    list: {
      type: Array,
      require: true,
    },
    formData: {
      type: Object,
      require: false,
    },
    nodeName: {
      type: String,
    },
    itemSelected: {
      type: String,
    },
    label: {
      type: String,
    },
    outlined: {
      type: Boolean,
    },
    itemText: {
      type: String,
    },
    itemValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    mandatory: {
      type: Boolean,
    },
  },

  watch: {
    search(pValue) {
      // console.log(pValue);
      this.hasItemInList(pValue);
    },
  },

  created() {
    if (this.formData[this.nodeName] != null) {
      this.selected = Object.assign(
        {},
        this.formData.included[this.nodeName].attributes
      );
    }
  },

  methods: {
    // CREATE
    callCreateItem() {
      // FUNÇÃO EMIT QUE DISPARA A CRIAÇÃO DE UM NOVO ITEM ATRAVES DO TITTLE
      this.$emit("createItem", { title: this.search });
      this.newItem = false;
    },

    callIncludedItem() {
      // FUNÇÃO EMIT QUE DISPARA A CRIAÇÃO DE UM NOVO ITEM ATRAVES DO TITTLE
      this.$emit("includedItem", this.selected);
      this.includedItem = false;
      this.selected = null;
    },

    // SERVICES
    verifyIsNewItem(v) {
      if (typeof this.selected === "string") {
        this.newItem = true;
      } else {
        // ESTE EMIT ATUALIZA A PROP RECEBIDA DO PAI
        this.$emit(
          "update:itemSelected",
          this.selected?.id ? this.selected.id : ""
        );
      }
      if (this.selected == null) {
        this.newItem = false;
      }
    },

    hasItemInList(pValue) {
      if (pValue === null) {
        this.newItem = false;
        return;
      }
      let aux = 0;
      this.list.forEach((element) => {
        if (element.title.includes(pValue)) {
          aux++;
        }
        if (element.title === pValue) {
          this.includedItem = true;
          return;
        }
      });
      if (aux === 0) {
        this.includedItem = false;
        this.newItem = true;
      } else {
        this.newItem = false;
      }
    },
  },

  data() {
    return {
      newItem: false,
      includedItem: false,
      selected: null,
      search: null,
    };
  },
};
</script>