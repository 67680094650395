import Vue from 'vue';
import axios from 'axios';
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();

const initialState = {
  me: null,
  tokens: [],
  message: "",
  isAuthenticated: null,
  loading: false,
};

export class UserManager {
  state;
  constructor() {
    this.state = Vue.observable(initialState);
  }

  // isAuthenticated() {
  //     return this.state.isAuthenticated;
  // }

  _reduceResponse(pList) {
    const list = { ...pList.data.attributes };
    // console.log(list);
    return (list);
  }

  // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
  extractData(pResponse) {
    const { data } = pResponse.data;

    const list = data.map(item => (globalManager._reduceResponse(item)));
    return (list);
  }

  async getUser() {
    try {
      this.state.loading = true;
      let response = await axios.get('/me');
      // console.log('me', response)
      let item = response.data;
      return item;
    } catch (error) {

      // console.log(error);
      globalManager.printErrors(error)

      return [];

    } finally {
      this.state.loading = false;
    }
  }

  async getUsers() {
    try {
      this.state.loading = true;
      let response = await axios.get('/users');
      return response.data;
    } catch (error) {

      // console.log(error);
      globalManager.printErrors(error);

      return [];

    } finally {
      this.state.loading = false;
    }
  }

  async showTokens() {
    try {
      this.state.loading = true;
      const response = await axios.get('/tokens');

      let list = this.extractData(response);
      // console.log(list);

      return list;

    } catch (error) {

      // console.log(error);
      globalManager.printErrors(error);

      return false;

    } finally {
      this.state.loading = false;
    }
  }

  async deleteToken(item) {
    try {
      // console.log(item);
      this.state.loading = true;
      await axios.get(`/quit/${item.id}`),
        snackbarManager.setSuccessMessage('Token Removido!');
      return true;
    } catch (error) {

      // console.log(error);
      globalManager.printErrors(error);

      return false;

    } finally {
      this.state.loading = false;
    }
  }

  async deleteTokens(item) {
    try {
      console.log(item);
      this.state.loading = true;
      await axios.get("/quit"),
        snackbarManager.setSuccessMessage('Tokens Removido!');
      return true;
    } catch (error) {

      // console.log(error);
      globalManager.printErrors(error);

      return false;

    } finally {
      this.state.loading = false;
    }
  }

  async updatePassword(formData) {

    try {
      this.state.loading = true;
      await axios.post('/password/update', { ...formData });
      snackbarManager.setSuccessMessage("Alterar Senha", "Senha atualizada com sucesso");
      return true;
    } catch (error) {

      // console.log(error);
      globalManager.printErrors(error);

      return false;

    } finally {
      this.state.loading = false;
    }
  }

  async updateUser(formData) {

    if (formData.id == undefined) {
      snackbarManager.setErrorMessage("Erro", 'Aguarde o usuário ser carregado');
      return false;
    }

    try {
      this.state.loading = true;
      let response = await axios.put(`/users/${formData.id}`, { ...formData });

      // console.log(typeof response);
      // console.log(response);

      if (typeof response == 'object') {
        console.log('É um objeto');
        if (response.request.status == 200) {
          console.log('DEU BOM');
        }
      }

      snackbarManager.setSuccessMessage('Cadastro Atualizado!');
      return true;

    } catch (error) {

      // console.log(error);
      globalManager.printErrors(error)

      return false;

    } finally {
      this.state.loading = false;
    }

  }

  async updateAdminUser(formData) {

    if (formData.id == undefined) {
      snackbarManager.setErrorMessage("Erro", 'Admin. Aguarde o usuário ser carregado');
      return false;
    }

    try {

      this.state.loading = true;
      let response = await axios.put(`admin/users/${formData.id}`, { ...formData });

      // console.log(typeof response);
      // console.log(response);

      if (typeof response == 'object') {
        console.log('É um objeto');
        if (response.request.status == 200) {
          console.log('DEU BOM');
        }
      }

      snackbarManager.setSuccessMessage('Cadastro Atualizado!');
      return true;

    } catch (error) {

      // console.log(error);
      globalManager.printErrors(error)

      return false;

    } finally {
      this.state.loading = false;
    }

  }

  async validationLogin(route) {
    try {
      this.state.loading = true;
      // console.log("route", route)
      const data = await axios.get(`${route}`);
      snackbarManager.setSuccessMessage(data.data.message, data.data.success.cadastro[0]);
      return true;
    } catch (error) {
      // console.log(error);
      globalManager.printErrors(error)
      return false;
    } finally {
      this.state.loading = false;
    }
  }

  async inviteUser(formData) {
    try {
      this.state.loading = true;
      await axios.post('/users/invite', formData);

      return {
        ok: true,
        message: "Usuário convidado. Deseja compartilhar ?"
      };
    } catch (error) {

      if (error.response.data) {
        globalManager.printErrors(error);
      }

      return {
        ok: false,
        message: error.response.data.errors["Usuário não encontrado"][0]
      };
    } finally {
      this.state.loading = false;
    }
  }

}
