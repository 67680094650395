<template>
  <div>
    <v-card
      outlined
      v-for="(file, key) in _files"
      :key="key"
      class="file-listing"
    >
      <v-card-text>
        <v-row dense justify="start" align="start">
          <v-col cols="2">
            <img class="preview" v-bind:ref="'preview' + parseInt(key)" />
          </v-col>
          <v-col cols="6">{{ file.name }}</v-col>
          <v-col cols="4">
            <v-btn text color="red" @click="removeFile(key)"> REMOVER </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ArquivoDefault from "@/assets/images/types-files/arquivo-default.png";
import Arquivopdf from "@/assets/images/types-files/arquivo-pdf.png";
import Arquivoppt from "@/assets/images/types-files/arquivo-ppt.png";
import Arquivotxt from "@/assets/images/types-files/arquivo-txt.png";
import Arquivoxls from "@/assets/images/types-files/arquivo-xls.png";
import Arquivozip from "@/assets/images/types-files/arquivo-zip.png";
import Arquivodoc from "@/assets/images/types-files/arquivo-doc.png";

export default {
  name: "UploadList",
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  watch: {
    _files() {
      this.getImagePreviews();
    },
  },
  computed: {
    _files: {
      get() {
        return this.files;
      },
      set(value) {
        this.$emit("update:files", value);
      },
    },
  },
  methods: {

    // REPONSAVEL POR REMOVER ITEM DA LISTA DE UPLOAD
    removeFile(key) {
      this._files.splice(key, 1);
    },

    // REPONSAVEL PARA GERAR O PREVIEW DOS ARUIVOS DE IMAGEM E ICONES DO TIPO DO ARQUIVO NA LISTA DE UPLOADS
    getImagePreviews() {
      for (let i = 0; i < this._files.length; i++) {
        if (/\.(jpe?g|png|gif)$/i.test(this._files[i].name)) {
          let reader = new FileReader();

          reader.addEventListener(
            "load",
            function () {
              this.$refs["preview" + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          );
          reader.readAsDataURL(this._files[i]);
        } else if (/\.(pdf)$/i.test(this._files[i].name)) {
          this.$nextTick(function () {
            this.$refs["preview" + parseInt(i)][0].src = Arquivopdf;
          });
        } else if (/\.(doc)$/i.test(this._files[i].name)) {
          this.$nextTick(function () {
            this.$refs["preview" + parseInt(i)][0].src = Arquivodoc;
          });
        } else if (/\.(txt)$/i.test(this._files[i].name)) {
          this.$nextTick(function () {
            this.$refs["preview" + parseInt(i)][0].src = Arquivotxt;
          });
        } else if (/\.(zip)$/i.test(this._files[i].name)) {
          this.$nextTick(function () {
            this.$refs["preview" + parseInt(i)][0].src = Arquivozip;
          });
        } else if (/\.(txt)$/i.test(this._files[i].name)) {
          this.$nextTick(function () {
            this.$refs["preview" + parseInt(i)][0].src = Arquivoppt;
          });
        } else if (/\.(xls)$/i.test(this._files[i].name)) {
          this.$nextTick(function () {
            this.$refs["preview" + parseInt(i)][0].src = Arquivoxls;
          });
        } else {
          this.$nextTick(function () {
            this.$refs["preview" + parseInt(i)][0].src = ArquivoDefault;
          });
        }
      }
    },
  },
};
</script>

<style>
div.file-listing {
  margin: auto;
  margin-top: 3px;
}

div.file-listing img {
  height: 50px;
}
</style>
