<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Timeline</div>
      <v-spacer></v-spacer>
    </v-row>

    <v-row class="px-1" justify="start">
      <v-col cols="12" sm="6">
        <span v-if="listTimeline <= 0">
          <div class="d-flex justify-center py-2">
            <v-icon color="grey" size="100">mdi-information-outline</v-icon>
          </div>
          <span class="grey--text d-flex pa-4 align-center justify-center">
            Não há registro com os filtros realizados.
          </span>
        </span>

        <v-timeline v-else :dense="$vuetify.breakpoint.smAndDown" reverse>
          <v-timeline-item
            v-for="item in listTimeline"
            :key="item.id"
            class="mb-4"
            color="pink"
            small
          >
            <span slot="opposite">
              {{ item.date | dateFormat(item.date) }}
              <p style="font-size: 12px; color: #ccc">
                {{ item.diff_days | dateDiff(item.diff_days) }}
              </p>
            </span>
            <v-card class="elevation-2">
              <v-card-title class="text-h5">
                {{ item.title }}
                <v-spacer />
                <v-btn
                  type="button"
                  icon
                  color="blue"
                  x-small
                  @click="redirectEdit(item.url_web)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                {{ item.content | truncar(300) }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
      <v-col cols="12" sm="6">
        <v-row>
          <v-col cols="12" lg="6">
            <div>
              <div
                style="
                  height: 88px;
                  background: #ccc;
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;

                  padding: 16px;
                  background-color: #1976d2 !important;
                  border-color: #1976d2 !important;
                "
              >
                <div class="text-h5 text-sm-h4" style="color: #ffff">
                  {{
                    this.yearStart == this.yearEnd
                      ? this.yearStart
                      : this.yearStart + " até " + this.yearEnd
                  }}
                </div>
                <div class="text-h6 text-sm-h5" style="color: #ffff">
                  {{ this.dayStart }} até {{ this.dayEnd }}
                </div>
              </div>
              <v-date-picker
                full-width
                no-title
                v-model="dates"
                range
              ></v-date-picker>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="filter.content"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            ></v-text-field>

            <h4 class="mt-4">Tipos de diário</h4>

            <div v-for="item in listTypeDiary" :key="item.id">
              <v-checkbox
                v-model="filter.types"
                :label="item.title"
                color="primary"
                :value="item.key"
                hide-details
              ></v-checkbox>
            </div>

            <h4 class="mt-4">Listar</h4>
            <div v-for="item in checkItemsTypesModels" :key="item.value">
              <v-checkbox
                v-model="filter.models"
                :label="item.label"
                color="primary"
                :value="item.value"
                hide-details
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div
              style="display: flex; flex-direction: row; justify-content: end"
            >
              <v-btn color="success" @click="Filter()">Filtrar</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
import moment from "moment";

import { DiaryManager } from "../../manager/diary-manager";
const diariesManager = new DiaryManager();
import { TimelineManager } from "../../manager/timeline-manager";
const timelineManager = new TimelineManager();
import { GlobalManager } from '@/manager/global-manager';
const globalManager = new GlobalManager();

export default {
  name: "TimeLine",
  components: {},

  data() {
    return {
      listTypeDiary: [],
      listTimeline: [],

      // FLAGS
      toggleDialogDiaryDelete: false,

      // TIMELINE EXAMPLE
      dates: [
        moment().subtract(5, "days").format("YYYY-MM-DD"),
        moment().add(2, "days").format("YYYY-MM-DD"),
      ],
      dayStart: "",
      dayEnd: "",
      yearStart: "",
      yearEnd: "",

      // FILTERS
      checkItemsTypesModels: [
        {
          value: "note_versions",
          label: "Notas",
        },
        {
          value: "diary",
          label: "Diário",
        },
      ],

      filter: {
        types: ["DAY", "REM", "FIN", "TGT", "TSH"],
        dateStart: moment().subtract(5, "days").format("YYYY-MM-DD"),
        dateEnd: moment().add(2, "days").format("YYYY-MM-DD"),
        content: "",
        models: ["note_versions", "diary"],
      },
    };
  },

  computed:{
    loading: () => timelineManager.state.loading
  },

  watch: {
    loading(value){
      globalManager.loading(value);
    },

    toggleDialogDiaryDelete(val) {
      val || this.close();
    },

    dates(val) {
      if (val[0] > val[1]) {
        this.dayStart = moment(this.dates[1]).format("DD/MM");
        this.dayEnd = moment(this.dates[0]).format("DD/MM");
        this.yearStart = moment(this.dates[1]).format("YYYY");
        this.yearEnd = moment(this.dates[0]).format("YYYY");
        this.filter.dateStart = this.dates[1];
        this.filter.dateEnd = this.dates[0];
      } else {
        this.dayStart = moment(this.dates[0]).format("DD/MM");
        this.dayEnd = moment(this.dates[1]).format("DD/MM");
        this.yearStart = moment(this.dates[0]).format("YYYY");
        this.yearEnd = moment(this.dates[1]).format("YYYY");
        this.filter.dateStart = this.dates[0];
        this.filter.dateEnd = this.dates[1];
      }
    },
  },

  filters: {
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    dateDiff(date) {
      if (date < 0) {
        return Math.abs(date) + " dias atras";
      }
      if (date > 0) {
        return "Em " + Math.abs(date) + " dias ";
      }
      if (date === 0) {
        return "Hoje";
      }
    },

    // ELLIPSES IN TABLE
    truncar(item, maxChar) {
      if (item != undefined && item.length > maxChar) {
        return item.substring(0, maxChar) + "  ...";
      } else {
        return item;
      }
    },
  },

  async created() {
    this.callListDiary();
    this.callListTimeline();

    this.dayStart = moment(this.dates[0]).format("DD/MM");
    this.dayEnd = moment(this.dates[1]).format("DD/MM");
    this.yearStart = moment(this.dates[0]).format("YYYY");
    this.yearEnd = moment(this.dates[1]).format("YYYY");
  },

  methods: {
    async callListDiary() {
      const data = await diariesManager.listDiariesTypes();
    //   console.log(data);
      this.listTypeDiary = data;
    },

    async callListTimeline() {
      const data = await timelineManager.list(this.filter);
    //   console.log(data);
      this.listTimeline = data;
    },

    // TIMELINE EXAMPLE
    comment() {
      const time = new Date().toTimeString();
      this.events.push({
        id: this.nonce++,
        text: this.input,
        time: time.replace(
          /:\d{2}\sGMT-\d{4}\s\((.*)\)/,
          (match, contents, offset) => {
            return ` ${contents
              .split(" ")
              .map((v) => v.charAt(0))
              .join("")}`;
          }
        ),
      });

      this.input = null;
    },

    async Filter() {
      const data = await timelineManager.list(this.filter);
      // console.log(data);
      this.listTimeline = data;
    },

    redirectEdit(url) {
      this.$router.push(url);
    },
  },
 
};
</script>

<style scoped>
</style>

  