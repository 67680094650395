<template>
  <div>
    <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
      <v-tab v-for="item in tabReaders" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in tabs" :key="item.title">
        <v-card flat>
          <component
            :is="item.component"
            :formData="formData"
            :files="files"
            :url="noteId"
            @emitSuccessUploadFiles="callListFiles"
            @do-delete="callDeleteFile"
            @setLink="emitSetLink"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteFile"
      @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteFile"
    />
  </div>
</template>

<script>
//Imports list
import axios from "axios";

import Galeria from "./Galeria";
import Arquivos from "./Arquivos";

import Upload from "@/components/Upload";
import Preview from "@/components/PreviewMd/Preview";
import PrintScreen from "@/components/PrintScreen";

import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";

import { FilesManager } from "@/manager/files-manager";
const filesManager = new FilesManager();


export default {
  name: "tabs",

  components: {
    Preview,
    Galeria,
    Arquivos,
    Upload,
    PrintScreen,
    ConfirmDialog
  },

  props: {
    formData: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      noteId: this.$route.params.id,
      loadingFiles: false,
      files: [],
      file: {},

      deleteText: "",
      toggleDialogDeleteFile: false,

      tab: null,
      tabs: [
        {
          title: "Preview",
          component: Preview,
          callback: null,
        },
        {
          title: "Galeria",
          component: Galeria,
          callback: function () {
            !this.loadingFiles && this.callListFiles();
          }.bind(this),
        },
        {
          title: "Arquivos",
          component: Arquivos,
          callback: function () {
            !this.loadingFiles && this.callListFiles();
          }.bind(this),
        },
        {
          title: "Upload",
          component: Upload,
          callback: null,
        },
        {
          title: "PrintScreen",
          component: PrintScreen,
          callback: null,
        },
      ],
    };
  },

  computed: {
    tabReaders() {
      return this.tabs.map((item) => item.title);
    },
  },


  watch: {
    tab(tabIndex) {
      if (null !== this.tabs[tabIndex].callback) {
        this.tabs[tabIndex].callback();
      }
    },
  },

  methods: {
    reduceResponse(rawFilesResponse) {
      // console.log(rawFilesResponse);
      return this.files.lenght === 0
        ? null
        : rawFilesResponse.data.map((item) => ({
            id: item.data.attributes.id,
            name: item.data.attributes.name,
            ext: item.data.attributes.extension,
            urlAWS: item.data.attributes.url,
            // ext: item.ext,
            // urlAWS: item.url,
            url: `${process.env.VUE_APP_WEB_URL}/img/${item.data.attributes.id}`,
          }));
    },

    emitSetLink(file) {
      this.$emit("setLink", file);
    },

    async callListFiles() {
      this.loadingFiles = true;
      const response = await filesManager.list(this.$route.params.id);
      this.files = response;
    },
    
    callDeleteFile(pItem) {
      // console.log(pItem)
      this.file = pItem;
      this.deleteText =
        "Tem certeza de deseja excluir o item: " + pItem.name + " ?";
      this.toggleDialogDeleteFile = true;
    },

    async deleteItemConfirm() {
      const ret = await filesManager.delete(this.file);
      if (ret.ok) {
        this.closeDialogConfirmDeleteFile();
        this.callListFiles();
      }
    },

    closeDialogConfirmDeleteFile() {
      this.file = {};
      this.toggleDialogDeleteFile = false;
    },
  },
};
</script>
