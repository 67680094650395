/* eslint-disable */
import Vue from 'vue';
import axios from 'axios'
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();

const initialState = {
    auxiliar: [],
    loading: false,
};

export class DocumentsManager {
    state;
    constructor() {
        this.state = Vue.observable(initialState);
    }

    // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
    extractData(pResponse) {
        const { data } = pResponse.data;

        // VERIFICA SE É UM OBJETO
        if (!!data?.attributes) {
            return globalManager._reduceResponse(data);
        }

        const list = data.map(item => (globalManager._reduceResponse(item)));
        return (list);
    }

    async list() {

        try {
            globalManager.loading(true);
            this.state.loading = true;
            let response = await axios.get(`/documents`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }
    }

    async create(pFormData) {

        try {
            globalManager.loading(true);
            this.state.loading = true;
            // console.log("create", pFormData)

            await axios.post("/documents", { ...pFormData });

            snackbarManager.setSuccessMessage('Documento adicionado!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }
    }

    async update(pFormData, pAutoSave) {

        try {

            globalManager.loading(true);
            this.state.loading = true;
            // console.log(pFormData);

            await axios.put(`/documents/${pFormData.id}`, { ...pFormData });

            if (!pAutoSave) {
                snackbarManager.setSuccessMessage('Documento atualizado!');
                return globalManager.returnOk(true);
            }

            return globalManager.returnOk(true);

        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);

        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }

    }

    async delete(pItem) {

        try {
            globalManager.loading(true);
            this.state.loading = true;

            await axios.delete(`/documents/${pItem.id}`);

            snackbarManager.setSuccessMessage('Documento removido!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }
    }

    async showDocument(pDocumentId) {

        try {
            globalManager.loading(true);
            this.state.loading = true;
            let response = await axios.get(`/documents/${pDocumentId}`);
            //  console.log("response", response);

            // console.log("response", this.extractData(response));
            let data = this.extractData(response);
            // console.log("List - Manager", list); 

            return data;
        } catch (error) {
            globalManager.printErrors(error);
            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }
    }

    // ----------------------------------------  DRIVE DOCUMENT -----------------------------
    async createDriveDocument(pFormData) {
        try {
    
            this.state.loading = true;
            // console.log("create", pFormData)
            const response = await axios.post("/drives", { ...pFormData });    

            let item = this.extractData(response);
            // console.log(item);
            
            snackbarManager.setSuccessMessage('Drive adicionado!');
            return {
                ok: true,    
                data: item
            };
    
        } catch (error) {
    
          globalManager.printErrors(error);
    
          return globalManager.returnOk(false);
    
        } finally {
          this.state.loading = false;
        }
    }

    // ----------------------------------------  NOTES DOCUMENT -----------------------------
    async listNotesInGroupDocument(pDriveId) {
        try {    
            this.state.loading = true;
            // console.log("create", pFormData)
            let response = await axios.get(`/notes/drive/${pDriveId}`);

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
    
        } catch (error) {
    
          globalManager.printErrors(error);
    
          return globalManager.returnOk(false);
    
        } finally {
          this.state.loading = false;
        }
    }

    // ----------------------------------------  GROUP DOCUMENT -----------------------------

    async listGroupDocument(pDocumentId) {
        
        try {
            globalManager.loading(true);
            let response = await axios.get(`/document-groups/document/${pDocumentId}`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            globalManager.loading(false);
        }
    }

    async createGroupDocument(pFormData) {

        try {
            globalManager.loading(true);
            this.state.loading = true;
            //  console.log("create", pFormData)

            const response = await axios.post("/document-groups", { ...pFormData });
            let list = this.extractData(response);
            // console.log(list);

            snackbarManager.setSuccessMessage('Grupo de documento adicionado!');

            return {
                ok: true,
                data: list
            };
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }
    }

    async updateGroupDocument(pFormData, pAutoSave) {

        try {

            globalManager.loading(true);
            this.state.loading = true;
            // console.log(pFormData);
            
            const response = await axios.put(`/document-groups/${pFormData.id}`, { ...pFormData });
            // console.log(response);
            let item = this.extractData(response);
            // console.log(item);

            if (!pAutoSave) {
                snackbarManager.setSuccessMessage('Grupo atualizado!');
                return {
                    ok: true,
                    data: item
                };
                // return globalManager.returnOk(true);
            }

           
            return globalManager.returnOk(true);

        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);

        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }

    }

    async deleteGroupDocument(pItem) {

        try {
            globalManager.loading(true);
            this.state.loading = true;

            await axios.delete(`/document-groups/${pItem.id}`);

            snackbarManager.setSuccessMessage('Grupo removido!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }
    }

    // ----------------------------------------  NOTE DOCUMENT -----------------------------
    
    async listNoteDocument(pGroupDocumentId) {

        try {
            globalManager.loading(true);
            let response = await axios.get(`/document-notes/group/${pGroupDocumentId}`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            globalManager.loading(false);
        }
    }

    async createNoteDocument(pFormData) {

        try {
            globalManager.loading(true);
            this.state.loading = true;
            // console.log("create", pFormData)

            await axios.post("/document-notes", { ...pFormData });

            snackbarManager.setSuccessMessage('Nota adicionada!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }
    }

    async createNewNoteDocument(pFormData) {

        try {
            globalManager.loading(true);
            this.state.loading = true;
            // console.log("create", pFormData)

            await axios.post("/document-notes-with-new-note", { ...pFormData });

            snackbarManager.setSuccessMessage('Nota criada adicionado!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }
    }

    async updateNoteDocument(pFormData, pAutoSave) {

        try {

            globalManager.loading(true);
            this.state.loading = true;
            // console.log(pFormData);

            await axios.put(`/document-notes/${pFormData.id}`, { ...pFormData });

            if (!pAutoSave) {
                snackbarManager.setSuccessMessage('Grupo atualizado!');
                return globalManager.returnOk(true);
            }

            return globalManager.returnOk(true);

        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);

        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }

    }

    async deleteNoteDocument(pItem) {

        try {
            globalManager.loading(true);
            this.state.loading = true;

            await axios.delete(`/document-notes/${pItem.id}`);

            snackbarManager.setSuccessMessage('Nota removida!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }
    }


    // ---------------------------------------- SHARED DOCUMENT -----------------------------

    async listDocumentShared() {

        try {
            globalManager.loading(true);
            let response = await axios.get(`/document-shared-with-me`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            globalManager.loading(false);
        }
    }

    async shareDocument(pFormData) {

        try {
            globalManager.loading(true);
            this.state.loading = true;
            // console.log("create", pFormData)

            await axios.post("/document-share-by-email", { ...pFormData });

            snackbarManager.setSuccessMessage('Documento compartilhado!');

            return { ok: true };
        } catch (error) {

            if (error.response.data?.errors["Usuário não encontrado"].lenght <= 0) {
                globalManager.printErrors(error);
            }

            return {
                ok: false,
                message: error.response.data.errors["Usuário não encontrado"][0]
            };
        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }
    }

    async listShareDocumentsUser(pDocumentId) {

        try {
            globalManager.loading(true);
            let response = await axios.get(`/document-users/document/${pDocumentId}`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            globalManager.loading(false);
        }
    }

    async updateShareDocumentUser(pFormData, pAutoSave) {

        try {

            globalManager.loading(true);
            this.state.loading = true;
            // console.log(pFormData);

            await axios.put(`/document-users/${pFormData.id}`, { ...pFormData });

            if (!pAutoSave) {
                snackbarManager.setSuccessMessage('Permisão atualizada!');
                return globalManager.returnOk(true);
            }

            return globalManager.returnOk(true);

        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);

        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }

    }

    async deleteShareDocument(pItem) {

        try {
            globalManager.loading(true);
            this.state.loading = true;

            await axios.delete(`/document-users/${pItem.id}`);

            snackbarManager.setSuccessMessage('Compartilhamento Removido!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
            globalManager.loading(false);
        }
    }

}
