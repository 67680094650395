<!-- AppLayout.vue -->
<template>
  <v-app>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="white"
      style="z-index: 3"
    ></v-progress-linear>
    <!-- Topo -->
    <Header @emitToggleNav="showNavDrawer = !showNavDrawer"></Header>
    <!-- Menu -->
    <Nav :showNav="showNavDrawer" @closeNav="closeNav"></Nav>

    <v-main>
      <!-- Conteudo -->
      <!-- https://gs.statcounter.com/screen-resolution-stats/mobile/brazil -->
      <!-- Alterar também em Header / v-app-bar -->
      <v-container fluid style="min-width: 320px">
        <router-view></router-view>
        <Snackbar />
      </v-container>
      <!-- Botão : id-global-btn-open-quick-options -->
      <!-- <v-btn
        v-if="isAuthenticated"
        class="mx-1 id-global-btn-open-quick-options"
        color="indigo"
        large
        icon
        absolute
        bottom
        right
        elevation="2"
        @click="overlay = true"
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn> -->
      <!-- <QuickOptions
        v-if="isAuthenticated"
        :show="overlay"
        @emitCloseOverlay="overlay = false"
      ></QuickOptions> -->
    </v-main>
    <!-- Rodapé -->
    <Footer></Footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

import { GlobalManager } from "@/manager/global-manager";
const globalManager = new GlobalManager();

import Header from "./components/Layouts/Header.vue";
import Nav from "./components/Layouts/Nav.vue";
import QuickOptions from "./components/Layouts/QuickOptions.vue";
// import MegaModal from './components/Layouts/MegaModal.vue';
import Footer from "./components/Layouts/Footer.vue";
// import HeaderPrivate from './components/private/NavPrivate.vue';
// import HeaderPublic from './components/public/NavPublic.vue';
import Snackbar from "@/components/Snackbar/Snackbar.vue";

export default {
  components: {
    Header,
    Nav,
    Footer,
    Snackbar,
    QuickOptions,
    // MegaModal,
    // HeaderPrivate,
    // HeaderPublic
  },
  data() {
    return {
      overlay: false,
      megaModal: false,
      showQuickOptions: false,
      showNavDrawer: true,
    };
  },
  computed: {
    ...mapGetters({
      // carregar o módulo de autenticação e o usuário logado
      user: "auth/user",
      isAuthenticated: "auth/isAuthenticated",
    }),
    loading: () => globalManager.state.loading,
  },
  methods: {
    closeNav(pValue) {
      this.showNavDrawer = pValue;
    },
  },

  // computed: {
  //   currentNav() {
  //     const routeMeta = this.$route.meta;
  //     console.log(this.$route.meta);
  //     if (routeMeta && routeMeta.requiresAuth == true) {
  //       return HeaderPrivate;
  //     }
  //     return HeaderPublic;
  //   }
  // },
};
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-navigation-drawer,
.v-app-bar {
  z-index: 1 !important;
}
</style>