/* eslint-disable */
import Vue from 'vue';
import axios from 'axios'
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();

const initialState = {
    auxiliar: [],
    loading: false,
};

export class DiaryManager {
    state;
    constructor() {
        this.state = Vue.observable(initialState);
    }


    // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
    extractData(pResponse) {
        const { data } = pResponse.data;

        // VERIFICA SE É UM OBJETO
        if (!!data?.attributes) {
            return globalManager._reduceResponse(data);
        }

        const list = data.map(item => (globalManager._reduceResponse(item)));
        return (list);
    }

    async list(pUrl) {

        try {
            this.state.loading = true;
            let response = await axios.get(`${pUrl ? pUrl : "/diaries"}`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async create(pFormData) {

        try {
            this.state.loading = true;
            // console.log("create", pFormData)

            await axios.post("/diaries", { ...pFormData });

            snackbarManager.setSuccessMessage('Diário adicionado!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async update(pFormData, pAutoSave) {

        try {

            this.state.loading = true;
            // console.log(pFormData);

            await axios.put(`/diaries/${pFormData.id}`, { ...pFormData });

            if (!pAutoSave) {
                snackbarManager.setSuccessMessage('Diário atualizado!');
                return globalManager.returnOk(true);
            }

            return globalManager.returnOk(true);

        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);

        } finally {
            this.state.loading = false;
        }

    }

    async delete(pItem) {

        try {
            this.state.loading = true;

            await axios.delete(`/diaries/${pItem.id}`);

            snackbarManager.setSuccessMessage('Diário removido!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async showDiary(pDiaryId) {

        try {
            this.state.loading = true;
            let response = await axios.get(`/diaries/${pDiaryId}`);
            // console.log("response", Array(response));

            // console.log("response", this.extractData(response));
            let data = this.extractData(response);
            // console.log("List - Manager", list); 

            return data;
        } catch (error) {
            globalManager.printErrors(error);
            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async listDiariesTypes() {

        try {
            this.state.loading = true;
            let response = await axios.get(`/diary-types`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async createAppendDiary(pFormData) {

        try {
            this.state.loading = true;
            // console.log("create", pFormData)
            await axios.post(`/diary-append/date/${pFormData.date}`, { ...pFormData });

            return {ok: true, message: 'Diário adicionado!', color: "success" }
        } catch (error) {

            // globalManager.printErrors(error);

            return globalManager.getErrorsMessage(error);
        } finally {
            this.state.loading = false;
        }
    }

    // ---------------------------------------------------------- LEMBRETES -----------------------------------------------------

    async listDiariesReminder() {

        try {
            this.state.loading = true;
            let response = await axios.get(`/diaries?types=REM&dateEnd=today`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

}
