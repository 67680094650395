/* eslint-disable */
import Vue from 'vue';
import axios from 'axios'
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();

const initialState = {
  Files: [],
  paginate: {},
  loading: false,
};

export class FilesManager {
  state;
  constructor() {
    this.state = Vue.observable(initialState);
  }

  // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
  extractData(pResponse) {
    const { data } = pResponse.data;

    // VERIFICA SE É UM OBJETO
    if (!!data?.attributes) {
      return globalManager._reduceResponse(data);
    }

    const list = data.map(item => (globalManager._reduceResponse(item)));
    return (list);
  }

  isLoading() {
    return this.state.loading;
  }


  async list(pNoteId) {

    try {
      this.state.loading = true;

      let response = await axios.get(`/files/note/${pNoteId}`);

      // console.log("response", response);

      let list = this.extractData(response);
      // console.log("List - Manager", list);

      return list;
    } catch (error) {

      globalManager.printErrors(error);
      
      return globalManager.returnOk(false);

    } finally {
      this.state.loading = false;
    }
  }

  async create(formData) {

    try {

      this.state.loading = true;
      // console.log("create",formData)

      await axios.post("/files", { ...formData });

      snackbarManager.setSuccessMessage('Arquivo adicionado!');

      return globalManager.returnOk(true);

    } catch (error) {

      globalManager.printErrors(error);

      return globalManager.returnOk(false);

    } finally {
      this.state.loading = false;
    }
  }

  async update(formData) {

    try {

      this.state.loading = true;
      console.log(formData);

      await axios.put(`/files/${formData.id}`, { ...formData });

      snackbarManager.setSuccessMessage('Arquivo atualizado!');

      return globalManager.returnOk(true);

    } catch (error) {

      globalManager.printErrors(error);

      return globalManager.returnOk(false);

    } finally {
      this.state.loading = false;
    }

  }

  async delete(pFile) {
    try {

      this.state.loading = true;

      await axios.delete(`/files/${pFile.id}`);

      snackbarManager.setSuccessMessage('Arquivo removido!');

      return globalManager.returnOk(true);

    } catch (error) {

      globalManager.printErrors(error);

      return globalManager.returnOk(false);

    } finally {
      this.state.loading = false;
    }
  }

  async convertBase64(formData) {

    try {

      this.state.loading = true;
      // console.log("create",formData)

      let response = await axios.post("/files/convert-base-64", { ...formData });

      console.log(response);
      
      snackbarManager.setSuccessMessage('Print enviado!');

      return globalManager.returnOk(true);

    } catch (error) {

      globalManager.printErrors(error);

      return globalManager.returnOk(false);

    } finally {
      this.state.loading = false;
    }
  }

}
