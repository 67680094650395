<template >
  <div class="pa-4">
    <!-- TITULO -->
    <v-row class="mb-2 ml-0">
      <v-col cols="12" lg="6" class="pa-0">
        <div class="d-flex align-center">
          <div class="text-h5 text-sm-h4">Workspace</div>
          <v-spacer></v-spacer>

          <v-btn-toggle v-model="toggleDiaryType" mandatory rounded>
            <v-btn small>
              <v-icon small>mdi-format-list-checks</v-icon>
              &nbsp;
              <div class="d-none d-sm-flex">Timesheet</div>
            </v-btn>
            <v-btn small>
              <v-icon small>mdi-notebook-edit-outline</v-icon>
              &nbsp;
              <div class="d-none d-sm-flex">Diário</div>
            </v-btn>
          </v-btn-toggle>
          <v-btn icon class=" ml-3" @click="callSendTodayReport()">
            <v-icon>mdi-email-fast-outline</v-icon>
          </v-btn>
          <v-btn icon class="mr-2" @click="openDiaryForm('TGT')">
            <v-icon class="">mdi-bullseye-arrow</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- CONTAINER -->
    <v-row class="">
      <!--EDITOR -->
      <v-col cols="12" lg="6">
        <v-row id="editor-container" class="pa-2">
          <v-textarea
            no-resize
            ref="refText"
            flat
            outlined
            height="450"
            @keyup="callAutoSave()"
            v-model="formData.diary"
            id="editor"
            hide-details
            loading="false"
          />
        </v-row>
        <v-row class="justify-space-between mt-4 px-1">
          <div>
            <v-btn text tile x-small :loading="loadingAutoSave">
              <v-icon class="mr-2">mdi-cloud-check</v-icon>
              {{ this.formData.date | dateFormat(this.formData.date) }}
            </v-btn>
            <span>{{ statusTextAutoSave }}</span>
          </div>

          <v-btn text tile x-small @click="callCreate(false)">
            Salvar <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-row>
      </v-col>

      <!-- LISTAS  -->
      <v-col cols="12" lg="6">
        <v-row>
          <!-- TABLE REMINDER  -->
          <v-col cols="12" md="6">
            <v-card :loading="loadingDiary" style="z-index: 0">
              <div>
                <v-card-title>
                  <div style="cursor: pointer" @click="toggleDiaryReminderLate">
                    <span v-if="countDiary > 0">
                      <v-badge
                        :content="countDiary"
                        style="user-select: none"
                        :color="colorBadge"
                      >
                        Lembrete
                      </v-badge>
                    </span>
                    <span v-else>Lembrete</span>
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!loadingDiary"
                    class="mr-2"
                    type="button"
                    color="blue"
                    icon
                    @click="toggleSearchDiaryReminder()"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!loadingDiary"
                    class="mr-2"
                    type="button"
                    color="blue"
                    icon
                    @click="callListDiariesReminder()"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                  <v-btn
                    class="mr-2"
                    type="button"
                    color="blue"
                    icon
                    @click="openDiaryForm('REM')"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </v-card-title>

                <div
                  class="d-flex justify-space-between align-center"
                  v-if="showSearchDiaryReminder"
                >
                  <v-col cols="9">
                    <v-text-field
                      v-model="searchDiary"
                      append-icon="mdi-magnify"
                      label="Pesquisar"
                      dense
                      single-line
                      autofocus
                      hide-details
                    />
                  </v-col>

                  <v-col cols="3" class="px-4">
                    <v-row justify="end" class="mr-2">
                      <v-btn icon @click="searchDiary = ''">
                        <v-icon color="grey"> mdi-broom </v-icon>
                      </v-btn>
                      <v-btn icon @click="toggleSearchDiaryReminder()">
                        <v-icon color="grey"> mdi-close </v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </div>

                <v-data-table
                  :headers="headersLembrete"
                  :search="searchDiary"
                  :items="
                    showDiaryReminderLate
                      ? listDiaryReminderLate
                      : listDiaryReminder
                  "
                  items-per-page="5"
                  loading-text="Carregando..."
                  progress
                  class="elevation-1"
                >
                  <template v-slot:[`item.diary`]="{ item }">
                    <span>
                      {{ item.diary | truncar(30) }}
                    </span>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(itemMenu, i) in menuLembrete"
                          :key="i"
                        >
                          <v-list-item-content
                            @click="actionsMenuLembrete(itemMenu.title, item)"
                            style="cursor: pointer"
                          >
                            {{ itemMenu.title }}
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>

          <!-- TABLE NOTES -->
          <v-col cols="12" md="6">
            <v-card :loading="loading" style="z-index: 0">
              <div>
                <v-card-title>
                  Notas
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!loading"
                    class="mr-2"
                    type="button"
                    color="blue"
                    icon
                    @click="toggleSearchNote()"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!loading"
                    class="mr-2"
                    type="button"
                    color="blue"
                    icon
                    @click="callListNotes()"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </v-card-title>

                <div
                  class="d-flex justify-space-between align-center"
                  v-if="showSearchNote"
                >
                  <v-col cols="9">
                    <v-text-field
                      v-model="searchNote"
                      append-icon="mdi-magnify"
                      label="Pesquisar"
                      dense
                      single-line
                      autofocus
                      hide-details
                    />
                  </v-col>

                  <v-col cols="3" class="px-4">
                    <v-row justify="end" class="mr-2">
                      <v-btn icon @click="searchNote = ''">
                        <v-icon color="grey"> mdi-broom </v-icon>
                      </v-btn>
                      <v-btn icon @click="toggleSearchNote()">
                        <v-icon color="grey"> mdi-close </v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </div>

                <v-data-table
                  :headers="headersNote"
                  :search="searchNote"
                  :items="listNotes"
                  items-per-page="5"
                  loading-text="Carregando..."
                  progress
                  class="elevation-1"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="showNote(item)">
                      mdi-application-export
                    </v-icon>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- WEEK -->
    <v-row>
      <!-- <v-col cols="12">
        <v-btn @click="getWeekDay('2024-08-19')">GET WEEK</v-btn>
      </v-col> -->

      <v-col cols="1.8" v-for="(week, index) in weekDays" :key="index">
        <v-card class="mx-auto" :color="todayColor(week.date)">
          <!-- dark -->
          <!-- max-height="250" -->
          <v-card-title>
            <span class="text-h6 font-weight-light">{{ week.weekDay }}</span>
            <v-spacer></v-spacer>
            <span class="text-h6 font-weight-light">{{ week.date }}</span>
          </v-card-title>

          <!-- quero filtrar a lista abaixo de acordo com a data do week.date  -->
          <v-list height="140px"  class="overflow-y-auto" dense>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in filterWeekDate(week.date)"
                :key="i"
              >
                <v-list-item-avatar size="16">
                  <v-icon size="16">{{ mapIcon[item.type] }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    item?.rating ? item.rating : item.diary | truncar(20)
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-card-text class="d-flex flex-row justify-space-between">
            <v-rating
              color="yellow darken-3"
              background-color="grey darken-1"
              empty-icon="$ratingFull"
              half-increments
              hover
              dense
            ></v-rating>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" size="16">{{
                  mapIcon["timesheet"]
                }}</v-icon>
              </template>
              <span style="max-height: 120px">Aqui vai todo o conteudo do toltip  
                vAqui vai todo o conteudo do toltip <br>
                Aqui vai todo o conteudo do toltip <br>
                Aqui vai todo o conteudo do toltip <br>
                Aqui vai todo o conteudo do toltip 
                Aqui vai todo o conteudo do toltip 
              </span>
            </v-tooltip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <DiaryForm
      :formData="diary"
      :showForm="showDiaryForm"
      @emitClose="closeDiaryForm"
    />

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteDiary"
      @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteDiary"
    />
  </div>
</template>

<script>
import moment from "moment";
moment.locale("pt-br");

import { WorkspaceManager } from "@/manager/workspace-manager";
const workspaceManager = new WorkspaceManager();
import { DiaryManager } from "../../manager/diary-manager";
const diaryManager = new DiaryManager();
import { GlobalManager } from "@/manager/global-manager";
const globalManager = new GlobalManager();

import DiaryForm from "../Diary/DiaryForm.vue";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";

var timeoutAutoSave;

export default {
  name: "Workspace",

  components: {
    DiaryForm,
    ConfirmDialog,
  },

  data() {
    return {
      formData: {},
      deleteText: "",
      toggleDiaryType: 0,

      // VARIAVEIS LEMBRETE
      diary: {},
      diaries: [],
      listDiaryReminder: [],
      listDiaryReminderLate: [],

      countDiary: 0,
      colorBadge: "red",

      searchDiary: "",
      showDiaryReminderLate: false,
      showSearchDiaryReminder: false,

      toggleDialogDeleteDiary: false,

      showDiaryForm: false,

      // VARIAVEIS NOTES
      listNotes: [],
      searchNote: "",
      showSearchNote: false,

      // CONTROLA AUTO SAVE
      statusTextAutoSave: "",
      loadingAutoSave: false,

      // WEEK
      weekDays: [],
      listContentWeek: [],

      mapIcon: {
        daily: "mdi-format-list-checks",
        reminder: "mdi-notebook-edit-outline",
        movements: "mdi-bullseye-arrow",
        timesheet: "mdi-calendar-clock",
      },

      headersNote: [
        {
          text: "Título",
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: "Ações", value: "actions", sortable: false, align: "end" },
      ],
      headersLembrete: [
        {
          text: "Lembrete",
          align: "start",
          sortable: false,
          value: "diary",
          hide: true,
        },
        { text: "Ações", value: "actions", sortable: false, align: "end" },
      ],

      menuLembrete: [
        { title: "Editar", icon: "mdi-application-export" },
        { title: "Excluir", icon: "mdi-trash" },
      ],
    };
  },

  computed: {
    loading: () => workspaceManager.state.loading,
    loadingDiary: () => diaryManager.state.loading,
  },

  watch: {
    loading(value) {
      globalManager.loading(value);
    },

    toggleDiaryType(val) {
      // console.log(val);
      if (val == 1) {
        // console.log("DAY");
        this.formData.date = moment().format("YYYY-MM-DD");
        this.formData.type = "DAY";
        this.formData.diary = "";
        this.callListWorckspace("DAY");
      } else {
        // console.log("TSH")
        this.formData.diary = "";
        this.formData.type = "TSH";
        this.callListWorckspace("TSH");
      }
    },
  },

  filters: {
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    // ELLIPSES IN TABLE
    truncar(item, maxChar) {
      if (item != undefined && item.length > maxChar) {
        return item.substring(0, maxChar) + "...";
      } else {
        return item;
      }
    },
  },

  async created() {
    this.getWeekDay(moment());
    this.callListWeeks(moment());
    this.callListDiariesReminder();
    this.callListNotes();
    this.callListWorckspace("TSH");
  },

  methods: {
    async callListDiariesReminder() {
      const data = await diaryManager.listDiariesReminder();
      // console.log(data)

      this.listDiaryReminder = data.filter((item) => {
        if (item.date == moment().format("YYYY-MM-DD")){
          return item;
        }
      });

      this.countDiary = 0;
      this.listDiaryReminderLate = data.filter((item) => {
        if (item.date < moment().format("YYYY-MM-DD")) {
          this.countDiary = this.countDiary + 1;
          return item;
        }
      });
    },

    async callListNotes() {
      const data = await workspaceManager.listLastNotesUpdates();
      // console.log(data)

      this.listNotes = data.map((objeto) => objeto);
    },

    async callListWorckspace(pDiaryType) {
      const data = await workspaceManager.list(pDiaryType);
      // console.log(data);

      if (data) {
        this.formData = data;
      } else {
        this.formData.type = pDiaryType;
        this.formData.date = moment().format("YYYY-MM-DD");
      }
    },

    // EDITAR REMINDER
    callEditDiary(pItem) {
      const goToEditDiary = this.$router.resolve({
        path: `/edit-diary/${pItem.id}?types=REM`,
      });
      window.open(goToEditDiary.href, "_blank");
    },

    async deleteItemConfirm() {
      const ret = await diaryManager.delete(this.diary);
      if (ret.ok) {
        this.closeDialogConfirmDeleteDiary();
        this.refreshData();
      }
    },

    callDeleteItem(pItem) {
      this.diary = pItem;
      this.deleteText =
        "Tem certeza de deseja excluir o item: " + pItem.date + " ?";
      this.toggleDialogDeleteDiary = true;
    },

    closeDialogConfirmDeleteDiary() {
      this.diary = {};
      this.toggleDialogDeleteDiary = false;
    },

    // SHOWSEARCH LEMBRETE
    toggleSearchDiaryReminder() {
      this.showSearchDiaryReminder = !this.showSearchDiaryReminder;
      this.searchDiary = "";
    },

    async callSendTodayReport() {
      const data = await workspaceManager.sendTodayReport();
    },

    // SHOWSEARCH NOTA
    toggleSearchNote() {
      this.showSearchNote = !this.showSearchNote;
      this.searchNote = "";
    },

    // NAVEGAR PARA A NOTA
    showNote(pItem) {
      const goToEditNote = this.$router.resolve({
        path: "/edit-note/" + pItem.id,
      });
      window.open(goToEditNote.href, "_blank");
    },

    // ABRE FORMULARIO DE INCLUSÃO
    openDiaryForm(pTypeDiary) {
      this.diary = { type: pTypeDiary };
      this.showDiaryForm = true;
    },

    // FECHAR MODAL INCLUSAO
    closeDiaryForm(pHasUpdate) {
      if (pHasUpdate) {
        this.refreshData();
      }
      this.diary = {};
      this.showDiaryForm = false;
    },

    refreshData() {
      this.callListDiariesReminder();
      this.callListNotes();
    },

    async callCreate(pAutoSave) {
      this.statusTextAutoSave = "Salvando...";
      this.loadingAutoSave = true;

      if (await workspaceManager.create(this.formData, pAutoSave)) {
        this.delayedCompletedAutoSave();
      }
    },

    // CONTROLA DELAY DO KEYUP PARA EFETUAR O AUTOSAVE
    callAutoSave() {
      this.clearMessage();
      timeoutAutoSave = setTimeout(() => {
        this.callCreate(true);
      }, 5000);
    },

    // CONTROLA MENSAGENS DE STATUS AUTOSAVE
    delayedCompletedAutoSave() {
      this.loadingAutoSave = false;
      this.statusTextAutoSave = "Alterações salvas.";
      setTimeout(() => {
        this.statusTextAutoSave = "";
      }, 1000);
      this.clearMessage();
    },

    // AUXILIAR QUE RESETA CONTADOR DE DELAY DO KEYUP
    clearMessage() {
      clearTimeout(timeoutAutoSave);
    },

    // MOSTRAR DIÁRIOS ATRASADOS
    toggleDiaryReminderLate() {
      this.showDiaryReminderLate = !this.showDiaryReminderLate;
      if (this.showDiaryReminderLate == true) {
        this.colorBadge = "grey";
      } else {
        this.colorBadge = "red";
      }
    },

    // AÇÕES DOS MENU DE LEMBRETE
    actionsMenuLembrete(pOptions, pItem) {
      switch (pOptions) {
        case "Editar":
          this.callEditDiary(pItem);
          break;

        case "Excluir":
          // this.editedIndex = diaryManager.state.diaries.indexOf(pItem);
          this.callDeleteItem(pItem);
          break;

        default:
          break;
      }
    },

    // WEEK
    // a PARTIR DE UMA DATA RETORNA O DIA DA SEMANA
    getWeekDay(pDate) {
      const date = moment(pDate);
      const weekDay = date.format("dddd");
      const weekDays = [];
      if (weekDay !== "Monday") {
        const monday = date.startOf("isoWeek");
        for (let i = 0; i < 7; i++) {
          const tempWeekDay = monday.format("ddd").toUpperCase();
          const formattedDate = monday.format("DD-MM-YYYY");
          weekDays.push({ date: formattedDate, weekDay: tempWeekDay });
          monday.add(1, "day");
        }
        this.weekDays = weekDays;
        // console.log(weekDays);
      } else {
        for (let i = 0; i < 7; i++) {
          const tempWeekDay = date.format("dddd");
          const formattedDate = date.format("DD-MM-YYYY");
          weekDays.push({ date: formattedDate, weekDay: tempWeekDay });
          monday.add(1, "day");
        }
      }
    },

    todayColor(date) {
      if (date == moment().format("DD-MM-YYYY")) {
        return "#26c6da";
      } else {
        return "#CCC";
      }
    },

    async callListWeeks(pDate) {
      let listContentFullWeek = [];

      const data = await workspaceManager.listWeek(moment().format("YYYY-MM-DD"));

      const { daily, reminders, movements, timesheet } = data;

      // para cada item da lista reminders quero adicionar um type = reminder
      daily.map((item) => {
        if (item.length == 0) return;
        listContentFullWeek.push({
          ...item,
          type: "daily",
        });
      });
      reminders.map((item) => {
        if (item.length == 0) return;
        listContentFullWeek.push({
          ...item,
          type: "reminder",
        });
      });
      movements.map((item) => {
        if (item.length == 0) return;
        listContentFullWeek.push({
          ...item,
          type: "movements",
        });
      });
      timesheet.map((item) => {
        if (item.length == 0) return;
        listContentFullWeek.push({
          ...item,
          type: "timesheet",
        });
      });
      this.listContentWeek = listContentFullWeek;
    },

    filterWeekDate(pDate) {
      const list = [];
      this.listContentWeek.filter((item) => {
        if (
          moment(item.date).format("DD-MM-YYYY") == pDate &&
          item.type != "daily"
        ) {
          list.push(item);
        }
      });
      return list;
    },
  },
};
</script>
