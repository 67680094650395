<template>
  <v-row justify="center" align="center">
    <v-col cols="12" sm="10">
      <div id="file-drag-drop">
        <form
          class="nupload-form"
          ref="fileform"
          method="POST"
          enctype="multipart/form-data"
        >
          <UploadMessage />

          <v-btn
            ref="btnuploader"
            color="primary"
            class="white--text mt-2"
            :loading="isSelecting"
            :disabled="loadingProgess"
            @click="handleFileImport"
          >
            <v-icon left> mdi-paperclip </v-icon> Escolher Arquivos
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            multiple
            type="file"
            @change="onFileChanged"
          />
          <UploadProgess
            style="width: 100%"
            v-if="this.loadingProgess"
            :uploadPercentage="uploadPercentage"
          />
        </form>
      </div>
    </v-col>
    <v-col cols="12" sm="10">
      <UploadButton
        :files.sync="files"
        :url="url"
        :loadingProgess.sync="loadingProgess"
        :uploadPercentage.sync="uploadPercentage"
        @emitSuccessUpload="successUploadOk()"
      />
    </v-col>
    <v-col cols="12" sm="10">
      <UploadList :files.sync="files" />
    </v-col>
  </v-row>
</template>

<script>
import UploadProgess from "./UploadProgess.vue";
import UploadList from "./UploadList.vue";
import UploadButton from "./UploadButton.vue";
import UploadMessage from "./UploadMessage.vue";
export default {
  name: "Nupload",
  components: {
    UploadProgess,
    UploadList,
    UploadButton,
    UploadMessage,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isSelecting: false,
      dragAndDropCapable: false,
      files: [],
      uploadPercentage: 0,
      loadingProgess: false,
    };
  },

  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable) {
      this.removeDefaultFormBehavior();
      this.addDropEventListener();
    }
  },

  methods: {
    successUploadOk(pValue) {
      // console.log('successUploadOk');
      // console.log(pValue);
      this.$emit("emitSuccessUploadFiles", true);
    },
    updateListFiles(v) {
      // console.log("update files in index", v);
    },
    addDropEventListener() {
      this.$refs.fileform.addEventListener(
        "drop",
        function (e) {
          for (let i = 0; i < e.dataTransfer.files.length; i++) {
            this.files.push(e.dataTransfer.files[i]);
          }
        }.bind(this)
      );
    },

    removeDefaultFormBehavior() {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop",
      ].forEach(
        function (evt) {
          this.$refs.fileform.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );
    },

    determineDragAndDropCapable() {
      var div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },

    // ATIVA LOADIN DE INPUT, ABRE O EXPLORER PARA SELECIONAR O ARQUIVO
    handleFileImport() {
      this.isSelecting = true;
      this.$refs.uploader.value = null;
      this.$refs.uploader.click();
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
    },

    // INCLUINDO ARQUIVO NA LISTA PARA UPLOAD
    onFileChanged(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.files.push(e.target.files[i]);
      }
    },
  },
};
</script>

<style>
.nupload-form {
  margin: auto;
  margin-top: 40px;
  padding: 30px;
  text-align: center;
  border: 1px dashed #ccc;
  border-radius: 15px;
}
</style>
