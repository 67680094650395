<template>
  <v-row justify="center">
    <v-dialog v-model="show" style="width: 100%; z-index: 99999999999" persistent max-width="400px" open-delay="5000">
      <v-card>
        <v-card-text>
          <!-- TOPO FORMULARIO  -->
          <div class="d-flex justify-end"></div>
          <v-card-actions>
            <v-row class="my-4 d-flex justify-space-between">
              <div class="d-flex display-1 align-center">Transferência</div>
              <v-btn fab rounded text tile x-small @click="closeFinancialTransfer(false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>

          <v-form ref="refFinancialTransfer" v-model="valid" lazy-validation onSubmit="return false;">
            <v-row>
              
              <!-- CAMPO TYPE  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <v-combobox
                    v-model="selectedSource"
                    :items="financialAccountsList"
                    item-text="title"
                    item-value="id"
                    label="Selecione a conta de origem"
                  ></v-combobox>
                </v-row>
              </v-col>
              
              <!-- CAMPO TYPE  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <v-combobox
                    v-model="selectedDestination"
                    ref="selectDestination"
                    :items="financialAccountsList"
                    item-text="title"
                    item-value="id"
                    label="Selecione a conta de destino"
                  ></v-combobox>
                </v-row>
              </v-col>

              <!-- INPUT - AMOUNT  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <v-text-field v-model="amount" 
                                label="Valor" 
                                maxlength="30" 
                                required
                                @keyup="amount = formatNumber(amount)"
                    ></v-text-field>
                </v-row>
              </v-col>

              <!-- CAMPO DATA -->
              <v-col cols="12">
                <v-menu
                  ref="menuDateDiary"
                  v-model="menuDateDiary"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="Data"
                      hint="DD/MM/AAAA"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormatted)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menuDateDiary = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              
              <v-col cols="12">
                <v-row justify="end">
                  <v-btn :disabled="!valid" color="success" class="white--text my-5 mr-3" @click="save()">
                    Salvar
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-form>

          <v-list-item-group
            color="primary"
            v-if="this.financialBankStatement?.id"
          >
            <v-list-item
              @click="importBankStatement(this.financialBankStatement)"
            >
              <v-list-item-icon >
                <v-icon @click="clearFinancialBankStatement()">mdi-delete</v-icon>
                <!-- <v-icon>mdi-inbox-arrow-up</v-icon> -->
              </v-list-item-icon>
              <v-list-item-content>
                <div>
                    <span style="float:left; width:50%; text-align: left; font-weight: bold;">{{ formatDate(this.financialBankStatement.date_movement) }}</span>
                    <span style="float:left; width:50%; text-align: right; font-weight: bold;">{{ formatNumberMask(this.financialBankStatement.amount) }}</span>
                </div>
                <div>
                  <div style="padding: 8px 0; " v-if="this.financialBankStatement.title!=null">{{ this.financialBankStatement.title }}</div>
                  <div style="padding: 8px 0; " v-if="this.financialBankStatement.memo!=null">{{ this.financialBankStatement.memo }}</div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  
<script>

import moment from "moment";

import { FinancialMovementCreateManager } from "../../manager/financial-movement-create-manager";
const financiaMovementCreateManager = new FinancialMovementCreateManager();

export default {
  name: "FinancialTransfer",

  props: {
    show: {
      type: Boolean,
    },
    financialBankStatement: {
      type: Object,
      require: false,
    },
  },
  data(vm) {
    return {

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),

      response: null,
      valid: false,
      hasAuth: false,
      showCalendar: false,

      amount: "",
      
      financialAccountsList: [],
      
      menuDateDiary: false,

      selectedSource:"",
      selectedDestination:"",

      item: {},

      listTypes: []

    };
  },
  watch: {
    show() {
      if(this.show){
        if(this.financialBankStatement.type_movement=='credit'){
          this.selectedSource = '';
          this.selectedDestination = this.financialAccountsList.find(item => item.id == this.financialBankStatement.account_id);
        } else {
          this.selectedDestination = '';
          this.selectedSource = this.financialAccountsList.find(item => item.id == this.financialBankStatement.account_id);
        }
        this.amount = (this.financialBankStatement.amount < 0) ? this.financialBankStatement.amount * (-1) : this.financialBankStatement.amount;
        this.dateFormatted = this.formatDate(this.financialBankStatement.date_movement);
        this.date = this.financialBankStatement.date_movement;
      }
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  created(){
    this.callGetAccounts();
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    // formatDate(pValue) {
    //   var vValue = String(pValue);
    //   vValue = moment(vValue, 'YYYY-MM-DD').format("DD/MM/YYYY");
    //   return vValue;
    // },
    formatNumber(pValue) {
      var vValue = String(pValue);
      if (vValue.indexOf('.') >= 0 && vValue.indexOf(',') >= 0) {
          vValue = vValue.replace('.', '');
      }
      vValue = vValue.replace(',', '.');
      return vValue;
    },
    formatNumberMask(pValue) {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
      var vValue = new Intl.NumberFormat(
          'pt-BR', 
          { style: 'decimal', 
            currency: 'BRL', 
            minimumFractionDigits : 2, 
            maximumFractionDigits: 2 
          }).format(pValue);
      return vValue;
    },

    closeFinancialTransfer(pHasUpdate) {
      this.$emit("emitCloseTransferForm", pHasUpdate);
    },
    async callGetAccounts() {
      const data = await financiaMovementCreateManager.listAccounts();
      this.financialAccountsList = data;
    },
    clearFinancialBankStatement(){
      this.financialBankStatement = null;
      this.selectedSource = '';
      this.selectedDestination = '';
      this.amount = '';
      this.dateFormatted = moment().format("DD/MM/YYYY");
      this.date = moment().format("YYYY-MM-DD");

    },
    async save() {
      this.item.source = this.selectedSource.id;
      this.item.destination = this.selectedDestination.id;
      this.item.movement = this.date;
      this.item.amount = this.amount;
      this.item.bank_statement = this.financialBankStatement;
      
      var response = await financiaMovementCreateManager.saveTransfer(this.item);
      console.log(response);
      if(response.status==201){
        this.item = {};
        this.selectedSource = "";
        this.selectedDestination = "";
        this.amount = "";
        if(this.financialBankStatement!=''){
          this.financialBankStatement = {};
          this.closeFinancialTransfer(true);
        } else {
          this.closeFinancialTransfer(false);
        }
      };
    },

    formatNumber(pValue) {
      var vValue = String(pValue);
      if (vValue.indexOf('.') >= 0 && vValue.indexOf(',') >= 0) {
          vValue = vValue.replace('.', '');
      }
      vValue = vValue.replace(',', '.');
      return vValue;
    },

  },
  
};
</script>
  
  