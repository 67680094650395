<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Dashboard</div>
      <v-spacer></v-spacer>
      <v-icon
        v-if="!enableExtraNav"
        large
        class="mr-1"
        v-show="isAuthenticated"
        @click="swapExtraNav()"
      >
        mdi-dots-horizontal-circle
      </v-icon>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" class="pa-0">
        <v-card class="pa-4">
          Olá

          <div v-if="user.is_active">Você está ativo</div>
          <div v-else>Você não está ativo</div>

          <div v-if="user.is_authorized">Você está autorizado</div>
          <div v-else>Você não está autorizado</div>

          <div v-if="user.is_admin">Você é admin</div>
          <div v-else>Você não é admin</div>
        </v-card>
      </v-col>
    </v-row>
    <ExtraNav v-if="enableExtraNav"></ExtraNav>

    <!-- <v-btn
      v-if="!enableExtraNav"
      class="mr-1"
      v-show="isAuthenticated"
      absolute
      top
      right
      elevation="2"
      @click="swapExtraNav()"
    >
      <v-icon>mdi-plus</v-icon>
      <v-icon v-if="enableExtraNav">mdi-minus</v-icon>
    </v-btn> -->
    <v-btn
      v-if="enableExtraNav"
      class="mr-1"
      v-show="isAuthenticated"
      absolute
      top
      right
      elevation=""
      icon
      style="z-index: 2"
      @click="swapExtraNav()"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import ExtraNav from "@/components/Layouts/ExtraNav.vue";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  
  components: {
    ExtraNav,
  },

  data() {
    return {
      enableExtraNav: false,
    };
  },

  computed: {
    ...mapGetters({
      // carregar o módulo de autenticação e o usuário logado
      user: "auth/user",
      isAuthenticated: "auth/isAuthenticated",
    }),
  },
  
  methods: {
    swapExtraNav() {
      this.enableExtraNav = !this.enableExtraNav;
    },
  },
};
</script>
