<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      overlay-opacity="0.8"
      style="width: 100%; z-index: 5000"
      persistent
      max-width="600px"
      open-delay="5000"
    >
      <v-card
        :loading="loading"
        max-height="600px"
      >
        <v-card-text>
          <!-- TOPO FORMULARIO  -->
          <v-card-actions>
            <v-row class="my-4 d-flex justify-space-between align-center">
              <div class="d-flex text-h5 align-center">
                <span>
                  Mover "{{ this.formData.title }}"
                </span>
              </div>
              <v-btn
                fab
                rounded
                text
                tile
                x-small
                @click="toggleForm(false)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>

          <div class="d-flex text-h5 flex-column col-12 pa-0 mt-0 breadcrumb-drives">

          </div>

          <div class="d-flex flex-column col-12 pa-0 breadcrumb-drives">
            <v-breadcrumbs
              class="pa-0"
              link
            >
              <template v-slot>

                <v-breadcrumbs-item
                  @click="callListDrives('')"
                  :disabled="getDisableBreadcrumbs(true)"
                  exact
                >
                  <span :class="!getDisableBreadcrumbs(true) && 'breadcrumbs-decoration'">
                    Meu Drive
                  </span>
                </v-breadcrumbs-item>

                <v-breadcrumbs-item
                  v-for="item in breadcrumbs"
                  :key="item.id"
                  @click="callListDrives(item.id)"
                  :disabled="getDisableBreadcrumbs(item)"
                >
                  <span
                    style="color: #ccc; cursor: default"
                    class="pr-2"
                  >/</span>
                  <span :class="!getDisableBreadcrumbs(item) && 'breadcrumbs-decoration'">
                    {{ item.title }}
                  </span>
                </v-breadcrumbs-item>

              </template>
            </v-breadcrumbs>
          </div>

          <v-list dense>
            <v-list-item-group
              v-model="selectedDriveItem"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in listDrives"
                :key="i"
              >
                <v-list-item-icon class="pl-2 pt-4">
                  <v-icon :color="item.color">mdi-folder</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    @click="callListDrives(item.id)"
                  >
                    <v-icon color="grey lighten-1">mdi-arrow-right</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-form
            ref="refForm"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-row>

              <v-spacer></v-spacer>

              <v-col cols="12">

                <v-row justify="space-between">
                  <div class="ml-2 d-flex text-h6 align-center">
                    <span v-if="selectedDriveItem == null">
                      Mover para {{ breadcrumbs[breadcrumbs.length-1]?.title && `"${breadcrumbs[breadcrumbs.length-1]?.title}"`  }}
                    </span>
                    <span v-else>
                      Mover para {{ listDrives[selectedDriveItem]?.title && `"${listDrives[selectedDriveItem]?.title}"`  }}
                    </span>
                  </div>
                  <v-btn
                    v-if="this.formData.id != undefined"
                    :disabled="!valid || loading || (selectedDriveItem == null && isMyDrive) "
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callUpdate()"
                  >
                    Mover
                  </v-btn>
                </v-row>
              </v-col>

              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { DrivesManager } from "@/manager/drives-manager";
const drivesManager = new DrivesManager();
import { NotesManager } from "@/manager/notes-manager";
const notesManager = new NotesManager();

export default {
  name: "NotesMoveForm",

  props: {
    formData: {
      type: Object,
      require: false,
    },
    showForm: {
      type: Boolean,
    },
  },

  data() {
    return {
      // VALIDAÇÃO DO FORM
      valid: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },
      selectedDrive: {},
      listDrives: [],
      isMyDrive: false,

      selectedDriveItem: null,

      // BREADCRUMBS
      breadcrumbs: [],
    };
  },

  computed: {
    loading: () => notesManager.state.loading,
  },

  watch: {
    showForm(pValue) {
      if (this.formData?.mode && pValue) {
        this.selectedDrive = this.formData.drive_id;
        this.callListBreadcrumbs(this.formData.drive_id);
        this.callListDrives(this.formData.drive_id);
      }
    },
  },

  methods: {
    // ATUALIZA UM ITEM
    async callUpdate() {
      if (!this.$refs.refForm.validate()) return;
      if (this.selectedDriveItem != null) {
        this.formData.drive_id = this.listDrives[this.selectedDriveItem].id;
      } else {
        this.formData.drive_id =
          this.breadcrumbs[this.breadcrumbs.length - 1].id;
      }
      var response = await notesManager.update(this.formData);
      if (response.ok) {
        this.toggleForm(true);
      }
    },

    // BREADCRUMBS
    async callListBreadcrumbs(pDriveId) {
      const data = await drivesManager.breadcrumbs(pDriveId);
      this.breadcrumbs = data;
    },

    // LIST DRIVES
    async callListDrives(pDriveId) {
      this.selectedDrive = pDriveId;
      const data = await drivesManager.list(pDriveId);
      if (pDriveId == "") {
        this.isMyDrive = true;
        this.breadcrumbs = [];
      } else {
        this.isMyDrive = false;
        this.callListBreadcrumbs(pDriveId);
      }
      this.listDrives = data;
    },

    // BREADCRUMB DISABLED
    getDisableBreadcrumbs(pDrive) {
      return pDrive.id === this.selectedDrive ? true : false;
    },

    // UTILIZADA PARA FECHAR O FORMULARIO. ELA RECEBE UM PARAMETRO
    // QUE VALIDA SE EXITE NESCESSIDADE DE ATUALIZAR A TABELA OU NAO
    toggleForm(pHasUpdate) {
      this.$refs.refForm.resetValidation();
      this.callClearForm();
      this.$emit("emitClose", pHasUpdate);
    },

    // USADO PARA LIMPAR O FORMULARIO
    callClearForm() {
      this.$refs.refForm.reset();
    },
  },
};
</script>

<style scoped>
.modal {
  width: 400px;
  padding: 20px;
  margin: 100px auto;
}

.backdrop {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.breadcrumbs-decoration {
  color: #1976d2;
}
</style>