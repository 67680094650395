<template>
 
    <progress
      class="nu-progress"
      max="100"      
      :value.prop="uploadPercentage"
    ></progress>
  
</template>

<script>
export default {
  name: 'UploadProgess',
  props: {
    uploadPercentage: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>
.nu-progress {
  width: 400px;
  margin: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
