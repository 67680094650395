import Vue from 'vue';

const initialState = {
  isActive: false,
  timeLimit: 6000,
  color: '',
  title: '',
  message: '',
};

export class SnackbarManager {
  state;
  constructor() {
    this.state = Vue.observable(initialState);
  }

  isActive() {
    return this.state.isActive;
  }
  setIsActive(value) {
    this.state.isActive = value;
  }
  timeLimit() {
    return this.state.timeLimit;
  }
  title() {
    return this.state.title;
  }
  message() {
    return this.state.message;
  }
  color() {
    return this.state.color;
  }

  handleResponseError(error) {
    if (error?.response?.status === 422) {
      const errors = error.response.data.errors;
      let title = errors[0].title;
      let validationErrors = Object.values(errors[0].meta);
      let message = '<ul>';
      validationErrors.forEach(err => {
        message += `<li>${err[0]} </li>`;
      });
      message += '</ul>';
      this.setWarningMessage(title, message);
    } else if (error?.response?.status === 401) {
      const erro = error.response.data.error;
      if(erro.credentials){
        this.setErrorMessage("Credentials", erro.credentials[0]);
      }else{
        this.setWarningMessage("Status", erro.status[0]);
      }
        
    
      // this.setWarningMessage('Usuário não autenticado');
    } else {
      if (process.env.NODE_ENV === 'development') {
        console.log(error);
      }
      this.setErrorMessage('Falha ao tratar a requisição');
    }
    error.response.data;
  }
  setGlobalMessage(title, message, color) {
    this.state.title = title;
    this.state.message = message;
    this.state.color = color;
    this.state.isActive = true;
  }
  setSuccessMessage(title, message) {
    this.state.title = title;
    this.state.message = message;
    this.state.color = 'success';
    this.state.isActive = true;
  }  
  setWarningMessage(title, message) {
    this.state.title = title;
    this.state.message = message;
    this.state.color = 'warning';
    this.state.isActive = true;
  }
  setErrorMessage(title, message) {
    this.state.title = title;
    this.state.message = message;
    this.state.color = 'error';
    this.state.isActive = true;
  }
}
