<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      overlay-opacity="0.8"
      style="width: 100%; z-index: 5000"
      persistent
      max-width="400px"
      open-delay="5000"
    >
      <v-card :loading="loading">
        <v-card-text>
          <!-- TOPO FORMULARIO  -->
          <div class="d-flex justify-end"></div>
          <v-card-actions>
            <v-row class="my-4 d-flex justify-space-between align-center">
              <div class="d-flex display-1 align-center">
                <span v-if="this.formData.id == undefined">
                  Cadastrar Meta
                </span>
                <span v-if="this.formData.id != undefined">Editar Meta</span>
              </div>
              <v-btn fab rounded text tile x-small @click="toggleForm(false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>

          <v-form
            ref="refForm"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-row>
              <!-- CAMPO TITULO  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <v-text-field
                    v-model="formData.title"
                    label="Título"
                    maxlength="30"
                    required
                    :rules="[rules.required]"
                    autofocus
                  />
                </v-row>
              </v-col>

              <!-- CAMPO TITULO  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <Select
                    v-if="showForm"
                    :list.sync="listAreas"
                    :formData="formData"
                    itemText="title"
                    itemValue="id"
                    label="Selecione uma área"
                    nodeName="area-life"
                    :rules="[rules.required]"
                    :itemSelected.sync="formData.area_id"
                    :mandatory="true"
                  />
                </v-row>
              </v-col>

              <!-- CAMPO COR  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <v-text-field
                    disabled
                    :rules="[rules.required]"
                    v-model="formData.color"
                    label="Cor"
                  ></v-text-field>
                </v-row>
                <v-color-picker
                  v-model="color"
                  :swatches="swatches"
                  :show-swatches="showSwatches"
                  mode="hexa"
                  hide-inputs
                  hide-canvas
                  hide-sliders
                >
                </v-color-picker>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12">
                <v-row justify="end">
                  <v-btn
                    v-if="this.formData.id == undefined"
                    :disabled="!valid || loading"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callCreate()"
                  >
                    Salvar
                  </v-btn>
                  <v-btn
                    v-if="this.formData.id != undefined"
                    :disabled="!valid || loading"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callUpdate()"
                  >
                    Editar
                  </v-btn>
                </v-row>
              </v-col>

              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Select from "@/components/Selects/Select.vue";

import { TargetsManager } from "../../manager/targets-manager";
const targetsManager = new TargetsManager();

export default {
  name: "TargetForm",

  components: {
    Select,
  },

  props: {
    formData: {
      type: Object,
      require: false,
    },
    showForm: {
      type: Boolean,
    },
  },

  data() {
    return {
      listAreas: [],

      valid: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },

      color: " ",
      showSwatches: true,
      swatches: [
        ["#b61827", "#ef5350"],
        ["#0077C2", "#42a5f5"],
        ["#338a3e", "#66bb6a"],
        ["#c79a00", "#ffca28"],
        ["#000000", "#616161"],
      ],
    };
  },

  computed:{
    loading: () => targetsManager.state.loading
  },

  watch: {
    color(pValue, pOldValue) {
      if (pOldValue == null) {
        this.color = " ";
      }
      if (pValue === "#F00000FF") {
        this.formData.color = null;
        this.valid = false;
        return;
      }
      this.formData.color = pValue;
    },
    formData(pValue) {
      if (!!pValue.color) {
        this.color = pValue.color;
        return;
      }
    },
  },

  created() {
    this.callListAreasLife();
  },

  methods: {
    async callListAreasLife() {
      const data = await targetsManager.listAreas();
      this.listAreas = data.map((objeto) => objeto);
    },

    // CREATE
    // CRIA UM NOVO ITEM
    async callCreate() {
      if (!this.$refs.refForm.validate()) return;
      // console.log(this.formData);

      var response = await targetsManager.create(this.formData);
      if (response.ok) {
        this.toggleForm(true);
      }
    },

    // ATUALIZA UM ITEM
    async callUpdate() {
      if (!this.$refs.refForm.validate()) return;
      var response = await targetsManager.update(this.formData);
      if (response.ok) {
        this.toggleForm(true);
      }
    },

    // SERVICES
    // UTILIZADA PARA FECHAR O FORMULARIO. ELA RECEBE UM PARAMETRO
    // QUE VALIDA SE EXITE NESCESSIDADE DE ATUALIZAR A TABELA OU NAO
    toggleForm(pHasUpdate) {
      this.$refs.refForm.resetValidation();
      this.$emit("emitClose", pHasUpdate);
    },

    // USADO PARA LIMPAR O FORMULARIO
    callClearForm() {
      this.$refs.refForm.reset();
    },
  },
  
};
</script>

