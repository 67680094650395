<template>
  <v-container>

    <!-- ajuste da margem do topo-->
    <!-- <div style="height: 25px;"> &nbsp; </div> -->

    <v-row class="mt-1">
      <v-col cols="12">
        <!-- Aqui abaixo vai o top do menu  -->
        <v-row>
          <div class="text-h5 text-md-h4">Criar movimento</div>
          <!-- <v-spacer>h1-spacer>
            <v-btn class="mr-2" type="button" color="blue" icon @click="this.account = ''">
              <v-icon x-large>mdi-bank</v-icon>
            </v-btn> -->
        </v-row>
      </v-col>
    </v-row>

    <v-container fluid>
      <v-form ref="form" v-model="valid" lazy-validation onSubmit="return false;">

        <v-row>

          <v-col cols="12" sm="8" class="mt-4">

            <v-card :loading="loading">

              <v-card-title>
                <!-- TITULO DA CONTA -->
                {{ this.accountSelected ? accountModel.title : 'Selecione a conta' }}
                <v-spacer></v-spacer>
                <!-- LIMPA/TROCA CONTA -->
                <v-btn class="mr-4" type="button" color="blue" icon @click="resetAccount()">
                  <v-icon>mdi-ballot-recount-outline</v-icon>
                </v-btn>
                <v-btn class="mr-4" type="button" color="blue" icon @click="transfer()">
                  <v-icon>mdi-bank-transfer</v-icon>
                </v-btn>
              </v-card-title>

              <v-divider></v-divider>

              <v-container v-if="scenariosListCount != 1" class="text-center">
                <v-icon size="140" color="info">mdi-alert-circle-outline</v-icon>
                <v-spacer></v-spacer>
                <v-container>
                  Você precisa configurar o cenário 
                </v-container>
                <v-btn color="success" to="/scenarios">
                  <v-icon left>mdi-link</v-icon>
                  Cadastro de cenários
                </v-btn>
              </v-container>

              <v-container v-if="financialAccountsListCount == 0" class="text-center">
                <v-icon size="140" color="info">mdi-alert-circle-outline</v-icon>
                <v-spacer></v-spacer>
                <v-container>
                  Você precisa cadastrar uma conta para criar movimentos
                </v-container>
                <v-btn color="success" to="/financial-accounts">
                  <v-icon left>mdi-link</v-icon>
                  Cadastro de contas
                </v-btn>
              </v-container>

              <!-- LIMPA/TROCA CONTA -->
              <v-card class="elevation-0">
                <div v-if="!this.accountSelected" class="elevation-0">
                  <v-card style="min-height: 300px;" class="elevation-0" v-if="financialAccountsListCount > 0 && scenariosListCount == 1">
                    <v-card-text>

                      <!-- CONTA - AUTOCOMPLETE -->
                      <!-- <v-autocomplete v-model="account_autocomplete" ref="account_autocomplete_ref" dense filled rounded
                      :items="financialAccountsListReduce" @change="getAccount"></v-autocomplete> -->

                      <!-- CONTA - LISTA -->
                      <div>
                        <div style="padding: 18px;">
                          <div class="d-flex">
                            <div>
                              <v-icon left style="color: #ccc;">mdi-bank</v-icon>
                            </div>
                            <div> Conta </div>
                            <div class="ml-auto"> Dia de fechamento </div>
                          </div>
                        </div>
                        <div v-for="(item, i) in financialAccountsList" :key="i"
                          style="font-size: 1.4em; cursor: pointer; ">
                          <div class="listColorBackground" style="padding: 18px;" @click="selectAccount(item)">
                            <div class="d-flex">
                              <div>
                                <v-icon v-if="item.type == 'AC'" left>mdi-receipt-text-check-outline</v-icon>
                                <v-icon v-if="item.type == 'CC'" left>mdi-receipt-text-clock-outline</v-icon>
                                <v-icon v-if="item.type == 'MO'" left>mdi-cash</v-icon>
                                <v-icon v-if="item.type == 'PP'" left>mdi-hand-coin</v-icon>
                              </div>
                              <div> {{ item.title }} </div>
                              <div class="ml-auto"> {{ item.closing_day | twoDigits }} <span class="text-caption">{{
                                bestDayForShopping(item.closing_day) }} </span> </div>
                            </div>
                          </div>
                          <v-progress-linear v-if="item.type == 'CC'" color="green lighten-2" buffer-value="100"
                            :value="calcProgressShopping(item.closing_day)" stream></v-progress-linear>
                          <v-progress-linear v-if="item.type != 'CC'" color="black" buffer-value="100" value="0"
                            stream></v-progress-linear>
                        </div>
                      </div>

                      <!-- <v-list>
                      <v-list-item-group color="indigo">
                        <v-list-item v-for="(item, i) in financialAccountsList" :key="i">
                          <v-list-item-icon>
                              <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.title" @click="selectAccount(item)"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list> -->

                    </v-card-text>
                  </v-card>
                </div>
                <div v-else>

                  <v-container class="d-flex justify-space-between d-md-none">
                    <v-btn x-small @click="changeTab('-')" v-if="this.currentTab!='tab-type-movement'">
                      <v-icon class="hidden-xs-and-down" left>mdi-arrow-left-bold-circle</v-icon>
                      <span>
                        anterior
                      </span>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn x-small @click="changeTab('+')" v-if="this.currentTab!='tab-submit' && this.currentTab!='tab-goal'">
                      <span>
                        próximo
                      </span>
                      <v-icon class="hidden-xs-and-down" right>mdi-arrow-right-bold-circle</v-icon>
                    </v-btn>
                  </v-container>

                  <!-- 
                  <v-toolbar flat color="primary" dark>
                    <v-toolbar-title>User Profile</v-toolbar-title>
                  </v-toolbar> 
                  -->

                  <!-- #############################################
                  # TABS
                  ############################################## -->
                  <v-tabs vertical v-model="currentTab">
                    <div class="hidden-sm-and-down">
                      <v-tab href="#tab-type-movement" :disabled="!this.accountSelected">
                        <div class="titleTabs">
                          <v-icon left>
                            mdi-swap-horizontal
                          </v-icon>
                          Movimento
                        </div>
                      </v-tab>
                      <v-tab href="#tab-date" :disabled="!this.accountSelected">
                        <div class="titleTabs">
                          <v-icon left>
                            mdi-calendar
                          </v-icon>
                          Data
                        </div>
                      </v-tab>
                      <v-tab href="#tab-amount" :disabled="!this.accountSelected">
                        <div class="titleTabs">
                          <v-icon left>
                            mdi-cash
                          </v-icon>
                          Valor
                        </div>
                      </v-tab>
                      <v-tab href="#tab-entity" :disabled="!this.accountSelected">
                        <div class="titleTabs">
                          <v-icon left>
                            mdi-shopping-outline
                          </v-icon>
                          Entidade
                        </div>
                      </v-tab>
                      <v-tab href="#tab-category" :disabled="!this.accountSelected">
                        <div class="titleTabs">
                          <v-icon left>
                            mdi-playlist-check
                          </v-icon>
                          Categoria
                        </div>
                      </v-tab>
                      <v-tab href="#tab-submit" :disabled="!this.accountSelected">
                        <div class="titleTabs">
                          <v-icon left>
                            mdi-check
                          </v-icon>
                          Enviar
                        </div>
                      </v-tab>
                      <v-tab href="#tab-goal" :disabled="!this.accountSelected || !this.enableTarget">
                        <div class="titleTabs">
                          <v-icon left>
                            mdi-target
                          </v-icon>
                          Meta financeira
                        </div>
                      </v-tab>
                    </div>

                    <!-- #############################################
                    # TIPO - CRÉDITO / DÉBITO
                    ############################################## -->
                    <v-tab-item value="tab-type-movement">
                      <v-card flat>
                        <v-card-text>
                          Selecione o tipo de movimento
                          <v-row class="pa-4">
                            <v-col cols="12" lg="6">
                              <v-btn color="warning" block class="white--text" @click="debit()" style="padding:40px">
                                <v-icon left v-if="movementModel.type_movement == 'D'">mdi-check-circle-outline</v-icon>
                                Gastei (d)
                              </v-btn>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-btn color="success" block class="white--text" @click="credit()" style="padding:40px">
                                <v-icon left v-if="movementModel.type_movement == 'C'">mdi-check-circle-outline</v-icon>
                                Recebi (c)
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- #############################################
                    # DATA
                    ############################################## -->
                    <v-tab-item value="tab-date">
                      <v-card flat>
                        <v-card-text>

                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="4">
                                <v-text-field v-model="movementDateBr" ref="inputMovement" clearable
                                  label="Data" maxlength="10" @keydown.enter="movementOk()"
                                  @blur="movementOk()">
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="8">
                                <v-btn small @click="setYesterday()">
                                  Ontem (o)
                                </v-btn>
                                <v-btn small @click="setToday()">
                                  Hoje (h)
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="8">
                                <v-date-picker full-width no-title locale="pt-br" v-model="movementModel.movement"
                                  @click:date="changeDate" style="border:1px solid #f2f2f2">
                                </v-date-picker>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <div v-if="accountModel.closing_day">
                                  <h4>Hoje:</h4>
                                  {{ this.today }}
                                  <h4>Dia de fechamento:</h4>
                                  <p>
                                    {{ accountModel.closing_day | twoDigits }}
                                    {{ bestDayForShopping(accountModel.closing_day) }}
                                  </p>
                                  <h4>Dia de pagamento:</h4>
                                  <p>{{ accountModel.payday }}</p>
                                  <h4>Referência:</h4>
                                  <h1><b>{{ reference }}</b></h1>
                                  <v-checkbox v-model="closedBill" @change="setReference" class="my-checkbox">
                                    <template v-slot:label>
                                      <span style="font-size: 12px">Fatura fechada no momento da compra</span>
                                    </template>
                                  </v-checkbox>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>

                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="8">
                                <div v-if="movementModel.situation == 'O'" style="color:red"> * Movimento configurado como
                                  a pagar. Não irá movimentar saldo </div>
                                <div v-if="movementModel.situation == 'R'"> * Movimento configurado como realizado. Irá
                                  movimentar saldo </div>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-btn :class="movementModel.situation == 'O' ? 'success' : 'default'" small
                                  @click="changeSituation()" :disabled="!checkDateIsToday()">
                                  Aberto
                                </v-btn>
                                <v-btn :class="movementModel.situation == 'R' ? 'success' : 'default'"
                                  small @click="changeSituation()" :disabled="!checkDateIsToday()">
                                  Realizado
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>

                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- #############################################
                    # VALOR
                    ############################################## -->
                    <v-tab-item value="tab-amount">
                      <v-card flat>
                        <v-card-text>
                          <!-- https://xeo3.github.io/vuetify-calculator/ -->
                          <!-- https://codepen.io/ivanlim/pen/xYBdbm -->
                          <v-container>
                            <v-row>
                              <v-col class="pa-0" cols="12" sm="6">
                                <v-text-field v-model="amount" ref="inputValue" prefix="R$" clearable
                                  label="Informe o valor" maxlength="15" @keydown.enter="amountOk()"
                                  :rules="floatRules" style="width: 95%;"
                                  @keyup="amount = formatNumber(amount)">
                                </v-text-field>

                                <v-container>

                                  <div class="row">
                                    <a class="keyBoard" @click="sendKey('1')">1</a>
                                    <a class="keyBoard" @click="sendKey('2')">2</a>
                                    <a class="keyBoard" @click="sendKey('3')">3</a>
                                  </div>
                                  <div class="row">
                                    <a class="keyBoard" @click="sendKey('4')">4</a>
                                    <a class="keyBoard" @click="sendKey('5')">5</a>
                                    <a class="keyBoard" @click="sendKey('6')">6</a>
                                  </div>
                                  <div class="row">
                                    <a class="keyBoard" @click="sendKey('7')">7</a>
                                    <a class="keyBoard" @click="sendKey('8')">8</a>
                                    <a class="keyBoard" @click="sendKey('9')">9</a>
                                  </div>
                                  <div class="row">
                                    <a class="keyBoard" @click="sendKey('.')">.</a>
                                    <a class="keyBoard" @click="sendKey('0')">0</a>
                                    <a class="keyBoard" @click="sendKey('b')"> &lt; </a>
                                  </div>
                                </v-container>
                                <v-container>
                                  <v-btn color="info" class="white--text my-5 mr-3" @click="amountOk()">
                                    Ok
                                  </v-btn>
                                </v-container>
                              </v-col>
                              <v-col class="pa-0 pr-2" cols="12" sm="6" align="right">
                                <v-text-field v-model="reference" label="Referência" ref="inputReference" clearable
                                  maxlength="7" :rules="referenceRule" width="50px" style="width: 95%;" right disabled>
                                </v-text-field>
                                <v-text-field type="numeric" v-model="recurrenceQty" @keyup="changeRecurrence(recurrenceQty)" label="Recorrência (+ ou -)"
                                  ref="inputRecurrence" clearable maxlength="2" :rules="numberRule" width="50px"
                                  style="width: 95%;" right>
                                </v-text-field>
                                <v-radio-group v-model="recurrenceType" row style="width: 95%;" right>
                                  <v-radio label="Parcela (P)" value="P"></v-radio>
                                  <v-radio label="Total (T)" value="T"></v-radio>
                                </v-radio-group>

                                <!-- LISTA DE PARCELAS -->
                                <div style="font-size: 1em; width: 95%;" right>
                                  <div style="border-bottom:1px solid #ccc; padding:10px;">
                                    <div class="d-flex">
                                      <div style="width:50px">
                                        Parcela
                                      </div>
                                      <div style="padding-left:20px;">
                                        Referência
                                      </div>
                                      <div class="ml-auto" v-if="recurrenceType == 'P'"> Total </div>
                                    </div>
                                  </div>
                                </div>
                                <div v-for="(item, i) in this.recurrenceQty" :key="i" style="font-size: 1em; width: 95%;" right>
                                  <div style="border-bottom:1px solid #ccc; padding:10px;">
                                    <div class="d-flex">
                                      <div style="width:50px">
                                        {{ i + 1 | twoDigits }}/{{ recurrenceQty | twoDigits }}
                                      </div>
                                      <div style="padding-left:20px;">
                                        {{ getRecurrenceRef(movementModel.reference, i) }}
                                      </div>
                                      <div class="ml-auto" v-if="recurrenceType == 'P'"> {{ amount | roundNumber }} </div>
                                      <div class="ml-auto" v-if="recurrenceType == 'T'"> {{ amount / recurrenceQty | roundNumber }} </div>
                                    </div>
                                  </div>
                                </div>

                                <!-- TOTAL PARCELAS -->
                                <div style="font-size: 1em; width: 95%;" right>
                                  <div style="background-color: #fafafa; padding:10px; font-weight:bold">
                                    <div class="d-flex">
                                      <div style="width:50px">
                                        {{ recurrenceQty }}x
                                      </div>
                                      <div style="padding-left:20px;">
                                        <div class="ml-auto" v-if="recurrenceType == 'P'"> {{ amount | roundNumber }}
                                        </div>
                                        <div class="ml-auto" v-if="recurrenceType == 'T'"> {{ amount / recurrenceQty | roundNumber }} </div>
                                      </div>
                                      <div class="ml-auto" v-if="recurrenceType == 'P'"> = {{ amount * recurrenceQty | roundNumber }} </div>
                                      <div class="ml-auto" v-if="recurrenceType == 'T'"> = {{ amount | roundNumber }} </div>
                                    </div>
                                  </div>
                                </div>

                              </v-col>
                            </v-row>
                          </v-container>

                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- #############################################
                    # ENTIDADE
                    ############################################## -->
                    <v-tab-item value="tab-entity">
                      <v-card flat>
                        <v-card-text>

                          <v-row class="mb-2">
                            <v-combobox class="ml-2" ref="combobox_entity" v-model="title_entity"
                              :items="financialEntitiesList | entitySuggestion(this.listAllEntities, this.movementModel.type_movement, this.financialEntitiesVsCategoriesList)" label="Entidade" @change="getEntity"
                              @keydown.enter="getTitleEntity" clearable>
                            </v-combobox>
                            <v-btn color="info" class="white--text ml-5 mr-5 my-5" @click="getTitleEntity">
                              Ok
                            </v-btn>
                            <v-checkbox v-model="listAllEntities" class="my-checkbox">
                              <template v-slot:label>
                                <span style="font-size: 12px">Listar todos</span>
                              </template>
                            </v-checkbox>
                          </v-row>
                          <v-virtual-scroll :height="300" :items="financialEntitiesList | entitySuggestion(this.listAllEntities, this.movementModel.type_movement, this.financialEntitiesVsCategoriesList)" itemHeight="30">
                            <template v-slot:default="{ item }">
                              <div @click="getEntity(item)" style="cursor: pointer">{{ item }}</div>
                            </template>
                          </v-virtual-scroll>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- #############################################
                    # CATEGORIA
                    ############################################## -->
                    <v-tab-item value="tab-category">
                      
                      <v-card flat v-if="this.movementModel.type_movement!=undefined">
                        <v-card-text>
                          <v-row class="mb-2">
                            <v-combobox class="ml-2" ref="combobox_category" v-model="title_category"
                              :items="financialCategoriesList | categorySuggestion(this.listAllCategories, this.movementModel.type_movement, this.movementModel.entity_id, this.financialEntitiesVsCategoriesList) " label="Categoria" @change="getCategory"
                              @keydown.enter="getTitleCategory" clearable>
                            </v-combobox>
                            <v-btn color="info" class="white--text ml-5 mr-5 my-5" @click="getTitleCategory">
                              Ok
                            </v-btn>
                            <v-checkbox v-model="listAllCategories" class="my-checkbox">
                              <template v-slot:label>
                                <span style="font-size: 12px">Listar todos</span>
                              </template>
                            </v-checkbox>
                          </v-row>
                          <v-virtual-scroll :height="300" :items="financialCategoriesList | categorySuggestion(this.listAllCategories, this.movementModel.type_movement, this.movementModel.entity_id, this.financialEntitiesVsCategoriesList)" itemHeight="30">
                            <template v-slot:default="{ item }">
                              <div @click="getCategory(item)" style="cursor: pointer">{{ item }}</div>
                            </template>
                          </v-virtual-scroll>
                        </v-card-text>
                      </v-card>
                      <div v-if="this.movementModel.type_movement==undefined">
                        <v-container class="text-center">
                          <v-icon size="140" color="info">mdi-alert-circle-outline</v-icon>
                          <v-spacer></v-spacer>
                          <v-container>
                            Selecione um tipo de movimento
                          </v-container>
                        </v-container>
                      </div>

                    </v-tab-item>
                    <!-- #############################################
                    # NOTE / ENVIAR
                    ############################################## -->
                    <v-tab-item value="tab-submit">
                      <v-card flat>
                        <v-card-text>
                          <v-textarea v-model="movementModel.note" outlined no-resize label="Observações"
                            maxlength="200"></v-textarea>
                            <div v-if="this.recurrenceQty>1">
                              <v-alert
                                border="bottom"
                                colored-border
                                type="warning"
                                elevation="2"
                              >
                                <b>Dica:</b> coloque uma observação para os movimentos e um título para o parcelamento.
                              </v-alert>
                            </div>
                            <v-text-field type="text" v-if="this.recurrenceQty>1" v-model="title_recurrence" label="Título do parcelamento"
                              ref="inputTitleRecurrence" clearable maxlength="60" width="50px"
                              style="width: 95%;" right>
                            </v-text-field>
                            <div class="h6 red--text" v-if="!this.validSend()">Registro inválido. Corrija para Salvar</div>
                              <div v-if="this.showIgnoreDuplicate">
                                <v-checkbox v-model="ignoreDuplicate" class="my-checkbox">
                                  <template v-slot:label>
                                    <span style="font-size: 12px" class="h6 red--text">Ignorar registros duplicados</span>
                                  </template>
                                </v-checkbox>

                                <v-data-table
                                  :headers="headersMovementDuplicate"
                                  :items="listFinancialMovementDuplicate"
                                  loading-text="Carregando..."
                                  progress
                                  class="elevation-1"
                                  dense
                                >
                                  <template v-slot:[`item.movement`]="{ item }">
                                    {{ formatDate(item.attributes.movement) }}
                                  </template>
                                  <template v-slot:[`item.amount`]="{ item }">
                                    <span class="red--text" v-if="item.attributes.amount < 0">{{ formatNumberMask(item.attributes.amount) }}</span>
                                    <span v-if="item.attributes.amount > 0">{{ formatNumberMask(item.attributes.amount) }}</span>
                                  </template>
                                  <template v-slot:[`item.actions`]="{ item }">
                                    <!-- <v-icon small class="mr-2" @click="callEditItem(item)">
                                      mdi-pencil
                                    </v-icon> -->
                                    <v-icon small class="mr-2" @click="callDeleteItemDuplicate(item)">
                                      mdi-delete
                                    </v-icon>
                                  </template>
                                </v-data-table>
                                
                              </div>
                            <v-btn
                              :disabled="!valid"
                              color="success"
                              class="white--text my-5 mr-3"
                              @click="callCreate()"
                            >
                              Salvar
                            </v-btn>
                  
                            <span class="h6">CTRL + Enter para enviar</span>
                            
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- #############################################
                    # META
                    ############################################## -->
                    <v-tab-item value="tab-goal">
                      <v-card flat>
                        <v-card-text>
                          
                          <v-row>
                            <v-col cols="12" sm="6" class="mt-4">

                              <v-list dense disabled>

                                <!-- META -->
                                <v-subheader class="font-weight-black text-uppercase">META</v-subheader>
                                <v-list-item-group >
                                  <v-list-item>
                                    <div>
                                      <div class="goal-title">
                                        Categoria: 
                                      </div>
                                      <div class="goal-value">
                                        {{ this.categoryModel.title }}
                                      </div>
                                    </div>
                                  </v-list-item>
                                  <v-list-item>
                                    <div>
                                      <div class="goal-title">
                                        Objetivo: 
                                      </div>
                                      <div class="goal-value">
                                        {{ this.goalValue | abs | roundNumber }}
                                      </div>
                                    </div>
                                  </v-list-item>
                                  <v-list-item>
                                    <div>
                                      <div class="goal-title">
                                        Movimento mensal: 
                                      </div>
                                      <div class="goal-value">
                                        {{ this.monthBalance | abs | roundNumber }}
                                      </div>
                                    </div>
                                  </v-list-item>
                                  <v-list-item>
                                    <div>
                                      <div class="goal-title">
                                        Saldo da meta: 
                                      </div>
                                      <div class="goal-value">
                                        {{ this.goalBalance | roundNumber }}
                                      </div>
                                    </div>
                                  </v-list-item>
                                  <v-list-item>
                                    <div>
                                      <div class="goal-title">
                                        Valor diário: 
                                      </div>
                                      <div class="goal-value">
                                        {{ this.dailyValue | abs | roundNumber }}
                                      </div>
                                    </div>
                                  </v-list-item>
                                </v-list-item-group>
                                <v-list-item-group >
                                  <v-list-item>
                                    <v-card
                                        class="mx-auto"
                                    >
                                      <v-card-title>
                                        <div style="width:100%">
                                          <v-icon
                                          v-if="this.goalIcon=='check'"
                                            large
                                            left 
                                            :color="this.goalColor"
                                          >
                                            mdi-checkbox-marked-circle-outline
                                          </v-icon>
                                          <v-icon
                                            v-if="this.goalIcon=='alert'"
                                            large
                                            left 
                                            :color="this.goalColor"
                                          >
                                          mdi-alert-outline
                                          </v-icon>
                                        </div>
                                        <div class="mt-1" style="font-size:0.8em; width:100%">{{ this.goalMessage }}</div>
                                      </v-card-title>

                                      <v-card-text  style="font-size:1em">
                                        {{ this.goalTip }}
                                      </v-card-text>

                                    </v-card>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>

                            </v-col>
                            <v-col cols="12" sm="6" class="mt-4">

                              <v-list dense disabled>

                                <!-- DATA -->
                                <v-subheader class="font-weight-black text-uppercase">Período</v-subheader>
                                <v-list-item-group >
                                  <v-list-item>
                                    <div>
                                      <div class="goal-title">
                                        Referência: 
                                      </div>
                                      <div class="goal-value">
                                        {{ this.reference }}
                                      </div>
                                    </div>
                                  </v-list-item>
                                  <v-list-item>
                                    <div>
                                      <div class="goal-title">
                                        Valor no período: 
                                      </div>
                                      <div class="goal-value">
                                        R$ xx,00
                                      </div>
                                    </div>
                                  </v-list-item>
                                </v-list-item-group>

                                <!-- CONTA -->
                                <v-subheader class="font-weight-black text-uppercase">Conta</v-subheader>
                                <v-list-item-group >
                                  <v-list-item> 
                                    <div>
                                      <div class="goal-title">
                                        Conta:  
                                      </div>
                                      <div class="goal-value">
                                        {{ this.accountModel.title }}
                                      </div>
                                    </div>
                                  </v-list-item>
                                  <v-list-item> 
                                    <div>
                                      <div class="goal-title">
                                        Saldo da conta:  
                                      </div>
                                      <div class="goal-value">
                                        {{ this.balanceAccount | roundNumber }}
                                      </div>
                                    </div>
                                  </v-list-item>
                                </v-list-item-group>
                                  
                                <!-- ENTIDADE -->
                                <v-subheader class="font-weight-black text-uppercase">Entidade</v-subheader>
                                <v-list-item-group >
                                  <v-list-item> 
                                    <div>
                                      <div class="goal-title">
                                        Entidade:  
                                      </div>
                                      <div class="goal-value">
                                        {{ this.entityModel.title }}
                                      </div>
                                    </div>
                                  </v-list-item>
                                  <v-list-item> 
                                    <div>
                                      <div class="goal-title">
                                        Valor no período 
                                      </div>
                                      <div class="goal-value">
                                        R$ xx,00
                                      </div>
                                    </div>
                                  </v-list-item>
                                </v-list-item-group>

                              </v-list>
                              
                            </v-col>
                          </v-row>

                          <v-btn
                              color="success"
                              class="white--text my-5 mr-3"
                              @click="newRecord()"
                            >
                              Novo movimento (N)
                          </v-btn>

                          <v-btn
                            v-if="this.goalValue==null"
                            color="blue"
                            class="white--text my-5 mr-3"
                            @click="openFinancialMonthlyGoalForm()"
                          >
                            Cadastrar meta
                          </v-btn>

                          <v-btn
                            v-if="this.movementModel.origin_movement!=null"
                            color="blue"
                            class="white--text my-5 mr-3"
                            @click="goToRecurrence()"
                          >
                            Ver parcelamento
                          </v-btn>


                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </div>
              </v-card>
              <v-container v-if="financialAccountsListCount > 0">
                <v-row>
                  <v-col cols="12" md="6" v-if="scenariosListCount == 1">
                    <b> Cenário: </b>
                    {{ scenariosList[0].title }}
                  </v-col>
                  <v-col cols="12" md="6" class="text-right" v-if="financialAccountsListCount > 0 && scenariosListCount == 1">
                    <v-icon right :color="validAccount()">
                      mdi-bank
                    </v-icon>
                    <v-icon right :color="validTypeMovement()">
                      mdi-swap-horizontal
                    </v-icon>
                    <v-icon right :color="validDate()">
                      mdi-calendar
                    </v-icon>
                    <v-icon right :color="validValue()">
                      mdi-cash
                    </v-icon>
                    <v-icon right :color="validEntity()">
                      mdi-shopping-outline
                    </v-icon>
                    <v-icon right :color="validCategory()">
                      mdi-playlist-check
                    </v-icon>
                    <v-icon right>
                      mdi-check
                    </v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

          </v-col>
          
          <v-col cols="12" sm="4" class="mt-4">

            <v-card :loading="loading">
              <v-card-title>
                Resumo do movimento
                <v-spacer></v-spacer>
                <!-- <v-btn class="mr-4" type="button" color="blue" icon @click="resetAccount()">
                  <v-icon>mdi-ballot-recount-outline</v-icon>
                </v-btn> -->
              </v-card-title>

              <v-divider></v-divider>
              
              <div v-if="this.accountSelected">
                
                <v-list dense>
                  <!-- <v-subheader>REPORTS</v-subheader> -->
                  <v-list-item-group
                    color="primary"
                  >
                    <!-- CONTA -->
                    <v-list-item @click="resetAccount()">
                      <v-list-item-icon>
                        <v-icon :color="validAccount()">
                          mdi-bank
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ this.accountModel.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- TIPO MOVIMENTO -->
                    <v-list-item @click="typeMovementFocus">
                      <v-list-item-icon>
                        <v-icon :color="validTypeMovement()">
                          mdi-swap-horizontal
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title><span v-if="this.movementModel.type_movement">{{ (this.movementModel.type_movement) == 'D' ? 'Débito' : 'Crédito' }}</span></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- DATA -->
                    <v-list-item @click="movementFocus">
                      <v-list-item-icon>
                        <v-icon :color="validDate()">
                        mdi-calendar
                      </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ this.movementDateBr }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- VALOR -->
                    <v-list-item @click="amountFocus">
                      <v-list-item-icon>
                        <v-icon :color="validValue()">
                        mdi-cash
                      </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="ml-auto" v-if="recurrenceType == 'P'"> {{ amount * recurrenceQty | roundNumber }} </div>
                          <div class="ml-auto" v-if="recurrenceType == 'T'"> {{ amount | roundNumber }} </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- ENTIDADE -->
                    <v-list-item @click="entityFocus">
                      <v-list-item-icon>
                        <v-icon :color="validEntity()">
                        mdi-shopping-outline
                      </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="validEntity()=='success'">{{ this.entityModel.title }}</v-list-item-title>
                        <v-list-item-title v-if="validEntity()=='warning'">{{ this.title_entity }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- CATEGORIA -->
                    <v-list-item @click="categoryFocus">
                      <v-list-item-icon>
                        <v-icon :color="validCategory()">
                        mdi-playlist-check
                      </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="validCategory()=='success'">{{ this.categoryModel.title }}</v-list-item-title>
                        <v-list-item-title v-if="validCategory()=='warning'">{{ this.title_category }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- SUBMIT -->
                    <v-list-item @click="categoryFocus">
                      <v-list-item-icon>
                        <v-icon :color="(validSend()?'success':'default')">
                        mdi-check
                      </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="validSend()">Ok!</v-list-item-title>
                        <v-list-item-title v-if="!validSend()">Registro inválido</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                  </v-list-item-group>
                </v-list>
                
                <v-divider></v-divider>

                <div v-if="loadingBankStatement">
                  <div class="d-flex justify-center align-stretch pa-10" style="height: 200px;">
                    <v-progress-circular :size="100" :width="4" color="indigo" indeterminate></v-progress-circular>
                  </div>
                </div>
                
                <div v-if="!loadingBankStatement">
                  
                  <v-card-title v-if="this.listFinancialBankStatement.length == 0" class="grey--text text--lighten-1">
                    Nenhum movimento para importar
                  </v-card-title>

                  <v-card-title v-if="this.listFinancialBankStatement.length > 0">
                  Importar movimentos
                  </v-card-title>

                  <v-list-item-group
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in this.listFinancialBankStatement"
                      :key="item.id"
                      @click="importBankStatement(item)"
                    >
                      <v-list-item-icon >
                        <v-icon>mdi-clipboard-arrow-left-outline</v-icon>
                        <!-- <v-icon>mdi-inbox-arrow-up</v-icon> -->
                      </v-list-item-icon>
                      <v-list-item-content>
                        <div>
                            <span style="float:left; width:50%; text-align: left; font-weight: bold;">{{ formatDate(item.date_movement) }}</span>
                            <span style="float:left; width:50%; text-align: right; font-weight: bold;">{{ formatNumberMask(item.amount) }}</span>
                        </div>
                        <div>
                          <div style="padding: 8px 0; " v-if="item.title!=null">{{ item.title }}</div>
                          <div style="padding: 8px 0; " v-if="item.memo!=null">{{ item.memo }}</div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </div>
              </div>

              <div v-if="!this.accountSelected">
                <v-container>
                  Selecione uma conta
                </v-container>
              </div>
            </v-card>
            
            <!--
              <h2>TAB</h2>
              {{ this.currentTab }}
              <h2>Conta</h2>
              {{ (this.accountSelected) ? 'Conta selecionada' : 'Conta NÃO selecionada' }} <br>
              {{ this.accountModel }}<br>
              {{ this.account_autocomplete }}<br>
              <h2>Entidade</h2>
              {{ this.title_entity }}<br>
              {{ this.entityModel }}
              <h2>Categoria</h2>
              {{ this.title_category }}<br>
              {{ this.categoryModel }}
              <h2>Movimento</h2>
              {{ this.movementDateBr }}<br>
              {{ this.movementModel }} 
            -->
              
          </v-col>

        </v-row>
      </v-form>
    </v-container>

    <FinancialMonthlyGoalForm
      :formData="FinancialMonthlyGoal"
      :showForm="showFinancialMonthlyGoalForm"
      @emitClose="closeFinancialMonthlyGoalForm"
    />

    <ConfirmeDialog :title="deleteText" :dialog="toggleDialogDelete" @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDelete" />

      <FinancialTransfer
      :isLoading="loading"
      :show="showTransfer"
      :financialBankStatement="this.financialBankStatement"
      @emitCloseTransferForm="closeTransferForm"
    />
    
  </v-container>
</template>
    
<script>

import { SnackbarManager } from "@/manager/snackbar-manager";
const snackbarManager = new SnackbarManager();

import moment from "moment";

import ConfirmeDialog from "@/components/Dialogs/ConfirmDialog.vue";
import FinancialMonthlyGoalForm from "@/views/FinancialMonthlyGoal/FinancialMonthlyGoalForm.vue";

import { ScenarioManager } from "@/manager/scenario-manager";
const scenarioManager = new ScenarioManager();

import { FinancialMovementCreateManager } from "../../manager/financial-movement-create-manager";
const financiaMovementCreateManager = new FinancialMovementCreateManager();
import FinancialTransfer from "./FinancialTransfer.vue";

import { FinancialBankStatementManager } from "@/manager/financial-bank-statement-manager";
const financialBankStatementManager = new FinancialBankStatementManager();

import { FinancialMovementManager } from "@/manager/financial-movement-list-manager";
const financialMovementManager = new FinancialMovementManager();

export default {

  name: "FinancialMovementCreate",
  components: {
    // FinancialMovementCreateForm,
    FinancialTransfer,
    ConfirmeDialog ,
    snackbarManager,
    FinancialMonthlyGoalForm
  },
  created() {
    this.resetData();
    window.addEventListener('keypress', this.onKeyPress);
    window.addEventListener('focus', this.getFocus);
    // setTimeout(() => {
    //   console.log(this.$refs.account_autocomplete_ref.$refs.input.focus());
    //   this.$refs.account_autocomplete_ref.$refs.input.focus();
    // }, 2000);
    this.today = moment().startOf('day').format("DD/MM/YYYY");
  },
  beforeDestroy() {
    window.removeEventListener('keypress', this.onKeyPress);
    window.removeEventListener('focus', this.getFocus);
  },
  computed: {
    loading: () => financiaMovementCreateManager.state.loading,
    loadingBankStatement: () => financialBankStatementManager.state.loading,
    accountSelected() {
      let ret = true;
      if (this.accountModel == undefined) {
        ret = false;
      } else {
        if (Object.keys(this.accountModel).length == 0) {
          ret = false;
        };
      };
      return ret;
    },
  },
  watch: {
    // date_movement() {
    //   if (this.accountSelected) {
    //     this.currentTab = 'tab-amount';
    //     this.amountFocus();
    //   }
    // }
    currentTab(pTab) {
      // console.log(pTab);
      // if (pTab === undefined) {
      //   return;
      // }
      // if(pTab=='tab-type-movement'){
      //   this.selectedItem = 1;
      // }
      // if(pTab=='tab-date'){
      //   this.selectedItem = 2;
      // }
      // if(pTab=='tab-amount'){
      //   this.selectedItem = 3;
      // }
      // if(pTab=='tab-entity'){
      //   this.selectedItem = 4;
      // }
      // if(pTab=='tab-category'){
      //   this.selectedItem = 5;
      // }
      // if(pTab=='tab-submit'){
      //   this.selectedItem = 6;
      // }
      // if(pTab=='tab-goal'){
      //   this.selectedItem = 7;
      // }
    },
    financialAccountsList() {
      let list = this.financialAccountsList.map(objeto => objeto.title);
      this.financialAccountsListReduce = list;
    },
    // financialEntitiesList() {
    //   let list = this.financialEntitiesList.map(objeto => objeto.title);
    //   // console.log(list);
    //   this.financialEntitiesListReduce = list;
    // },
    // financialCategoriesList() {
    //   let list = this.financialCategoriesList.map(objeto => objeto.title);
    //   this.financialCategoriesListReduce = list;
    // }
  },
  filters: {
    abs(pVal){
      return Math.abs(pVal);
    },
    twoDigits(pVal) {
      if (pVal == null || isNaN(pVal) || pVal == '') { return '' };
      var vVal = '0' + pVal;
      return vVal.substring(vVal.length - 2);
    },
    roundNumber(pVal) {
      if (pVal == null || isNaN(pVal) || pVal == '') { return '' };
      var vVal = pVal * 1;
      return vVal.toFixed(2);
    },
    entitySuggestion(pEntities, pListAllEntities, pType, pEntitiesVsCategories){
      let vEntities = pEntities;
      if(!pListAllEntities){
        if(pEntitiesVsCategories!=undefined){
          vEntities = vEntities.filter((el) => {
            return pEntitiesVsCategories.some((f) => {
              return f.entity_id === el.id && f.type_movement === pType;
            });
          });
        }
      }
      let list = vEntities.map(objeto => objeto.title);
      return list;
    },
    categorySuggestion(pCategories, pListAllCategories, pType, pEntity, pEntitiesVsCategories){
      let vCategories = pCategories;
      let list;
      if(pType!=undefined){
        vCategories = vCategories.filter(objeto => objeto.type == pType);
      }
      if(!pListAllCategories){
        if(pEntity!= undefined && pEntitiesVsCategories!=undefined){
          vCategories = vCategories.filter((el) => {
            return pEntitiesVsCategories.some((f) => {
              return f.category_id === el.id && f.entity_id === pEntity;
            });
          });
        }
      }
      list = vCategories.map(objeto => objeto.title);
      if( list.length == 0){
        list = pCategories.map(objeto => objeto.title);
      }
      return list;
    }
    
  },
  data() {
    return {

      showTransfer: false,

      // FORM META
      FinancialMonthlyGoal: {},
      showFinancialMonthlyGoalForm: false,

      // TAB
      currentTab: 'tab-type-movement',
      valid: true,
      today: '',

      // RESUMO
      // selectedItem: 1,

      // MOVEMENT
      movementModel: {},
      ignoreDuplicate : false,
      showIgnoreDuplicate : false,

      financialMovement:{},

      listFinancialMovementDuplicate: [],
      headersMovementDuplicate: [
        {
          text: "Movimento",
          align: "start",
          value: "movement",
          sortable: true,
        },
        {
          text: "Ordem",
          align: "start",
          sortable: false,
          value: "order",
        },
        {
          text: "Entidade",
          align: "start",
          sortable: false,
          value: "included.entity_id.attributes.title",
        },
        {
          text: "Categoria",
          align: "start",
          sortable: false,
          value: "included.category_id.attributes.title",
        },
        {
          text: "Valor",
          align: "end",
          sortable: false,
          value: "amount",
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],

      /*
      movementModel = {
        'account_id' : '',
        'type_movement' : '',
        'movement' : '',
        'situation' : '',
        'reference' : '',
        'amount' : '',
        'entity_id' : '',
        'category_id' : '',
        'note' : '',
      };
      */
      // SCENARIO
      scenariosList: [],
      scenariosListCount: -1,

      // ACCOUNT
      financialAccountsList: [],
      financialAccountsListReduce: [],
      financialAccountsListCount: -1,
      account_autocomplete: '',
      accountModel: {},
      // DATE
      movementDateBr: '',
      // AMOUNT
      amount: '',
      reference: '',
      closedBill: false,
      floatRules: [
        v => !!v || 'Campo obrigatório',
        v => /^\d+(\.\d{1,2})?$/.test(v) || 'Número inválido (formato: 99999.99)'
      ],
      referenceRule: [
        v => !!v || 'Campo obrigatório',
        v => /^\d{4}-\d{2}$/.test(v) || 'Número inválido (formato: 20XX.XX)'
      ],
      numberRule: [
        v => !!v || 'Campo obrigatório',
        // v => /^\d{4}-\d{2}$/.test(v) || 'Número inválido (formato: 20XX.XX)'
      ],
      title_recurrence: '',
      recurrenceQty: 1,
      recurrenceType: 'P',
      // ENTITY
      title_entity: '',
      financialEntitiesList: [],
      financialEntitiesListReduce: [],
      financialEntitiesListCount: -1,
      entityModel: {},
      listAllEntities: false,
      newEntity: true,
      // CATEGORY
      title_category: '',
      categoryModel: {},
      financialCategoriesList: [],
      financialCategoriesListReduce: [],
      financialCategoriesListCount: -1,
      listAllCategories: false,
      newCategory:true,
      // CATEGORY vs. ENTITIES
      financialEntitiesVsCategoriesList: [],
      financialEntitiesVsCategoriesListCount: -1,

      headersEntity: [
        {
          text: "Entidade",
          align: "start",
          value: "title",
          sortable: true,
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "center"
        },
      ],
      headersCategory: [
        {
          text: "Categoria",
          align: "start",
          value: "title",
          sortable: true,
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "center"
        },
      ],

      // NOTES
      note: '',

      toggleDialogDelete: false,
      deleteText: 'Tem certeza de deseja excluir este item ?',
      deleteManager : '',

      // TARGET
      enableTarget: false,
      balanceAccount: null,
      dailyValue: null,
      goalValue: null,
      goalBalance: null,
      monthBalance: null,
      goalColor: null,
      goalIcon: 'check',
      goalMessage: null,
      goalTip: null,

      // BANK STATEMENT

      listFinancialBankStatement : [],
      financialBankStatement : {},

    };
  },

  methods: {

    changeTab(pDirection){
      let vTab = this.currentTab;
      let vOrderTab = 0;
      if(vTab=='tab-type-movement'){ vOrderTab = 1;}
      if(vTab=='tab-date'){ vOrderTab = 2;}
      if(vTab=='tab-amount'){ vOrderTab = 3;}
      if(vTab=='tab-entity'){ vOrderTab = 4;}
      if(vTab=='tab-category'){ vOrderTab = 5;}
      if(vTab=='tab-submit'){ vOrderTab = 6;}
      if(vTab=='tab-goal'){ vOrderTab = 7;}
      if(pDirection=='-' && vOrderTab>1){
        vOrderTab--;
      }
      if(pDirection=='+'&& vOrderTab<7){
        vOrderTab++;
      }
      if(vOrderTab == 1) { this.currentTab ='tab-type-movement';}
      if(vOrderTab == 2) { this.currentTab ='tab-date';}
      if(vOrderTab == 3) { this.currentTab ='tab-amount';}
      if(vOrderTab == 4) { this.currentTab ='tab-entity';}
      if(vOrderTab == 5) { this.currentTab ='tab-category';}
      if(vOrderTab == 6) { this.currentTab ='tab-submit';}
      if(vOrderTab == 7) { this.currentTab ='tab-goal';}
    },

    // ABRIR MODAL PARA CRIAR DOCUMENTO
    transfer() {
      if(this.listFinancialBankStatement.length>0){
        this.financialBankStatement = this.listFinancialBankStatement[0]; 
      }
      this.showTransfer = !this.showTransfer;
    },

        // FECHAR MODAL DE CRIAR DOCUMENTO
    closeTransferForm(pHasUpdate) {
      this.showTransfer = false;
      if(pHasUpdate){
        this.callGetBankStatement();
      }
    },

    getFocus(e) {
      // console.log(e);
      if (this.accountSelected) {
        snackbarManager.setSuccessMessage('Bem vindo de volta!');
      }
    },
    onKeyPress(e) {
      // console.log('KEYPRESS EVENT', e)
      // console.log('KEYCODE', e.code)
      if (this.accountSelected) {
        if (this.currentTab == 'tab-type-movement' && (e.code == 'KeyD' || e.code == 'KeyG')) {
          this.debit();
        }
        if (this.currentTab == 'tab-type-movement' && (e.code == 'KeyC' || e.code == 'KeyR')) {
          this.credit();
        }
        if (this.currentTab == 'tab-date' && e.code == 'KeyH') {
          this.setToday();
        }
        if (this.currentTab == 'tab-date' && e.code == 'KeyO') {
          this.setYesterday();
        }
        if (this.currentTab == 'tab-amount' && e.key == '+') {
          e.preventDefault();
          this.setRecurrence('+');
        }
        if (this.currentTab == 'tab-amount' && e.key == '-') {
          e.preventDefault();
          this.setRecurrence('-');
        }
        if (this.currentTab == 'tab-amount' && e.code == 'KeyT') {
          e.preventDefault();
          this.setRecurrence('T');
        }
        if (this.currentTab == 'tab-amount' && e.code == 'KeyP') {
          e.preventDefault();
          this.setRecurrence('P');
        }
        if (this.currentTab != 'tab-submit' && e.code == 'Enter' && e.ctrlKey == true) {
          this.currentTab = 'tab-submit';
        }
        if (this.currentTab == 'tab-submit' && e.code == 'Enter' && e.ctrlKey == true) {
          this.callCreate();
        }
        if (this.currentTab == 'tab-goal' && (e.code == 'Enter' || e.code== 'KeyN')) {
          this.newRecord();
        }
      }
    },

    sendKey(pKey) {
      // console.log(pKey);
      // console.log(this.amount);
      // console.log(this.$refs.inputValue.$refs.input.value);
      if (this.amount == null) {
        this.amount = '';
      }
      if (pKey == 'b') {
        // console.log('backspace');
        this.amount = this.amount.substring(0, this.amount.length - 1);
      } else {
        this.amount = this.amount + pKey;
      }
      if (this.amount == '') {
        this.$refs.inputValue.$refs.label.innerText = 'Informe o valor';
      } else {
        this.$refs.inputValue.$refs.label.innerText = '';
      }
      this.$refs.inputValue.$refs.input.value = this.amount;
      this.movementModel.amount = this.amount;
    },
    calcDayForShopping(pDay) {
      // console.warn('Dia : ' + pDay);
      // hoje
      var td = moment().startOf('day');
      // console.log('td', td);
      // dia informado
      var m = moment().month();
      var a = moment().year();
      var cDay = new Date(a, m, pDay);
      cDay = moment(cDay).startOf('day');
      // console.log('cDay 1', cDay);
      if (td > cDay) {
        cDay = moment(cDay).add(1, "month");
      }
      // console.log('cDay 2', cDay);
      var calc = cDay.diff(td, 'days');
      // console.log('calc', calc);
      return parseInt(calc);
    },
    bestDayForShopping(pDay) {
      if (pDay == null) { return '' };
      var calc = this.calcDayForShopping(pDay);
      if (calc == 0) {
        return '(Hoje)';
      }
      if (calc == 1) {
        return '(Amanhã)';
      }
      return '(' + calc + ' dias)';
    },
    calcProgressShopping(pDay) {
      if (pDay == null) { return '' };
      var calc = this.calcDayForShopping(pDay);
      var diff = 31 - calc;
      var perc = parseInt((diff * 100) / 31);
      // console.log(perc+'%');
      return perc;
    },
    debit() {
      if (this.movementModel.type_movement == 'C') {
        this.resetType();
      }
      this.movementModel.type_movement = 'D';
      this.movementFocus();
    },
    credit() {
      if (this.movementModel.type_movement == 'D') {
        this.resetType();
      }
      this.movementModel.type_movement = 'C';
      this.movementFocus();
    },
    getRecurrenceRef(pRef, pAdd) {
      var addRef = moment(pRef, 'YYYY-MM').startOf('day').add(pAdd, "M").format("YYYY-MM");
      return addRef;
    },
    setToday() {
      this.movementModel.movement = moment().startOf('day').format("YYYY-MM-DD");
      this.movementModel.posting = this.movementModel.movement;
      this.movementDateBr = moment().startOf('day').format("DD/MM/YYYY");
      this.setReference();
      this.setSituation(this.movementModel.movement);
      this.amountFocus();
    },
    setYesterday() {
      this.movementDateBr = moment().startOf('day').add(-1, "days").format("DD/MM/YYYY");
      this.movementModel.movement = moment().startOf('day').add(-1, "days").format("YYYY-MM-DD");
      this.movementModel.posting = this.movementModel.movement;
      this.setReference();
      this.setSituation(this.movementModel.movement);
      this.amountFocus();
    },
    changeSituation() {
      if (this.movementModel.situation == 'O') {
        // console.log('Registrado');
        this.movementModel.situation = 'R';
        this.$forceUpdate();
        return;
      }
      if (this.movementModel.situation == 'R') {
        // console.log('Aberto');
        this.movementModel.situation = 'O';
        this.$forceUpdate();
        return;
      }
    },
    checkDateIsToday() {
      var today = this.today;
      // console.log('today', today);
      var dateMov = moment(this.movementModel.movement, "YYYY-MM-DD").startOf('day').format("DD/MM/YYYY");
      // console.log('dateMov',dateMov);
      if (today == dateMov) {
        return true;
      }
      return false;
    },
    // validAmount(pValue) {
    //   if(pValue==undefined){
    //     return;
    //   }
    //   if(!isNaN(pValue)){
    //     return pValue.replace(',', '.');
    //   } else {
    //     return pValue;
    //   }
    // },
    formatDate(pValue) {
      var vValue = String(pValue);
      vValue = moment(vValue, 'YYYY-MM-DD').format("DD/MM/YYYY");
      return vValue;
    },
    formatNumber(pValue) {
      var vValue = String(pValue);
      if (vValue.indexOf('.') >= 0 && vValue.indexOf(',') >= 0) {
          vValue = vValue.replace('.', '');
      }
      vValue = vValue.replace(',', '.');
      return vValue;
    },
    formatNumberMask(pValue) {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
      var vValue = new Intl.NumberFormat(
          'pt-BR', 
          { style: 'decimal', 
            currency: 'BRL', 
            minimumFractionDigits : 2, 
            maximumFractionDigits: 2 
          }).format(pValue);
      return vValue;
    },
    amountOk() {
      // console.log(this.$refs.form);
      // console.log(this.$refs.inputValue);
      if (this.$refs.form.validate()) {
        this.currentTab = 'tab-entity';
        this.movementModel.amount = this.amount;
        this.entityFocus();
      }
    },
    setSituation(pDate) {
      var moment_date = moment(pDate, 'YYYY/MM/DD').startOf('day');
      if (!moment_date.isValid()) {
        // console.alert(pDate);
        // console.alert('data inválida');
        return false;
      }
      if (moment_date.isAfter(moment().startOf('day'))) {
        this.movementModel.situation = 'O';
        snackbarManager.setWarningMessage('Esse registro foi configurado para o futuro e não irá movimentar saldo.');
      } else {
        if (this.movementModel.situation == 'O') {
          snackbarManager.setWarningMessage('Esse registro movimentará saldo.');
        }
        this.movementModel.situation = 'R';
      }
    },
    movementOk() {
      var date = moment(this.movementDateBr, "DD/MM/YYYY").startOf('day');
      if (moment(date).isValid()) {
        this.movementDateBr = moment(date).format('DD/MM/YYYY');
        this.movementModel.movement = moment(date).format('YYYY-MM-DD');
        this.movementModel.posting = this.movementModel.movement;
        this.setReference();
        this.setSituation(this.movementModel.movement);
        this.amountFocus();
      }
    },
    changeDate(pDate) {
      console.log(pDate);
      // this.$set(this.done, 0, true)
      // alert(`You have just double clicked the following date: ${date}`)
      var date = moment(pDate, "YYYY-MM-DD").startOf('day');
      if (moment(date).isValid()) {
        this.movementDateBr = moment(date).format('DD/MM/YYYY');
        this.movementModel.movement = moment(date).format('YYYY-MM-DD');
        this.movementModel.posting = this.movementModel.movement;
        this.setReference();
        this.setSituation(pDate);
        this.amountFocus();
      }
      this.$forceUpdate();
    },
    typeMovementFocus() {
      this.currentTab = 'tab-type-movement';
    },
    movementFocus() {
      this.currentTab = 'tab-date';
      setTimeout(() => {
        this.$refs.inputMovement.$refs.input.focus();
      }, 200);
    },
    amountFocus() {
      this.currentTab = 'tab-amount';
      setTimeout(() => {
        this.$refs.inputValue.$refs.input.focus();
      }, 200);
    },
    entityOk() {
      // setTimeout(() => {
        this.currentTab = 'tab-category';
      // }, 250);
      // setTimeout(() => {
        this.categoryFocus();
      // }, 300);
      setTimeout(() => {
        if(this.$refs.combobox_category.$options.propsData.items.length==1){
          let oneCategory = this.$refs.combobox_category.$options.propsData.items[0];
          this.title_category = oneCategory;
          this.getCategory(oneCategory, false);
        }
      }, 500);
    },
    categoryOk() {
      setTimeout(() => {
        this.currentTab = 'tab-submit';
      }, 250);
    },
    entityFocus() {
      this.currentTab = 'tab-entity';
      setTimeout(() => {
        this.$refs.combobox_entity.$refs.input.focus();
      }, 200);
    },
    categoryFocus() {
        this.currentTab = 'tab-category';
      setTimeout(() => {
        this.$refs.combobox_category.$refs.input.focus();
      }, 200);
    },
    resetData(pTab) {
      if(pTab!=''){
        this.currentTab = pTab;
      }
      this.movementModel = {};
      if(this.accountModel.type=='CC'){
        this.movementModel.type_movement = 'D';
      }
      this.reference = '';
      this.recurrenceQty = 1;
      this.recurrenceType = 'P';
      this.account_autocomplete = '';
      this.categoryModel = {};
      this.entityModel = {};
      this.title_entity = '';
      this.title_category = '';
      this.title_recurrence = '';
      this.amount = '';
      this.movementDateBr = '';
      this.showIgnoreDuplicate = false;
      this.ignoreDuplicate = false;
      this.listFinancialMovementDuplicate = [];
      this.financialMovement = {};
      this.resetTarget();      
      this.callGetScenarios();
      this.callGetAccounts();
      if(this.newEntity){
        this.callGetEntities();
      }
      if(this.newCategory){
        this.callGetCategories();
      }
      if(this.newCategory||this.newEntity){
        this.callGetEntitiesVsCategories();
      }
    },
    resetType() {
      this.movementModel = {};
      this.movementDateBr = '';
      this.title_entity = '';
      this.entityModel = {};
      this.title_category = '';
      this.categoryModel = {};
      this.amount = '';
      snackbarManager.setWarningMessage('Os valores informados foram resetados.');
    },
    resetAccount(){
      this.accountModel = {};
      this.listFinancialBankStatement =[];
      this.resetData();
    },
    resetTarget(){
      this.enableTarget = false;
      this.balanceAccount = null;
      this.dailyValue = null;
      this.goalBalance = null;
      this.goalValue = null;
      this.monthBalance = null;
      this.goalColor = null;
      this.goalIcon = 'check';
      this.goalMessage = null;
      this.goalTip = null;
    },
    getAccount(account) {
      // console.log('getAccount', account);
      for (const [key, value] of Object.entries(this.financialAccountsList)) {
        for (const [key2, value2] of Object.entries(value)) {
          if (key2 == 'title' && value2 == account) {
            // console.log(this.accountModel);
            this.accountModel = value;
          };
        }
      }
    },
    setReference() {
      if (this.movementModel.hasOwnProperty('movement')) {

        var accountType = this.accountModel.type;
        var accountClosingDay = this.accountModel.closing_day;
        var accountPayday = this.accountModel.payday;
        var movement = moment(this.movementModel.movement, 'YYYY-MM-DD').startOf('day');
        var dateBillRef = '';

        if (accountType == '' || !movement.isValid()) {
          this.movementModel.reference = '';
          this.reference = '';
          return;
        }
        dateBillRef = movement.format('YYYY-MM');

        var movementDay = parseInt(movement.format('D'));

        if (accountType == 'CC' && accountClosingDay > 0 && accountPayday > 0) {
          // fechamento e pagamento no mesmo mês
          if (accountClosingDay < accountPayday) {
            // movimento antes de fechar fatura = movimento registrado para o mesmo mês
            if (movementDay <= accountClosingDay && !this.closedBill) {
              dateBillRef = movement.format('YYYY-MM');
            }
            // movimento depois de fechar fatura = movimento registrado para o mes seguinte
            else {
              dateBillRef = movement.add(1, 'M').format('YYYY-MM');
            }
          }
          // fechamento em um mês e pagamento no outro mês
          else {
            // movimento antes de fechar fatura = movimento registrado para o mês seguinte
            if (movementDay <= accountClosingDay && this.closedBill) {
              dateBillRef = movement.add(1, 'M').format('YYYY-MM');
            }
            // movimento depois de fechar fatura = movimento registrado para 2 meses a frente
            // será um período curto entre o final do mês e o início do mês.
            else {
              dateBillRef = movement.add(2, 'M').format('YYYY-MM');
            }
          }
        }
        this.movementModel.reference = dateBillRef;
        this.reference = dateBillRef;
      }
    },
    changeRecurrence(pRecurrence) {
      if (isNaN(pRecurrence)) {
        this.recurrenceQty = 1;
      } else {
        this.recurrenceQty = parseInt(pRecurrence);
      }
    },
    setRecurrence(pKey) {
      if (isNaN(this.recurrenceQty)) {
        this.recurrenceQty = 1;
      }
      if (pKey == '+') {
        this.recurrenceQty++;
      }
      if (pKey == '-') {
        if (this.recurrenceQty > 1) {
          this.recurrenceQty--;
        }
      }
      if (pKey == 'T') {
        this.recurrenceType = 'T'
      }
      if (pKey == 'P') {
        this.recurrenceType = 'P'
      }
    },
    getTitleEntity(){
      setTimeout(() => {
        this.$refs.combobox_entity.isMenuActive = false;
      }, 100);
      if(this.title_entity==''){
        this.entityOk();
      } else {
        this.getEntity(this.title_entity);  
      }
    },
    getEntity(entity, next) { 
      // console.log('getEntity', entity);
      // console.log('next', next);
      this.categoryModel = {};
      this.movementModel.category_id = '';
      this.title_category = '';
      if(entity==null){
        this.entityModel = {};
        this.movementModel.entity_id = '';
      }
      setTimeout(() => {
        this.$refs.combobox_entity.isMenuActive = false;
      }, 200);
      if (entity) {
        this.entityModel = {};
        this.movementModel.entity_id = ''; 
        for (const [key, value] of Object.entries(this.financialEntitiesList)) {
          for (const [key2, value2] of Object.entries(value)) {
            if (key2 == 'title' && value2.toUpperCase() == entity.toUpperCase()) {
              this.entityModel = value;
              this.movementModel.entity_id = value.id;
              this.title_entity = value.title;
            };
          }
        }
        if(next || next == undefined){
          this.entityOk();
        }
      }
    },
    getTitleCategory(){
      setTimeout(() => {
        this.$refs.combobox_category.isMenuActive = false;
      }, 100);
      if(this.title_category==''){
        this.categoryOk();
      } else {
        this.getCategory(this.title_category);  
      }
    },
    getCategory(category, next) {
      // console.log('getCategory', category);
      // console.log('next', next);
      if(category==null){
        this.categoryModel = {};
        this.movementModel.category_id = '';
      }
      setTimeout(() => {
        this.$refs.combobox_category.isMenuActive = false;
      }, 200);
      if (category) {
        this.categoryModel = {};
        for (const [key, value] of Object.entries(this.financialCategoriesList)) {
          for (const [key2, value2] of Object.entries(value)) {
            if (key2 == 'title' && value2.toUpperCase() == category.toUpperCase()) {
              this.categoryModel = value;
              this.movementModel.category_id = value.id;
              this.title_category = value.title;
            };
          }
        }
        if(next || next == undefined){
          this.categoryOk();
        }
      }
    },
    selectAccount(item) {
      this.today = moment().startOf('day').format("DD/MM/YYYY");
      this.accountModel = item;
      // this.movementModel.account_id = item.id;
      if (this.accountModel.type == 'CC') {
        this.movementModel.type_movement = 'D';
        this.movementFocus();
      }
      this.callGetBankStatement();
    },
    viewEntity(item) {
      // console.log(item);
    },
    viewCategory(item) {
      // console.log(item);
    },
    // https://codepen.io/borsTiHD/pen/YzpVrNJ
    // selectEntity(item, row) {
    //   // console.log('selectEntity', item);
    //   // console.log(row);
    //   // console.log(item);
    //   this.entityModel = item;
    //   this.title_entity = item.title;
    //   this.movementModel.entity_id = item.id;
    //   this.currentTab = 'tab-category';
    // },
    // rowCtrlClicked(item, row) {
    //   console.log('row ctrl clicked')
    //   console.log(row);
    //   console.log(item);
    // },
    // clickEvent(event) {
    //   console.log('clicked')
    //   console.log('event', event)
    // },
    selectCategory(item, row) {
      // console.log(row);
      // console.log(item);
      this.title_category = item.title;
      this.categoryModel = item;
      this.title_category = item.title;
      this.currentTab = 'tab-submit';
    },

    async callGetScenarios() {
      var filterData = { 'active_scenario': true };
      const data = await scenarioManager.listFilter(filterData);
      console.log('scenarios');
      console.log(data);
      this.scenariosList = data;
      this.scenariosListCount = data.length;
      if(this.scenariosListCount==1){
        this.movementModel.scenario_id = this.scenariosList[0].id;
      }
    },

    async callGetAccounts() {
      const data = await financiaMovementCreateManager.listAccounts();
      this.financialAccountsList = data;
      this.financialAccountsListCount = data.length;
    },

    async callGetEntities() {
      this.financialEntitiesList = [];
      const data = await financiaMovementCreateManager.listEntities();
      // console.log('entities');
      // console.log(data);
      this.financialEntitiesList = data;
      this.financialEntitiesListCount = data.length;
    },

    async callGetCategories() {
      this.financialCategoriesList = [];
      const data = await financiaMovementCreateManager.listCategories();
      // console.log('categories');
      // console.log(data);
      this.financialCategoriesList = data;
      this.financialCategoriesListCount = data.length;
    },

    async callGetEntitiesVsCategories() {
      this.financialEntitiesVsCategoriesList = [];
      const data = await financiaMovementCreateManager.listEntitiesVsCategories();
      // console.log('entities vs categories');
      // console.log(data);
      this.financialEntitiesVsCategoriesList = data;
      this.financialEntitiesVsCategoriesListCount = data.length;
    },

    async callGetBankStatement() {
      var account_id = this.accountModel.id;
      var filterData = { 'id': null, 'account_id' : account_id, 'movement_id' : null };
      const data = await financialBankStatementManager.listFilter(filterData);
      console.log(data);
      this.listFinancialBankStatement = data;
    },

    async deleteItemConfirm() {
      if(this.deleteManager=='movement'){
        console.log('apagou');
        const ret = await financialMovementManager.delete(this.financialMovement);
        if (ret.ok) {
          console.log(this.listFinancialMovementDuplicate);
          console.log(this.financialMovement);
          this.listFinancialMovementDuplicate = this.listFinancialMovementDuplicate.filter(item => item.attributes !== this.financialMovement);
          this.closeDialogConfirmDelete();
        }
      }
    },

    closeDialogConfirmDelete() {
      this.toggleDialogDelete = false;
    },

    callDeleteItemDuplicate(pItem) {
      console.log(pItem);
      this.deleteManager = 'movement';
      this.financialMovement = pItem.attributes;
      console.log(this.financialMovement);
      if(this.financialMovement.transfer==null){
        this.deleteText = "Tem certeza de deseja excluir o movimento: " + pItem.movement + " no valor de R$ " + pItem.amount + " ?";
      } else {
        this.deleteText = "Tem certeza de deseja excluir a transferência: " + pItem.movement + " no valor de R$ " + pItem.amount + " ?";
      }
      this.toggleDialogDelete = true;
    },

    validAccount() {
      if (this.financialAccountsListCount > -1) {
        // if (this.movementModel.hasOwnProperty('account_id')) {
        if (this.account) {
          return 'success';
        } else {
          return 'info';
        }
      } else {
        return 'default';
      }
    },
    validTypeMovement() {
      if (this.movementModel.hasOwnProperty('type_movement')) {
        return 'success';
      } else {
        return 'default';
      }
    },
    validDate() {
      if (this.movementModel.hasOwnProperty('movement')) {
        if (this.movementModel.movement != '') {
          if (moment(this.movementModel.movement, 'YYYY-MM-DD').startOf('day').isValid()) {
            return 'success';
          }
        }
      }
      return 'default';
    },
    validValue() {
      if (this.movementModel.hasOwnProperty('amount')) {
        if (this.movementModel.amount != '') {
          return 'success';
        }
      }
      return 'default';
    },
    validEntity() {
      this.newEntity = true;
      if (this.financialEntitiesListCount > -1) {
        if (Object.keys(this.entityModel).length) {
          this.newEntity = false;
          return 'success';
        } else {
          if (this.title_entity != null && this.title_entity != '') {
            return 'warning';
          }
        }
        return 'default';
      } else {
        return 'error';
      }
    },
    validCategory() {
      this.newCategory = true;
      if (this.financialCategoriesListCount > -1) {
        // console.log(Object.keys(this.categoryModel));
        if (Object.keys(this.categoryModel).length) {
          this.newCategory = false;
          return 'success';
        } else {
          if (this.title_category != null && this.title_category != '') {
            return 'warning';
          }
        }
        return 'default';
      } else {
        return 'error';
      }
    },
    validSend(){
      if( this.validAccount()=='info' && this.validTypeMovement() == 'success' && this.validDate() == 'success' && this.validValue() == 'success'){
        return true;
      }
      return false;
    },
    async callCreate() {
      var data = {
        'account': this.accountModel,
        'category': this.categoryModel,
        'entity': this.entityModel,
        'movement': this.movementModel,
        'title_entity': this.title_entity,
        'title_category': this.title_category,
        'recurrence-qty': this.recurrenceQty,
        'recurrence-type': this.recurrenceType,
        'title_recurrence': this.title_recurrence,
        'bank_statement': this.financialBankStatement,
        'ignore_duplicate': this.ignoreDuplicate,
      }
      if(this.validSend()){
        // var data = this.movementModel;
        var response = await financiaMovementCreateManager.create(data);
        console.log('response');
        console.log(response);
        if(response.status==200){
          if(response.data.errors?.duplicate){
            console.log('ALERTA DUPLICADO');
            this.showIgnoreDuplicate = true;
            this.listFinancialMovementDuplicate = response.data.data;
          }
        }
        if(response.status==201){
          console.log('-- GRAVADO --');
          this.enableTarget = true;
          this.balanceAccount = response.data.data.balanceAccount;
          this.dailyValue = response.data.data.dailyValue;
          this.goalBalance = response.data.data.goalBalance;
          this.goalValue = response.data.data.goalValue;
          this.monthBalance = response.data.data.monthBalance;
          this.movementModel.origin_movement = response.data.data.movement.origin_movement;
          this.calcGoal();
          if(response.data.refresh.bank_statement){
            this.callGetBankStatement();
          }
          this.currentTab = 'tab-goal';
        }
      }
    },
    calcGoal(){
      if(this.movementModel.type_movement=='D'){
        if(this.goalBalance==null){
          this.goalColor = 'error';
          this.goalIcon = 'alert';
          this.goalMessage = 'Não há meta para essa categoria';
          this.goalTip = 'Cadastre uma meta para não estourar o orçamento';
          return;
        }
        if(parseFloat(this.goalBalance) > 0){
          this.goalColor = 'success';
          this.goalIcon = 'check';
          this.goalMessage = 'Parabéns! Você está dentro da meta';
          this.goalTip = 'Faltam R$'+ this.$options.filters.roundNumber(this.goalBalance) + ' para o restante do mês';
        } else {
          this.goalColor = 'error';
          this.goalIcon = 'alert';
          this.goalMessage = 'Você estourou a meta em R$ ' + this.$options.filters.roundNumber(this.goalBalance) + '';
          this.goalTip = 'Verifique qual meta poderá ser revisada';
        }
      }
    },
    newRecord(){
      if (this.accountModel.type == 'CC') {
        this.resetData('tab-date');
      } else {
        this.resetData('tab-type-movement');
      }
    },
    goToRecurrence(pOriginMovement){
      console.log(pOriginMovement);
    },
    openFinancialMonthlyGoalForm() {
      this.FinancialMonthlyGoal = {};
      this.showFinancialMonthlyGoalForm = true;
    },
    closeFinancialMonthlyGoalForm(pHasUpdate) {
      // if (pHasUpdate) {
      //   this.refreshData();
      // }
      this.FinancialMonthlyGoal = {};
      this.showFinancialMonthlyGoalForm = false;
    },
    importBankStatement(item){
      this.resetData();
      console.log(item);
      if(this.accountModel.id!=item.account_id){
        snackbarManager.setWarningMessage('A conta selecionada é diferente do registro a ser importado. Favor escolher novamente uma conta');
      }
      if(item.type_movement=='debit'){
        this.movementModel.type_movement = 'D';
      }
      if(item.type_movement=='credit'){
        this.movementModel.type_movement = 'C';
      }
      this.changeDate(item.date_movement);
      setTimeout(() => {
        if(item.amount<0){
          this.amount = item.amount * (-1);
        } else {
          this.amount = item.amount;
        }
      }, 500);
      setTimeout(() => {
        this.amountOk();
      }, 1500);
      this.financialBankStatement = item;
      this.movementModel.note = '';
      this.movementModel.note += (item.title != null) ? item.title : '';
      this.movementModel.note += (item.title != null) ? '\n' : '';
      this.movementModel.note += (item.memo != null) ? item.memo : '';
      setTimeout(() => {
        this.title_entity = '';
        this.title_category = '';
      }, 2000);
    }
  }

};
</script>
<style scoped>
.titleTabs {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.keyBoard {
  margin: 2px;
  flex-direction: column;
  flex: 1;
  font-size: 1.4em;
  padding: 26px;
  border: 1px solid #e1e1e1;
  text-align: center;
  border-radius: 6px;
}

/* .listColorBackground:hover {
  background-color: #e5f2e6;
} */

.my-checkbox .v-label {
  font-size: 10px;
}

.goal-title{
  font-weight:bold;
  float:left;
  width:100%;
  height:24px;
}
.goal-value{
  float:left;
  width:100%;
  height:24px;
  margin-bottom:4px;
}
</style>