<template>
	<v-row justify="center">
		<v-dialog
			v-model="showForm"
			overlay-opacity="0.8"
			style="width: 100%; z-index: 5000"
			persistent
			max-width="400px"
			open-delay="5000"
		>
			<v-card :loading="loading">
				<v-card-text>
					<!-- TOPO FORMULARIO  -->
					<div class="d-flex justify-end"></div>
					<v-card-actions>
						<v-row
							class="my-4 d-flex justify-space-between align-center"
						>
							<div class="d-flex display-1 align-center">
								<span
									v-if="
										this.formData.id == undefined &&
										this.createDrive
									"
									>Novo Drive
								</span>
								<span
									v-if="
										this.formData.id == undefined &&
										!this.createDrive
									"
									>Novo Grupo
								</span>
								<span v-if="this.formData.id != undefined"
									>Editar Grupo</span
								>
							</div>
							<v-btn
								fab
								rounded
								text
								tile
								x-small
								@click="toggleForm(false)"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-row>
					</v-card-actions>

					<v-form
						ref="refForm"
						v-model="valid"
						lazy-validation
						onSubmit="return false;"
					>
						<v-row>
							<!-- CAMPO TITULO  -->
							<v-col v-if="this.createDrive" cols="12">
								<v-row class="ma-1">
									<v-text-field
										autofocus
										v-model="formData.title"
										label="Título"
										maxlength="30"
										required
										:rules="[rules.required]"
									/>
								</v-row>
							</v-col>

							<v-col v-else cols="12">
								<v-row class="ma-1">
									<Select
										v-if="showForm"
										:list.sync="listDrive"
										:formData="formData"
										itemText="title"
										itemValue="id"
										label="Selecione um Drive"
										nodeName="drive"
										:mandatory="true"
										:itemSelected.sync="formData.drive"
									/>
								</v-row>
							</v-col>

							<v-spacer></v-spacer>

							<v-col cols="12">
								<v-row justify="end">
									<v-btn
										v-if="this.formData.id == undefined"
										:disabled="loading"
										color="primary"
										class="white--text my-5 mr-3"
										@click="createDrive = !createDrive"
									>
										{{
											this.createDrive
												? "Novo Grupo"
												: "Novo Drive"
										}}
									</v-btn>
									<v-btn
										v-if="this.formData.id == undefined"
										:disabled="!valid || loading"
										color="success"
										class="white--text my-5 mr-3"
										@click="callCreate()"
									>
										Salvar
									</v-btn>
									<v-btn
										v-if="this.formData.id != undefined"
										:disabled="!valid || loading"
										color="success"
										class="white--text my-5 mr-3"
										@click="callUpdate()"
									>
										Editar
									</v-btn>
								</v-row>
							</v-col>

							<v-spacer></v-spacer>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { DocumentsManager } from "@/manager/document-manager"
const documentsManager = new DocumentsManager()

import { DrivesManager } from "@/manager/drives-manager"
const drivesManager = new DrivesManager()

import Select from "@/components/Selects/Select.vue"

export default {
	name: "GroupDocumentForm",

	components: {
		Select,
	},

	props: {
		formData: {
			type: Object,
			require: false,
		},
		showForm: {
			type: Boolean,
		},
	},

	data() {
		return {
			valid: false,
			listDrive: [],
			listGroupDocument: [],
			createDrive: false,
			rules: {
				required: (value) => !!value || "Campo obrigatório",
			},
		}
	},

	computed: {
		loading: () => documentsManager.state.loading,
	},

	watch: {
		showForm(pValue) {
			// console.log(this.formData)
			// console.log(pValue)
			if (pValue) {
				this.callListGroupDocument()
			}
		},
	},

	methods: {
		// LISTA DOS DRIVES DESTE DOCUMENTO
		async callListGroupDocument() {
			const data = await documentsManager.listGroupDocument(
				this.formData.document_id
			)
			this.listGroupDocument = data

			this.callListDrive()
		},

		// LISTA TODOS OS DRIVES DO DOCUMENTO
		async callListDrive() {
			const listDrive = []
			const data = await drivesManager.list(this.formData.parent_drive)

			// if (this.listGroupDocument.length > 0) {
			// 	const listIdGropup = this.listGroupDocument.map(
			// 		(item) => item.drive
			// 	)

			// 	data.map((itemDrive) => {
			// 		if (!listIdGropup.includes(itemDrive.id)) {
			// 			listDrive.push(itemDrive)
			// 		}
			// 	})
			// 	this.listDrive = listDrive
			// 	return
			// }
			this.listDrive = data
		},

		// CRIA UM NOVO DRIVE
		async callCreateDrive() {
			if (!this.$refs.refForm.validate()) return

			this.formData.drive_id = this.formData.parent_drive
			console.log(this.formData)
			var response = await documentsManager.createDriveDocument(
				this.formData
			)
			console.log(response)
			if (response.ok) {
				this.formData.drive = response.data.id
				this.formData.title = response.data.title
			}
		},

		// CRIA O GROUP DOCUMENT COM BASE NO DRIVE JA EXISTENTE
		async callCreate() {
			if (!this.$refs.refForm.validate()) return

			if (this.createDrive) {
				await this.callCreateDrive()
			}

			this.listDrive
				.filter((item) => item.id == this.formData.drive)
				.map((item) => {
					this.formData.title = item.title
				})

			var response = await documentsManager.createGroupDocument(
				this.formData
			)
			if (response.ok) {
				this.toggleForm(true, response.data)
			}
		},

		// ATUALIZA UM ITEM
		async callUpdate() {
			if (!this.$refs.refForm.validate()) return
			var response = await documentsManager.updateGroupDocument(
				this.formData
			)
			if (response.ok) {
				this.toggleForm(true, response.data)
			}
		},

		// SERVICES
		// UTILIZADA PARA FECHAR O FORMULARIO. ELA RECEBE UM PARAMETRO
		// QUE VALIDA SE EXITE NESCESSIDADE DE ATUALIZAR A TABELA OU NAO
		toggleForm(pHasUpdate, pData) {
			if (!pHasUpdate) {
				this.callClearForm()
			}
			this.$refs.refForm.resetValidation()
			this.$emit("emitClose", { hasUpdate: pHasUpdate, data: pData })
		},

		// USADO PARA LIMPAR O FORMULARIO
		callClearForm() {
			this.$refs.refForm.resetValidation()
			this.$refs.refForm.reset()
		},
	},
}
</script>
