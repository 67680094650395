/* eslint-disable */
import Vue from 'vue';
import axios from 'axios'
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
import moment from 'moment';
const globalManager = new GlobalManager();

const initialState = {
    auxiliar: [],
    loading: false,
};

export class WorkspaceManager {
    state;
    constructor() {
        this.state = Vue.observable(initialState);
    }

    isLoading() {
        return this.state.loading;
    }

    // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
    extractData(pResponse) {
        const { data } = pResponse.data;

        if (!!!data) {
            return false
        }

        // VERIFICA SE É UM OBJETO
        if (!!data?.attributes) {
            return globalManager._reduceResponse(data);
        }

        const list = data.map(item => (globalManager._reduceResponse(item)));
        return (list);
    }

    async list(pDiaryType) {

        try {
            this.state.loading = true;
            let response = await axios.get(`/diary/date/${moment().format("YYYY-MM-DD")}/type/${pDiaryType}`);
            // console.log("response", response);

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async sendTodayReport() {

        try {
            this.state.loading = true;
            let response = await axios.get(`/report/today`);
            console.log("response", response);

            snackbarManager.setSuccessMessage(response.data.message);

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    // UTILIZADO NO APPEND DIARY - LISTAR TODAS AS NOTAS DO DIA PASSANDO UM DATA
    async listAppendDiay(pDiaryDate, pDiaryType) {

        try {
            this.state.loading = true;
            let response = await axios.get(`/diary/date/${pDiaryDate}/type/${pDiaryType}`);
            // console.log("response", response);

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async listLastNotesUpdates() {

        try {
            this.state.loading = true;
            let response = await axios.get(`/notes/last/update/10`);
            // console.log("response", response);

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    // UPDATE ATIPICO POIS EU PRECISO TER UMA URL DE ACORDO COM DATA E HORA
    async create(pFormData, pAutoSave) {

        try {

            this.state.loading = true;
            // console.log(pFormData);

            await axios.post(`/diary/date/${moment().format("YYYY-MM-DD")}`, { ...pFormData });

            if (!pAutoSave) {
                snackbarManager.setSuccessMessage('Diário adicionado!');
                return globalManager.returnOk(true);
            }

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }

    }

    async delete(pItem) {

        try {
            this.state.loading = true;

            await axios.delete(`/diaries/${pItem.id}`);

            snackbarManager.setSuccessMessage('Diário removido!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async showDiary(pDiaryId) {

        try {
            this.state.loading = true;
            let response = await axios.get(`/diaries/${pDiaryId}`);
            // console.log("response", Array(response));

            // console.log("response", this.extractData(response));
            let data = this.extractData(response);
            // console.log("List - Manager", list); 

            return data;
        } catch (error) {
            globalManager.printErrors(error);
            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async listDiariesTypes() {

        try {
            this.state.loading = true;
            let response = await axios.get(`/diary-types`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    // ---------------------------------- WEEKLY ----------------------------------
    async listWeek(pDate) {
        try {
            this.state.loading = true;
            let response = await axios.get(`/report/week/${pDate}`);
            // console.log("response", response);

            let list = response.data;
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }
}
