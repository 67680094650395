<template>
  <div>
    <v-row class="ma-1">
      <h1>Validação do cadastro</h1>
      <v-spacer></v-spacer>
      <!-- <v-icon>mdi-plus-circle</v-icon> -->
    </v-row>

    <v-card class="pa-4" style="min-height: 300px;">
      <div v-if="loading">
        <div class="d-flex justify-center align-stretch pa-10" style="height: 200px;">
          <v-progress-circular :size="140" :width="4" color="indigo" indeterminate></v-progress-circular>
        </div>
      </div>
      <div v-if="!loading">
        <div v-if="result == 'OK'">
          <v-icon style="font-size: 8em;" class="mb-4 mt-2" color="green">mdi-checkbox-marked-circle-auto-outline</v-icon>
          <br>
          <h1 class="mb-4">Oi {{ user.name }},</h1>
          <p>
            O email <b>{{ user.email }}</b> foi validado com sucesso! <br>
            <br>
            Acesse <RouterLink to="/login">aqui</RouterLink> o portal.<br>
            <br>
            Seja bem vindo 😊!!!
          </p>
        </div>
        <div v-if="result == 'CODE'">
          <v-icon style="font-size: 7em;" class="mb-4 mt-2" color="warning">mdi-alert</v-icon>
          <br>
          <h1 class="mb-4">Atenção!!!</h1>
          <p>
            Código inválido! <br>
            <br>
            O código informado não foi encontrado.<br>
            Acesse <RouterLink :to="link">aqui</RouterLink> para gerar um novo código.<br>
          </p>
        </div>
        <div v-if="result == 'EXPIRATION'">
          <v-icon style="font-size: 7em;" class="mb-4 mt-2" color="warning">mdi-alert</v-icon>
          <br>
          <h1 class="mb-4">Atenção!!!</h1>
          <p>
            Validação não realizada! <br>
            <br>
            O código expirou. Envie um novo código para validação..<br>
            Acesse <RouterLink :to="link">aqui</RouterLink> para gerar um novo código.<br>
          </p>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
import { RegisterManager } from "../../manager/register-manager";
const registerManager = new RegisterManager();

export default {
    name: "Validation",
    data: () => ({
        user: {
            name: "",
            email: "",
        },
        code: "",
        userId: "",
        result: "",
        message: "",
        link: "",
    }),
    computed: {
        loading: () => registerManager.state.loading,
    },
    created() {
        this.validation();
    },
    methods: {
        async validation() {
            const response  = await registerManager.validationLogin(this.$route.fullPath);
            
            console.log(response);
            console.log(response.status);
            
            if (response.status == 200) {
              if (response.data.message == "Validação realizada") {
                this.result = "OK";
                this.user = response.data.user;
              }
            }
            if (response.status == 405) {
                this.result = "ERROR";
                if (response.data.message == "Código de validação não encontrado") {
                    this.result = "CODE";
                    this.message = "Código de validação não encontrado";
                    this.link = '/novo-codigo/'+response.data.email;
                }
                if (response.data.message == "Validação não realizada") {
                    this.result = "EXPIRATION";
                    this.message = "Código de validação não encontrado";
                    this.link = '/novo-codigo/'+response.data.email;
                }
            }
        },
    },
    components: { RouterLink }
};
</script>