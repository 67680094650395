<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Entidades financeiras</div>
      <v-spacer></v-spacer>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" sm="6" class="pa-0">
        <v-card :loading="loading" style="z-index: 0">
          <div>
            <v-card-title>
              Lista de entidades financeiras
              <v-spacer></v-spacer>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="toggleSearch()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="refreshData()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="openFinancialEntityForm()"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-card-title>

            <div
              class="d-flex justify-space-between align-center"
              v-if="showSearch"
            >
              <v-col cols="9">
                <v-text-field
                  v-model="searchFinancialEntity"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  dense
                  single-line
                  autofocus
                  hide-details
                />
              </v-col>

              <v-col cols="3" class="px-4">
                <v-row justify="end" class="mr-2">
                  <v-btn icon @click="clearSearch()">
                    <v-icon color="grey"> mdi-broom </v-icon>
                  </v-btn>
                  <v-btn icon @click="toggleSearch()">
                    <v-icon color="grey"> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </div>

            <v-data-table
              :headers="headers"
              :search="searchFinancialEntity"
              :items="listFinancialEntity"
              loading-text="Carregando..."
              progress
              class="elevation-1"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="callEditItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="callDeleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <FinancialEntityForm
      :formData="financialEntity"
      :showForm="showFinancialEntityForm"
      @emitClose="closeFinancialEntityForm"
    />

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteFinancialEntity"
      @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteFinancialEntity"
    />

  </div>
</template>
  
  <script>

import { FinancialEntityManager } from "@/manager/financial-entity-manager";
const financialEntityManager = new FinancialEntityManager();
  
import FinancialEntityForm from "./FinancialEntityForm.vue";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";

export default {
  name: "ListFinancialEntity",
  components: {
    FinancialEntityForm,
    ConfirmDialog,
  },

  created() {
    this.refreshData();
  },

  computed: {
    loading: () => financialEntityManager.state.loading,
  },

  methods: {
    // TARGETS
    async callList() {
      const data = await financialEntityManager.list();
      // console.log(data);
      this.listFinancialEntity = data;
    },

    // ABRE FORMULARIO DE INCLUSÃO
    openFinancialEntityForm() {
      this.financialEntity = {};
      this.showFinancialEntityForm = true;
    },

    // FECHAR MODAL INCLUSAO
    closeFinancialEntityForm(pHasUpdate) {
      if (pHasUpdate) {
        this.refreshData();
      }
      this.financialEntity = {};
      this.showFinancialEntityForm = false;
    },

    // EDITAR DOCUMENTO
    callEditItem(pItem) {
      // console.log(pItem)
      // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
      this.financialEntity = Object.assign({}, pItem);
      this.showFinancialEntityForm = true;
    },

    async deleteItemConfirm() {
      const ret = await financialEntityManager.delete(this.financialEntity);
      if (ret.ok) {
        this.closeDialogConfirmDeleteFinancialEntity();
        this.refreshData();
      }
    },

    callDeleteItem(pItem) {
      this.financialEntity = pItem;
      this.deleteText =
        "Tem certeza de deseja excluir o item: " + pItem.title + " ?";
      this.toggleDialogDeleteFinancialEntity = true;
    },

    closeDialogConfirmDeleteFinancialEntity() {
      this.financialEntity = {};
      this.toggleDialogDeleteFinancialEntity = false;
    },

    // UTILS
    refreshData() {
      this.callList();
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

    clearSearch() {
      this.searchFinancialEntity = " ";
    },
  },
  data() {
    return {
      // TARGETS
      listFinancialEntity: [],
      financialEntity: {},
      showFinancialEntityForm: false,

      // UTILS
      deleteText: "",
      toggleDialogDeleteFinancialEntity: false,

      // DEFINIÇÕES DA TABELA
      showSearch: false,
      searchFinancialEntity: "",
      headers: [
        {
          text: "Entidade financeira",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: "UF",
          align: "start",
          value: "region_iso_code",
          sortable: false,
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
};
</script>
  
  <style scoped>
</style>
  