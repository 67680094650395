import Vue from 'vue';
import axios from 'axios';
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();

const initialState = {
    user: [],
    isAuthenticated: null,
    loading: false,
    sessionKey: 'token',
};

export class RegisterManager {
    state;
    constructor() {
        this.state = Vue.observable(initialState);
    }

    async create(formData) {

        try {

            this.state.loading = true;
            // console.log(formData);

            const response = await axios.post(`/register`, { ...formData });
            const { data } = response.data
            // console.log(response);

            // snackbarManager.setSuccessMessage('Seu cadastro foi realizado com sucesso!');

            return { ok: true, data: globalManager._reduceResponse(data) };

        } catch (error) {

            // console.log(error);
            globalManager.printErrors(error)
            return false;
        } finally {
            this.state.loading = false;
        }
    }

    async createNewCode(formData) {
        try {

            this.state.loading = true;
            // console.log(formData);

            const response = await axios.post(`/user/new-code`, { ...formData });
            // console.log(response);
            const { data } = response.data

            snackbarManager.setSuccessMessage('Novo código enviado com sucesso!');

            // return {ok: true, data: this._reduceResponse(data)};
            return { ok: true };

        } catch (error) {
            // console.log(error);
            globalManager.printErrors(error)
            return false;

        } finally {
            this.state.loading = false;
        }
    }

    async validationLogin(route) {
        try {
            this.state.loading = true;
            const data = await axios.get(`${route}`);
            // snackbarManager.setSuccessMessage(data.data.message, data.data.success.cadastro[0]);
            return data;
        } catch (error) {
            // console.log(error);
            // snackbarManager.setErrorMessage(error.response.data.message, error.response.data.errors.Link[0]);
            return { error, ...error.response };
        } finally {
            this.state.loading = false;
        }
    }

}
