<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      overlay-opacity="0.8"
      style="width: 100%; z-index: 5000"
      persistent
      max-width="400px"
      open-delay="5000"
    >
      <v-card :loading="loading">
        <v-card-text>
          <!-- TOPO FORMULARIO  -->
          <div class="d-flex justify-end"></div>
          <v-card-actions>
            <v-row class="my-4 d-flex justify-space-between align-center">
              <div class="d-flex display-1 align-center">
                <span class="text-h6 sm text-h5" v-if="this.formData.id == undefined">
                  Cadastrar conta financeira
                </span>
                <span v-if="this.formData.id != undefined">Editar conta financeira</span>
              </div>
              <v-btn fab rounded text tile x-small @click="toggleForm(false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>

          <v-form ref="refForm" v-model="valid" lazy-validation onSubmit="return false;">
            <v-row>

              <!-- CAMPO TITLE  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <v-text-field v-model="formData.title" autofocus label="Título" maxlength="30" required></v-text-field>
                </v-row>
              </v-col>

              <!-- CAMPO TYPE  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <v-combobox v-model="selectedType" :items="listTypesAccount" item-text="label" item-value="key"
                    label="Selecione um tipo"></v-combobox>
                </v-row>
              </v-col>

              <v-expand-transition class="w-100">
                <div v-show="selectedType.key == 'CC'">

                  <div class="text-h6 ma-1 ml-4"> Parâmetros cartão de crédito </div>

                  <!-- CAMPO CLOSING_DAY  -->
                  <v-col cols="12">
                    <v-row class="ma-1">
                      <v-text-field v-model="formData.closing_day" label="Dia do fechamento da fatura"
                        maxlength="2"></v-text-field>
                    </v-row>
                  </v-col>

                  <!-- CAMPO PAYDAY  -->
                  <v-col cols="12">
                    <v-row class="ma-1">
                      <v-text-field v-model="formData.payday" label="Dia de pagamento" maxlength="2"></v-text-field>
                    </v-row>
                  </v-col>

                  <!-- CAMPO PAYDAY  -->
                  <v-col cols="12">
                    <v-row class="ma-1">
                      <v-text-field v-model="formData.reference" @focus="showCalendar = true" label="Referência da próxima fatura"
                      maxlength="7"></v-text-field>
                    </v-row>
                    <v-expand-transition class="w-100">
                      <div v-show="showCalendar">
                        <v-row class="ma-2 mb-4" @click="showCalendar=false" style="cursor: pointer;">
                          <v-icon left>mdi-close-circle</v-icon>
                          Fechar calendário
                        </v-row>
                        <v-row class="ma-1">
                          <v-date-picker v-show="showCalendar" v-model="formData.reference" type="month" />
                        </v-row>
                      </div>
                    </v-expand-transition>
                  </v-col>

                </div>
              </v-expand-transition>

              <v-col cols="12">
                <v-row justify="end">
                  <v-btn
                    v-if="this.formData.id == undefined"
                    :disabled="!valid || loading"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callCreate()"
                  >
                    Salvar
                  </v-btn>
                  <v-btn
                    v-if="this.formData.id != undefined"
                    :disabled="!valid || loading"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callUpdate()"
                  >
                    Editar
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { FinancialAccountManager } from "@/manager/financial-account-manager";
const financialCategoryManager = new FinancialAccountManager();

export default {
  name: "FinancialAccountForm",

  props: {
    formData: {
      type: Object,
      require: false,
    },
    showForm: {
      type: Boolean,
    },
  },

  created() {
    console.log(this.formData);
  },

  computed: {
    loading: () => financialCategoryManager.state.loading,
  },

  methods: {

    // CREATE
    // CRIA UM NOVO ITEM
    async callCreate() {
      this.formData.type = this.selectedType.key;
      if (!this.$refs.refForm.validate()) return;
      // console.log(this.formData);
      
      var response = await financialCategoryManager.create(this.formData);
      if (response.ok) {
        this.toggleForm(true);
      }
    },
    
    // ATUALIZA UM ITEM
    async callUpdate() {
      this.formData.type = this.selectedType.key;
      if (!this.$refs.refForm.validate()) return;
      var response = await financialCategoryManager.update(this.formData);
      if (response.ok) {
        this.toggleForm(true);
      }
    },

    // SERVICES
    // UTILIZADA PARA FECHAR O FORMULARIO. ELA RECEBE UM PARAMETRO
    // QUE VALIDA SE EXITE NESCESSIDADE DE ATUALIZAR A TABELA OU NAO
    toggleForm(pHasUpdate) {
      this.$refs.refForm.resetValidation();
      this.$emit("emitClose", pHasUpdate);
    },

    // USADO PARA LIMPAR O FORMULARIO
    callClearForm() {
      this.$refs.refForm.reset();
    },
  },
  data() {
    return {
      listAreas: [],

      selectedType: "",
      showCalendar: false,

      valid: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },
      listTypesAccount:[
        {"key": "AC","label":"Conta corrente"},
        {"key": "SA","label":"Poupança"},
        {"key": "CC","label":"Cartão de crédito"},
        {"key": "PP","label":"Pré-pago"},
        {"key": "MO","label":"Dinheiro"},
      ]
    };
  },
};
</script>

