<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Categorias financeiras</div>
      <v-spacer></v-spacer>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" sm="6" class="pa-0">
        <v-card :loading="loading" style="z-index: 0">
          <div>
            <v-card-title>
              Lista de categorias financeiras
              <v-spacer></v-spacer>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="toggleSearch()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="refreshData()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="openFinancialCategoryForm()"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-card-title>

            <div
              class="d-flex justify-space-between align-center"
              v-if="showSearch"
            >
              <v-col cols="9">
                <v-text-field
                  v-model="searchFinancialCategory"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  dense
                  single-line
                  autofocus
                  hide-details
                />
              </v-col>

              <v-col cols="3" class="px-4">
                <v-row justify="end" class="mr-2">
                  <v-btn icon @click="clearSearch()">
                    <v-icon color="grey"> mdi-broom </v-icon>
                  </v-btn>
                  <v-btn icon @click="toggleSearch()">
                    <v-icon color="grey"> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </div>

            <v-data-table
              :headers="headers"
              :search="searchFinancialCategory"
              :items="listFinancialCategory"
              loading-text="Carregando..."
              progress
              class="elevation-1"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="callEditItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="callDeleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <FinancialCategoryForm
      :formData="financialCategory"
      :showForm="showFinancialCategoryForm"
      @emitClose="closeFinancialCategoryForm"
    />

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteFinancialCategory"
      @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteFinancialCategory"
    />

  </div>
</template>
  
  <script>

import { FinancialCategoryManager } from "@/manager/financial-category-manager";
const financialCategoryManager = new FinancialCategoryManager();
  
import FinancialCategoryForm from "./FinancialCategoryForm.vue";

import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";

export default {
  name: "ListFinancialCategory",
  components: {
    FinancialCategoryForm,
    ConfirmDialog,
  },

  created() {
    this.refreshData();
  },

  computed: {
    loading: () => financialCategoryManager.state.loading,
  },

  methods: {
    // TARGETS
    async callList() {
      const data = await financialCategoryManager.list();
      // console.log(data);
      this.listFinancialCategory = data;
    },

    // ABRE FORMULARIO DE INCLUSÃO
    openFinancialCategoryForm() {
      this.financialCategory = {};
      this.showFinancialCategoryForm = true;
    },

    // FECHAR MODAL INCLUSAO
    closeFinancialCategoryForm(pHasUpdate) {
      if (pHasUpdate) {
        this.refreshData();
      }
      this.financialCategory = {};
      this.showFinancialCategoryForm = false;
    },

    // EDITAR DOCUMENTO
    callEditItem(pItem) {
      // console.log(pItem)
      // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
      this.financialCategory = Object.assign({}, pItem);
      this.showFinancialCategoryForm = true;
    },

    async deleteItemConfirm() {
      const ret = await financialCategoryManager.delete(this.financialCategory);
      if (ret.ok) {
        this.closeDialogConfirmDeleteFinancialCategory();
        this.refreshData();
      }
    },

    callDeleteItem(pItem) {
      this.financialCategory = pItem;
      this.deleteText =
        "Tem certeza de deseja excluir o item: " + pItem.title + " ?";
      this.toggleDialogDeleteFinancialCategory = true;
    },

    closeDialogConfirmDeleteFinancialCategory() {
      this.financialCategory = {};
      this.toggleDialogDeleteFinancialCategory = false;
    },

    // UTILS
    refreshData() {
      this.callList();
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

    clearSearch() {
      this.searchFinancialCategory = " ";
    },
  },
  data() {
    return {
      // TARGETS
      listFinancialCategory: [],
      financialCategory: {},
      showFinancialCategoryForm: false,

      // UTILS
      deleteText: "",
      toggleDialogDeleteFinancialCategory: false,

      // DEFINIÇÕES DA TABELA
      showSearch: false,
      searchFinancialCategory: "",
      headers: [
        {
          text: "Categoria financeira",
          align: "start",
          sortable: true,
          value: "title",
        },
        {
          text: "Tipo",
          align: "start",
          sortable: true,
          value: "type",
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
      listTypesCategory:[
        {"key": "C","label":"Crédito"},
        {"key": "D","label":"Débito"}
      ]
    };
  },
};
</script>
  
  <style scoped>
</style>
  