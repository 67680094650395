import Vue from 'vue';
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();


const initialState = {
    user: [],
    isAuthenticated: null,
    loading: false,
    sessionKey: 'token',
};

export class GlobalManager {
    state;
    constructor() {
        this.state = Vue.observable(initialState);
    }

    _reduceResponse(pItem) {
        // console.log("03 - pItem", pItem)
        const item = { ...pItem.attributes, included: { ...pItem?.included } };
        // console.log("03.1 - item", item)
        return (item);
    }

    returnOk(pOk) {
        var vReturn = { 'ok': pOk };
        return vReturn;
    }

    loading(pItem) {
        this.state.loading = pItem;
        return;
    }

    // VALIDA ERROS DE RETORNO DA API
    printErrors(error) {
        if (error.response?.status) {
            console.log(error);
            if(error.response.data?.message){
                let errorsMsg = Object.values(error.response.data?.errors).flat().join("<br>");
                
                if(error.response.data?.color){
                    snackbarManager.setGlobalMessage(error.response.data.message, errorsMsg + "<br>", error.response.data.color);
                } else {
                    snackbarManager.setGlobalMessage(error.response.data.message, errorsMsg + "<br>", 'danger');
                }
            }else{
                snackbarManager.setGlobalMessage("Esta ação não pode ser executada.", error.response.data.msg, error.response.data.style);
            }
            return;
        }
    }

    getErrorsMessage(error) {
        if (error.response?.status) {
            console.log(error);
            let errorsMsg = Object.values(error.response.data.errors).flat().join("<br>");
            return {
                ok: false,
                title: error.response.data.message,
                message: errorsMsg + "<br>",
                color: error.response.data.color
            }
        }
    }

}
