/* eslint-disable */
import Vue from 'vue';
import axios from 'axios'
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();

// list: (driveGuid) => axios.get(`${url}/drive/${driveGuid}`),    
// show: (noteId) => axios.get(`${url}/${noteId}`),
// listAll: () => axios.get(`${url}`),
// add: formData => axios.post(url, {...formData}),
// delete: id => axios.delete(`${url}/${id}`),
// update: formData => axios.put(`${url}/${formData.id}`, { ...formData }),
// publish: formData => axios.post(`${url}/publish/${formData.id}`,{ ...formData }),
// unPublish: formData => axios.get(`${url}/unpublish/${formData.id}`),
// notePublish: (id, password) => axios.post(`/view/${id}`, password)

const initialState = {
    notes: [],
    loading: false,
};

export class NotesManager {
    state;
    constructor() {
        this.state = Vue.observable(initialState);
    }

    // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
    extractData(pResponse) {
        const { data } = pResponse.data;

        // VERIFICA SE É UM OBJETO
        if (!!data?.attributes) {
            return globalManager._reduceResponse(data);
        }

        const list = data.map(item => (globalManager._reduceResponse(item)));
        return (list);
    }

    note() {
        return this.state.notes.length < 1
            ? []
            : this.state.notes.map(item => this._reduceResponse(item));
    }

    allNotes() {
        return this.state.notes.length < 1
            ? []
            : this.state.notes.map(item => this._reduceResponseCombobox(item));
    }

    async list(pDriveId, pUrl) {
        try {
            globalManager.loading(true);
            this.state.loading = true;
            let response = await axios.get(pUrl ? pUrl : `/notes/drive/${pDriveId}`);
            //  console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list); 

            return list;
        } catch (error) {
            globalManager.printErrors(error);
            globalManager.loading(false);
            return globalManager.returnOk(false);
        } finally {
            globalManager.loading(false);
            this.state.loading = false;
        }
    }

    async create(pFormData) {

        try {
            globalManager.loading(true);
            this.state.loading = true;
            // console.log("create", pFormData)

            await axios.post("/notes", { ...pFormData });

            snackbarManager.setSuccessMessage('Nota adicionada!');

            return globalManager.returnOk(true);

        } catch (error) {

            globalManager.printErrors(error);
            globalManager.loading(false);
            return globalManager.returnOk(false);

        } finally {
            globalManager.loading(false);
            this.state.loading = false;
        }
    }

    async update(pFormData, pAutoSave) {

        try {
            globalManager.loading(true);
            this.state.loading = true;
            // console.log(pFormData);

            await axios.put(`/notes/${pFormData.id}`, { ...pFormData });

            if (!pAutoSave) {
                snackbarManager.setSuccessMessage('Nota atualizada!');
                return globalManager.returnOk(true);
            }

            return globalManager.returnOk(true);

        } catch (error) {

            globalManager.printErrors(error);
            globalManager.loading(false);

            return globalManager.returnOk(false);

        } finally {
            globalManager.loading(false);
            this.state.loading = false;
        }

    }

    async delete(pItem) {
        try {

            globalManager.loading(true);
            this.state.loading = true;

            await axios.delete(`/notes/${pItem.id}`);

            snackbarManager.setSuccessMessage('Nota removido!');

            return globalManager.returnOk(true);

        } catch (error) {

            globalManager.printErrors(error);
            globalManager.loading(false);

            return globalManager.returnOk(false);

        } finally {
            globalManager.loading(false);
            this.state.loading = false;
        }
    }

    async showNote(pNoteId) {

        try {
            globalManager.loading(true);
            this.state.loading = true;
            let response = await axios.get(`/notes/${pNoteId}`);
            //  console.log("response", Array(response));

            // console.log("response", this.extractData(response));
            let data = this.extractData(response);
            // console.log("List - Manager", list); 

            return data;
        } catch (error) {
            globalManager.printErrors(error);
            globalManager.loading(false);
            return globalManager.returnOk(false);
        } finally {
            globalManager.loading(false);
            this.state.loading = false;
        }
    }

    // -------------------------------------------------- HOT LIST ----------------------------------------------------------

    async listHotList() {
        try {
            globalManager.loading(true);
            this.state.loading = true;
            let response = await axios.get('/notes/backlogs/hot-list');
            //  console.log("response", response);   

            let list = response.data;
            // console.log("List - Manager", list); 

            return list;
        } catch (error) {
            globalManager.printErrors(error);
            globalManager.loading(false);
            return globalManager.returnOk(false);
        } finally {
            globalManager.loading(false);
            this.state.loading = false;
        }
    }

    // --------------------------------------------------- LEGADO ------------------------------------------------------------
    async saveNotes(pFormData) {

        try {
            globalManager.loading(true);
            this.state.loading = true;
            // console.log(pFormData);

            await axios.put(`/notes/${pFormData.id}`, { ...pFormData });

            if (!pAutoSave) {
                snackbarManager.setSuccessMessage('Nota atualizada!');
                return globalManager.returnOk(true);
            }

            return globalManager.returnOk(true);

        } catch (error) {

            globalManager.printErrors(error);
            globalManager.loading(false);

            return globalManager.returnOk(false);

        } finally {
            globalManager.loading(false);
            this.state.loading = false;
        }
    }

    async publishNote(formData) {
        // try {

        //     this.state.loading = true;
        //     let { data } = await notesService.publish(formData);
        //     console.log(data)
        //     snackbarManager.setSuccessMessage('Nota publicada!');
        //     return true;

        // } catch (error) {
        //     snackbarManager.setErrorMessage("Erro", error.response.data.message);
        //     return false;
        // } finally {
        //     this.state.loading = false;
        // }
    }

    async unPublishNote(formData) {
        // try {

        //     this.state.loading = true;
        //     let { data } = await notesService.unPublish(formData);
        //     console.log(data)
        //     snackbarManager.setSuccessMessage('Nota despublicada!');
        //     return true;

        // } catch (error) {
        //     snackbarManager.setErrorMessage("Erro", error.response.data.message);
        //     return false;
        // } finally {
        //     this.state.loading = false;
        // }
    }

    async viewPublishNote(noteId, pass) {
        // try {
        //     //debugger;        
        //     this.state.notes = [];
        //     const password = { 'password': pass }
        //     this.state.loading = true;
        //     var { data } = await notesService.notePublish(noteId, password);
        //     this.state.notes.push(data)
        //     //console.log(this.state.notes);

        //     return true;
        // } catch (error) {
        //     if (error.response) {
        //         console.log(error.response.data);
        //         console.log(error.response.status);
        //         console.log(error.response.headers);

        //     }
        //     snackbarManager.setMessage(error.response.data.msg);
        //     return false;
        // } finally {
        //     this.state.loading = false;
        // }

    }

    async deleteNotes(index) {
        // try {
        //     this.state.loading = true;
        //     const noteId = this.state.notes[index].data.id;
        //     await notesService.delete(noteId);
        //     this.state.notes.splice(index, 1);
        //     snackbarManager.setSuccessMessage('Nota Removida!');
        //     return true;
        // } catch (error) {
        //     snackbarManager.setErrorMessage("Erro", error.response.data.message);
        //     return false;
        // } finally {
        //     this.state.loading = false;
        // }
    }

}
