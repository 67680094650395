<template>
  <div class="ma-5">
    <v-row justify="start" align-item="start" v-if="images.length < 1">
      Sem arquivos para exibir
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" v-for="file in images" :key="file.id">
        <v-card outlined>
          <v-img
            :src="file.url"
            class="white--text preview align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="100px"
          >

            <v-card-subtitle>{{ file.name }}</v-card-subtitle>

          </v-img>
          <v-card-actions>
            
            <v-spacer></v-spacer>

            <v-btn icon @click="$emit('setLink', file)">
              <v-icon>mdi-link</v-icon>
            </v-btn>

            <v-btn icon @click="doDelete(file)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>

            <v-btn icon @click="download(file)">
              <v-icon>mdi-download</v-icon>
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';

import { FilesManager } from "@/manager/files-manager";
const filesManager = new FilesManager();

export default {
  name: "galeria",
  props: {
    files: {
      type: Array,
      required: true,
    },
  },

  data(){
    return{
    baseUrl: process.env.VUE_APP_API_URL,
    }
  },
  
  computed: {
    images() {
      // console.log(
      //   this.files.filter((item) => /\.(jpe?g|png|gif)$/i.test(item.filename))
      // );
      return this.files.filter((item) => /\.(jpe?g|png|gif)$/i.test(item.filename));
    },
  },

  methods: {

    doDelete(item) {
      // console.log('doDelete');
      this.$emit("do-delete", item);
    },

    forceFileDownload(response, title) {
      console.log('-- forceFileDownload --');
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', title)
        document.body.appendChild(link)
        link.click()
    },

    async download(item) {
      axios({
        method: "get",
        url: `${this.baseUrl}/files/download/${item.id}`,
        responseType: "arraybuffer",
      })
        .then((response) => {
          // console.log(response);
          this.forceFileDownload(response, item.name);

        })
        .catch(
            () => console.log('error occured')
        )
    },
  },
 
};
</script>

<style></style>
