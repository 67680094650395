<template>
  <!-- CAMPO TRADE  -->
  <v-combobox
    v-model="selected"
    :items.sync="list"
    input
    @change="verifyIsNewItem"
    :label="label"
    :item-text="itemText"
    :item-value="itemValue"
    :disabled="disabled"
    :rules="mandatory ? [rules.required] : []"
    clearable
  >
    <!-- <template v-if="newItem" v-slot:append-outer>
          <v-icon @click="callCreateItem" color="success" class="mt-1">
            mdi-content-save-plus
          </v-icon>
        </template> -->
  </v-combobox>
</template>
  
  <script>
export default {
  name: "Select",
  props: {
    list: {
      type: Array,
      require: true,
    },
    formData: {
      type: Object,
      require: false,
    },
    nodeName: {
      type: String,
    },
    itemSelected: {
      type: String,
    },
    label: {
      type: String,
    },
    itemText: {
      type: String,
    },
    itemValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    mandatory: {
      type: Boolean,
    },
  },

  created() {
    if (this.formData[this.nodeName] != null) {
      this.selected = Object.assign(
        {},
        this.formData.included[this.nodeName].attributes
      );
    }
  },

  methods: {
    // CREATE
    callCreateItem() {  
      // FUNÇÃO EMIT QUE DISPARA A CRIAÇÃO DE UM NOVO ITEM ATRAVES DO TITTLE
      this.$emit("createItem", { title: this.selected });
      this.newItem = false;
    },

    // SERVICES
    verifyIsNewItem() {
      // console.log("verify item.... ", this.selected)
      if (typeof this.selected === "string") {
        this.newItem = true;
      } else {
        // ESTE EMIT ATUALIZA A PROP RECEBIDA DO PAI
        this.$emit(
          "update:itemSelected",
          this.selected?.id ? this.selected.id : ""
        );
        this.$emit("change", this.selected?.id ? this.selected.id : false);
      }
    },
  },

  data() {
    return {
      newItem: false,
      selected: null,
      listProductCategories: [],
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },
    };
  },
};
</script>