<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Usuários</div>
      <v-spacer></v-spacer>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" class="pa-0">
        <v-card :loading="loading">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nome</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Autorizado</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in usersList" :key="item.attributes.id">
                  <td>{{ item.attributes.name }}</td>
                  <td>{{ item.attributes.email }}</td>
                  <td>
                    <v-checkbox
                      v-model="item.attributes.is_authorized"
                      @click="updateAuthorized(item)"
                    ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { UserManager } from "@/manager/user-manager";
const userManager = new UserManager();
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "AdminUsers",
  
  data() {
    return {
      usersList: [],
    };
  },

  computed: {
    ...mapGetters({
      // carregar o módulo de autenticação e o usuário logado
      user: "auth/user",
      isAuthenticated: "auth/isAuthenticated",
    }),
    loading: () => userManager.state.loading,
  },

  created() {
    this.callUsers();
  },

  methods: {
    async callUsers() {
      const response = await userManager.getUsers();
      // console.log(response);
      // response.created_at = moment(response.created_at).format(
      //   "DD/MM/YYYY - HH:mm"
      // );
      this.usersList = response.data;
    },
    async updateAuthorized(pItem) {
      // console.log(pItem);
      // console.log(pItem.attributes.is_authorized);
      var user = {
        id: pItem.attributes.id,
        name: pItem.attributes.name,
        email: pItem.attributes.email,
        is_authorized: pItem.attributes.is_authorized,
      };
      const response = await userManager.updateAdminUser(user);
      console.log(response);
      this.callUsers();
    },
  },
};
</script>
