<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Home</div>
      <v-spacer></v-spacer>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" class="pa-0">
        <v-card class="pa-4">
          Welcome Gold Task 
        </v-card>
      </v-col>
    </v-row>  
  </div>

</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
