
import { SnackbarManager } from '../manager/snackbar-manager';
const snackbarManager = new SnackbarManager();

export default {
  namespaced: true,
  state: {
    user: null,
    token: null,
    step: null,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_STEP(state, step) {
      state.step = step;
    },
    CLEAR(state) {
      state.user = null;
      state.token = null;
      state.step = null;
    }
  },
  actions: {

    async login({ commit }, credentials) {
      commit('SET_STEP', 'Ligando motores...');
      console.log('Ligando motores...');
      console.log('Ligando motores...');
      console.log('Passando credenciais...');
      console.log(credentials);
      try {
        commit('SET_STEP', 'Enviando credenciais...');
        console.log('Enviando credenciais...');
        const response = await axios.post('/login', credentials);
        // .catch(function (error) { 
        //   console.log('Analisando erros...');
        //   if (error.response) {
        //     // The request was made and the server responded with a status code
        //     // that falls out of the range of 2xx
        //     console.log(error.response.data);
        //     console.log(error.response.status);
        //     console.log(error.response.headers);
        //     commit('SET_STEP', 'Erro: ' + error.response.status +': '+error.response.data);
        //   } else if (error.request) {
        //     // The request was made but no response was received
        //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        //     // http.ClientRequest in node.js
        //     console.log(error.request);
        //     commit('SET_STEP', 'Request: ' + error.request);
        //   } else {
        //     // Something happened in setting up the request that triggered an Error
        //     commit('SET_STEP', 'Message: '+ error.message);
        //     console.log('Error', error.message);
        //   }
        //   console.log(error.config);
        // }
      // );
        
        ;
        commit('SET_STEP', 'Aguardando resposta...');
        console.log(response)
        if(response==undefined){
          commit('SET_STEP', 'Não houve resposta do servidor');
          return {ok: false};
        } else {

          commit('SET_STEP', 'Checando acesso...');
          const token = response.data.token;
          const user = response.data.user;
          
          commit('SET_STEP', 'Autorizando acesso...');
          if (!user.is_authorized) {
            commit('SET_STEP', 'Não autorizado');
            snackbarManager.setWarningMessage("Login", "Usuário não autorizado. Entre em contato com o suporte.");
            return {ok: false};
          } else {
            commit('SET_STEP', 'Usuário autorizado');
          }
          
          commit('SET_TOKEN', token);
          commit('SET_USER', user);
          commit('SET_STEP', 'Autenticado!!!');
  
          return {ok: true, data: user};
        }

      } catch (error) {
        console.error(error);
        commit('CLEAR');
        commit('SET_STEP', 'Erro ao autenticar!!!');
        if (error.message == "Network Error") {
          snackbarManager.setErrorMessage("Erro de rede.");
          return {ok: false};
        }
        if (error.request.status == 401) {
          snackbarManager.setWarningMessage("Login", "Usuário ou senha inválido.");
          return {ok: false};
        }
        if (error.request.status == 403) {
          snackbarManager.setWarningMessage("Login", "Acesse o seu email e valide o seu cadastro.");
          return {ok: false, route: 'novo-codigo' };
        }
        snackbarManager.setWarningMessage("Login", "Solicitação não pode ser atendida. Entre em contato com suporte");
          return {ok: false};
      }
    },

    async initConfig({ commit, state }, token) {

      // console.log('-- STORE | AUTH | INITICONFIG ATTEMPT --');

      if (token) {
        // console.log('Se o token existir no local storage gravar ele no estado');
        commit('SET_TOKEN', token);
      }

      if (!state.token) {
        // console.log('Se não tiver token no local storage. Sair da funcão');
        return;
      }

      try {
        // console.log('Recuperar usuário pela rota "me"');
        const response = await axios.get('/me');
        const user = response.data;
        // console.log(user);
        // console.log('setar token no estado');
        commit('SET_TOKEN', token);
        // console.log('setar user no estado');
        commit('SET_USER', user);
      } catch (error) {
        console.error('Erro ao recuperar usuário. Limpar o estado');
        commit('CLEAR');
        // commit('SET_TOKEN', null);
        // commit('SET_USER', null);
      }
    },

    async logout({ commit }) {
      // console.log('-- STORE | AUTH | LOGOUT --');

      try {
        // console.log('Deslogar usuário');
        const response = await axios.get('/logout');
      } catch (error) {
        console.error('Erro ao deslogar usuário.');
        // commit('SET_TOKEN', null);
        // commit('SET_USER', null);
      } finally {
        // console.log('Limpar o estado');
        commit('CLEAR');
      }

      // commit('SET_TOKEN', null);
      //   commit('SET_USER', null);
    },

    // async checkToken({ commit }, token) {
    //   // console.log('-- STORE | AUTH | CHECKTOKEN --');
    //   // console.log(token);
    //   try {
    //     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    //     // console.log(axios.defaults.headers.common['Authorization']);
    //     const response = await axios.get('/me');
    //     const user = response.data;
    //     commit('SET_TOKEN', token);
    //     commit('SET_USER', user);
    //   } catch (error) {
    //     commit('CLEAR');
    //   }
    // }

  },
  getters: {
    isAuthenticated(state) {
      let authenticated = false;
      if (state.token && state.user) {
        authenticated = true;
      }
      return authenticated;
    },
    user(state) {
      return state.user;
    },
    step(state) {
      return state.step;
    }
  }
};
