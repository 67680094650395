<template>
  <v-navigation-drawer
    v-model="toggleShowNav"
    style="z-index: 3 !important"
    app
  >
    <router-link to="/">
      <v-sheet
        :color="colorApp"
        class="pa-4 d-flex flex-column align-center justify-center"
        height="64"
      >
        <v-img
          alt="Logomarca"
          class="shrink mr-2"
          contain
          :src="logo"
          transition="scale-transition"
          width="150"
          style="cursor: pointer"
        />
      </v-sheet>
    </router-link>

    <v-divider></v-divider>

    <v-list dense nav>
      <template
        v-for="item in items.filter((resItem) => permisionsFilter(resItem))"
      >
        <!-- Verifica se o item possui um filho, se exitir ele gera um grupo -->
        <v-list-group
          v-if="item.childrens.length > 0"
          :key="item.title"
          v-model="item.active"
          color="#ccc"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="children in item.childrens"
            :key="children.title"
            :to="children.url"
          >
            <v-list-item-icon>
              <v-icon>{{ children.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ children.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- Se o item não possuir filho ele vira um item -->
        <v-list-item
          v-else
          :key="item.title"
          :to="item.url"
          @click="setActiveGroup()"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <template v-slot:append>
      <div class="pa-2" v-if="isAuthenticated && user.is_admin">
        <v-btn class="mt-2" block to="/admin">
          <v-icon left>mdi-account-cog</v-icon>
          Admin
        </v-btn>
        <!-- <v-btn class="mt-2" block to="/admin">
          <v-icon left>mdi-account-cog</v-icon>
          Admin
        </v-btn> -->
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import logo from "@/assets/logo.png";

export default {
  name: "NavPrivate",
  props: {
    showNav: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      /// https://pictogrammers.com/library/mdi/
      items: [
        {
          title: "Workspace",
          icon: "mdi-home",
          url: "/",
          private: true,
          active: false,
          childrens: [],
        },
        {
          title: "Drives",
          icon: "mdi-folder",
          url: "/drives",
          private: true,
          active: false,
          childrens: [],
        },
        {
          title: "Financeiro",
          icon: "mdi-currency-usd",
          private: true,
          active: true,
          childrens: [
            {
              title: "Contas",
              icon: "",
              url: "/financial-accounts",
              private: true,
            },
            {
              title: "Categorias financeiras",
              icon: "",
              url: "/financial-categories",
              private: true,
            },
            {
              title: "Entidades financeiras",
              icon: "",
              url: "/financial-entities",
              private: true,
            },
            {
              title: "Extratos",
              icon: "",
              url: "/financial-bank-statements",
              private: true,
            },
            {
              title: "Metas financeiras",
              icon: "",
              url: "/financial-monthly-goal",
              private: true,
            },
            {
              title: "Movimentos - Criar",
              icon: "",
              url: "/financial-movement-create",
              private: true,
            },
            {
              title: "Movimentos",
              icon: "",
              url: "/financial-movement",
              private: true,
            },
            {
              title: "Parcelamentos",
              icon: "",
              url: "/financial-recurrence",
              private: true,
            },
            {
              title: "Cenários",
              icon: "",
              url: "/scenarios",
              private: true,
            },
          ],
        },
        {
          title: "Metas",
          icon: "mdi-bookmark",
          url: "/targets",
          private: true,
          active: false,
          childrens: [],
        },
        {
          title: "Diário",
          icon: "mdi-notebook",
          url: "/diary",
          private: true,
          active: false,
          childrens: [],
        },
        {
          title: "Calendário",
          icon: "mdi-calendar",
          url: "/calendar",
          private: true,
          active: false,
          childrens: [],
        },
        {
          title: "Timeline",
          icon: "mdi-timeline-clock-outline",
          url: "/timeline",
          private: true,
          active: false,
          childrens: [],
        },
        {
          title: "Documentos",
          icon: "mdi-list-box",
          url: "/documents",
          private: true,
          active: false,
          childrens: [],
        },
        {
          title: "Reuniões",
          icon: "mdi-laptop-account",
          url: "/meeting",
          private: true,
          active: false,
          childrens: [],
        },
      ],
      right: null,
    };
  },
  created() {
    this.setActiveGroup();
  },
  computed: {
    toggleShowNav: {
      get() {
        return this.showNav;
      },
      set(value) {
        this.$emit("closeNav", value);
      },
    },
    ...mapGetters({
      // carregar o módulo de autenticação e o usuário logado
      user: "auth/user",
      isAuthenticated: "auth/isAuthenticated",
    }),
    logo: () => logo,
    colorApp() {
      // Esta condição está na Header também.
      let themeColor = "";
      if (this.user) {
        if (this.user.theme_dark != "D") {
          themeColor = this.user.theme_color;
        }
      } else {
        if (localStorage.getItem("theme_dark") != "D") {
          themeColor = localStorage.getItem("theme_color");
        }
      }
      return themeColor;
    },
  },
  methods: {
    // verifica se a rota esta disponivel para usuário autenticado, privado ou se é uma rota publica.
    permisionsFilter(pItem) {
      if (!pItem.private || (pItem.private && this.isAuthenticated)) {
        return pItem;
      }
    },
    // verifica qual rota estou para ativar o menu se eu estiver acessando diretamente a rota via url.
    setActiveGroup() {
      const currentRoute = this.$route.path;
      this.items.forEach((item) => {
        if (item.childrens.length > 0) {
          const itemRoutes = item.childrens.map((children) => children.url);
          if (itemRoutes.includes(currentRoute)) {
            item.active = true;
            return;
          } else {
            item.active = false;
            return;
          }
        } else if (item.url === currentRoute) {
          item.active = true;
          return;
        } else {
          item.active = false;
          return;
        }
      });
    },
  },
};
</script>
