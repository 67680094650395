/* eslint-disable */
import Vue from 'vue';
import axios from 'axios'
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();


const initialState = {
    calendar: [],
    loading: false
}

export class CalendarManager {
    state;
    constructor() {
        this.state = Vue.observable(initialState);
    }


    // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
    extractData(pResponse) {
        const { data } = pResponse.data;

        // VERIFICA SE É UM OBJETO
        if (!!data?.attributes) {
            return globalManager._reduceResponse(data);
        }

        const list = data.map(item => (globalManager._reduceResponse(item)));
        return (list);
    }


    // async fetchCalendars(token, url, formData) {
    //     try {

    //         //debugger;
    //         this.state.loading = true;
    //         this.state.calendar = [];
    //         axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

    //         // console.log(formData)
    //         let  {data}  = await calendarService.list(url,formData);
    //         this.state.calendar = data.data;
    //         // console.log("imprimindo a lista calendars");
    //         // console.log(data);
    //         return true;

    //     } catch (error) {
    //         console.log(error);
    //         snackbarManager.setErrorMessage("Sua sessão expirou", "Efetue o login novamente");
    //         return false;
    //     } finally {
    //         this.state.loading = false;
    //     }

    // }

    // list: (url,formData) => axios.post(`${url}`, {...formData}),    
    // show: id => axios.get(`/calendar/${id}`),
    // add: formData => axios.post("/calendar", { ...formData }),
    // delete: id => axios.delete(`/calendar/${id}`),
    // update: (id,formData) => axios.put(`/calendar/${id}`, { ...formData }),  


    async list(pFilterDate) {

        try {
            this.state.loading = true;
            let response = await axios.post(`/calendar/list`, {...pFilterDate});
            //  console.log("response", response);   

            let list =  response.data.data;
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async listDiariesCalendar() {

        try {
            this.state.loading = true;
            let response = await axios.get(`/diaries?types=DAY,REM,FIN,TGT,TSH`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async listTimelinesCalendar(pFilter) {

        try {
            this.state.loading = true;
            let response = await axios.post(`/timeline/list`, {...pFilter});
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }


}