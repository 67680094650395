import Vue from 'vue';
import axios from 'axios'
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();

const initialState = {
    auxiliar: [],
    loading: false,
};

export class FinancialAccountManager {
    state;
    constructor() {
        this.state = Vue.observable(initialState);
    }

    // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
    extractData(pResponse) {
        const { data } = pResponse.data;
        // VERIFICA SE É UM OBJETO
        if (!!data?.attributes) {
            return globalManager._reduceResponse(data);
        }
        const list = data.map(item => (globalManager._reduceResponse(item)));
        return (list);
    }

    async list() {

        try {
            this.state.loading = true;
            let response = await axios.get(`/financial-accounts`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async create(pFormData) {

        try {
            this.state.loading = true;
            // console.log("create", pFormData)

            await axios.post("/financial-accounts", { ...pFormData });

            snackbarManager.setSuccessMessage('Conta financeira adicionada');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async update(pFormData) {

        try {
            this.state.loading = true;
            // console.log(pFormData);

            await axios.put(`/financial-accounts/${pFormData.id}`, { ...pFormData });

            snackbarManager.setSuccessMessage('Conta financeira atualizadas');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async delete(pItem) {

        try {
            this.state.loading = true;

            await axios.delete(`/financial-accounts/${pItem.id}`);

            snackbarManager.setSuccessMessage('Conta financeira removida');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async getFilesList(pAccountId) {
        try {

            this.state.loading = true;
            let response = await axios.get(`/files/financial-account/${pAccountId}`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;

        } catch (error) {
            snackbarManager.setErrorMessage("Erro", "Não foi possível recuperar os arquivos");
            return false;
        } finally {
            this.state.loading = false;
        }
    }

    async getOrphanFiles(pAccountId) {
        try {

            this.state.loading = true;
            let response = await axios.get(`/files/financial-accounts/orphan-files/${pAccountId}`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;

        } catch (error) {
            snackbarManager.setErrorMessage("Erro", "Não foi possível recuperar os arquivos órfãos");
            return false;
        } finally {
            this.state.loading = false;
        }
    }

    async getFilesProcess(pAction, pAccountId) {
        try {

            this.state.loading = true;
            let response = await axios.get(`/files/financial-accounts/file-process/${pAction}/${pAccountId}`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;

        } catch (error) {
            snackbarManager.setErrorMessage("Erro", "Não foi possível recuperar os arquivos para processar");
            return false;
        } finally {
            this.state.loading = false;
        }
    }

    async getLayouts() {
        try {

            this.state.loading = true;
            let response = await axios.get(`/financial-layouts`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;

        } catch (error) {
            snackbarManager.setErrorMessage("Erro", "Não foi possível recuperar os layouts");
            return false;
        } finally {
            this.state.loading = false;
        }
    }

    async getProcessFile(pProcessId) {
        try {

            this.state.loading = true;
            let response = await axios.get(`/financial-bank-statement-process/process/${pProcessId}`);
            // console.log("response", response);   

            snackbarManager.setSuccessMessage('Arquivo procesado');

            return globalManager.returnOk(true);

        } catch (error) {
            // console.log(error);
            if(error.response.data?.message){
                snackbarManager.setErrorMessage("Erro", error.response.data.message);
            } else {
                snackbarManager.setErrorMessage("Erro", "Não foi possível processar os arquivos");
            }
            return false;
        } finally {
            this.state.loading = false;
        }
    }

    async addToProcess(pFormData) {

        try {
            this.state.loading = true;
            // console.log("create", pFormData)

            await axios.post("/financial-bank-statement-process", { ...pFormData });

            snackbarManager.setSuccessMessage('Arquivo adicionado a fila');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

}