<template>
  <v-row justify="start">
    <v-col cols="12">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisar"
        single-line
        hide-details
        class="mb-2"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-data-table :headers="headers" :items="tokens" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="callDeleteToken(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-col>

    <v-row justify="start">
      <v-col cols="12" sm="4">
        <v-btn
          color="error"
          class="white--text my-4"
          @click="callDeleteTokens()"
        >
          <v-icon> mdi-delete </v-icon>
          Excluir todas as sessões
        </v-btn>
      </v-col>
    </v-row>

    <ConfirmDialog
      title="Tem certeza de deseja excluir este item ?"
      :description="deleteDescritionToken"
      :dialog="toggleDeleteToken"
      @confirm="deleteConfirmToken"
      @cancel="closeDialogDeleteToken"
    />

    <ConfirmDialog
      title="Tem certeza de deseja excluir todos os itens ?"
      :description="deleteDescritionTokens"
      :dialog="toggleDeleteTokens"
      @confirm="deleteConfirmTokens"
      @cancel="closeDialogDeleteTokens"
    />
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";
import { UserManager } from "@/manager/user-manager";
const userManager = new UserManager();

export default {
  name: "Tokens",

  components: {
    ConfirmDialog,
  },

  created() {
    this.callTokens();
  },

  watch: {},

  computed: {},

  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),

    async callTokens() {
      const response = await userManager.showTokens();
      // console.log("res", response);
      this.tokens = response;
    },

    // INIT DELETE
    callDeleteToken(item) {
      this.token = item;
      this.deleteDescritionToken = item.name;
      this.toggleDeleteToken = true;
    },

    async deleteConfirmToken() {
      const response = await userManager.deleteToken(this.token);
      if (response) {
        this.closeDialogDeleteToken();
        this.callTokens();
      }
    },

    closeDialogDeleteToken() {
      this.token = {};
      this.toggleDeleteToken = false;
    },

    callDeleteTokens() {
      this.deleteDescritionTokens =
        "Você esta preste a remover todos os tokens.";
      this.toggleDeleteTokens = true;
    },

    async deleteConfirmTokens() {
      const response = await userManager.deleteTokens();
      if (response) {
        this.closeDialogDeleteTokens();
        this.logout();
        this.$router.push("/login");
      }
    },

    closeDialogDeleteTokens() {
      this.toggleDeleteTokens = false;
    },
    // FINISHE DELETE
  },

  data: () => ({
    // Variables
    token: {},
    search: "",
    deleteDescritionToken: "",
    deleteDescritionTokens: "",

    // Dialog Delete
    toggleDeleteToken: false,
    toggleDeleteTokens: false,

    // Lists
    tokens: [],
    headers: [
      {
        text: "Dispositivo",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Primeiro acesso", value: "created_at" },
      { text: "Ultimo acesso", value: "last_used_at" },
      { text: "Excluir", value: "actions", sortable: false, align: "center" },
    ],
  }),
};
</script>
