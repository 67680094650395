<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="white"
        style="z-index: 3"
      ></v-progress-linear>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :style="{ color: fontColorApp }"
          color="default"
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon>mdi-notebook-plus-outline</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar
          dark
          color="default"
        >
          <!-- <v-btn icon dark @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn> -->
          <v-toolbar-title>Diário</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              <v-icon left>mdi-close</v-icon>
              Fechar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-col
          v-if="showAlert"
          cols="12"
        >
          <v-row class="ma-1">
            <v-alert
              height="100%"
              width="100%"
              dense
              outlined
              :type="propsAlert.color"
            >
              <v-row>
                <v-col class="grow">
                  {{ propsAlert.title }}
                  <div
                    v-if="propsAlert.message"
                    class="body-2"
                    v-html="propsAlert.message"
                  ></div>
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    @click="showAlert = !showAlert"
                    icon
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-row>
        </v-col>

        <v-form
          ref="refForm"
          lazy-validation
          onSubmit="return false;"
        >
          <v-list subheader>
            <v-list-item class="mt-6">
              <v-textarea
                outlined
                name="texto"
                label="Diário"
                height="350"
                hide-details=""
                v-model="formData.diary"
              ></v-textarea>
            </v-list-item>
          </v-list>
        </v-form>

        <v-row class="ma-1 justify-space-between">
          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <v-row class="ma-1">
              <DatePikerMenu
                :formDataDate.sync="formData.date"
                :formData="formData"
                label="Data"
                :mandatory="true"
                :maxDate="currentDate"
                nodeName="date"
                @change="callListDiary"
              />
            </v-row>
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
          >
            <v-row class="ma-1">
              <Select
                v-if="dialog"
                :list.sync="targets"
                :formData="formData"
                itemText="title"
                itemValue="id"
                label="Selecione uma meta"
                nodeName="target"
                :itemSelected.sync="formData.target"
                :mandatory="false"
              />
            </v-row>
          </v-col>

          <v-col
            cols="12"
            sm="2"
            md="3"
            align-self="end"
            class="d-flex justify-end"
          >
            <div class="py-4">
              <v-btn
                color="success"
                class="white--text"
                @click="callCreate()"
              >
                Salvar
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-col
          cols="12"
          class="ma-1 d-flex justify-start"
        >
          <div v-html="formattedDiary"></div>
        </v-col>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import { DiaryManager } from "../../manager/diary-manager";
const diaryManager = new DiaryManager();
import { TargetsManager } from "../../manager/targets-manager";
const targetsManager = new TargetsManager();
import { WorkspaceManager } from "../../manager/workspace-manager";
const workspaceManager = new WorkspaceManager();
import DatePikerMenu from "../DatePikers/DatePikerMenu.vue";
import Select from "@/components/Selects/Select.vue";

export default {
  components: {
    DatePikerMenu,
    Select,
  },

  data() {
    return {
      targets: [],
      dialog: false,
      notifications: false,
      currentDate: moment().format(),
      sound: true,
      widgets: false,
      formData: {},
      showDiary: "",
      propsAlert: {},
      showAlert: false,
    };
  },
  computed: {
    ...mapGetters({
      // carregar o módulo de autenticação e o usuário logado
      user: "auth/user",
      isAuthenticated: "auth/isAuthenticated",
    }),
    fontColorApp() {
      return this.user
        ? this.user.theme_font_color
        : localStorage.getItem("theme_font_color");
    },
    formattedDiary() {
      return this.showDiary.replace(/(\r\n|\n|\r)/g, "<br>");
    },
    loading: () => diaryManager.state.loading,
  },

  watch: {
    dialog(pValue) {
      this.callListTarget();
      this.callListDiary();
      diaryManager.listDiariesTypes();
    },
    $route(to, from) {
      if (to.name == "quik-diary") {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },

  created() {
    if (this.$route.name == "quick-diary") {
      this.dialog = true;
    } else {
      this.dialog = false;
    }
  },

  methods: {
    async callListTarget() {
      const data = await targetsManager.list();
      // console.log(data);
      this.targets = data.map((objeto) => objeto);
    },

    async callListDiary(pDateDiary) {
      if (!pDateDiary) return;
      const data = await workspaceManager.listAppendDiay(
        moment(pDateDiary).format("YYYY-MM-DD"),
        "DAY"
      );
      // console.log(data);

      if (data) {
        this.showDiary = data.diary;
      } else {
        this.showDiary = "";
      }
    },

    // CREATE
    // CRIA UM NOVO ITEM
    async callCreate() {
      //   console.log(this.formData);
      if (!this.$refs.refForm.validate()) return;
      this.formData.date = moment(this.formData.date).format("YYYY-MM-DD");
      this.formData.type = "DAY";
      var response = await diaryManager.createAppendDiary(this.formData);
      if (response.ok) {
        this.callListDiary(this.formData.date);
        this.$refs.refForm.reset();
        this.openAlert(response.ok, response, 3000);
      } else {
        this.openAlert(!response.ok, response, 0);
      }
    },

    openAlert(pShow, pPropsAlert, pTimeout) {
      this.showAlert = pShow;
      this.propsAlert = pPropsAlert;

      if (pTimeout > 0) {
        setTimeout(() => {
          this.showAlert = false;
        }, pTimeout);
      }
    },
  },
};
</script>
