<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Admin Dashboard</div>
      <v-spacer></v-spacer>
      <v-icon large v-if="user.is_admin">mdi-plus-circle</v-icon>
    </v-row>

    <v-row class="mt-6" v-if="!user.is_admin">
      <v-col cols="12" class="pa-0">
        <v-card class="pa-4">
          ⚠️ <b>Atenção:</b> você não possui acesso a esta rota.
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-6" v-if="user.is_admin">
      <v-col cols="12" class="pa-0">
        <v-card class="pa-4"> 
        <router-link to="/admin-users">
          Autorizar usuários  
        </router-link>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdminDashboard",
  
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      // carregar o módulo de autenticação e o usuário logado
      user: "auth/user",
      isAuthenticated: "auth/isAuthenticated",
    }),
  },

};
</script>
