import Vue from 'vue';
import axios from 'axios'
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();

const initialState = {
    auxiliar: [],
    loading: false,
};

export class MeetingManager {
    state;
    constructor() {
        this.state = Vue.observable(initialState);
    }

    // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
    extractData(pResponse) {

        const { data } = pResponse.data;

        // console.log("02 - type data", typeof data)
        // console.log("02.1 - data", data)

        // VERIFICA SE É UM OBJETO
        if (!!data?.attributes) {
            return globalManager._reduceResponse(data);
        }

        const list = data.map(item => (globalManager._reduceResponse(item)));
        // console.log("02.2 lista final", list)
        
        return (list);
    }

    async list() {

        try {
            this.state.loading = true;
            let response = await axios.get(`/meetings`);
            // console.log("01 - response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async show(pId) {

        try {
            this.state.loading = true;
            let response = await axios.get(`/meetings/${pId}`);
            console.log("response", response);   

            let meeting = this.extractData(response);
            console.log("List - Manager", meeting);

            return meeting;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async create(pFormData) {

        try {
            this.state.loading = true;
            // console.log("create", pFormData)

            await axios.post("/meetings", { ...pFormData });

            snackbarManager.setSuccessMessage('Reunião adicionada!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async update(pFormData) {

        try {
            this.state.loading = true;
            // console.log(pFormData);

            await axios.put(`/meetings/${pFormData.id}`, { ...pFormData });

            snackbarManager.setSuccessMessage('Reunião atualizadas!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async delete(pItem) {

        try {
            this.state.loading = true;

            await axios.delete(`/meetings/${pItem.id}`);

            snackbarManager.setSuccessMessage('Reunião removida!');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }
}