import Vue from "vue"
import App from "./App.vue"
import store from "./store"
import router from "./router"
import vuetify from "./plugins/vuetify"
import axios from "axios"
import Echo from "laravel-echo"

// TUDO COMECA AQUI.

/// incluir subscribe (nada é executado nesse momento).
require("@/store/subscribe")

/// Configura o axios como global
window.axios = axios
axios.defaults.baseURL = process.env.VUE_APP_API_URL

/// ???
Vue.config.productionTip = false

      // NAO ESTAMOS UTILIZANDO ESTE
      // COnfigure global pusher
      // Vue.prototype.$pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
      // 	cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      // })


// console.log(localStorage.getItem("token"))

// ESTE É O QUE ESTÁ FUNCIONANDO
window.Echo = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    // forceTLS: true,
    // authEndpoint: "/broadcasting/auth",
    // encrypted: true,
    authEndpoint: process.env.VUE_APP_API_URL + "/broadcasting/auth",
  
    auth: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  });



/// 1º auth/initConfig verifica se tem token no local storage e grava no estado.
/// 2º auth/initConfig recupera o usuário da api e grava no estado.
/// 3º O subscribe escuta a alteração do estado e configura o token na header do axios.
store.dispatch("auth/initConfig", localStorage.getItem("token")).then(() => {
    new Vue({
        vuetify,
        router,
        store,
        render: (h) => h(App),
    }).$mount("#app")
})
