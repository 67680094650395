<template>
  <v-menu
    ref="menuDatePosting"
    v-model="toggleMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :rules="mandatory ? [rules.required] : []"
        v-model="dateFormat"
        :label="label"
        
        hint="DD/MM/AAAA"
        persistent-hint
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        @blur="date = parseDate(dateFormat)"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      :max="maxDate && maxDate"
      @input="toggleMenu = false"
      @change="updateDate"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: "DatePikerMenu",

  props: {
    formData: {
      type: Object,
      require: false,
    },
    formDataDate: {
      type: String,
    },
    nodeName: {
      type: String,
    },
    label: {
      type: String,
    },
    maxDate: {
      type: String,
    },
    mandatory: {
      type: Boolean,
    },
  },

  created() {
    this.updateDate();
  },

  computed: {
    dateFormat() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
  },

  watch: {
    formData(pValue) {
      if (!!pValue[this.nodeName]) {
        this.date = pValue[this.nodeName];
        return;
      }
      this.date = moment().format();
    },
  },

  methods: {
    // UTILS
    parseDate(pDate) {
      // console.log(pDate);
      if (!pDate) return null;
      return moment(pDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    },

    updateDate() {
      // console.log("date....", this.date);
      this.$emit("change", this.date ? this.date : "");
      this.$emit("update:formDataDate", this.date ? this.date : "");
    },
  },

  data() {
    return {
      date: moment().format(),
      toggleMenu: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },
    };
  },
};
</script>

<style>
</style>