<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      overlay-opacity="0.8"
      style="width: 100%; z-index: 5000"
      persistent
      max-width="600px"
      open-delay="5000"
    >
      <v-card :loading="loading">
        <v-card-text>
          <!-- TOPO FORMULARIO  -->
          <div class="d-flex justify-end"></div>
          <v-card-actions>
            <v-row class="my-4 d-flex justify-space-between align-center">
              <div class="d-flex display-1 align-center">
                <span
                  class="text-h6 sm text-h5"
                  v-if="this.formData.id == undefined"
                >
                  Cadastrar metas financeiras
                </span>
                <span v-if="this.formData.id != undefined">Editar metas financeiras</span>
              </div>
              <v-btn fab rounded text tile x-small @click="toggleFinancialMonthlyGoalValues(false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>

          <v-form
            ref="refFinancialMonthlyGoalValuesForm"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-row>

              <!-- DATE PIKER - MOVEMENT  -->
              <v-col cols="6">
                <v-row class="pa-2">
                  <DatePikerMenu
                    :formDataDate.sync="formData.movement"
                    :formData="formData"
                    label="Data"
                    :mandatory="true"
                    nodeName="movement"
                  />
                </v-row>
              </v-col>

              <!-- INPUT - AMOUNT -->
              <v-col cols="6">
                <v-row class="pa-2">
                  <v-text-field
                    v-model="formData.amount"
                    label="Valor"
                    maxlength="10"
                    @keyup="formData.amount = formatNumber(formData.amount)"
                  />
                </v-row>
              </v-col>

              <!-- INPUT - PAID_BY -->
              <v-col cols="12">
                <v-row class="pa-2">
                  <v-text-field
                    v-model="formData.barcode"
                    label="Código de barras"
                    maxlength="60"
                  />
                </v-row>
              </v-col>

              <!-- TEXTAREA - NOTE  -->
              <v-col cols="12">
                <v-row class="pa-2">
                  <v-textarea
                    v-model="formData.diary"
                    outlined
                    no-resize
                    label="Diário"
                    maxlength="4000"
                  ></v-textarea>
                </v-row>
              </v-col>

              <!-- SWITCH - PAID -->
              <v-col cols="6">
                <v-row class="pa-2">
                  <v-switch
                    v-model="formData.paid"
                    label="Realizado"
                  ></v-switch>
                </v-row>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12">
                <v-row justify="end">
                  <v-btn
                    v-if="this.formData.id != undefined"
                    :disabled="!valid"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callUpdate()"
                  >
                    Editar
                  </v-btn>
                </v-row>
              </v-col>

              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  
<script>
// BIBLIOTECAS
// import moment from "moment";
import DatePikerMenu from "@/components/DatePikers/DatePikerMenu.vue";
// COMPONENTES

//MANAGERS
import { FinancialMonthlyGoalValuesManager } from "../../manager/financial-monthly-goal-values-manager";
export const financialMonthlyGoalValuesManager = new FinancialMonthlyGoalValuesManager();

export default {
  name: "FinancialMonthlyGoalValues",

  components: {
    DatePikerMenu
  },

  props: {
    formData: {
      type: Object,
      require: false,
    },

    showForm: {
      type: Boolean,
    },
  },

  data() {
    return {
      valid: false,
      movementDateBr : '',
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },
    };
  },

  created() {
    
  },

  computed: {
     loading: () => financialMonthlyGoalValuesManager.state.loading,
  },

  watch: {
    formData: function () {
      if(!isNaN(this.formData.amount)){
        if(this.formData.amount<0){
          this.formData.amount = this.formData.amount * (-1);
        }
      }
    }
  },

  methods: {
    refreshData() {
    },
    // CREATE
    // CRIA UM NOVO ITEM
    // async callCreate() {
    //   if (!this.$refs.refFinancialMonthlyGoalValuesForm.validate()) return;
    //   this.formData.type = this.selectTypeFinancialMonthlyGoalValues;
    //   var response = await financialMonthlyGoalValuesManager.create(this.formData);
    //   if (response.ok) {
    //     this.toggleFinancialMonthlyGoalValues(true);
    //   }
    // },
    // LIST
    // UPDATE
    // ATUALIZA UM ITEM
    async callUpdate() {
      console.log(this.formData);
      if (!this.$refs.refFinancialMonthlyGoalValuesForm.validate()) return;
      var response = await financialMonthlyGoalValuesManager.update(this.formData);
      if (response.ok) {
        this.toggleFinancialMonthlyGoalValues(true);
      }
    },

    // SERVICES
    // UTILIZADA PARA FECHAR O FORMULARIO. ELA RECEBE UM PARAMETRO
    // QUE VALIDA SE EXITE NESCESSIDADE DE ATUALIZAR A TABELA OU NAO
    toggleFinancialMonthlyGoalValues(pHasUpdate) {
      this.$refs.refFinancialMonthlyGoalValuesForm.resetValidation();
      this.$emit("emitClose", pHasUpdate);
    },

    // USADO PARA LIMPAR O FORMULARIO
    callClearForm() {
      this.$refs.refFinancialMonthlyGoalValuesForm.reset();
    },

    formatNumber(pValue) {
      var vValue = String(pValue);
      if (vValue.indexOf('.') >= 0 && vValue.indexOf(',') >= 0) {
          vValue = vValue.replace('.', '');
      }
      vValue = vValue.replace(',', '.');
      return vValue;
    },

  },
};
</script>