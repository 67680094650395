<template>
  <span class="markdown-note" v-html="formData.content_md"></span>
</template>

<script>

export default {
  name: "preview",
  props: {
    formData: {
      type: Object,
    },
  },
};
</script>

<style>
/*V - 1.0*/
.text-area-input {
  border: solid 1px;
  border-radius: 5px;
}
.markdown-note {
  font-family: Helvetica, arial, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  padding: 6px;
  color: #333;
}

.markdown-note > *:first-child {
  margin-top: 8px !important;
}

.markdown-note > *:last-child {
  margin-bottom: 30px !important;
}

.markdown-note > a {
  color: #4183c4;
  text-decoration: none;
}

.markdown-note > a.absent {
  color: #cc0000;
}

.markdown-note > a.anchor {
  display: block;
  padding-left: 30px;
  margin-left: -30px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.markdown-note > h1,
.markdown-note > h2,
.markdown-note > h3,
.markdown-note > h4,
.markdown-note > h5,
.markdown-note > h6 {
  margin: 20px 0 10px;
  padding: 0;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: text;
  position: relative;
}

.markdown-note > h2:first-child,
.markdown-note > h1:first-child,
.markdown-note > h1:first-child + h2,
.markdown-note > h3:first-child,
.markdown-note > h4:first-child,
.markdown-note > h5:first-child,
.markdown-note > h6:first-child {
  margin-top: 0;
  padding-top: 0;
}

.markdown-note > h1:hover a.anchor,
.markdown-note > h2:hover a.anchor,
.markdown-note > h3:hover a.anchor,
.markdown-note > h4:hover a.anchor,
.markdown-note > h5:hover a.anchor,
.markdown-note > h6:hover a.anchor {
  text-decoration: none;
}

.markdown-note > h1 tt,
.markdown-note > h1 code {
  font-size: inherit;
}
.markdown-note > h2 tt,
.markdown-note > h2 code {
  font-size: inherit;
}
.markdown-note > h3 tt,
.markdown-note > h3 code {
  font-size: inherit;
}
.markdown-note > h4 tt,
.markdown-note > h4 code {
  font-size: inherit;
}
.markdown-note > h5 tt,
.markdown-note > h5 code {
  font-size: inherit;
}
.markdown-note > h6 tt,
.markdown-note > h6 code {
  font-size: inherit;
}

.markdown-note > h1 {
  font-size: 28px;
  color: black;
}

.markdown-note > h2 {
  font-size: 24px;
  border-bottom: 1px solid #cccccc;
  color: black;
}

.markdown-note > h3 {
  font-size: 18px;
}

.markdown-note > h4 {
  font-size: 16px;
}

.markdown-note > h5 {
  font-size: 14px;
}

.markdown-note > h6 {
  color: #777777;
  font-size: 14px;
}

.markdown-note > p,
.markdown-note > blockquote,
.markdown-note > ul,
.markdown-note > ol,
.markdown-note > dl,
.markdown-note > li,
.markdown-note > table,
.markdown-note > pre {
  margin: 15px 0;
}

.markdown-note > hr {
  border: 0 none;
  color: #cccccc;
  height: 4px;
  padding: 0;
}

.markdown-note > h2:first-child {
  margin-top: 0;
  padding-top: 0;
}

.markdown-note > h1:first-child {
  margin-top: 0;
  padding-top: 0;
}

.markdown-note > h1:first-child + h2 {
  margin-top: 0;
  padding-top: 0;
}

.markdown-note > h3:first-child,
.markdown-note > h4:first-child,
.markdown-note > h5:first-child,
.markdown-note > h6:first-child {
  margin-top: 0;
  padding-top: 0;
}

.markdown-note > a:first-child h1,
.markdown-note > a:first-child h2,
.markdown-note > a:first-child h3,
.markdown-note > a:first-child h4,
.markdown-note > a:first-child h5,
.markdown-note > a:first-child h6 {
  margin-top: 0;
  padding-top: 0;
}

.markdown-note > h1 p,
.markdown-note > h2 p,
.markdown-note > h3 p,
.markdown-note > h4 p,
.markdown-note > h5 p,
.markdown-note > h6 p {
  margin-top: 0;
}

.markdown-note > li p.first {
  display: inline-block;
}

.markdown-note > ul,
.markdown-note > ol {
  padding-left: 30px;
}

.markdown-note > ul :first-child,
.markdown-note > ol :first-child {
  margin-top: 0;
}

.markdown-note > ul :last-child,
.markdown-note > ol :last-child {
  margin-bottom: 0;
}

.markdown-note > dl {
  padding: 0;
}

.markdown-note > dl dt {
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  padding: 0;
  margin: 15px 0 5px;
}

.markdown-note > dl dt:first-child {
  padding: 0;
}

.markdown-note > dl dt > :first-child {
  margin-top: 0;
}

.markdown-note > dl dt > :last-child {
  margin-bottom: 0;
}

.markdown-note > dl dd {
  margin: 0 0 15px;
  padding: 0 15px;
}

.markdown-note > dl dd > :first-child {
  margin-top: 0;
}

.markdown-note > dl dd > :last-child {
  margin-bottom: 0;
}

.markdown-note > blockquote {
  border-left: 4px solid #dddddd;
  padding: 0 15px;
  color: #777777;
}

.markdown-note > blockquote > :first-child {
  margin-top: 0;
}

.markdown-note > blockquote > :last-child {
  margin-bottom: 0;
}

.markdown-note > table {
  padding: 0;
}
.markdown-note > table tr {
  border-top: 1px solid #cccccc;
  background-color: white;
  margin: 0;
  padding: 0;
}

.markdown-note > table tr:nth-child(2n) {
  background-color: #f8f8f8;
}

.markdown-note > table tr th {
  font-weight: bold;
  border: 1px solid #cccccc;
  text-align: left;
  margin: 0;
  padding: 6px 13px;
}

.markdown-note > table tr td {
  border: 1px solid #cccccc;
  text-align: left;
  margin: 0;
  padding: 6px 13px;
}

.markdown-note > table tr th :first-child,
.markdown-note > table tr td :first-child {
  margin-top: 0;
}

.markdown-note > table tr th :last-child,
.markdown-note > table tr td :last-child {
  margin-bottom: 0;
}

.markdown-note > img {
  max-width: 100%;
}

.markdown-note > span.frame {
  display: block;
  overflow: hidden;
}

.markdown-note > span.frame > span {
  border: 1px solid #dddddd;
  display: block;
  float: left;
  overflow: hidden;
  margin: 13px 0 0;
  padding: 7px;
  width: auto;
}

.markdown-note > span.frame span img {
  display: block;
  float: left;
}

.markdown-note > span.frame span span {
  clear: both;
  color: #333333;
  display: block;
  padding: 5px 0 0;
}

.markdown-note > span.align-center {
  display: block;
  overflow: hidden;
  clear: both;
}

.markdown-note > span.align-center > span {
  display: block;
  overflow: hidden;
  margin: 13px auto 0;
  text-align: center;
}

.markdown-note > span.align-center span img {
  margin: 0 auto;
  text-align: center;
}

.markdown-note > span.align-right {
  display: block;
  overflow: hidden;
  clear: both;
}

.markdown-note > span.align-right > span {
  display: block;
  overflow: hidden;
  margin: 13px 0 0;
  text-align: right;
}

.markdown-note > span.align-right span img {
  margin: 0;
  text-align: right;
}

.markdown-note > span.float-left {
  display: block;
  margin-right: 13px;
  overflow: hidden;
  float: left;
}

.markdown-note > span.float-left span {
  margin: 13px 0 0;
}

.markdown-note > span.float-right {
  display: block;
  margin-left: 13px;
  overflow: hidden;
  float: right;
}

.markdown-note > span.float-right > span {
  display: block;
  overflow: hidden;
  margin: 13px auto 0;
  text-align: right;
}

.markdown-note > code,
.markdown-note > tt {
  margin: 0 2px;
  padding: 0 5px;
  white-space: nowrap;
  border: 1px solid #eaeaea;
  background-color: #f8f8f8;
  border-radius: 3px;
}

.markdown-note > pre code {
  margin: 0;
  padding: 0;
  white-space: pre;
  border: none;
  background: transparent;
}

.markdown-note > .highlight pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

.markdown-note > pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

.markdown-note > pre code,
pre tt {
  background-color: transparent;
  border: none;
}

/* ABAIXO ESTA A ESTILIZAÇÃO DO FLUITUANTE TO TOP */

.btn-scrol-page-top {
  position: fixed;
  bottom: 5rem;
  right: 2rem;

  background: #0d6efd;
  color: white;
  cursor: pointer;

  border: none;
  box-shadow: 0 5px 10px #ccc;
}
.btn-scrol-page-top:hover {
  background: #0a58ca;
}

/* ABAIXO ESTA A ESTILIZAÇÃO DO FLUITUANTE TO TOP */
.list {
  padding-left: 0;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: black;
}
</style>