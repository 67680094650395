<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Diário</div>
      <v-spacer></v-spacer>
    </v-row>

    <v-row class="mt-6">
      <v-col class="pa-0">
        <v-card :loading="loading" style="z-index: 0">
          <div>
            <v-card-title>
              Lista de anotações diarias
              <v-spacer></v-spacer>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="toggleSearch()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="refreshData()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="openDiaryForm()"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-card-title>

            <div
              class="d-flex justify-space-between align-center"
              v-if="showSearch"
            >
              <v-col cols="9">
                <v-text-field
                  v-model="searchDiary"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  dense
                  single-line
                  autofocus
                  hide-details
                />
              </v-col>

              <v-col cols="3" class="px-4">
                <v-row justify="end" class="mr-2">
                  <v-btn icon @click="clearSearch()">
                    <v-icon color="grey"> mdi-broom </v-icon>
                  </v-btn>
                  <v-btn icon @click="toggleSearch()">
                    <v-icon color="grey"> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </div>

            <v-data-table
              :headers="headers"
              :search="searchDiary"
              :items="diaries"
              loading-text="Carregando..."
              progress
              class="elevation-1"
            >
              <template v-slot:[`item.date`]="{ item }">
                {{ item.date | dateFormat(item.date) }}
              </template>

              <template v-slot:[`item.diary`]="{ item }">
                <span>
                  {{ item.diary | truncar(80) }}
                </span>
              </template>

              <template v-slot:[`item.metas`]="{ item }">
                {{
                  item.included.target && item.included.target.attributes.title
                }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <router-link :to="`/edit-diary/${item.id}${item.included.diary_type.attributes.title == 'Relembrar' ? '?types=REM': ''}`">
                  <v-icon small class="mr-2"> mdi-pencil </v-icon>
                </router-link>
                <v-icon small class="mr-2" @click="callDeleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <DiaryForm
      :formData="diary"
      :showForm="showDiaryForm"
      @emitClose="closeDiaryForm"
    />

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteDiary"
      :loading="loading"
      @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteDiary"
    />
  </div>
</template>
  
  <script>
import moment from "moment";

import { DiaryManager } from "../../manager/diary-manager";
const diaryManager = new DiaryManager();

import DiaryForm from "./DiaryForm";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";

var timeoutAutoSave;

export default {
  name: "Diary",
  
  components: {
    DiaryForm,
    ConfirmDialog,
  },

  filters: {
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    // ELLIPSES IN TABLE
    truncar(item, maxChar) {
      if (item != undefined && item.length > maxChar) {
        return item.substring(0, maxChar) + "...";
      } else {
        return item;
      }
    },
  },

  data() {
    return {
      // DIARY
      diaries: [],
      diary: {},
      showDiaryForm: false,

      // UTILS
      deleteText: "",
      toggleDialogDeleteDiary: false,

      // DEFINIÇÕES DA TABELA
      showSearch: false,
      searchDiary: "",
      headers: [
        {
          text: "Data",
          align: "start",
          value: "date",
          sortable: false,
        },
        {
          text: "Diário",
          align: "start",
          sortable: false,
          value: "diary",
        },
        {
          text: "Tipo",
          align: "start",
          value: "included.diary_type.attributes.title",
          sortable: false,
        },
        {
          text: "Metas",
          align: "start",
          value: "included.target.attributes.title",
          sortable: false,
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
    };
  },

  computed: {
    // listTargers: () => diariesManager.diaries(),
    _listDiary() {
      let tempDiary = [];
      // diariesManager.diaries().filter((item) =>
      // {
      //   if (item.data.included.type != "Diário") {
      //     tempDiary.push(item);
      //   }
      // });
      return tempDiary;
    },
    loading: () => diaryManager.state.loading,
  },

  async created() {
    this.refreshData();
  },

  methods: {
    // LISTA TODOS OS DIARIOS
    async callList() {
      const data = await diaryManager.list();
      // console.log(data);
      this.diaries = data;
    },

    // ABRE FORMULARIO DE INCLUSÃO
    openDiaryForm() {
      this.diary = {};
      this.showDiaryForm = true;
    },

    // FECHAR MODAL INCLUSAO
    closeDiaryForm(pHasUpdate) {
      if (pHasUpdate) {
        this.refreshData();
      }
      this.diary = {};
      this.showDiaryForm = false;
    },

    // EDITAR DOCUMENTO
    callEditItem(pItem) {
      // console.log(pItem)
      // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
      this.diary = Object.assign({}, pItem);
      this.showDiaryForm = true;
    },

    async deleteItemConfirm() {
      const ret = await diaryManager.delete(this.diary);
      if (ret.ok) {
        this.closeDialogConfirmDeleteDiary();
        this.refreshData();
      }
    },

    callDeleteItem(pItem) {
      this.diary = pItem;
      this.deleteText =
        "Tem certeza de deseja excluir o item: " + pItem.date + " ?";
      this.toggleDialogDeleteDiary = true;
    },

    closeDialogConfirmDeleteDiary() {
      this.diary = {};
      this.toggleDialogDeleteDiary = false;
    },

    // UTILS
    refreshData() {
      this.callList();
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

    clearSearch() {
      this.searchDiary = " ";
    },

  },
};
</script>


  