<template>
  <div>
    <v-overlay opacity="0.8" :value="show" v-if="isAuthenticated">

      <v-card class="mx-auto" max-width="344" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              Adicionar
            </v-list-item-title>
            <!-- <v-list-item-subtitle>texto</v-list-item-subtitle> -->
          </v-list-item-content>

        </v-list-item>

        <v-card-actions>
          <v-btn outlined rounded text @click="action('A')">
            <v-icon left>
              mdi-plus
            </v-icon>
            Financeiro
          </v-btn>
          <v-btn outlined rounded text @click="action('B')">
            <v-icon left>
              mdi-plus
            </v-icon>
            Diário
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-overlay>
  </div>
</template>


<script>

import { mapGetters } from "vuex";

export default {
  name: "QuickOptions",
  props: {
    show: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      // carregar o módulo de autenticação e o usuário logado
      user: "auth/user",
      isAuthenticated: "auth/isAuthenticated",
    }),
  }, 
  methods:{
    action(pAction){
      console.log(pAction);
      this.$emit("emitCloseOverlay");
    }
  }
};
</script>