<template>
  <v-footer app>
    <span style="color:#ccc;">
      {{ timeIcon }} {{ now }}
    </span>
    <v-spacer></v-spacer>
    <span class="ml-5 d-none d-sm-flex" style="color:#ccc;">
      <span class="ml-5 font-weight-thin">
        Tudo posso naquele que me fortalece.
      </span>
      Filipenses 4:13
    </span>
  </v-footer>
</template>

<script>

import moment from "moment";

export default {
  name: 'Footer',
  data() {
    return {
      now: '',
      timeIcon:'',
    }
  },
  created() {
    this.getNow();
  },
  methods: {
    getNow() {
      this.now = moment().format('HH:mm:ss');

      const now = moment(); // Obtém o horário atual
      const sixPm = moment().set({ hour: 0, minute: 0, second: 0 }); // Define as 6:00 PM de hoje
      const diff = now.diff(sixPm); // Calcula a diferença em milissegundos
      const duration = moment.duration(diff); // Converte a diferença em uma duração
      const hours = duration.hours();
      // const minutes = duration.minutes();
      // console.log(this.user.theme_dark);
      // console.log(hours);
      this.timeDiff = hours;
      
      if (this.timeDiff < 6){
        this.timeIcon = '😴'
      }
      if (this.timeDiff >= 6 && this.timeDiff < 7){
        this.timeIcon = '🙏🏼'
      }
      if (this.timeDiff >= 7 && this.timeDiff < 8){
        this.timeIcon = '💪🏼'
      }
      if (this.timeDiff >= 8 && this.timeDiff < 12){
        this.timeIcon = '☀️'
      }
      if (this.timeDiff >= 12 && this.timeDiff < 14){
        this.timeIcon = '🍽️'
      }
      if (this.timeDiff >= 14 && this.timeDiff < 17){
        this.timeIcon = '🌤️'
      }
      if (this.timeDiff >= 17 && this.timeDiff < 18){
        this.timeIcon = '⏳'
      }
      if (this.timeDiff >= 18 && this.timeDiff < 21){
        this.timeIcon = '🌙'
      }
      if (this.timeDiff >= 21 && this.timeDiff < 22){
        this.timeIcon = '🥱'
      }
      if (this.timeDiff >= 22 && this.timeDiff < 23){
        this.timeIcon = '🙏🏼'
      }
      if (this.timeDiff >= 23 && this.timeDiff < 24){
        this.timeIcon = '😴'
      }

      setTimeout(this.getNow, 1000);
    }
  }
}
</script>
