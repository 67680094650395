import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import AdminDashboard from    '../views/Admin/AdminDashboard.vue'
import AdminUsers from        '../views/Admin/AdminUsers.vue'
import Monitor from           '../views/Admin/Monitor.vue'
import Dashboard from         '../views/Dashboard/Dashboard.vue'
import HomeView from          '../views/Public/Home.vue'
import Login from             '../views/User/Login.vue'
import NewCode from           '../views/User/NewCode.vue'
import Perfil from            '../views/Perfil/Perfil.vue'
import Register from          '../views/User/Register.vue'
import Validation from        '../views/User/Validation.vue'

// GOLD TASK
import Drives from            '../views/Drives/Drives.vue'
import EditNote from          '../views/Notes/EditNote/EditNote.vue'
import Targets from           '../views/Targets/Targets.vue'
import Diary from             '../views/Diary/Diary.vue'
import EditDiary from         '../views/Diary/EditDiary/EditDiary.vue'
import HotList from           '../views/Workspace/HotList'

import FinancialAccount from  '../views/FinancialAccount/FinancialAccount.vue'
import FinancialBankStatement from  '../views/FinancialBankStatement/FinancialBankStatement.vue'
import FinancialCategory from '../views/FinancialCategory/FinancialCategory.vue'
import FinancialEntity from   '../views/FinancialEntity/FinancialEntity.vue'
// import FinancialBankStatement from '../views/FinancialBankStatement/ListFinancialBankStatement.vue'
import FinancialMonthlyGoal from '../views/FinancialMonthlyGoal/FinancialMonthlyGoal.vue'
import FinancialMovement from '../views/FinancialMovements/FinancialMovement.vue'
import FinancialMovementCreate from '../views/FinancialMovements/FinancialMovementCreate.vue'
import FinancialRecurrence from '../views/FinancialRecurrence/FinancialRecurrence.vue'
import Workspace from         '../views/Workspace/Workspace.vue'
import QuickDiary from        '../views/Workspace/Workspace.vue'
import Document from          '../views/Document/Document.vue'
import DocumentShow from      '../views/Document/DocumentShow.vue'
import Calendar from          '../views/Calendar/Calendar.vue'
import Timeline from          '../views/Timeline/Timeline.vue'
import Meeting from           '../views/Meeting/Meeting.vue'
import MeetingRoom from       '../views/Meeting/MeetingRoom.vue'
import Scenario from          '../views/Scenario/Scenario.vue'

// import Welcome from        '../views/Perfil/Welcome'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminDashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin-users',
    name: 'admin-users',
    component: AdminUsers,
    meta: { requiresAuth: true }
  },
  {
    path: '/monitor',
    name: 'monitor',
    component: Monitor,
  },
  {
    path: '/drives/:id?',
    name: 'drives',
    component: Drives,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/financial-accounts',
    name: 'FinancialAccount',
    component: FinancialAccount,
    meta: { requiresAuth: true }
  },
  {
    path: '/financial-bank-statements',
    name: 'FinancialBankStatement',
    component: FinancialBankStatement,
    meta: { requiresAuth: true }
  },
  {
    path: '/financial-categories',
    name: 'FinancialCategory',
    component: FinancialCategory,
    meta: { requiresAuth: true }
  },
  {
    path: '/financial-entities',
    name: 'FinancialEntity',
    component: FinancialEntity,
    meta: { requiresAuth: true }
  },
  // {
    //   path: '/financial-bank-statement',
    //   name: 'financialBankStatement',
    //   component: FinancialBankStatement
  // },
  {
    path: '/financial-monthly-goal',
    name: 'financialMonthlyGoal',
    component: FinancialMonthlyGoal,
    meta: { requiresAuth: true }
  },
  {
    path: '/financial-movement',
    name: 'financialMovement',
    component: FinancialMovement,
    meta: { requiresAuth: true }
  },
  {
    path: '/financial-recurrence',
    name: 'financialRecurrence',
    component: FinancialRecurrence,
    meta: { requiresAuth: true }
  },
  {
    path: '/scenarios',
    name: 'scenario',
    component: Scenario,
    meta: { requiresAuth: true }
  },
  {
    path: '/financial-movement-create',
    name: 'financialMovementCreate',
    component: FinancialMovementCreate,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-note/:id?',
    name: 'edit-note',
    component: EditNote,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/novo-codigo/:email?',
    name: 'novo-codigo',
    component: NewCode
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },  
  {
    path: '/perfil/:activeTab?',
    name: 'perfil',
    component: Perfil,
    meta: { requiresAuth: true }
  },
  {
    path: '/user/:uuid/validation/:code',
    name: 'validation',
    component: Validation
  },
  {
    path: '/targets',
    name: 'targets',
    component: Targets,
    meta: { requiresAuth: true }
  },
  {
    path: '/diary',
    name: 'diary',
    component: Diary,
    meta: { requiresAuth: true }
  },
  {
    path: '/quick-diary',
    name: 'quick-diary',
    component: QuickDiary,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-diary/:id?',
    name: 'editDiary',
    component: EditDiary,
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'workspace',
    component: Workspace,
    meta: { requiresAuth: true }
  },
  {
    path: '/documents',
    name: 'documents',
    component: Document,
    meta: { requiresAuth: true }
  },
  {
    path: '/document-show/:id/:group?/:note?',
    name: 'document-show',
    component: DocumentShow,
    meta: { requiresAuth: true }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar,
    meta: { requiresAuth: true }
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: Timeline,
    meta: { requiresAuth: true }
  },
  {
    path: '/meeting',
    name: 'meeting',
    component: Meeting,
    meta: { requiresAuth: true }
  },
  {
    path: '/meeting/:id',
    name: 'meeting-show',
    component: MeetingRoom,
    meta: { requiresAuth: true }
  },
  {
    path: '/hot-list',
    name: 'hot-list',
    component: HotList,
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  
  // console.log('from', from);
  // console.log('to', to);
  
  if (to.matched.some(route => route.meta.requiresAuth)) {
    
    // console.log('-- REQUER AUTENTICAÇÃO -- ');
    // console.log('Está autenticado?', store.getters['auth/isAuthenticated']);

    if (!store.getters['auth/isAuthenticated']) {
      // console.log('VAI PRO LOGIN');
      next('/login');
    } 
    next();
  } else {
    // console.log('ROTA PUBLICA');
    next();
  }
});

export default router
