<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" sm="3">
        <div class="text-h5 text-sm-h4">Movimentos financeiros</div>
      <v-spacer></v-spacer>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="3">
        <v-card :loading="loading" style="z-index: 0">
          
            <v-card-title>
              Lista de contas financeiras
            </v-card-title>
            
            <v-list-item-group
              v-model="account"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in this.listFinancialAccount"
                :key="item.id"
                @click="callList(item)"
              >
                <v-list-item-icon >
                  <v-icon v-if="item.type=='AC'">mdi-bank-transfer</v-icon>
                  <v-icon v-if="item.type=='SA'">mdi-piggy-bank</v-icon>
                  <v-icon v-if="item.type=='CC'">mdi-credit-card-outline</v-icon>
                  <v-icon v-if="item.type=='PP'">mdi-credit-card-plus</v-icon>
                  <v-icon v-if="item.type=='MO'">mdi-cash</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          
        </v-card>
      </v-col>

      <v-col cols="12" sm="9">
        <v-card :loading="loading" style="z-index: 0">
          <div>
            <v-card-title>
              Lista de movimentos financeiros
              <v-spacer></v-spacer>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="toggleSearch()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="refreshData()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <!-- <v-btn
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="openFinancialMovementForm()"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn> -->
            </v-card-title>

            <div
              class="d-flex justify-space-between align-center"
              v-if="showSearch"
            >
              <v-col cols="9">
                <v-text-field
                  v-model="searchFinancialMovement"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  dense
                  single-line
                  autofocus
                  hide-details
                />
              </v-col>

              <v-col cols="3" class="px-4">
                <v-row justify="end" class="mr-2">
                  <v-btn icon @click="clearSearch()">
                    <v-icon color="grey"> mdi-broom </v-icon>
                  </v-btn>
                  <v-btn icon @click="toggleSearch()">
                    <v-icon color="grey"> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </div>

            <v-data-table
              :headers="headers"
              :search="searchFinancialMovement"
              :items="listFinancialMovement"
              loading-text="Carregando..."
              progress
              class="elevation-1"
              items-per-page="1000"
              hide-default-footer
            >
              <template v-slot:[`item.movement`]="{ item }">
                {{ formatDate(item.movement) }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                <span class="red--text" v-if="item.amount < 0">{{ formatNumberMask(item.amount) }}</span>
                <span v-if="item.amount > 0">{{ formatNumberMask(item.amount) }}</span>
              </template>
              <template v-slot:[`item.balance`]="{ item }">
                <span class="red--text" v-if="item.balance < 0">{{ formatNumberMask(item.balance) }}</span>
                <span v-if="item.balance > 0">{{ formatNumberMask(item.balance) }}</span>
              </template>
              <template v-slot:[`item.transfer`]="{ item }">
                <v-icon v-if="typeof item.transfer == 'string'">mdi-bank-transfer</v-icon>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <!-- <v-icon small class="mr-2" @click="callEditItem(item)">
                  mdi-pencil
                </v-icon> -->
                <v-icon small class="mr-2" @click="callDeleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- <FinancialMovementForm
      :formData="financialMovement"
      :showForm="showFinancialMovementForm"
      @emitClose="closeFinancialMovementForm"
    /> -->

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteFinancialMovement"
      @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteFinancialMovement"
    />

  </div>
</template>
  
  <script>
import moment from "moment";

import { FinancialMovementManager } from "@/manager/financial-movement-list-manager";
const financialMovementManager = new FinancialMovementManager();

import { FinancialAccountManager } from "@/manager/financial-account-manager";
const financialAccountManager = new FinancialAccountManager();
  
// import FinancialMovementForm from "./FinancialMovementForm.vue";

import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";

export default {
  name: "FinancialMovement",
  components: {
    // FinancialMovementForm,
    ConfirmDialog,
  },

  created() {
    this.refreshData();
  },

  computed: {
    loading: () => financialMovementManager.state.loading,
  },

  methods: {
    // TARGETS
    async callList(item) {
      var account_id = item.id;
      var filterData = { 'account_id' : account_id };
      const data = await financialMovementManager.listFilter(filterData);
      console.log(data);
      this.listFinancialMovement = data;
    },
    async callListAccount() {
      const data = await financialAccountManager.list();
      // console.log(data);
      this.listFinancialAccount = data;
    },

    // ABRE FORMULARIO DE INCLUSÃO
    // openFinancialMovementForm() {
    //   this.financialMovement = {};
    //   this.showFinancialMovementForm = true;
    // },

    // FECHAR MODAL INCLUSAO
    // closeFinancialMovementForm(pHasUpdate) {
    //   if (pHasUpdate) {
    //     this.refreshData();
    //   }
    //   this.financialMovement = {};
    //   this.showFinancialMovementForm = false;
    // },

    // EDITAR DOCUMENTO
    // callEditItem(pItem) {
    //   // console.log(pItem)
    //   // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
    //   this.financialMovement = Object.assign({}, pItem);
    //   this.showFinancialMovementForm = true;
    // },

    async deleteItemConfirm() {
      const ret = await financialMovementManager.delete(this.financialMovement);
      if (ret.ok) {
        this.closeDialogConfirmDeleteFinancialMovement();
        this.refreshData();
      }
    },

    callDeleteItem(pItem) {
      this.financialMovement = pItem;
      if(this.financialMovement.transfer==null){
        this.deleteText = "Tem certeza de deseja excluir o movimento: " + pItem.movement + " no valor de R$ " + pItem.amount + " ?";
      } else {
        this.deleteText = "Tem certeza de deseja excluir a transferência: " + pItem.movement + " no valor de R$ " + pItem.amount + " ?";
      }
      this.toggleDialogDeleteFinancialMovement = true;
    },

    closeDialogConfirmDeleteFinancialMovement() {
      this.financialMovement = {};
      this.toggleDialogDeleteFinancialMovement = false;
    },

    // UTILS
    refreshData() {
      this.callListAccount();
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

    clearSearch() {
      this.searchFinancialMovement = " ";
    },

    formatDate(pValue) {
      var vValue = String(pValue);
      vValue = moment(vValue, 'YYYY-MM-DD').format("DD/MM/YYYY");
      return vValue;
    },

    formatNumberMask(pValue) {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
      var vValue = new Intl.NumberFormat(
          'pt-BR', 
          { style: 'decimal', 
            currency: 'BRL', 
            minimumFractionDigits : 2, 
            maximumFractionDigits: 2 
          }).format(pValue);
      return vValue;
    },
  },
  data() {
    return {
      
      listFinancialAccount: [],
      account : [],

      // TARGETS
      listFinancialMovement: [],
      financialMovement: {},
      // showFinancialMovementForm: false,

      // UTILS
      deleteText: "",
      toggleDialogDeleteFinancialMovement: false,

      // DEFINIÇÕES DA TABELA
      showSearch: false,
      searchFinancialMovement: "",
      headers: [
        {
          text: "Movimento",
          align: "start",
          value: "movement",
          sortable: true,
        },
        {
          text: "Ordem",
          align: "start",
          sortable: false,
          value: "order",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "transfer",
        },
        {
          text: "Referência",
          align: "start",
          sortable: false,
          value: "reference",
        },
        {
          text: "Entidade",
          align: "start",
          sortable: false,
          value: "included.entity_id.attributes.title",
        },
        {
          text: "Categoria",
          align: "start",
          sortable: false,
          value: "included.category_id.attributes.title",
        },
        {
          text: "Recorrência",
          align: "start",
          sortable: false,
          value: "recurrence",
        },
        {
          text: "Valor",
          align: "end",
          sortable: false,
          value: "amount",
        },
        {
          text: "Saldo",
          align: "end",
          sortable: false,
          value: "balance",
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ]
    };
  },
};
</script>
  
  <style scoped>
</style>
  