<template>
	<div class="pa-0">
		<v-row>
			<v-app-bar
				style="margin-top: 64px; height: 112px"
				class="header-tabs-responsive"
				app
				color="#6A76AB"
				dark
			>
				<v-app-bar-title class="text-h5 text-sm-h4">
					{{ document.title }}
				</v-app-bar-title>

				<v-spacer></v-spacer>
				<v-btn
					v-if="user.id === document.creator"
					class="mr-2"
					type="button"
					color="white"
					icon
					@click="openFormGroupDocument()"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>

				<template v-slot:extension>
					<v-tabs v-model="tabsGroupDocument">
						<v-tab
							v-for="(item, index) in listGroupDocument"
							:key="index"
							:href="`#group-${item.id}`"
							@click="nextGroup(item)"
						>
							<div class="d-flex">
								{{ item.title }}
							</div>
						</v-tab>
					</v-tabs>
				</template>
			</v-app-bar>
		</v-row>

		<div class="pa-4">
			<v-row v-if="listGroupDocument.length == 0" class="mt-1">
				<Message
					sizeIcon="200"
					styleIcon="color: #CCC;"
					styleTitle="color: #DBDBDB;"
					styleDescription="color: #DBDBDB;"
					title="Grupo não encontrado"
					description="Por favor, criar um grupo para continuar com os cadastros."
				/>
			</v-row>
			<v-tabs-items v-model="tabsGroupDocument">
				<v-tab-item
					v-for="item in listGroupDocument"
					:key="item.id"
					:value="`group-${item.id}`"
				>
					<v-row v-if="!loading && !loadingCurrent" class="mt-1">
						<v-col cols="12" md="3" lg="2">
							<v-list v-if="listNoteDocument.length > 0" dense>
								<template v-for="item in listNoteDocument">
									<div class="d-flex align-center">
										<div
											class="d-flex align-center text--secondary text-truncate list-itens-draggable"
											:key="item.id"
											@click="openNoteDocument(item)"
										>
											<span
												:class="
													$route.params?.note ===
														item.id &&
													'list-item-active-route'
												"
												>{{ item.title }}</span
											>
										</div>
										<v-spacer />
										<v-menu
											v-if="activeEdition"
											transition="slide-x-transition"
											bottom
											left
										>
											<template
												v-slot:activator="{ on, attrs }"
											>
												<v-btn
													class=""
													type="button"
													icon
													dense
													v-bind="attrs"
													v-on="on"
												>
													<v-icon
														>mdi-dots-vertical</v-icon
													>
												</v-btn>
											</template>

											<v-list>
												<v-list-item
													@click="
														callEditNoteDocument(
															item
														)
													"
													style="cursor: pointer"
												>
													<v-list-item-title>
														<v-icon
															size="20"
															class="mr-2"
														>
															mdi-pencil
														</v-icon>
														Editar
													</v-list-item-title>
												</v-list-item>
												<v-list-item
													@click="
														callDeleteItemNoteDocument(
															item
														)
													"
													style="cursor: pointer"
												>
													<v-list-item-title>
														<v-icon
															size="20"
															class="mr-2"
														>
															mdi-close
														</v-icon>
														Excluir
													</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</div>
								</template>
							</v-list>

							<!-- <v-text-field
								v-if="user.id === document.creator"
								v-model="newNote"
								append-icon="mdi-send"
								clearable
								type="text"
								label="Nota"
								dense
								outlined
								@click:append="
									newNote ? callCreateNote() : () => {}
								"
							></v-text-field> -->

							<SelectAutoCompleted
								v-if="user.id === document.creator"
								:list.sync="listNotesGroupDocuments"
								:formData="noteDocument"
								itemText="title"
								:outlined="true"
								itemValue="id"
								label="Selecione Nota"
								nodeName=""
								@includedItem="callCreateIncludedNote"
								:itemSelected.sync="noteDocument.note"
								:mandatory="false"
							/>
						</v-col>

						<v-col cols="12" md="9" lg="8">
							<div v-if="noteDocument?.id" class="d-flex">
								<div class="text-h5 text-sm-h4">
									{{ noteDocument.title }}
								</div>
								<v-spacer></v-spacer>
								<v-btn
									type="button"
									:to="'/edit-note/' + noteDocument.id"
									color="blue"
									icon
								>
									<v-icon>mdi-file-edit</v-icon>
								</v-btn>
							</div>

							<Message
								v-else
								sizeIcon="200"
								styleIcon="color: #CCC;"
								styleTitle="color: #DBDBDB;"
								styleDescription="color: #DBDBDB;"
								title="Nota não encontrado"
								description="Por favor, vincule ou crie uma nota para adicionar ao grupo."
							/>

							<Message
								v-if="
									noteDocument.content_md == null &&
									noteDocument?.id
								"
								sizeIcon="200"
								icon="mdi-file-hidden"
								styleIcon="color: #CCC;"
								styleTitle="color: #DBDBDB;"
								styleDescription="color: #DBDBDB;"
								title="Nota em branco"
								description="A nota não possui conteúdo."
							/>

							<PreviewMd :formData="noteDocument" />
						</v-col>

						<v-col class="d-md-none d-lg-flex" cols="12" lg="2">
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs-items>
		</div>

		<GroupDocumentForm
			:formData="groupDocument"
			:showForm="showFormGroupDocument"
			@emitClose="closeFormGroupDocument"
		/>
	</div>
</template>

<script>
import { mapGetters } from "vuex"

import { DocumentsManager } from "@/manager/document-manager"
const documentsManager = new DocumentsManager()

import GroupDocumentForm from "./GroupDocument/GroupDocumentForm.vue"
import PreviewMd from "@/components/PreviewMd/Preview.vue"
import Message from "@/components/Alert/Message.vue"
import SelectAutoCompleted from "@/components/Selects/SelectAutoCompleted.vue"

export default {
	name: "DocumentShow",

	components: {
		PreviewMd,
		Message,
		GroupDocumentForm,
		SelectAutoCompleted,
	},

	data() {
		return {
			// GLOBAL
			activeEdition: false,

			// DOCUMENT
			document: {},
			loadingCurrent: true,

			// DRIVE DOCUMENT
			showFormGroupDocument: false,
			tabsGroupDocument: `group-${
				this.$route.params.group ? this.$route.params.group : "0"
			}`,
			listGroupDocument: [],
			groupDocument: {},
			toggleDialogDeleteGroupDocument: false,

			// NOTES DOCUMENT
			listNotesGroupDocuments: [],
			listNoteDocument: [],
			noteDocument: {},
			newNote: "",
			showFormNoteDocument: false,
		}
	},

	computed: {
		loading: () => documentsManager.state.loading,
		...mapGetters({
			// carregar o módulo de autenticação e o usuário logado
			user: "auth/user",
		}),
	},

	created() {
		this.callDocument()
	},

	methods: {
		nextGroup(pItem) {
			this.loadingCurrent = true
			this.listNoteDocument = {}
			this.noteDocument = {}
			this.groupDocument = pItem
			this.$router
				.replace(
					`/${this.$route.name}/${this.$route.params.id}/${pItem.id}`
				)
				.then()
				.catch((err) => console.log(err))

			this.callListNoteDocument();
		},

		// LISTA O DOCUENTO
		async callDocument() {
			const data = await documentsManager.showDocument(
				this.$route.params.id
			)
			this.loadingCurrent = true;
			// console.log(data)
			this.document = data

			if (data?.drive) {
				this.callListGroupDocument()
				return;
			}
			this.loadingCurrent = false;
		},

		// LISTA DOS DRIVES DESTE DOCUMENTO
		async callListGroupDocument() {
			const data = await documentsManager.listGroupDocument(
				this.$route.params.id
			)
			this.listGroupDocument = data

			if (data.length > 0) {
				this.callListNoteDocument()
				return
			}
			this.loadingCurrent = false;
		},

		// LISTA AS NOTAS DE UM GRUPO DOCUMENT SELECIONADO
		async callListNoteDocument() {
			this.loadingCurrent = true
			let id = ""
			if (this.$route.params.group == undefined) {
				this.$router.replace(
					`/document-show/${this.$route.params.id}/${this.listGroupDocument[0].id}`
				)
				id = this.listGroupDocument[0].id
			} else {
				id = this.$route.params.group
			}

			const data = await documentsManager.listNoteDocument(id)
			this.listNoteDocument = data

			this.callListNote(id)

			if (data.length > 0 && data?.ok != false) {
				if (this.$route.params.note == undefined) {
					this.$router.replace(
						`/document-show/${this.$route.params.id}/${this.$route.params.group}/${data[0].id}`
					)
					this.noteDocument = data[0].included.note.attributes
				} else {
					this.noteDocument = data
						.filter((item) => item.id === this.$route.params.note)
						.map((item) => item.included.note.attributes)
						.reduce((item) => item)
				}
			} else {
				this.noteDocument = {}
			}
			this.loadingCurrent = false;
		},

		// LISTA AS NOTAS DE UM GRUPO DOCUMENT SELECIONADO
		async callListNote(pGroupDocumentId) {
		 	if(this.user.id !== this.document.creator){
				return;
			}
			
			const listNotes = []
			const driveId = this.listGroupDocument
			.filter((item) => item.id === pGroupDocumentId)
			.map((item) => item.drive)
			.reduce((item) => item)		

			const data = await documentsManager.listNotesInGroupDocument(driveId)

			if (this.listNoteDocument.length > 0) {
				const listIdNotes = this.listNoteDocument.map((item) => item.note_id)
				data.map((itemNote) => {
					if (!listIdNotes.includes(itemNote.id)) {
						listNotes.push(itemNote)
					}
				})
				this.listNotesGroupDocuments = listNotes
				return
			}
			this.listNotesGroupDocuments = data
		},

		// ABRE FORMULARIO DE INCLUSÃO DE GRUPO DOCUMENT
		openFormGroupDocument() {
			// console.log(this.document)
			this.groupDocument = {
				document_id: this.document.id,
				parent_drive: this.document.drive,
			}
			this.showFormGroupDocument = true
		},

		// FECHAR MODAL INCLUSAO DO GRUPO DOCUMENT
		async closeFormGroupDocument(pHasUpdate) {
			if (pHasUpdate) {
				await this.callListGroupDocument()
			}
			this.showFormGroupDocument = false
		},

		//  FUNÇÃO INCLUI NOTA (NOTE DOCUMENT)
		async callCreateIncludedNote(pValue) {
			const formData = {
				group_id: this.$route.params.group,
				note_id: pValue.id,
				...pValue,
			}

			const response = await documentsManager.createNoteDocument(formData)
			if (response.ok) {
				this.callListNoteDocument()
			}
		},

		//  FUNÇÃO CRIA NOTA (NOTE DOCUMENT)
		async callCreateNote() {
			const formData = {
				group_id: this.$route.params.group,
				title: this.newNote,
			}
			// console.log(formData);
			const response = await documentsManager.createNewNoteDocument(
				formData
			)
			if (response.ok) {
				this.newNote = ""
				this.callListNoteDocument()
			}
		},

		// ABRE NOTA APOS CLICAR AO ITEM DE MENU DA NOTA
		openNoteDocument(pItem) {
			// console.log(pItem)
			if (this.$route.params.note === pItem.id) return
			this.$router.replace(
				`/document-show/${this.$route.params.id}/${this.$route.params.group}/${pItem.id}`
			)
			// console.log(this.$route);
			// console.log(`${this.$route.path}`);
			this.noteDocument = pItem.included.note.attributes
		},
	},
}
</script>

<style scoped>
.disable-link {
	pointer-events: none;
}

.list-itens-draggable:hover span {
	font-weight: 500;
	color: #627df7;
	cursor: pointer;
}
.list-itens-draggable:hover {
	cursor: pointer;
}
.list-itens-draggable {
	height: 36px;
}
.list-item-active-route {
	font-weight: 500;
	color: #627df7;
}
/* UTILIZADO PARA MANTER O DOCUMENTO ALINHADO AO HEADER */
@media (max-width: 960px) {
	.header-tabs-responsive {
		margin-top: 56px !important;
	}
}
</style>
