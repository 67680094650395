<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Reunião</div>
      <v-spacer></v-spacer>
    </v-row>

    <v-row justify="start">
      <v-col cols="12" sm="6" class="pa-0">
        <v-card :loading="loading" style="z-index: 0">
          <div>
            <v-card-title>
              Lista de Reuniões
              <v-spacer></v-spacer>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="toggleSearch()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="refreshData()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="openMeetingForm()"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-card-title>

            <div
              class="d-flex justify-space-between align-center"
              v-if="showSearch"
            >
              <v-col cols="9">
                <v-text-field
                  v-model="searchMeeting"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  dense
                  single-line
                  autofocus
                  hide-details
                />
              </v-col>

              <v-col cols="3" class="px-4">
                <v-row justify="end" class="mr-2">
                  <v-btn icon @click="clearSearch()">
                    <v-icon color="grey"> mdi-broom </v-icon>
                  </v-btn>
                  <v-btn icon @click="toggleSearch()">
                    <v-icon color="grey"> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </div>

            <v-data-table
              :headers="headers"
              :search="searchMeeting"
              :items="listMeeting"
              loading-text="Carregando..."
              progress
              class="elevation-1"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="callEditItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="callDeleteItem(item)">
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click="openMeetingRoom(item)">
                  mdi-laptop-account
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <MeetingForm
      :formData="meeting"
      :showForm="showMeetingForm"
      @emitClose="closeMeetingForm"
    />

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteMeeting"
      :loading="loading"
      @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteMeeting"
    />
  </div>
</template>
  
  <script>
import MeetingForm from "./MeetingForm.vue";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";

import { MeetingManager } from "@/manager/meeting-manager";
const meetingManager = new MeetingManager();


export default {
  name: "Meeting",
  components: {
    MeetingForm,
    ConfirmDialog,
  },

  created() {
    this.callList();
  },

  computed: {
    loading: () => meetingManager.state.loading,
  },

  methods: {
    // TARGETS
    async callList() {
      const data = await meetingManager.list();
      console.log(data);

      // console.log("01 - filter");
      // var item1 = data.filter((item) => (console.log("item -> ", item.agenda + "\n resultado = ", item.agenda === "Meet 2"), item.agenda === "Meet 2") );
      // console.log("01 - item1 é um array - ", item1);
      // console.log("01.1 - Objeto item1 por indice - ", item1[1]);
      // console.log("02 - filter and map");

      // var item2 = []
      // data.map((item) => {
      //   if(item.agenda === "Meet 2"){
      //     item2.push(item)
      //   }
      // });
      // console.log("02 - item2 é um array - ", item2);
      // console.log("01.1 - Objeto item1 por indice - ", item1[1]);

      // var item3 =  data.filter((item) => item.agenda === "Meet 2").map((item) => item)
      // console.log("03 - item3 é um array - ", item3);
      // console.log("03.1 - Objeto item1 com filter - ", item3[1]);

      // data.forEach((element) => {
      //   console.log(element);
      // });

      // for (let index = 0; index < data.length; index++) {
      //   console.log(data[index]);
      // }

      // for (const key in data.filter((item) => item.agenda === "Meet 2").map((item) => item)) {
      //   console.log("--- Fora IF --key--", key);
      //   console.log("--- Fora IF ----", data[key]);
      //   if (Object.hasOwnProperty.call(data, key)) {
      //     console.log("--- IF --key--", key);
      //     console.log("--- IF ----", data[key]);
      //   }
      // }

      // for (const iterator of data
      //   .filter((item) => item.agenda === "Meet 2")
      //   .map((item) => item)) {
      //   console.log("---iterator--", iterator);
      // }

      // data.map(function (item) {
      //   console.log("function", item);
      // });
      // data.map((item) => {
      //   console.log("arrow", item);
      // });

      this.listMeeting = data;
    },

    // ABRE FORMULARIO DE INCLUSÃO

    // ABRE FORMULARIO DE INCLUSÃO
    openMeetingRoom(pItem) {
      this.$router.push("/meeting/" + pItem.id);
    },
    // ABRE FORMULARIO DE INCLUSÃO
    openMeetingForm() {
      this.meeting = {};
      this.showMeetingForm = true;
    },

    // FECHAR MODAL INCLUSAO
    closeMeetingForm(pHasUpdate) {
      if (pHasUpdate) {
        this.refreshData();
      }
      this.meeting = {};
      this.showMeetingForm = false;
    },

    // EDITAR DOCUMENTO
    callEditItem(pItem) {
      // console.log(pItem)
      // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
      this.meeting = Object.assign({}, pItem);
      this.showMeetingForm = true;
    },

    async deleteItemConfirm() {
      const ret = await meetingManager.delete(this.meeting);
      if (ret.ok) {
        this.closeDialogConfirmDeleteMeeting();
        this.refreshData();
      }
    },

    callDeleteItem(pItem) {
      this.meeting = pItem;
      this.deleteText =
        "Tem certeza de deseja excluir o item: " + pItem.agenda + " ?";
      this.toggleDialogDeleteMeeting = true;
    },

    closeDialogConfirmDeleteMeeting() {
      this.meeting = {};
      this.toggleDialogDeleteMeeting = false;
    },

    // UTILS
    refreshData() {
      this.callList();
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

    clearSearch() {
      this.searchMeeting = " ";
    },
  },

  data() {
    return {
      // TARGETS
      listMeeting: [],
      meeting: {},
      showMeetingForm: false,

      // UTILS
      deleteText: "",
      toggleDialogDeleteMeeting: false,

      // DEFINIÇÕES DA TABELA
      showSearch: false,
      searchMeeting: "",
      headers: [
        {
          text: "Pauta",
          align: "start",
          sortable: false,
          value: "agenda",
        },
        {
          text: "Ata",
          align: "start",
          value: "minutes",
          sortable: false,
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
};
</script>

<style scoped></style>
