import Vue from 'vue';
import axios from 'axios'
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();

const initialState = {
    auxiliar: [],
    loading: false,
};

export class FinancialBankStatementManager {
    state;
    constructor() {
        this.state = Vue.observable(initialState);
    }

    // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
    extractData(pResponse) {
        const { data } = pResponse.data;
        // VERIFICA SE É UM OBJETO
        if (!!data?.attributes) {
            return globalManager._reduceResponse(data);
        }
        const list = data.map(item => (globalManager._reduceResponse(item)));
        return (list);
    }

    async list() {

        try {
            this.state.loading = true;
            let response = await axios.get(`/financial-bank-statement`);
            // console.log("response", response);   

            let list = this.extractData(response);
            // console.log("List - Manager", list);

            return list;
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    async listFilter(filterData) {

      try {
        this.state.loading = true;
  
        let response = await axios.post(`/financial-bank-statement-list`, { ...filterData });
        // console.log("response", response);
  
        let list = this.extractData(response);
        // console.log("List - Manager", list);
  
        return list;
      } catch (error) {
  
        globalManager.printErrors(error);
  
        return globalManager.returnOk(false);
  
      } finally {
        this.state.loading = false;
      }
    }

    // async create(pFormData) {

    //     try {
    //         this.state.loading = true;
    //         // console.log("create", pFormData)

    //         await axios.post("/financial-bank-statement", { ...pFormData });

    //         snackbarManager.setSuccessMessage('Entidade financeira adicionada');

    //         return globalManager.returnOk(true);
    //     } catch (error) {

    //         globalManager.printErrors(error);

    //         return globalManager.returnOk(false);
    //     } finally {
    //         this.state.loading = false;
    //     }
    // }

    async update(pFormData) {

        try {
            this.state.loading = true;
            // console.log(pFormData);

            await axios.put(`/financial-bank-statement/${pFormData.id}`, { ...pFormData });

            snackbarManager.setSuccessMessage('Entidade financeira atualizadas');

            return globalManager.returnOk(true);
        } catch (error) {

            globalManager.printErrors(error);

            return globalManager.returnOk(false);
        } finally {
            this.state.loading = false;
        }
    }

    // async delete(pItem) {

    //     try {
    //         this.state.loading = true;

    //         await axios.delete(`/financial-bank-statement/${pItem.id}`);

    //         snackbarManager.setSuccessMessage('Entidade financeira removida');

    //         return globalManager.returnOk(true);
    //     } catch (error) {

    //         globalManager.printErrors(error);

    //         return globalManager.returnOk(false);
    //     } finally {
    //         this.state.loading = false;
    //     }
    // }
}