<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      overlay-opacity="0.8"
      style="width: 100%; z-index: 5000"
      persistent
      max-width="400px"
      open-delay="5000"
    >
      <v-card :loading="loading || loadingTarget">
        <v-card-text>
          <!-- TOPO FORMULARIO  -->
          <div class="d-flex justify-end"></div>
          <v-card-actions>
            <v-row class="my-4 d-flex justify-space-between align-center">
              <div class="d-flex display-1 align-center">
                <span v-if="this.formData.id == undefined">{{
                  formData.type === "REM" ? "Novo Lembrete" : "Novo Diário"
                }}</span>
                <!-- <span v-if="formData.type === 'REM'">Novo Lembrete</span> -->
                <!-- <span v-if="this.formData.id != undefined">Editar Diário</span> -->
              </div>
              <v-btn fab rounded text tile x-small @click="toggleForm(false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>

          <v-form
            ref="refForm"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-row>
              <!-- CAMPO DIARIO  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <v-textarea
                    v-model="formData.diary"
                    autofocus
                    outlined
                    no-resize
                    label="Diário"
                    maxlength="4000"
                    :rules="[rules.required]"
                    required
                  ></v-textarea>
                </v-row>
              </v-col>

              <!-- CAMPO META  -->
              <v-col cols="12" v-if="formData.type !== 'REM'">
                <v-row class="ma-1">
                  <Select
                    v-if="showForm"
                    :list.sync="targets"
                    :formData="formData"
                    itemText="title"
                    itemValue="id"
                    label="Selecione uma meta"
                    nodeName="target"
                    :rules="[rules.required]"
                    :itemSelected.sync="formData.target"
                    :mandatory="false"
                  />
                </v-row>
              </v-col>

              <!-- CAMPO DATE  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <DatePikerMenu
                    :formDataDate.sync="formData.date"
                    :formData="formData"
                    label="Data"
                    :mandatory="true"
                    nodeName="date"
                  />
                </v-row>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12">
                <v-row justify="end">
                  <v-btn
                    v-if="this.formData.id == undefined"
                    :disabled="!valid || loading || loadingTarget"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callCreate()"
                  >
                    Salvar
                  </v-btn>
                  <!-- NÃO EXISTE EDIÇÃO DE DIARIO -->
                  <!-- <v-btn
                    v-if="this.formData.id != undefined"
                    :disabled="!valid"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callUpdate()"
                  >
                    Editar
                  </v-btn> -->
                </v-row>
              </v-col>

              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { TargetsManager } from "../../manager/targets-manager";
const targetsManager = new TargetsManager();
import { DiaryManager } from "../../manager/diary-manager";
const diaryManager = new DiaryManager();

import Select from "@/components/Selects/Select.vue";
import DatePikerMenu from "@/components/DatePikers/DatePikerMenu.vue";

export default {
  name: "DiaryForm",

  components: {
    Select,
    DatePikerMenu,
  },

  props: {
    formData: {
      type: Object,
      require: false,
    },
    showForm: {
      type: Boolean,
    },
  },

  data() {
    return {
      targets: [],

      valid: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },
    };
  },

  computed: {
    loading: () => diaryManager.state.loading,
    loadingTarget: () => targetsManager.state.loading,
  },

  created() {
    this.callListTarget();
  },

  methods: {
    async callListTarget() {
      const data = await targetsManager.list();
      // console.log(data)
      this.targets = data.map((objeto) => objeto);
    },

    // CREATE
    // CRIA UM NOVO ITEM
    async callCreate() {
      if (!this.$refs.refForm.validate()) return;

      if (!!this.formData.target) {
        this.formData.type = "TGT";
      }
      if (!!!this.formData.type && !!!this.formData.target) {
        this.formData.type = "DAY";
      }
      // console.log(this.formData);

      var response = await diaryManager.create(this.formData);
      if (response.ok) {
        this.toggleForm(true);
      }
    },

    // SERVICES
    // UTILIZADA PARA FECHAR O FORMULARIO. ELA RECEBE UM PARAMETRO
    // QUE VALIDA SE EXITE NESCESSIDADE DE ATUALIZAR A TABELA OU NAO
    toggleForm(pHasUpdate) {
      this.$refs.refForm.resetValidation();
      this.$emit("emitClose", pHasUpdate);
    },

    // USADO PARA LIMPAR O FORMULARIO
    callClearForm() {
      this.$refs.refForm.reset();
    },
  },
};
</script>

<style scoped>
</style>