import Vue from 'vue';
import axios from 'axios'
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();

const initialState = {
  auxiliar: [],
  loading: false,
};

export class FinancialMovementCreateManager {
  state;
  constructor() {
    this.state = Vue.observable(initialState);
  }

  // _reduceResponse(pList) {
  //   const list = { ...pList.data.attributes, 'id': pList.data.id };
  //   // console.log(list);
  //   return (list);
  // }

  // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
  extractData(pResponse) {
      const { data } = pResponse.data;
      // VERIFICA SE É UM OBJETO
      if (!!data?.attributes) {
          return globalManager._reduceResponse(data);
      }
      const list = data.map(item => (globalManager._reduceResponse(item)));
      return (list);
  }
  
  async listAccounts() {
    try {

      this.state.loading = true;
      let response = await axios.get(`/financial-accounts`);
      // console.log(response);   

      let list = this.extractData(response);
      // console.log(list);

      return list;

    } catch (error) {
      snackbarManager.setErrorMessage("Erro", "Não foi possível carregar a lista de Entidades");
      return false;
    } finally {
      this.state.loading = false;
    }
  }

  async listEntities() {
    try {
      this.state.loading = true;
      let response = await axios.get(`/financial-entities`);
      // console.log(response);   

      let list = this.extractData(response);
      // console.log(list);

      return list;

    } catch (error) {
      console.error('ERRO AO RECUPERAR AS ENTIDADES');
      console.log(error);
      snackbarManager.setErrorMessage("Erro", "Não foi possível carregar a lista de Entidades");
      return false;
    } finally {
      this.state.loading = false;
    }
  }

  async listCategories() {
    try {
      this.state.loading = true;
      let response = await axios.get(`/financial-categories`);
      // console.log(response);   

      let list = this.extractData(response);
      // console.log(list);

      return list;

    } catch (error) {
      console.error('ERRO AO RECUPERAR AS CATEGORIAS');
      console.log(error);
      snackbarManager.setErrorMessage("Erro", "Não foi possível carregar a lista de Entidades");
      return false;
    } finally {
      this.state.loading = false;
    }
  }

  async listEntitiesVsCategories() {
    try {
      this.state.loading = true;
      let response = await axios.get(`/entities-vs-categories`);
      // console.log(response);   

      // let list = this.extractData(response);
      let list = response.data;
      // console.log(list);

      return list;

    } catch (error) {
      console.error('ERRO AO RECUPERAR A LISTA DE ENTIVDADES VS CATEGORIAS');
      console.log(error);
      snackbarManager.setErrorMessage("Erro", "Não foi possível carregar a lista de Entidades vs. Categorias");
      return false;
    } finally {
      this.state.loading = false;
    }
  }

  async create(formData) {

    try {
      this.state.loading = true;
      var response = await axios.post("/financial-movements", { ...formData });
      if(response.status==200){
        let errosMsg = Object.values(response.data.errors).flat().join("<br>")
        console.table(errosMsg);
        snackbarManager.setWarningMessage('Alerta',errosMsg);
      }
      if(response.status==201){
        snackbarManager.setSuccessMessage('Movimento adicionado!');
      }
      return response;
      return true;
    } catch (error) {
      console.log(error);

      if(error.response.status==405){
        let errosMsg = Object.values(error.response.data.errors).flat().join("<br>")
        console.table(errosMsg);
        snackbarManager.setWarningMessage('Alerta',errosMsg);
        return false;
      }

      if(error.response.status==422){
        let errosMsg = Object.values(error.response.data.errors).flat().join("<br>")
        console.table(errosMsg);
        snackbarManager.setWarningMessage('Alerta',errosMsg);
        return false;
      }

      if (error.response.data.message != undefined) {
        snackbarManager.setErrorMessage("Error", error.response.data.message);
      } else {
        snackbarManager.setErrorMessage("Erro", error.response.data.message);
      }

      return false;
    } finally {
      this.state.loading = false;
    }
  }

  async saveTransfer(formData) {

    try {
      this.state.loading = true;
      var response = await axios.post("/transfer", { ...formData });
      snackbarManager.setSuccessMessage('Transferência realizada');
      return response;
      return true;
    } catch (error) {
      console.log(error);

      if(error.response.status==422){
        let errosMsg = Object.values(error.response.data.errors).flat().join("<br>")
        console.table(errosMsg);
        snackbarManager.setWarningMessage('Alerta',errosMsg);
        return false;
      }

      if (error.response.data.message != undefined) {
        snackbarManager.setErrorMessage("Error", error.response.data.message);
      } else {
        snackbarManager.setErrorMessage("Erro", error.response.data.message);
      }

      return false;
    } finally {
      this.state.loading = false;
    }
  }

  // async update(formData) {

  //   try {

  //     this.state.loading = true;
  //     await axios.put(`/financial-entities/${formData.id}`, { ...formData });
  //     snackbarManager.setSuccessMessage('Entidade Atualizado!');
  //     return true;

  //   } catch (error) {
  //     console.error(error);
  //     // snackbarManager.setErrorMessage("Erro", error.response.data.message);
  //     snackbarManager.setErrorMessage("Erro", 'Deu erro');
  //     return false;

  //   } finally {
  //     this.state.loading = false;
  //   }

  // }

  // async delete(item) {
  //   try {
  //     console.log(item);
  //     this.state.loading = true;
  //     await axios.delete(`/financial-entities/${item.id}`),
  //     snackbarManager.setSuccessMessage('Entidade Removida!');
  //     return true;
  //   } catch (error) {
  //     if (error.response.status === 405) {
  //       snackbarManager.setErrorMessage("Apague todo conteúdo");
  //     } else {
  //       snackbarManager.setErrorMessage("Erro", error.response.data.message);
  //     }

  //     return false;
  //   } finally {
  //     this.state.loading = false;
  //   }
  // }

  isLoading() {
    return this.state.loading;
  }

}
