<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Calendário</div>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col cols="12" lg="8">
        <!-- START HEADER CALENDAR -->
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- ALTERA O TIPO DO CALENDARIO -->
            <!-- <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
          </v-toolbar>
        </v-sheet>
        <!-- END HEADER CALENDAR -->

        <!-- START BODY CALENDAR -->
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            :now="today"
            :value="today"
            v-model="focus"
            color="primary"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          >
            <template v-slot:day="{ date }">
              <div style="padding-top: 10px; padding-inline: 16px">
                <v-row class="fill-height">
                  <template v-for="(item, index) in listCalendar">
                    <div v-if="item[date]" :key="index">
                      <v-tooltip
                        v-for="(itemTarget, index) in item"
                        :key="index"
                        top
                      >
                        <template
                          v-if="itemTarget"
                          v-slot:activator="{ on, attrs }"
                        >
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="showEvent($event, itemTarget)"
                            :color="itemTarget.cor"
                            style="padding: 2px"
                            small
                          >
                            mdi-circle
                          </v-icon>
                        </template>
                        <span>{{ itemTarget.target }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-row>
              </div>
            </template>
          </v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.cor" dark>
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span>{{ selectedEvent.target }}</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.diary"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
        <!-- END BODY CALENDAR -->
      </v-col>
      <v-col cols="12" lg="4">
        <v-expansion-panels v-model="controllPanel" multiple>
          <!-- NOTES -->
          <v-expansion-panel>
            <v-progress-linear
              :active="loadingTimeLine || loadingDiary"
              :indeterminate="loadingTimeLine || loadingDiary"
              absolute
              top
              color="primary"
              style="z-index: 9"
            ></v-progress-linear>
            <v-expansion-panel-header>Notas</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list two-line>
                <template v-for="(itemNote, indexNote) in listNotes">
                  <v-list-item  class="ma-0" :key="itemNote.id">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          itemNote.title
                        }}</v-list-item-title>

                        <v-list-item-subtitle>
                          {{ itemNote.content | truncar(300) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text>
                          <v-icon
                            small
                            class="mr-2"
                            @click="showNote(itemNote)"
                          >
                            mdi-application-export
                          </v-icon>
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="indexNote < items.length - 1"
                    :key="indexNote"
                  ></v-divider>
                </template>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- DIARIES -->
          <v-expansion-panel>
            <v-progress-linear
              :active="loadingTimeLine || loadingDiary"
              :indeterminate="loadingTimeLine || loadingDiary"
              absolute
              top
              color="primary"
              style="z-index: 9"
            ></v-progress-linear>
            <v-expansion-panel-header>Diários</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list two-line>
                <template v-for="(itemDiary, indexDiary) in listDay">
                  <v-list-item  class="ma-0" :key="itemDiary.id">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          itemDiary.title
                        }}</v-list-item-title>

                        <v-list-item-subtitle>
                          {{ itemDiary.content | truncar(300) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text>
                          <v-icon
                            small
                            class="mr-2"
                            @click="showDiary(itemDiary)"
                          >
                            mdi-application-export
                          </v-icon>
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="indexDiary < items.length - 1"
                    :key="indexDiary"
                  ></v-divider>
                </template>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- TIMESHEET -->
          <v-expansion-panel>
            <v-progress-linear
              :active="loadingTimeLine || loadingDiary"
              :indeterminate="loadingTimeLine || loadingDiary"
              absolute
              top
              color="primary"
              style="z-index: 9"
            ></v-progress-linear>
            <v-expansion-panel-header>Timesheet</v-expansion-panel-header>
            <v-expansion-panel-content class="expansionContentTsh">
              <template v-for="itemTimesheet in listTimesheet">
                <v-list-item class="ma-0" :key="itemTimesheet.id">
                  <template>
                    <v-card elevation="0" width="100%">
                      <v-card-title>
                        <span class="text-h6 font-weight-light"
                          >{{ itemTimesheet.title }}
                        </span>
                        <v-spacer></v-spacer>
                        <v-list-item-action-text>
                          <v-icon
                            small
                            class="mr-2"
                            @click="showTimesheet(itemTimesheet)"
                          >
                            mdi-application-export
                          </v-icon>
                        </v-list-item-action-text>
                      </v-card-title>

                      <v-card-text>
                        {{ itemTimesheet.content }}
                      </v-card-text>
                      <!-- <v-list-item-title>
                              <v-list-item-action>
                                <v-list-item-action-text>
                                  <v-icon
                                    small
                                    class="mr-2"
                                    @click="showNote(item)"
                                  >
                                    mdi-application-export
                                  </v-icon>
                                </v-list-item-action-text>
                              </v-list-item-action>
                            </v-list-item-title>
                             -->
                    </v-card>
                  </template>
                </v-list-item>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import moment from "moment";

import { CalendarManager } from "@/manager/calendar-manager";
const calendarManager = new CalendarManager();
import { GlobalManager } from "../../manager/global-manager";
const globalManager = new GlobalManager();

export default {
  name: "Calendar",

  data: () => ({
    listCalendar: [],
    listTimeline: [],
    listDiary: [],

    listNotes: [],
    listDay: [],
    listTimesheet: [],

    focus: "",
    type: "month",
    today: moment().format("YYYY-MM-DD"),
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [
      { qtd: 2, color: "red" },
      { qtd: 5, color: "blue" },
      { qtd: 1, color: "grey" },
      { qtd: 10, color: "green" },
    ],

    listTargets: [],
    
    filter: {
      types: ["DAY", "REM", "FIN", "TGT", "TSH"],
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      content: "",
      models: ["note_versions", "diary"],
    },

    filterCalendar: {
      dateStart: moment().subtract(30, "days").format("YYYY-MM-DD"),
      dateEnd: moment().add(30, "days").format("YYYY-MM-DD"),
    },

    // EXPANSION PANEL
    controllPanel: [0, 1, 2],
    optionsPanel: ["Notas", "Diários", "Timesheet"],

    // LIST IN EXPANSION PANEL
    selected: [2],
    items: [
      {
        action: "15 min",
        headline: "Brunch this weekend?",
        subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        title: "Ali Connors",
      },
      {
        action: "2 hr",
        headline: "Summer BBQ",
        subtitle: `Wish I could come, but I'm out of town this weekend.`,
        title: "me, Scrott, Jennifer",
      },
      {
        action: "6 hr",
        headline: "Oui oui",
        subtitle: "Do you have Paris recommendations? Have you ever been?",
        title: "Sandra Adams",
      },
    ],
  }),

  computed: {
    loading: () => calendarManager.state.loading,
    loadingDiary: () => calendarManager.state.loading,
    loadingTimeLine: () => calendarManager.state.loading,
  },

  watch: {
    loading(value) {
      globalManager.loading(value);
    },
  },

  filters: {
    // ELLIPSES IN TABLE
    truncar(item, maxChar) {
      if (item != undefined && item.length > maxChar) {
        return item.substring(0, maxChar) + "  ...";
      } else {
        return item;
      }
    },
  },

  async created() {
    this.callListCalendar();
    this.callListTimeline();
    this.callListDiary();
  },

  mounted() {
    this.$refs.calendar.checkChange();
  },

  methods: {
    // CALENDAR
    async callListCalendar() {
      const data = await calendarManager.list(this.filterCalendar);
      // console.log(data);
      this.listCalendar = data;
    },
    // TIMELINE
    async callListTimeline() {
      const data = await calendarManager.listTimelinesCalendar(this.filter);
      this.listTimeline = data;

      this.listNotes = data.filter((item) => {
        if (item.type == "note") {
          return item;
        }
      });

      this.listDay = data.filter((item) => {
        if (item.type != "Timesheet" && item.type != "note") {
          return item;
        }
      });

      this.listTimesheet = data.filter((item) => {
        if (item.type == "Timesheet") {
          return item;
        }
      });
    },

    // DIARY
    async callListDiary() {
      const data = await calendarManager.listDiariesCalendar();
      // console.log(data);
      this.listDiary = data;
    },

    async viewDay({ date }) {
      // console.log(date);
      this.today = date;
      this.filter.dateStart = date;
      this.filter.dateEnd = date;
      this.callListTimeline();
      // this.focus = date;
      // this.type = "day";
    },

    getEventColor(event) {
      return event.color;
    },

    async setToday() {
      this.focus = "";
    },

    prev() {
      this.$refs.calendar.prev();
      this.updateFilterCalendar(this.$refs.calendar._data.lastStart.date);
    },

    next() {
      this.$refs.calendar.next();
      this.updateFilterCalendar(this.$refs.calendar._data.lastEnd.date);
    },

    showEvent(nativeEvent, event) {
      this.selectedElement = nativeEvent.target;
      const open = () => {
        this.selectedEvent = event;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },

    // IR PARA EDIT NOTE
    showNote(item) {
      let url = item.url_web;
      const goToEditNote = this.$router.resolve({ path: url });
      window.open(goToEditNote.href, "_blank");
    },

    // IR PARA EDIT NOTE
    showDiary(item) {
      let url = "edit-diary/" + item.id;
      const goToEditNote = this.$router.resolve({ path: url });
      window.open(goToEditNote.href, "_blank");
    },

    // IR PARA EDIT NOTE
    showTimesheet(item) {
      let url = "edit-diary/" + item.id;
      const goToEditNote = this.$router.resolve({ path: url });
      window.open(goToEditNote.href, "_blank");
    },

    // GET DATE-START AND DATE-END OF DATE-NOW
    async updateFilterCalendar(d) {
      let date = {
        dateStart: moment(d).subtract(30, "days").format("YYYY-MM-DD"),
        dateEnd: moment(d).add(30, "days").format("YYYY-MM-DD"),
      };
      const data = await calendarManager.list(date);
      this.listCalendar = data;
    },
  },
};
</script>

<style>
.expansionContentTsh .v-expansion-panel-content__wrap {
  padding: 0px;
  flex: 1 1 auto;
  max-width: 100%;
}
</style>