  <template>
  <div>
    <span
      v-for="(note, index) in notes"
      :key="index"
      class="arquivos"
      @dblclick="enterNote(note)"
      @click.right="selectEditedItem(note)"
      @contextmenu="showContextMenu"
    >
      <v-icon size="70" style="cursor: pointer">mdi-note-outline</v-icon>
      <br />
      <div style="font-size: 1em" class="itens">{{ note.title }}</div>
    </span>
  </div>
</template>

<script>

export default {
  name: "Notes",

  props: {
    notes: {
      type: Array,
    },
  },

  data() {
    return {
      // NOTES
      note: {},

      // UTILS
      x: 0,
      y: 0,
    };
  },

  methods: {
    // SELECIONA UMA NOTA AO CLIK DO BUTÃO DIREITO
    selectEditedItem(pItem) {
      this.note = Object.assign({}, pItem);
    },

    // CONTROLE DO CONTEXT MENU
    showContextMenu(e) {
      e.preventDefault();
      this.x = e.clientX;
      this.y = e.clientY;
      this.$emit("emitOpenContextMenuNote", this.note, this.x, this.y);
    },

    // ABRIR O EDITOR DE NOTAS
    enterNote(item) {
      // console.log("entrei na função para acessar uma nota");
      // console.log(item);
      let id = item.id;
      // console.log("estou imprimindo a id para montar rota da nota");
      // console.log(id);

      this.$router.push("/edit-note/" + id).catch(() => {});
    },
  },
};
</script>


<style scoped>
.arquivos {
  float: left;
  height: auto;
  width: 140px;
  text-align: center;
  padding: 2px;
  border-radius: 6px;
  max-width: 140px;
}
.itens {
  text-align: center;
  padding: 2px;
  border-radius: 6px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
