<template>
  <div class="pa-4">
    <!-- TITULO -->
    <v-row>
      <div class="text-h5 text-sm-h4">Editor de Notas</div>
      <v-spacer></v-spacer>
      <div class="d-flex flex-column col-12 pa-0 breadcrumb-drives">
        <v-breadcrumbs class="pa-0">
          <template v-slot>

            <v-breadcrumbs-item
              :to="`/drives`"
              :disabled="getDisableBreadcrumbs(true)"
              exact
            >
              Meu Drive
            </v-breadcrumbs-item>

            <v-breadcrumbs-item
              v-for="item in breadcrumbs"
              :key="item.id"
              :to="`/drives/${item.id}`"
              exact
              :disabled="getDisableBreadcrumbs(item)"
            >
              <span
                style="color: #ccc; cursor: default"
                class="pr-2"
              >/</span>
              {{ item.title }}
            </v-breadcrumbs-item>

          </template>
        </v-breadcrumbs>
      </div>
    </v-row>

    <!-- CONTAINER -->
    <v-row class="">
      <v-col
        md="6"
        xl="6"
        lg="6"
        sm="12"
        class="pa-0"
      >
        <div class="row d-flex justify-space-between mb-2 mt-1">
          <div style="width: 70%">
            <v-text-field
              v-model="formData.title"
              flat
              solo
              style="height: 100%; width: 100%; font-size: 1.5em; font-weight: bold"
              hide-details="auto"
            >
            </v-text-field>
          </div>
          <div style="width: 30%; padding-right: 12px">
            <v-combobox
              class="mt-1 pa-0"
              v-model="selectedMode"
              :items="listModes"
              item-text="value"
              item-value="id"
              outlined
              dense
              placeholder="Selecione um tipo"
              hide-details=""
              hide-no-data
              @blur="callUpdate(false)"
            ></v-combobox>
          </div>
        </div>

        <div
          id="editor-container"
          @click="setFocusToTextarea()"
        >
          <v-textarea
            id="editor"
            ref="refText"
            @keyup="convertMd(), callAutoSave()"
            v-model="formData.content"
            hide-details
            no-resize
            loading="false"
            style="float: left; width: 100%; padding: 12px"
            :rows="getNumberOfLines()"
          />
        </div>
        <v-row class="justify-space-between mt-4 px-1">
          <div>
            <v-btn
              text
              tile
              x-small
              :loading="loadingAutoSave"
            >
              <v-icon class="mr-2">mdi-cloud-check</v-icon>
            </v-btn>
            <span>{{ statusTextAutoSave }}</span>
          </div>

          <v-btn
            text
            tile
            x-small
            @click="callUpdate(false)"
          >
            Salvar <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-row>
      </v-col>

      <v-col
        md="6"
        xl="6"
        lg="6"
        sm="12"
      >
        <Tabs
          :formData="formData"
          @setLink="setLinkFileTextarea"
        />
        <div style="height: 1900px"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import showdown from "showdown";

import Tabs from "./Tabs.vue";

import { NotesManager } from "@/manager/notes-manager";
const notesManager = new NotesManager();
import { DrivesManager } from "@/manager/drives-manager";
const drivesManager = new DrivesManager();

import { GlobalManager } from "@/manager/global-manager";
const globalManager = new GlobalManager();

const converter = new showdown.Converter();
converter.setFlavor("github");

var timeoutAutoSave;

export default {
  name: "EditNote",

  components: {
    Tabs,
  },

  data() {
    return {
      // CONTROLA AUTO SAVE
      timeoutAutoSave: null,
      statusTextAutoSave: "",
      loadingAutoSave: false,

      baseUrl: process.env.VUE_APP_API_URL,

      // NOTAS
      note: {},
      formData: {},

      // BREADCRUMBS
      breadcrumbs: [],

      //  ---------------------------------------------

      // CONTROLA STATUS AÇÕES DE PUBLICAÇÃO DA NOTA
      showBtnsNotesPublics: false,
      showPublicNotes: false,

      // VARIAVVEIS DE CONTROLE DA NOTA
      editNoteIndex: -1,
      editNoteItem: {
        title: "",
        content: "",
        content_md: "",
        drive_id: "",
        password: "",
        published_at: "",
        mode: "",
        mode_value: "",
      },

      // VARIAVEIS DE INSERIR LINK NO MARKDOWN
      linkText: "![NLW](https://i.imgur.com/2y5GdI3.png)",

      // MODE
      selectedMode: { id: "", value: "" },
      listModes: [
        { id: "K", value: "Conhecimento" },
        { id: "M", value: "Manual" },
        { id: "B", value: "Backlog" },
      ],
    };
  },

  created() {
    this.callNote();
    // this.pastePrint();
  },

  methods: {
    // NOTES
    async callNote() {
      const data = await notesManager.showNote(this.$route.params.id);
      // console.log(data);
      this.callListBreadcrumbs(data.drive_id);
      if (data) {
        this.formData = data;
        this.selectedMode = data.mode_value;
        setTimeout(() => {
          document.querySelector("#editor").rows = this.getNumberOfLines();
        }, 500);
      }

      this.note = data;
    },

    // BREADCRUMBS
    async callListBreadcrumbs(pDriveId) {
      const data = await drivesManager.breadcrumbs(pDriveId);
      // console.log(data);

      this.breadcrumbs = data;
    },

    async callUpdate(pAutoSave) {
      this.statusTextAutoSave = "Salvando...";
      this.loadingAutoSave = true;
      if (this.selectedMode != null) {
        if (this.selectedMode.id != undefined) {
          this.formData.mode = this.selectedMode.id;
          this.formData.mode_value = this.selectedMode.value;
        }
      }
      if (await notesManager.update(this.formData, pAutoSave)) {
        this.delayedCompletedAutoSave();
      }
    },

    // CONTROLA DELAY DO KEYUP PARA EFETUAR O AUTOSAVE
    callAutoSave() {
      this.clearMessage();
      timeoutAutoSave = setTimeout(() => {
        this.callUpdate(true);
      }, 5000);
    },

    // FUNÇÕES DE CONTROLE TEXTAREA
    baseScrollHeight() {
      var savedValue = document.querySelector("#editor").value;
      document.querySelector("#editor").value = "";

      var baseScrollHeight = document.querySelector("#editor").scrollHeight;
      document.querySelector("#editor").value = savedValue;

      return baseScrollHeight;
    },

    // CALCULO PARA AUTO-GROW TEXTAREA
    // getNumberOfLines(text) {
    getNumberOfLines() {
      /** replacement for the 'auto-grow' property of v-textarea
       *  as this feature has issue with maintaining the scroll
       *  position of the textarea.
       *  Source: https://github.com/vuetifyjs/vuetify/issues/5314
       */

      // console.log("-------------------");

      if (document.querySelector("#editor")) {
        // if (text && typeof text === "string" && text != null) {

        // var nLines = text.replace(/\r\n/g, "\n").split("\n").length;
        // console.log('nLines', nLines);

        // var baseScrollHeight = this.baseScrollHeight();
        // console.log('baseScrollHeight', baseScrollHeight);

        var nScrollHeight = document.querySelector("#editor").scrollHeight;
        // console.log("nScrollHeight", nScrollHeight);

        var nScrollHeightLines = Math.ceil(nScrollHeight / 28);
        // console.log('nScrollHeightLines', nScrollHeightLines);
        return nScrollHeightLines;
      }
    },

    // MANIPULA CURSOR DO TEXTO DENTRO DO TEXTAREA
    setFocusToTextarea() {
      /* [1] */
      if (this.$refs.refText.value != null) {
        const textLength = this.$refs.refText.value.length;
        if (this.$refs.refText !== document.activeElement) {
          this.$refs.refText.focus();
        }
      }
    },

    // INSERIR LINK DA IMAGEM NO TEXTAREA
    setLinkFileTextarea(file) {
      const linkText = `![${file.name}](${this.baseUrl}/img/${file.id})`;
      if (!linkText.length) return;

      const textarea = this.$refs.refText.$refs.input;
      const sentence = textarea.value;
      const len = sentence.length;
      let pos = textarea.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }

      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);

      this.formData.content = before + linkText + after;

      this.$nextTick().then(() => {
        textarea.setSelectionRange(
          pos + linkText.length,
          pos + linkText.length
        );
        this.setFocusToTextarea();
      });
    },

    // CONVERTE TEXTO  MD EM HTML
    convertMd() {
      this.formData.content_md = converter.makeHtml(this.formData.content);
    },

    // BREADCRUMB DISABLED
    getDisableBreadcrumbs(pItem) {
      return pItem.id === this.$route.params.id ? true : false;
    },

    // CONTROLA MENSAGENS DE STATUS AUTOSAVE
    delayedCompletedAutoSave() {
      this.loadingAutoSave = false;
      this.statusTextAutoSave = "Alterações salvas.";
      setTimeout(() => {
        this.statusTextAutoSave = "";
      }, 1000);
    },

    // AUXILIAR QUE RESETA CONTADOR DE DELAY DO KEYUP
    clearMessage() {
      clearTimeout(timeoutAutoSave);
    },

    pastePrint() {
      this.$refs.refText.addEventListener("paste", function (e) {
        var p;
        const items = e.clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const blob = items[i].getAsFile();
            const reader = new FileReader();
            reader.onload = function (event) {
              const base64 = event.target.result;
              const img = document.createElement("img");
              img.src = base64;
              // console.log(img.src);
              // console.log('01');
              // this.print = img.src;
              // this.printed = true;
              // console.log('02');
              // console.log(this.printed);
              // console.log(this);
              // imageContainer.appendChild(img);
            };
            // console.log(p);
            reader.readAsDataURL(blob);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 20px;
  overflow: hidden;
  min-height: 40px;
  line-height: 20px;
}

#editor {
  overflow: hidden !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.v-textarea textarea {
  overflow: hidden;
}

#editor-container {
  min-height: 600px; /* fits container to remaining page height between nav bars */
  float: left;
  width: 100%;
  overflow-y: auto;
  overflow: hidden;
  cursor: text; /* [1] */
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 16px;
}

.breadcrumb-drives {
  padding-left: 2px !important;
}
</style>