<template>
  <div class="d-flex row pa-4">
    <v-col :cols="showNavgationInformation ? 9 : 12">
      <v-row>
        <div class="text-h5 text-sm-h4">Documentos</div>
        <v-spacer></v-spacer>
      </v-row>

      <!-- OBS: VERIFICAR COM MUITOS DOCUMENTOS SE PODE MINIMIZAR O V-ROW E V-COL ABAIXO -->
      <v-row class="my-6">
        <v-col cols="12" class="pa-0">
          <v-row justify="start">
            <div
              v-for="(item, index) in documents"
              :key="index"
              class="arquivos"
              @click="callListShareDocumentUser(item)"
              @dblclick="showDocument(item)"
              @contextmenu="controlContextMenuDrive($event, true)"
              @click.right="callEditItem(item)"
            >
              <template>
                <div class="arquivos">
                  <v-icon
                    size="80"
                    dense
                    style="cursor: pointer"
                    :color="item.color"
                  >
                    mdi-list-box-outline
                  </v-icon>
                  <br />
                  <div style="font-size: 1em; cursor: pointer" class="itens">
                    {{ item.title }}
                  </div>
                </div>
              </template>
            </div>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <div class="text-h5 text-sm-h4">Compartilhados comigo</div>
        <v-spacer></v-spacer>
      </v-row>

      <v-row class="my-6">
        <v-col cols="12" class="pa-0">
          <v-row justify="start">
            <div
              v-for="(item, index) in documentsShared"
              :key="index"
              class="arquivos"
              @dblclick="showDocument(item.included.document.attributes)"
              @contextmenu="controlContextMenuDrive($event, false)"
            >
              <template>
                <div class="arquivos">
                  <v-icon size="80" style="cursor: pointer" :color="item.color">
                    mdi-list-box-outline
                  </v-icon>
                  <br />
                  <div style="font-size: 1em; cursor: pointer" class="itens">
                    {{ item.included.document.attributes.title }}
                  </div>
                </div>
              </template>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <transition>
      <v-navigation-drawer
        v-show="showNavgationInformation"
        transition="scroll-x-reverse-transition"
        width="350px"
        absolute
        permanent
        right
      >
        <template v-slot:prepend>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ document.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                type="button"
                icon
                dense
                @click="showNavgationInformation = !showNavgationInformation"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>

        <v-divider></v-divider>

        <v-col>
          <div class="text-h5">Compartilhamentos</div>
        </v-col>

        <span v-if="listShareds <= 0">
          <div class="d-flex justify-center py-2">
            <v-icon color="grey" size="100">mdi-information-outline</v-icon>
          </div>
          <span class="grey--text d-flex pa-4 align-center justify-center">
            Documento não compartilhado
          </span>
        </span>

        <v-list v-else two-line>
          <template v-for="(item, index) in listShareds">
            <v-list-item v-if="listShareds" :key="index">
              <v-list-item-avatar>
                <v-avatar color="grey">
                  <v-icon dark> mdi-account-circle </v-icon>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{
                    item.included.guest.attributes.name
                      ? item.included.guest.attributes.name
                      : "Convidado"
                  }}
                </v-list-item-title>

                <v-list-item-subtitle class="text-caption">
                  {{ item.included.guest.attributes.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <!-- @click="typeShareDocumentItem(item)" -->
                <v-btn @click="callEditTypeShareDocument(item)" x-small icon>
                  <v-icon
                    :color="
                      item.type === 'Editor' ? 'success' : 'grey lighten-1'
                    "
                  >
                    mdi-account-edit
                  </v-icon>
                </v-btn>
              </v-list-item-action>
              <!-- @click="deleteShareDocumentItem(item)" -->
              <v-list-item-action>
                <v-btn @click="callDeleteItemShareDocument(item)" x-small icon>
                  <v-icon color="grey lighten-1">mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
    </transition>

    <v-menu
      top
      v-model="showContextMenu"
      :position-x="x"
      :position-y="y"
      absolute
    >
      <v-list>
        <v-list-item
          @click="selectorMenu(item.title)"
          v-for="(item, index) in dropDownItems"
          :key="index"
        >
          <v-list-item-title>
            <v-icon size="20">
              {{ item.icon }}
            </v-icon>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <DocumentForm
      :formData="document"
      :showForm="showFormDocument"
      @emitClose="closeFormDocument"
    />

    <SharedDocument
      :formData="shareDocument"
      :showForm="showShareDocument"
      @emitClose="closeShareDocument"
    />

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteDocument"
      @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteDocument"
    />

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteShareDocument"
      @confirm="deleteItemConfirmShareDocument"
      @cancel="closeDialogConfirmDeleteShareDocument"
    />
  </div>
</template>
  
  <script>
import { DocumentsManager } from "@/manager/document-manager";
const documentsManager = new DocumentsManager();

import DocumentForm from "./DocumentForm.vue";
import SharedDocument from "./Shared/ShareDocument.vue";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";

export default {
  name: "Document",
  
  components: {
    DocumentForm,
    ConfirmDialog,
    SharedDocument,
  },

  data() {
    return {
      // DOCUMENTS
      documents: [],
      document: {},
      showFormDocument: false,

      //SHARED
      documentsShared: [],
      shareDocument: {},
      showShareDocument: false,
      listShareds: [],
      documentSharedUser: {},
      toggleDialogDeleteShareDocument: false,

      // UTILS
      deleteText: "",
      toggleDialogDeleteDocument: false,
      showNavgationInformation: false,

      // CONTEXT MENU DATA AND FLAGS
      dropDownItems: [],
      showContextMenu: false,
      showMenu: false,
      x: 0,
      y: 0,
    };
  },

  created() {
    this.refreshData();
  },

  methods: {

    // LISTA OS DOCUMENTOS
    async callList() {
      const data = await documentsManager.list();
      // console.log(data);
      this.documents = data;
    },

    // LISTA OS DOCUMENTOS COMPARTILHADOS
    async callListDocumentsShared() {
     const data = await documentsManager.listDocumentShared();
      // console.log("document shares list", data);
      this.documentsShared = data;
    },

    // LISTA OS USUARIOS DE UM DOCUMENTO COMPARTILHADO 
    async callListShareDocumentUser(pDocument) {
      this.document = pDocument;
      const data = await documentsManager.listShareDocumentsUser(pDocument.id);
      // console.log("document user", data);
      this.listShareds = data;
      this.showNavgationInformation = true;
    },

    // ABRE FORMULARIO DE INCLUSÃO DO DOCUMENTO
    openFormDocument() {
      this.document = {};
      this.showFormDocument = true;
    },

    // FECHAR MODAL INCLUSAO DO DOCUMENTO
    closeFormDocument(pHasUpdate) {
      if (pHasUpdate) {
        this.refreshData();
      }
      this.shareDocument = {};
      this.showFormDocument = false;
    },

    // EDITAR DOCUMENTO
    callEditItem(pItem) {
      // console.log(pItem)
      // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
      this.document = Object.assign({}, pItem);
      this.showContextMenu = true;
    },

    // SHOW DOCUMENT
    async showDocument(pItem) {
      // console.log("Documents Shared", pItem)
      this.$router.push("/document-show/" + pItem.id).catch(() => {});
    },

    // ABRIR MODAL PARA  COMPARTILHAR DOCUMENTO
    openShareDocument(pItem) {
      this.shareDocument = { document_id: pItem.id };
      this.showShareDocument = !this.showShareDocument;
    },
    
    // FECHAR MODAL INCLUSAO
    closeShareDocument(pHasUpdate) {
      if (pHasUpdate) {
        this.refreshData();
      }
      this.shareDocument = {};
      this.showShareDocument = false;
    },

    // DELETAR DOCUMENTO
    async deleteItemConfirm() {
      const ret = await documentsManager.delete(this.document);
      if (ret.ok) {
        this.closeDialogConfirmDeleteDocument();
        this.refreshData();
      }
    },

    callDeleteItem(pItem) {
      this.document = pItem;
      this.deleteText =
        "Tem certeza de deseja excluir o item: " + pItem.title + " ?";
      this.toggleDialogDeleteDocument = true;
    },

    closeDialogConfirmDeleteDocument() {
      this.document = {};
      this.toggleDialogDeleteDocument = false;
    },

    // DELETAR COMPARTILHAMENTO
    async deleteItemConfirmShareDocument() {
      const res = await documentsManager.deleteShareDocument(
        this.documentSharedUser
      );
      if (res.ok) {
        this.callListShareDocumentUser(
          this.documentSharedUser.included.document.attributes
        );
        this.closeDialogConfirmDeleteShareDocument();
      }
    },

    callDeleteItemShareDocument(pItem) {
      this.documentSharedUser = pItem;
      this.deleteText =
        "Tem certeza de deseja excluir o item: " +
        pItem.included.guest.attributes.name +
        " ?";
      this.toggleDialogDeleteShareDocument = true;
    },

    closeDialogConfirmDeleteShareDocument() {
      this.target = {};
      this.toggleDialogDeleteShareDocument = false;
    },

    // ABRIR DIALOG DELETE DO SHARE DOCUMENTO
    async callEditTypeShareDocument(pItem) {
      // console.log(pItem);

      this.documentSharedUser = pItem;

      if (pItem.type === "Editor") {
        this.documentSharedUser.type = "V";
      } else {
        this.documentSharedUser.type = "E";
      }
      let res = await documentsManager.updateShareDocumentUser(
        this.documentSharedUser,
        false
      );
      if (res.ok) {
        this.callListShareDocumentUser(pItem.included.document.attributes);
      }
      this.documentSharedUser = {};
    },

    // CONTROLE DO MENU DE CONTEXTO DOS DRIVES
    controlContextMenuDrive(e, pShowContext) {
      if (!pShowContext) {
        e.preventDefault();
        this.showContextMenu = false;
        return;
      }
      e.preventDefault();
      this.dropDownItems = [
        { title: "Editar", icon: "mdi-pencil" },
        { title: "Excluir", icon: "mdi-close" },
        { title: "Compartilhar", icon: "mdi-share-variant" },
      ];

      this.showContextMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showContextMenu = true;
      });
    },

    // SELETOR DO MENU DOS DRIVES
    selectorMenu(value) {
      const options = value;

      switch (options) {
        case "Excluir":
          this.callDeleteItem(this.document);
          break;

        case "Editar":
          this.showFormDocument = true;
          break;

        case "Compartilhar":
          this.openShareDocument(this.document);
          break;
        default:
          console.log(`Não foi reconhecido como opção ${options}.`);
      }
    },

    // UTILS
    refreshData() {
      this.callList();
      this.callListDocumentsShared();
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

    toggleSearchShared() {
      this.showSearchShared = !this.showSearchShared;
    },

    clearSearch() {
      this.searchDocuments = " ";
    },
    
    clearSearchShared() {
      this.searchDocumentsShared = " ";
    },
  },
};
</script>
  
<style scoped>
.arquivos {
  float: left;
  height: auto;
  width: 140px;
  text-align: center;
  padding: 2px;
  border-radius: 6px;
  max-width: 140px;
}
.itens {
  text-align: center;
  padding: 2px;
  border-radius: 6px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
  