<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Hot List</div>

      <v-spacer></v-spacer>

      <div
        class="d-none d-sm-flex"
        style="
          width: 100%;
          position: absolute;
          display: flex;
          justify-content: flex-end;
          padding-right: 40px;
        "
      >
        <v-col
          class="pa-0"
          sm="3"
          lg="2"
          md="3"
        >
          <v-text-field
            v-model="search"
            class="mr-2"
            outlined
            dense
            label="Pesquisar"
            append-icon="mdi-magnify"
            @change="persistSearch"
          ></v-text-field>
        </v-col>

        <v-col
          class="pa-0"
          sm="3"
          lg="2"
          md="3"
        >
          <v-autocomplete
            v-model="tags"
            :disabled="isUpdating"
            :items="tagList"
            outlined
            dense
            chips
            label="Pesquisar Tags"
            item-text="name"
            item-value="name"
            multiple
          >
            <template v-slot:selection="data">
              <v-chip
                class="ma-1"
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                small
                @click="data.select"
                @click:close="remove(data.item)"
              >
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object' ">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>

                  <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </div>

      <v-col
        class="pa-0 d-sm-none"
        cols="12"
        sm="3 mr-2"
        lg="2"
      >
        <v-text-field
          v-model="search"
          outlined
          dense
          label="Pesquisar"
          append-icon="mdi-magnify"
          @change="persistSearch"
        ></v-text-field>
      </v-col>

      <v-col
        class="pa-0 d-sm-none"
        cols="12"
        sm="3"
        lg="2"
      >
        <v-autocomplete
          v-model="tags"
          :disabled="isUpdating"
          :items="tagList"
          outlined
          dense
          chips
          label="Pesquisar Tags"
          item-text="name"
          item-value="name"
          multiple
        >
          <template v-slot:selection="data">
            <v-chip
              class="ma-1"
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              small
              @click="data.select"
              @click:close="remove(data.item)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object' ">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>

                <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>

      <!-- <div>
        <v-text-field
          v-model="searchTag"
          outlined
          dense
          label="Pesquisar Tag"
          append-icon="mdi-tag-search-outline"
        ></v-text-field>
      </div> -->
    </v-row>

    <div
      class="mt-10"
      v-for="item in listFilter"
      :key="item.id"
    >
      <router-link :to="`edit-note/${item.id}`">
        <h1>{{ item.title }}</h1>
      </router-link>
      <PreviewMd :formData="{ content_md: convertMd(item.hotlist) }" />
    </div>
  </div>
</template>

<script>
import showdown from "showdown";

import PreviewMd from "@/components/PreviewMd/Preview.vue";
import { NotesManager } from "@/manager/notes-manager";
const notesManager = new NotesManager();

const converter = new showdown.Converter();
converter.setFlavor("github");

export default {
  name: "HotList",

  components: {
    PreviewMd,
  },

  data() {
    return {
      hotLists: [],
      noteDocument: { content_md: "<h1>Não ah conteúdo</h1>" },
      search: "",
      searchTag: "",

      tags: [],
      isUpdating: false,
      name: "Midnight Crew",
      title: "The summer breeze",
    };
  },

  computed: {
    tagList() {
      let list = [];
      this.hotLists.map((item) => {
        if (item.tags.length > 0) {
          list.push(...item.tags);
        }
      });

      const listTagsUnique = Array.from(Array.from(new Set(list)), (value) => {
        return { name: value };
      });
      // console.log(listTagsUnique);

      return listTagsUnique;
    },

    listFilter() {
      let listSearch = [];
      let listSearchTag = [];
      let list = [];

      listSearch = this.hotLists.filter((item) => {
        return (
          item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.hotlist.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });

      this.hotLists.map((item) => {
        const hotListContainsTags = item.tags.some((itemTags) => {
          if (this.tags.includes(itemTags)) {
            return item;
          }
        });

        if (hotListContainsTags) {
          listSearchTag.push(item);
        }
      });

      if (this.search.length > 0) {
        list = Array.from(new Set([...listSearch, ...listSearchTag]));
        return list;
      }
      if (this.tags.length > 0) {
        list = Array.from(new Set([...listSearchTag]));
        return list;
      }

      return listSearch;
    },
  },

  created() {
    if (localStorage.searchHotList) {
      this.search = localStorage.searchHotList;
    }
    this.callList();
  },

  methods: {
    // TARGETS
    async callList() {
      const data = await notesManager.listHotList();
      // console.log(data);
      this.hotLists = data;
    },

    persistSearch(e) {
      localStorage.searchHotList = e;
    },

    remove(item) {
      const index = this.tags.indexOf(item.name);
      if (index >= 0) this.tags.splice(index, 1);
    },

    // CONVERTE TEXTO  MD EM HTML
    convertMd(pItem) {
      return converter.makeHtml(pItem);
    },
  },
};
</script>

<style>
</style>
