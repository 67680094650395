<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Cenários</div>
      <v-spacer></v-spacer>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" sm="6" class="pa-0">
        <v-card :loading="loading" style="z-index: 0">
          <div>
            <v-card-title>
              Lista de cenários
              <v-spacer></v-spacer>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="toggleSearch()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="refreshData()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="openScenarioForm()"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-card-title>

            <div
              class="d-flex justify-space-between align-center"
              v-if="showSearch"
            >
              <v-col cols="9">
                <v-text-field
                  v-model="searchScenario"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  dense
                  single-line
                  autofocus
                  hide-details
                />
              </v-col>

              <v-col cols="3" class="px-4">
                <v-row justify="end" class="mr-2">
                  <v-btn icon @click="clearSearch()">
                    <v-icon color="grey"> mdi-broom </v-icon>
                  </v-btn>
                  <v-btn icon @click="toggleSearch()">
                    <v-icon color="grey"> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </div>

            <v-data-table
              :headers="headers"
              :search="searchScenario"
              :items="listScenario"
              loading-text="Carregando..."
              progress
              class="elevation-1"
            >
              <template v-slot:[`item.active_scenario`]="{ item }">
                <v-icon color="success" v-if="item.active_scenario">mdi-check-circle-outline</v-icon>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="callEditItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="callDeleteItem(item)">
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click="makeActive(item)">
                  mdi-check-circle-outline
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <ScenarioForm
      :formData="scenario"
      :showForm="showScenarioForm"
      @emitClose="closeScenarioForm"
    />

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogDeleteScenario"
      @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteScenario"
    />

  </div>
</template>
  
  <script>

import { ScenarioManager } from "@/manager/scenario-manager";
const scenarioManager = new ScenarioManager();
  
import ScenarioForm from "./ScenarioForm.vue";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";

export default {
  name: "scenario",
  components: {
    ScenarioForm,
    ConfirmDialog,
  },

  created() {
    this.refreshData();
  },

  computed: {
    loading: () => scenarioManager.state.loading,
  },

  methods: {
    // TARGETS
    async callList() {
      const data = await scenarioManager.list();
      // console.log(data);
      this.listScenario = data;
    },

    // ABRE FORMULARIO DE INCLUSÃO
    openScenarioForm() {
      this.scenario = {};
      this.showScenarioForm = true;
    },

    // FECHAR MODAL INCLUSAO
    closeScenarioForm(pHasUpdate) {
      if (pHasUpdate) {
        this.refreshData();
      }
      this.scenario = {};
      this.showScenarioForm = false;
    },

    // EDITAR DOCUMENTO
    callEditItem(pItem) {
      // console.log(pItem)
      // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
      this.scenario = Object.assign({}, pItem);
      this.showScenarioForm = true;
    },

    async deleteItemConfirm() {
      const ret = await scenarioManager.delete(this.scenario);
      if (ret.ok) {
        this.closeDialogConfirmDeleteScenario();
        this.refreshData();
      }
    },

    callDeleteItem(pItem) {
      this.scenario = pItem;
      this.deleteText =
        "Tem certeza de deseja excluir o item: " + pItem.title + " ?";
      this.toggleDialogDeleteScenario = true;
    },

    async makeActive(pItem) {
      this.scenario = pItem;
      this.scenario.active_scenario = true;
      var response = await scenarioManager.update(this.scenario);
      if (response.ok) {
        this.refreshData();
      }
    },

    closeDialogConfirmDeleteScenario() {
      this.scenario = {};
      this.toggleDialogDeleteScenario = false;
    },

    // UTILS
    refreshData() {
      this.callList();
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

    clearSearch() {
      this.searchScenario = " ";
    },
  },
  data() {
    return {
      // TARGETS
      listScenario: [],
      scenario: {},
      showScenarioForm: false,

      // UTILS
      deleteText: "",
      toggleDialogDeleteScenario: false,

      // DEFINIÇÕES DA TABELA
      showSearch: false,
      searchScenario: "",
      headers: [
        {
          text: "Entidade financeira",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: "Ativo",
          align: "start",
          value: "active_scenario",
          sortable: false,
        },
        { text: "Ações", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
};
</script>
  
  <style scoped>
</style>
  