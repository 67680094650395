<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      overlay-opacity="0.8"
      style="width: 100%; z-index: 5000"
      persistent
      max-width="400px"
      open-delay="5000"
    >
      <v-card>
        <v-card-text>
          <!-- TOPO FORMULARIO  -->
          <div class="d-flex justify-end"></div>
          <v-card-actions>
            <v-row class="my-4 d-flex justify-space-between align-center">
              <div class="d-flex display-1 align-center">
                <span v-if="this.formData.id == undefined">
                  Novo Documento
                </span>
                <span v-if="this.formData.id != undefined"
                  >Editar Documento</span
                >
              </div>
              <v-btn fab rounded text tile x-small @click="toggleForm(false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>

          <v-form
            ref="refForm"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-row>
              <!-- CAMPO TITULO  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <v-text-field
                    v-model="formData.title"
                    label="Título"
                    maxlength="30"
                    required
                    :rules="[rules.required]"
                    autofocus
                  />
                </v-row>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12">
                <v-row justify="end">
                  <v-btn
                    v-if="this.formData.id == undefined"
                    :disabled="!valid"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callCreate()"
                  >
                    Salvar
                  </v-btn>
                  <v-btn
                    v-if="this.formData.id != undefined"
                    :disabled="!valid"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callUpdate()"
                  >
                    Editar
                  </v-btn>
                </v-row>
              </v-col>

              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { DocumentsManager } from "../../manager/document-manager";
const documentsManager = new DocumentsManager();

export default {
  name: "DocumentForm",

  props: {
    formData: {
      type: Object,
      require: false,
    },
    showForm: {
      type: Boolean,
    },
  },

  data() {
    return {
      valid: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },
    };
  },

  methods: {
    // CREATE
    async callCreate() {
      if (!this.$refs.refForm.validate()) return;
      // console.log(this.formData);

      var response = await documentsManager.create(this.formData);
      if (response.ok) {
        this.toggleForm(true);
      }
    },

    // ATUALIZA UM ITEM
    async callUpdate() {
      if (!this.$refs.refForm.validate()) return;
      var response = await documentsManager.update(this.formData);
      if (response.ok) {
        this.toggleForm(true);
      }
    },

    // SERVICES
    // UTILIZADA PARA FECHAR O FORMULARIO. ELA RECEBE UM PARAMETRO
    // QUE VALIDA SE EXITE NESCESSIDADE DE ATUALIZAR A TABELA OU NAO
    toggleForm(pHasUpdate) {
      this.$refs.refForm.resetValidation();
      this.$emit("emitClose", pHasUpdate);
    },

    // USADO PARA LIMPAR O FORMULARIO
    callClearForm() {
      this.$refs.refForm.reset();
    },
  },
};
</script>

