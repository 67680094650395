<template>
  <v-row justify="center" align="center">
    <v-col cols="12" sm="12">
      <div class="area-print" ref="areaPrint">
        <div>Cole sua aqui</div>
        <img
          v-if="this.printed"
          :src="this.printImg"
          alt="Converted Image"
          class="image-cover"
        />
      </div>
    </v-col>

    <!-- ----------- LIST IMAGES ---------------- -->
    <!-- <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="12" sm="3" v-for="(image, index) in images" :key="index">
          <v-card outlined>
            <v-img
              :src="image"
              class="white--text preview align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="100px"
            >
              <v-card-subtitle>{{ index }}</v-card-subtitle>
            </v-img>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn icon @click="$emit('setLink', index)">
                <v-icon>mdi-link</v-icon>
              </v-btn>

              <v-btn icon @click="doDelete(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <v-btn icon @click="download(index)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col> -->
  </v-row>
</template>

<script>
import { FilesManager } from "@/manager/files-manager";
const filesManager = new FilesManager();

export default {
  name: "PrintScreen",
  components: {},

  data() {
    return {
      printed: false,
      printImg: "",
      formData: {},

      // LIST IMAGES
      // images: [],
    };
  },

  mounted() {
    // const imageContainer = document.getElementById('area-print');
    // imageContainer.addEventListener("paste", this.pastePrint);
    this.$refs.areaPrint.addEventListener("paste", this.pastePrint);
  },

  methods: {
    // CRIA UM NOVO ITEM
    async convertBase64(pPrint) {
      this.printed = pPrint;
      this.formData.note = "IdCabulosoAqui";
      this.formData.img = pPrint;
      var response = await filesManager.convertBase64(this.formData);
      if (response.ok) {
        // this.toggleForm(true);
      }
    },

    pastePrint(e) {

      // COPIA DO COMPONENT ATUAL
      const auxComponent = this;

      const items = e.clipboardData.items;

      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          const blob = items[i].getAsFile();
          const reader = new FileReader();

          reader.onload = (event) => {
            const base64 = event.target.result;
            auxComponent.printImg = base64;
            auxComponent.convertBase64(base64.replace('data:image/png;base64,', ''));
            // console.log("base 64")
            // console.log(base64.replace('data:image/png;base64,', ''))
            // LIST IMAGES
            // auxComponent.images.push(base64);

          };
          reader.readAsDataURL(blob);
        }
      }
    },
  },
};
</script>

<style>
.area-print {
  margin: auto;
  margin-top: 40px;
  padding: 30px;
  text-align: center;
  border: 1px dashed #ccc;
  border-radius: 15px;
  min-height: 250px;
}

.image-cover {
  width: 100%; 
  height: auto; 
  object-fit: cover; 
}
</style>
