<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Reunião</div>
      <v-spacer></v-spacer>
    </v-row>

    <v-row justify="start">
      <v-col cols="12" sm="6" class="pl-0">
        <div id="editor-container">
          <v-textarea
            style="border-color: red !important"
            no-resize
            ref="refText"
            flat
            outlined
            height="500"
            v-model="minutes"
            @keydown="editingNote"
            id="editor"
            hide-details
            loading="false"
          />
          <!-- @keyup="callAutoSave()" -->
        </div>
        <v-row class="justify-space-between mt-4 px-1">
          <div>
            <v-btn text tile x-small :loading="false">
              <v-icon class="mr-2">mdi-cloud-check</v-icon>
            </v-btn>
            <!-- <span class="mt-2">{{ statusTextAutoSave }}</span> -->
          </div>

          <v-btn class="mt-1" text tile x-small @click="callUpdate()">
            <!-- @click="callUpdate(false)" -->
            Salvar <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6">
        <span>Status = {{ status }}</span>
        <v-list three-line>
          <template v-for="item in usersEditing">
            <v-divider :key="item.id"> </v-divider>

            <v-list-item :key="item.title">
              <v-list-item-avatar>
                <v-icon>mdi-account-circle</v-icon>
              </v-list-item-avatar>

              <v-list-item-content> {{ item.name }} </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Enable pusher logging - don't include this in production
import Pusher from "pusher-js";
import { mapGetters } from "vuex";

// Pusher.logToConsole = true;

// var channel = pusher.subscribe("my-channel")

import { MeetingManager } from "@/manager/meeting-manager";
const meetingManager = new MeetingManager();

export default {
  name: "Meeting",
  components: {},

  computed: {
    ...mapGetters({
      // carregar o módulo de autenticação e o usuário logado
      user: "auth/user",
      isAuthenticated: "auth/isAuthenticated",
    }),
  },

  mounted() {
    console.log("1 - Mounted", window.Echo);

    window.Echo.join(`meeting.${this.$route.params.id}`)
      .here((users) => {
        console.log("2", users);
        this.usersEditing = users;
      })
      .joining((user) => {
        console.log("3", user);
        this.usersEditing.push(user);
        this.editingNote();
      })
      .leaving((user) => {
        console.log("4", user);
        this.usersEditing = this.usersEditing.filter((u) => u != user);
      })
      .listenForWhisper("editing", (e) => {
        console.log("5", e);
        this.status = e.user.name + " " + e.status;
        this.minutes = e.minutes;
        // this.body = e.body;
        // console.log("------------- user -------------", this.user)
        setTimeout(() => {
          this.status = "";
        }, 5000);
      })
      .listenForWhisper("saved", (e) => {
        console.log("6", e);
        this.status = e.status;

        // clear is status after 1s
        setTimeout(() => {
          this.status = "";
        }, 1000);
      });
  },

  created() {
  },

  methods: {
    // TARGETS

    editingNote() {
      console.log("pisquei->", this.user, this.usersEditing);
      let channel = window.Echo.join(`meeting.${this.$route.params.id}`);

      console.log("channel ", channel);
      // show changes after 1s
      setTimeout(() => {
        channel.whisper("editing", {
          minutes: this.minutes,
          status: "está editando...",
          user: this.usersEditing
            .filter((user) => user.id === this.user.id)
            .map((user) => user)
            .reduce((user) => user.name),
        });
      }, 1000);
    },

    updateNote() {
      // persist to database
      axios.patch(`/edit/${this.note.slug}`, note).then((response) => {
        // show saved status
        this.status = response.data;

        // clear is status after 1s
        setTimeout(() => {
          this.status = "";
        }, 1000);

        // show saved status to others
      
      });
    },

    // ATUALIZA UM ITEM
    async callUpdate() {
      const formData = {
        agenda: "this.minutes",
        minutes: this.minutes,
        id: this.$route.params.id,
      };
      this.status = "Salvando...."
      var response = await meetingManager.update(formData);
      if (response.ok) {
       console.log("imprime o resultado--->", response)



         window.Echo.join(`note.${this.note.slug}`).whisper("saved", {
          status: response.data,
        });
      }
    },
  },

  data() {
    return {
      content: [],
      meeting: {},
      agendar: "",
      minutes: "",
      status: "",
      usersEditing: [],
    };
  },
};
</script>

<style scoped></style>
