<template>
  
  <div id="content-page" class="pa-2">

    <!-- TITULO -->

    <v-row>
      <v-col cols="12" sm="6">
        <div class="text-h5 text-sm-h4">Metas financeiras</div>
      </v-col>
      <v-col cols="12" sm="6" class="text-right">
        <v-btn-toggle v-model="toggleTables" mandatory rounded>
          <v-btn small> Lista </v-btn>
          <v-btn small> Orçamento </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <!-- CONTEUDO -->
    <v-row>

      <v-col cols="12" sm="6" v-if="!this.toggleTables">
        <v-card :loading="loading" style="z-index: 0">
          <div>
            <v-card-title>
              Lista de metas financeiras
              <v-spacer></v-spacer>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="toggleSearch()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="refreshData()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="openFinancialMonthlyGoalForm()"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-card-title>

            <div
              class="d-flex justify-space-between align-center"
              v-if="showSearch"
            >
              <v-col cols="9">
                <v-text-field
                  v-model="searchFinancialMonthlyGoal"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  dense
                  single-line
                  autofocus
                  hide-details
                />
              </v-col>

              <v-col cols="3" class="px-4">
                <v-row justify="end" class="mr-2">
                  <v-btn icon @click="clearSearch()">
                    <v-icon color="grey"> mdi-broom </v-icon>
                  </v-btn>
                  <v-btn icon @click="toggleSearch()">
                    <v-icon color="grey"> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </div>

            <v-data-table
              :headers="headers"
              :search="searchFinancialMonthlyGoal"
              :items="listFinancialMonthlyGoal"
              loading-text="Carregando..."
              progress
              class="elevation-1"
            >

              <template v-slot:[`item.amount`]="{ item }">
                <span :class="getColor(item)">
                  {{ formatNumber(item.amount) }}
                </span>
              </template>
              
              <template v-slot:[`item.actions`]="{ item }">
                <!-- <v-icon small class="mr-2" @click="callEditItem(item)"
                  >mdi-pencil</v-icon
                > -->
                <v-icon small class="mr-2" @click="callDeleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" v-if="this.toggleTables">
        <v-card :loading="loading" style="z-index: 0">
          <div>
            <v-card-title>
              Orçamento
              <v-spacer></v-spacer>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="toggleSearchValues()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                v-if="!loading"
                class="mr-2"
                type="button"
                color="blue"
                icon
                @click="refreshData()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-card-title>

            <div
              class="d-flex justify-space-between align-center"
              v-if="showSearchValues"
            >
              <v-col cols="9">
                <v-text-field
                  v-model="searchFinancialMonthlyGoalValues"
                  append-icon="mdi-magnify"
                  label="Pesquisar"
                  dense
                  single-line
                  autofocus
                  hide-details
                />
              </v-col>

              <v-col cols="3" class="px-4">
                <v-row justify="end" class="mr-2">
                  <v-btn icon @click="clearSearch()">
                    <v-icon color="grey"> mdi-broom </v-icon>
                  </v-btn>
                  <v-btn icon @click="toggleSearch()">
                    <v-icon color="grey"> mdi-close </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </div>

            <v-data-table
              :headers="headersValues"
              :search="searchFinancialMonthlyGoalValues"
              :items="listFinancialMonthlyGoalValues"
              loading-text="Carregando..."
              progress
              class="elevation-1"
            >

              <template v-slot:[`item.monthly_values[0].amount`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="`${getColorValues(item, item.monthly_values[0].amount, item.monthly_values[0].paid)} text-right`">
                        {{ formatNumber(item.monthly_values[0].amount) }}
                      </div>
                  </template>
                  <v-list dense>
                    <v-subheader>{{ item.category_title }} <br>Janeiro de {{ item.year }}</v-subheader>
                    <v-list-item @click="callEditValuesItem(item.monthly_values[0])">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="callTogglePaid(item.monthly_values[0])" v-if="item.monthly_values[0].amount != 0">
                      <v-list-item-title v-if="item.monthly_values[0].paid">Reabrir</v-list-item-title>
                      <v-list-item-title v-if="!item.monthly_values[0].paid">Realizar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.monthly_values[1].amount`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="`${getColorValues(item, item.monthly_values[1].amount, item.monthly_values[1].paid)} text-right`">
                        {{ formatNumber(item.monthly_values[1].amount) }}
                      </div>
                  </template>
                  <v-list dense>
                    <v-subheader>{{ item.category_title }} <br>Fevereiro de {{ item.year }}</v-subheader>
                    <v-list-item @click="callEditValuesItem(item.monthly_values[1])">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="callTogglePaid(item.monthly_values[1])" v-if="item.monthly_values[1].amount != 0">
                      <v-list-item-title v-if="item.monthly_values[1].paid">Reabrir</v-list-item-title>
                      <v-list-item-title v-if="!item.monthly_values[1].paid">Realizar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.monthly_values[2].amount`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="`${getColorValues(item, item.monthly_values[2].amount, item.monthly_values[2].paid)} text-right`">
                        {{ formatNumber(item.monthly_values[2].amount) }}
                      </div>
                  </template>
                  <v-list dense>
                    <v-subheader>{{ item.category_title }} <br>Março de {{ item.year }}</v-subheader>
                    <v-list-item @click="callEditValuesItem(item.monthly_values[2])">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="callTogglePaid(item.monthly_values[2])" v-if="item.monthly_values[2].amount != 0">
                      <v-list-item-title v-if="item.monthly_values[2].paid">Reabrir</v-list-item-title>
                      <v-list-item-title v-if="!item.monthly_values[2].paid">Realizar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.monthly_values[3].amount`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="`${getColorValues(item, item.monthly_values[3].amount, item.monthly_values[3].paid)} text-right`">
                        {{ formatNumber(item.monthly_values[3].amount) }}
                      </div>
                  </template>
                  <v-list dense>
                    <v-subheader>{{ item.category_title }} <br>Abril de {{ item.year }}</v-subheader>
                    <v-list-item @click="callEditValuesItem(item.monthly_values[3])">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="callTogglePaid(item.monthly_values[3])" v-if="item.monthly_values[3].amount != 0">
                      <v-list-item-title v-if="item.monthly_values[3].paid">Reabrir</v-list-item-title>
                      <v-list-item-title v-if="!item.monthly_values[3].paid">Realizar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.monthly_values[4].amount`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="`${getColorValues(item, item.monthly_values[4].amount, item.monthly_values[4].paid)} text-right`">
                        {{ formatNumber(item.monthly_values[4].amount) }}
                      </div>
                  </template>
                  <v-list dense>
                    <v-subheader>{{ item.category_title }} <br>Maio de {{ item.year }}</v-subheader>
                    <v-list-item @click="callEditValuesItem(item.monthly_values[4])">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="callTogglePaid(item.monthly_values[4])" v-if="item.monthly_values[4].amount != 0">
                      <v-list-item-title v-if="item.monthly_values[4].paid">Reabrir</v-list-item-title>
                      <v-list-item-title v-if="!item.monthly_values[4].paid">Realizar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.monthly_values[5].amount`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="`${getColorValues(item, item.monthly_values[5].amount, item.monthly_values[5].paid)} text-right`">
                        {{ formatNumber(item.monthly_values[5].amount) }}
                      </div>
                  </template>
                  <v-list dense>
                    <v-subheader>{{ item.category_title }} <br>Junho de {{ item.year }}</v-subheader>
                    <v-list-item @click="callEditValuesItem(item.monthly_values[5])">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="callTogglePaid(item.monthly_values[5])" v-if="item.monthly_values[5].amount != 0">
                      <v-list-item-title v-if="item.monthly_values[5].paid">Reabrir</v-list-item-title>
                      <v-list-item-title v-if="!item.monthly_values[5].paid">Realizar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.monthly_values[6].amount`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="`${getColorValues(item, item.monthly_values[6].amount, item.monthly_values[6].paid)} text-right`">
                        {{ formatNumber(item.monthly_values[6].amount) }}
                      </div>
                  </template>
                  <v-list dense>
                    <v-subheader>{{ item.category_title }} <br>Julho de {{ item.year }}</v-subheader>
                    <v-list-item @click="callEditValuesItem(item.monthly_values[6])">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="callTogglePaid(item.monthly_values[6])" v-if="item.monthly_values[6].amount != 0">
                      <v-list-item-title v-if="item.monthly_values[6].paid">Reabrir</v-list-item-title>
                      <v-list-item-title v-if="!item.monthly_values[6].paid">Realizar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.monthly_values[7].amount`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="`${getColorValues(item, item.monthly_values[7].amount, item.monthly_values[7].paid)} text-right`">
                        {{ formatNumber(item.monthly_values[7].amount) }}
                      </div>
                  </template>
                  <v-list dense>
                    <v-subheader>{{ item.category_title }} <br>Agosto de {{ item.year }}</v-subheader>
                    <v-list-item @click="callEditValuesItem(item.monthly_values[7])">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="callTogglePaid(item.monthly_values[7])" v-if="item.monthly_values[7].amount != 0">
                      <v-list-item-title v-if="item.monthly_values[7].paid">Reabrir</v-list-item-title>
                      <v-list-item-title v-if="!item.monthly_values[7].paid">Realizar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.monthly_values[8].amount`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="`${getColorValues(item, item.monthly_values[8].amount, item.monthly_values[8].paid)} text-right`">
                        {{ formatNumber(item.monthly_values[8].amount) }}
                      </div>
                  </template>
                  <v-list dense>
                    <v-subheader>{{ item.category_title }} <br>Setembro de {{ item.year }}</v-subheader>
                    <v-list-item @click="callEditValuesItem(item.monthly_values[8])">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="callTogglePaid(item.monthly_values[8])" v-if="item.monthly_values[8].amount != 0">
                      <v-list-item-title v-if="item.monthly_values[8].paid">Reabrir</v-list-item-title>
                      <v-list-item-title v-if="!item.monthly_values[8].paid">Realizar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.monthly_values[9].amount`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="`${getColorValues(item, item.monthly_values[9].amount, item.monthly_values[9].paid)} text-right`">
                        {{ formatNumber(item.monthly_values[9].amount) }}
                      </div>
                  </template>
                  <v-list dense>
                    <v-subheader>{{ item.category_title }} <br>Outubro de {{ item.year }}</v-subheader>
                    <v-list-item @click="callEditValuesItem(item.monthly_values[9])">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="callTogglePaid(item.monthly_values[9])" v-if="item.monthly_values[9].amount != 0">
                      <v-list-item-title v-if="item.monthly_values[9].paid">Reabrir</v-list-item-title>
                      <v-list-item-title v-if="!item.monthly_values[9].paid">Realizar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.monthly_values[10].amount`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="`${getColorValues(item, item.monthly_values[10].amount, item.monthly_values[10].paid)} text-right`">
                        {{ formatNumber(item.monthly_values[10].amount) }}
                      </div>
                  </template>
                  <v-list dense>
                    <v-subheader>{{ item.category_title }} <br>Novembro de {{ item.year }}</v-subheader>
                    <v-list-item @click="callEditValuesItem(item.monthly_values[10])">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="callTogglePaid(item.monthly_values[10])" v-if="item.monthly_values[10].amount != 0">
                      <v-list-item-title v-if="item.monthly_values[10].paid">Reabrir</v-list-item-title>
                      <v-list-item-title v-if="!item.monthly_values[10].paid">Realizar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:[`item.monthly_values[11].amount`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" :class="`${getColorValues(item, item.monthly_values[11].amount, item.monthly_values[11].paid)} text-right`">
                        {{ formatNumber(item.monthly_values[11].amount) }}
                      </div>
                  </template>
                  <v-list dense>
                    <v-subheader>{{ item.category_title }} <br>Dezembro de {{ item.year }}</v-subheader>
                    <v-list-item @click="callEditValuesItem(item.monthly_values[11])">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="callTogglePaid(item.monthly_values[11])" v-if="item.monthly_values[11].amount != 0">
                      <v-list-item-title v-if="item.monthly_values[11].paid">Reabrir</v-list-item-title>
                      <v-list-item-title v-if="!item.monthly_values[11].paid">Realizar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              

            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <FinancialMonthlyGoalForm
      :formData="FinancialMonthlyGoal"
      :showForm="showFinancialMonthlyGoalForm"
      @emitClose="closeFinancialMonthlyGoalForm"
    />

    <FinancialMonthlyGoalValuesForm
      :formData="FinancialMonthlyGoalValues"
      :showForm="showFinancialMonthlyGoalValuesForm"
      @emitClose="closeFinancialMonthlyGoalValuesForm"
    />

    <ConfirmDialog
      :title="deleteText"
      :dialog="toggleDialogFinancialMonthlyGoalDelete"
      @confirm="deleteItemConfirm"
      @cancel="closeDialogConfirmDeleteFinancialMonthlyGoal"
    />
  </div>
</template>

<script>
// COMPONENTES
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";
//FORMS
import FinancialMonthlyGoalForm from "./FinancialMonthlyGoalForm.vue";
import FinancialMonthlyGoalValuesForm from "./FinancialMonthlyGoalValuesForm.vue";
//MANAGERS
import { FinancialMonthlyGoalManager } from "../../manager/financial-monthly-goal-manager";
const financialMonthlyGoal = new FinancialMonthlyGoalManager();
import { FinancialMonthlyGoalValuesManager } from "../../manager/financial-monthly-goal-values-manager";
const financialMonthlyGoalValues = new FinancialMonthlyGoalValuesManager();
 
export default {
  name: "FinancialMonthlyGoal",
  components: {
    FinancialMonthlyGoalForm,
    FinancialMonthlyGoalValuesForm,
    ConfirmDialog,
  },

  data() {
    return {

      indexBudget: 1,

      showList : true,
      toggleTables : 0,

      // FORM
      FinancialMonthlyGoal: {},
      FinancialMonthlyGoalValues: {},
      showFinancialMonthlyGoalForm: false,
      showFinancialMonthlyGoalValuesForm: false,

      // DIALOG
      toggleDialogFinancialMonthlyGoalDelete: false,
      deleteText: "Tem certeza de deseja excluir este item ?",

      // TABLE
      listFinancialMonthlyGoal: [],
      listFinancialMonthlyGoalValues: [],
      searchFinancialMonthlyGoal: "",
      searchFinancialMonthlyGoalValues: "",
      showSearch: false,
      showSearchValues: false,
      headers: [
        {
          text: "Dia",
          align: "start",
          sortable: false,
          value: "day",
        },
        {
          text: "Categoria",
          align: "start",
          sortable: false,
          value: "included.category.attributes.title",
        },
        {
          text: "Valor",
          align: "end",
          sortable: false,
          value: "amount",
        },
        {
          text: "Ações",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      headersValues: [
        {
          text: "Dia",
          align: "start",
          sortable: false,
          value: "day",
        },
        {
          text: "Categoria",
          align: "start",
          sortable: false,
          value: "category_title",
        },
        {
          text: "Jan",
          align: "end",
          sortable: false,
          value: "monthly_values[0].amount",
        },
        {
          text: "Fev",
          align: "end",
          sortable: false,
          value: "monthly_values[1].amount",
        },
        {
          text: "Mar",
          align: "end",
          sortable: false,
          value: "monthly_values[2].amount",
        },
        {
          text: "Abr",
          align: "end",
          sortable: false,
          value: "monthly_values[3].amount",
        },
        {
          text: "Mai",
          align: "end",
          sortable: false,
          value: "monthly_values[4].amount",
        },
        {
          text: "Jun",
          align: "end",
          sortable: false,
          value: "monthly_values[5].amount",
        },
        {
          text: "Jul",
          align: "end",
          sortable: false,
          value: "monthly_values[6].amount",
        },
        {
          text: "Ago",
          align: "end",
          sortable: false,
          value: "monthly_values[7].amount",
        },
        {
          text: "Set",
          align: "end",
          sortable: false,
          value: "monthly_values[8].amount",
        },
        {
          text: "Out",
          align: "end",
          sortable: false,
          value: "monthly_values[9].amount",
        },
        {
          text: "Nov",
          align: "end",
          sortable: false,
          value: "monthly_values[10].amount",
        },
        {
          text: "Dez",
          align: "end",
          sortable: false,
          value: "monthly_values[11].amount",
        },
      ],
    };
  },

  created() {
    this.refreshData();
  },
  computed: {
    loading: () => financialMonthlyGoal.state.loading,
    loading: () => financialMonthlyGoalValues.state.loading,
  },

  watch: {
    toggleTables(val) {
      // console.log(val);
    },
  },

  methods: {
    async callList() {
      const data = await financialMonthlyGoal.list();
      // console.log(data);
      this.listFinancialMonthlyGoal = data;
    },
    async callListValues() {
      const data = await financialMonthlyGoalValues.list();
      console.log(data);
      this.listFinancialMonthlyGoalValues = data;
    },

    refreshData() {
      this.callList();
      this.callListValues();
    },

    async deleteItemConfirm() {
      const ret = await financialMonthlyGoal.delete(this.FinancialMonthlyGoal);
      if (ret.ok) {
        this.closeDialogConfirmDeleteFinancialMonthlyGoal();
        this.refreshData();
      }
    },
    // EDITAR VALOR META
    callEditValuesItem(pItem) {
      // console.log(pItem);
      // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
      this.FinancialMonthlyGoalValues = Object.assign({}, pItem);
      this.showFinancialMonthlyGoalValuesForm = true;
    },
    // TOGGLE PAID
    async callTogglePaid(pItem) {
      // console.log(pItem);
      // para evitar que as atualizações afetem o objeto original, Object.assign() pode ser uma ferramenta útil.
      const ret = await financialMonthlyGoalValues.togglePaid(pItem);
      if (ret.ok) {
        this.callListValues();
      }
    },

    callDeleteItem(pItem) {
      this.FinancialMonthlyGoal = pItem;
      this.deleteText =
        "Tem certeza de deseja excluir registro?";
      this.toggleDialogFinancialMonthlyGoalDelete = true;
    },

    openFinancialMonthlyGoalForm() {
      this.FinancialMonthlyGoal = {};
      this.showFinancialMonthlyGoalForm = true;
    },

    // FECHAR MODAL INCLUSAO
    closeFinancialMonthlyGoalForm(pHasUpdate) {
      if (pHasUpdate) {
        this.refreshData();
      }
      this.FinancialMonthlyGoal = {};

      this.showFinancialMonthlyGoalForm = false;
    },

    // FECHAR MODAL INCLUSAO
    closeFinancialMonthlyGoalValuesForm(pHasUpdate) {
      if (pHasUpdate) {
        this.refreshData();
      }
      this.FinancialMonthlyGoalValues = {};

      this.showFinancialMonthlyGoalValuesForm = false;
    },

    closeDialogConfirmDeleteFinancialMonthlyGoal() {
      this.FinancialMonthlyGoal = {};
      this.toggleDialogFinancialMonthlyGoalDelete = false;
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

    clearSearch() {
      this.searchFinancialMonthlyGoal = " ";
    },

    getColor(pItem){
      if(pItem.included.category.attributes.type=='D'){
        return 'red--text text--lighten-2'
        // return 'red--text text--darken-3'
        // return 'red--text text--accent-1'
      } 
    },
    getColorValues(pItem, pAmount, pPaid){
      if(pAmount==0 || pPaid == true){
        return 'grey--text transparent'
      }
      if(pItem.category_type=='D'){
        return 'red--text text--lighten-2'
        // return 'red--text text--darken-3'
        // return 'red--text text--accent-1'
      } 
    },
    formatNumber(pValue){
      var vValue = pValue;
      if(vValue<0){
        vValue = vValue * (-1);
      }
      if(!isNaN(vValue)){
        return vValue.toFixed(2);
      }
    },
    
  },
};
</script>
