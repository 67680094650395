<template>
  <div>
    <v-row class="ma-1">
      <h1>Login</h1>
      <v-spacer></v-spacer>
      <!-- <v-icon>mdi-plus-circle</v-icon> -->
    </v-row>
    <v-card class="ma-10">
      <div v-if="!isAuthenticated">
        <v-form
          class="ma-6"
          ref="formLogin"
          v-model="valid"
          lazy-validation
          onSubmit="return false;"
        >
          <v-row justify="space-around">
            <v-col cols="12">
              <v-text-field
                class="mt-10"
                v-model="formData.email"
                label="Nome de usuário"
                :rules="rules.email"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.password"
                @keyup.enter="submit()"
                ref="password"
                label="Senha"
                type="password"
                :rules="rules.password"
                required
              />
            </v-col>

            <v-col cols="12">
              <v-row justify="center">
                <v-btn
                  rounded
                  :disabled="!valid || loading"
                  :loading="loading"
                  color="primary"
                  class="white--text my-5 mr-3"
                  @click="submit()"
                >
                  Entrar
                </v-btn>
              </v-row>
            </v-col>
            <v-row class="my-1 align-center" justify="center">
              <div class="d-flex align-center">
                Não possui login ?
                <router-link class="link ma-2" to="/register">
                  Cadastre-se
                </router-link>
              </div>
            </v-row>
          </v-row>
        </v-form>
      </div>
      <div v-else class="pa-8">
        Você está autenticado
        <v-spacer class="mb-4"></v-spacer>
        <v-btn
          @click="callLogout"
          class="success"
          :disabled="loading"
          :loading="loading"
          >Logout</v-btn
        >
      </div>
    </v-card>
    <div class="mr-10 float-right">
      <h6 class="font-weight-light">Versão beta 0.2.4</h6> 
      <h6 class="font-weight-light">{{ this.state }}</h6> 
      <h6 class="font-weight-light">{{ step }}</h6> 
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "Login",

  computed: {
    ...mapGetters({
      // carregar o módulo de autenticação e se o usuário está logado
      isAuthenticated: "auth/isAuthenticated",
      step: "auth/step",
    }),
  },
  methods: {
    //
    ...mapActions({
      // carregar o módulo de autenticação e o método de login
      login: "auth/login",
      // carregar o módulo de autenticação e o método de logout
      logout: "auth/logout",
    }),
    callLogout() {
      this.loading = true;
      this.logout();
      this.loading = false;
    },
    submit() {
      this.loading = true;
      this.state = 'validando...';
      console.log('validando...');

      if (!this.$refs.formLogin.validate()) {
        this.loading = false;
        this.state = 'Dados inválidos';
        console.log('Dados inválidos');
        return;
      } else {
        this.state = 'Dados válidos';
        console.log('Dados válidos');
      }
      
      this.state = 'Enviando dados...';
      console.log('Enviando dados...');
      this.login(this.formData).then((response) => {
        this.state = 'Checando credenciais...';
        console.log('Checando credenciais...');
        try {
          this.state = 'ok! redirecionando...';
          console.log('ok! redirecionando...');
          if (response == undefined) {
            this.state = 'erro na resposta';
            console.log('erro na resposta');
            this.loading = false;
            return;
          }
          if (response.ok) {
            this.state = 'acionando dashboard';
            console.log('acionando dashboard');
            this.$router.replace({
              name: "dashboard",
            });
          }
          if (response?.route) {
            this.state = 'acionando '+ response.route;
            console.log('acionando '+ response.route);
            this.$router.replace({
              name: response.route,
              params: {
                email: this.formData.email,
              },
            });
          }
          this.state = 'Requisição não atendida.';
          console.log('Requisição não atendida.');
        } catch (error) {
          this.state = 'Erro ao autenticar';
          console.error(error);
        } finally {
          this.loading = false;
        }
      });
    },
  },
  data() {
    return {
      loading: false,
      hasAuth: false,

      state : '',
      
      formData: {
        email: "",
        password: "",
      },

      valid: false,
      rules: {
        email: [
          (v) => !!v || "Campo obrigatório",
          (v) => /.+@.+\..+/.test(v) || "E-mail precisa ser válido",
        ],
        password: [(v) => !!v || "Campo obrigatório"],
      },
    };
  },
};
</script>