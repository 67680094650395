import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { pt } from 'vuetify/lib/locale';

Vue.use(Vuetify);

export default new Vuetify({
  // theme: { dark: true },
  lang: { locales: { pt }, current: 'pt' },
});
