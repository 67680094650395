<template>
  <div class="ma-5">
    <v-row justify="start" align="start" v-if="_files.length < 1">
      Sem arquivos para exibir
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" v-for="file in _files" :key="file.id">
        <v-card outlined>
          <v-row justify="start" align="start">
            <v-col cols="12" sm="2">
              <v-icon large>
                {{ getIcon(file.ext) }}
              </v-icon>
            </v-col>
            <v-col cols="12" sm="10">
              {{ file.name }}
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn icon @click="doDelete(file)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>

            <v-btn icon @click="download(file)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "arquivos",

  props: {
    files: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
    };
  },

  computed: {
    _files() {
      // console.log(this.files);
      return this.files.filter(
        (item) => !/\.(jpe?g|png|gif)$/i.test(item.filename)
      );
    },
  },

  methods: {
    getIcon(ext) {
      if (/\/(pdf)$/i.test(ext)) {
        return "mdi-file-pdf-box";
      }
      return "mdi-file";
    },

    doDelete(item) {
      this.$emit("do-delete", item);
    },

    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },

    async download(item) {
      axios({
        method: "get",
        url: `${this.baseUrl}/files/download/${item.id}`,
        responseType: "arraybuffer",
      })
        .then((response) => {
          // console.log(response);
          this.forceFileDownload(response, item.name);
        })
        .catch(() => console.log("error occured"));
    },

  },
 
};
</script>

<style></style>
