<template>
  <v-row>
    <v-dialog overlay-opacity="0.8" v-model="dialog" persistent max-width="500px">
      <v-card :loading="loading">
        <v-card-title class="text-h6 text-sm-h5 justify-center">
          <v-icon color="red" size="100">mdi-information-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-h6 text-sm-h5 justify-center font-weight-regular justify-center">
          {{ title }}
        </v-card-text>
        <v-card-text> {{ description }} </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="loading" color="blue white--text" @click="$emit('cancel')"> Cancelar </v-btn>
          <v-btn :disabled="loading" color="error white--text" @click="$emit('confirm')"> {{ titleConfirm }} </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  <script>
export default {
  name: "ConfirmDialog",
  props: {
    title: {
      type: String,
      required: true,
    },
    titleConfirm: {
      type: String,
      default: "Excluir",
    },
    description: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>