<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Monitor</div>
      <!-- <v-spacer></v-spacer> -->
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" class="pa-0">
        <v-card class="pa-4" :loading="loading">
          <v-btn @click="ping()">Teste de ping</v-btn><br><br>
          <b>Versão:</b> {{ appVersion }} (v.1.X) <br>
          <b>Base URL:</b> {{ baseUrl }}  <br>
          <b>Pong:</b> {{ pong }}  <br>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import { AdminManager } from "@/manager/admin-manager";
const adminManager = new AdminManager();

export default {
  name: "Monitor",
  
  data() {
    return {
      pong:'',
      appVersion: process.env.APP_VERSION,
      baseUrl: process.env.VUE_APP_API_URL,
    };
  },

  computed: {
    loading: () => adminManager.state.loading,
  },

  methods: {
      async ping(){
        const data = await adminManager.ping();
        console.log(data);
        this.pong = data.data.time;
      },
    }

};
</script>
