<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Perfil</div>
      <v-spacer></v-spacer>
    </v-row>

    <v-row>
      <v-col cols="12" md="3" class="pa-0">
        <v-card
          color="transparent"
          flat
          class="pa-4 d-flex flex-column align-center justify-center"
        >
          <v-avatar class="mb-4" color="grey darken-1" size="64">
            <v-icon dark size="68"> mdi-account-circle </v-icon>
          </v-avatar>
          <div>{{ me.name }}</div>
        </v-card>
      </v-col>
      <v-col cols="12" md="9" class="pa-4">
        <v-card :loading="loading" class="px-4">
          <v-tabs show-arrows v-model="activeTab">
            <v-tab to="/perfil/account">
              <v-icon left> mdi-account </v-icon>
              Conta
            </v-tab>
            <v-tab to="/perfil/password">
              <v-icon left> mdi-key </v-icon>
              Senha
            </v-tab>
            <v-tab to="/perfil/sessions">
              <v-icon left> mdi-monitor-cellphone </v-icon>
              Sessões
            </v-tab>
            <v-tab to="/perfil/theme">
              <v-icon left> mdi-application-cog </v-icon>
              Tema
            </v-tab>

            <v-tab-item value="/perfil/account">
              <v-form onSubmit="return false;">
                <v-row justify="space-around">
                  <v-col cols="12">
                    <v-text-field class="mt-5" label="Nome" v-model="me.name" />

                    <v-text-field
                      class="mt-5"
                      label="Email"
                      v-model="me.email"
                    />

                    <v-text-field
                      class="mt-5"
                      label="Criado em"
                      v-model="me.created_at"
                      disabled
                    />

                    <v-text-field
                      class="mt-5"
                      label="Email verificado em"
                      v-model="me.email_verified_at"
                      disabled
                    />
                  </v-col>
                  <v-col cols="6"> </v-col>

                  <v-col cols="12">
                    <v-row class="ml-1" justify="start">
                      <v-btn
                        v-if="this.isOnline"
                        color="success"
                        :loading="loading"
                        :disabled="loading || me == '' || !isOnline"
                        @click="updateUser()"
                        class="white--text my-5 mr-3"
                      >
                        Atualizar cadastro
                      </v-btn>
                      <v-btn
                        color="error"
                        v-if="!this.isOnline"
                        class="white--text my-5 mr-3"
                      >
                        Você está off-line
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item value="/perfil/password">
              <v-form
                lazy-validation
                v-model="valid"
                ref="updatePasswordForm"
                onSubmit="return false;"
              >
                <v-row justify="start">
                  <v-col cols="12">
                    <v-text-field
                      class="mt-2"
                      type="password"
                      v-model="itemUpdatePassword.password_old"
                      label="Senha Atual"
                      :rules="[rules.required, rules.min]"
                      required
                    />

                    <v-text-field
                      class="mt-2"
                      type="password"
                      v-model="itemUpdatePassword.password"
                      label="Senha"
                      :rules="[rules.required, rules.min]"
                      required
                    />

                    <v-text-field
                      class="mt-2"
                      type="password"
                      v-model="itemUpdatePassword.password_confirmation"
                      label="Confirmar Senha"
                      :rules="[rules.required, passwordMatch]"
                      required
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-row class="ml-1" justify="start">
                      <v-btn
                        color="success"
                        class="white--text my-5 mr-3"
                        @click="updatePassword()"
                      >
                        Atualizar senha
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item value="/perfil/sessions">
              <Tokens></Tokens>
            </v-tab-item>
            <v-tab-item value="/perfil/theme">
              <v-row class="mt-4">
                <v-col cols="12" md="8">
                  <v-app-bar
                    :style="{
                      background: this.me.theme_color,
                      width: '100%',
                      border: '1px dashed #fafafa',
                    }"
                  >
                    <v-icon :color="me.theme_font_color">mdi-menu</v-icon>

                    <v-spacer></v-spacer>
                    <h5
                      class="font-weight-thin"
                      :style="{
                        color: me.theme_font_color,
                        'max-width': '140px',
                      }"
                    >
                      {{ user.name }}
                    </h5>

                    <v-btn class="" icon>
                      <v-icon :color="me.theme_font_color"
                        >mdi-account-circle</v-icon
                      >
                    </v-btn>

                    <v-btn class="" icon>
                      <v-icon :color="me.theme_font_color">mdi-logout</v-icon>
                    </v-btn>
                  </v-app-bar>

                  <h5 class="mt-1 font-weight-thin">
                    * Verifique como ficará após as seleções. Atenção ao
                    constrate da letra com a cor de fundo.
                  </h5>

                  <h3 class="mt-4">Tema escuro</h3>

                  <v-radio-group v-model="me.theme_dark">
                    <v-radio
                      v-for="item in itensDark"
                      :key="item.key"
                      :label="item.label"
                      :value="item.key"
                    ></v-radio>
                  </v-radio-group>

                  <h3 class="mt-1">Cor da fonte</h3>
                  <v-radio-group v-model="me.theme_font_color">
                    <v-radio
                      v-for="item in colorFonts"
                      :key="item.key"
                      :label="item.label"
                      :value="item.key"
                    ></v-radio>
                  </v-radio-group>

                  <v-select
                    v-model="corSelecionada"
                    ref="combobox_entity"
                    label="Selecione uma cor"
                    :items="cores"
                    item-text="nome"
                    item-value="cor"
                    return-object
                    menu-props="auto"
                  >
                    <template v-slot:selection="{ item }">
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon :style="{ color: item.cor }"
                            >mdi-checkbox-blank-circle</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title>{{ item.nome }}</v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item @click="selectColor(item)">
                        <v-list-item-icon>
                          <v-icon :style="{ color: item.cor }"
                            >mdi-checkbox-blank-circle</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title>{{ item.nome }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>

                  <v-btn
                    v-if="enableSaveTheme"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="saveTheme()"
                  >
                    Salvar tema
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-color-picker
                    v-model="me.theme_color"
                    class="ma-2"
                    show-swatches
                    hide-canvas
                    hide-inputs
                    hide-sliders
                    width="100%"
                    swatches-max-height="400px"
                  ></v-color-picker>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
      <ConfirmDialog
        title="Tem certeza de deseja sair sem salvar os dados ?"
        titleConfirm="Confirmar"
        :dialog="toggleConfirmDialog"
        @confirm="comfirmDialogUnsave"
        @cancel="closeComfirmDialogUnsave"
      />
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import ConfirmDialog from "@/components/Dialogs/ConfirmDialog.vue";
import Tokens from "@/views/Tokens/Tokens.vue";
import { UserManager } from "@/manager/user-manager";
const userManager = new UserManager();

export default {
  name: "Perfil",
  components: {
    Tokens,
    ConfirmDialog,
  },
  data() {
    return {
      cores: [
        { nome: "Vermelho", cor: "#FF0000" },
        { nome: "Azul", cor: "#0000FF" },
        { nome: "Verde", cor: "#00FF00" },
        // Adicione mais cores, se necessário
      ],
      corSelecionada: null,

      toggleConfirmDialog: false,
      enableSaveTheme: false,
      confirmUnsave: false,
      newRoute: null,

      activeTab: "",
      me: [],
      valid: false,
      isOnline: true,
      itensDark: [
        { key: "W", label: "Claro" },
        { key: "D", label: "Escuro" },
        { key: "H", label: "Automático" },
      ],
      colorFonts: [
        { key: "#FFFFFFFF", label: "Branca" },
        { key: "#333333FF", label: "Preta" },
      ],
      itemUpdatePassword: {
        password_old: "",
        password: "",
        password_confirmation: "",
      },
      rules: {
        password: [(v) => !!v || "Campo obrigatório"],
        required: (value) => !!value || "Informe uma senha.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
    };
  },
  created() {
    this.callMe();
  },
  mounted() {
    this.isOnline = navigator.onLine;
    // this.activeTab = this.$route.params.activeTab || 'account';
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  computed: {
    ...mapGetters({
      // carregar o módulo de autenticação e o usuário logado
      user: "auth/user",
      isAuthenticated: "auth/isAuthenticated",
    }),
    loading: () => userManager.state.loading,
    passwordMatch() {
      return () =>
        this.itemUpdatePassword.password ===
          this.itemUpdatePassword.password_confirmation ||
        "Suas credenciais não conferem";
    },
  },
  watch: {
    me: {
      deep: true,
      handler(newValue, oldValue) {
        // console.log(newValue);
        // console.log(oldValue);
        // console.log(typeof oldValue);
        // console.log(Object.keys(oldValue).length);
        // Esse trem era 0 e depois virou 1
        if (Object.keys(oldValue).length > 1) {
          // console.log(this.me.theme_color);
          // console.log('The list of colours has changed!');
          this.enableSaveTheme = true;
        }
      },
    },
  },
  methods: {
    selectColor(item) {
      this.corSelecionada = item.cor;
      this.$refs.combobox_entity.isMenuActive = false;
    },
    updateOnlineStatus() {
      this.isOnline = navigator.onLine;
    },

    async callMe() {
      const response = await userManager.getUser();
      response.created_at = moment(response.created_at).format(
        "DD/MM/YYYY - HH:mm"
      );
      this.me = response;
    },

    async updatePassword() {
      if (!this.$refs.updatePasswordForm.validate()) return;
      await userManager.updatePassword(this.itemUpdatePassword);
    },

    async updateUser() {
      await userManager.updateUser(this.me);
    },

    saveTheme() {
      this.updateUser();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },

    // Função que recebe confirmação do dialog e avança rota.
    comfirmDialogUnsave() {
      this.enableSaveTheme = false;
      this.$router.replace({
        path: this.newRoute,
      });
      this.closeComfirmDialogUnsave();
    },

    // Função que recebe cancela a confirmação do dialog e permanece na rota.
    closeComfirmDialogUnsave() {
      this.toggleConfirmDialog = false;
      this.newRoute = null;
    },
  },

  beforeRouteUpdate(to, from, next) {
    this.activeTab = to.params.activeTab || "account";
    next();
  },

  beforeRouteLeave(to, from, next) {
    // Verifica se existem dados não salvos e se a rota é diferente da atual
    if (this.enableSaveTheme && to.path !== from.path) {
      // Armazena proxima rota
      this.newRoute = to.path;

      // Exibe um alerta personalizado
      this.toggleConfirmDialog = true;
    } else {
      // Se não houver dados não salvos, permite a mudança de rota
      next();
    }
  },
};
</script>

