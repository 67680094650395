<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      overlay-opacity="0.8"
      style="width: 100%; z-index: 5000"
      persistent
      max-width="400px"
      open-delay="5000"
    >
      <v-card :loading="loading">
        <v-card-text>
          <!-- TOPO FORMULARIO  -->
          <div class="d-flex justify-end"></div>
          <v-card-actions>
            <v-row class="my-4 d-flex justify-space-between align-center">
              <div class="d-flex display-1 align-center">
                <span v-if="this.formData.id == undefined">
                  Cadastrar Drive
                </span>
                <span v-if="this.formData.id != undefined">Editar Drive</span>
              </div>
              <v-btn
                fab
                rounded
                text
                tile
                x-small
                @click="toggleForm(false)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>

          <v-form
            ref="refForm"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-row>
              <!-- CAMPO TITULO  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <v-text-field
                    v-model="formData.title"
                    label="Título"
                    maxlength="30"
                    required
                    :rules="[rules.required]"
                    autofocus
                    @keyup.enter="formData.id == undefined ? callCreate() : callUpdate()"
                  />
                </v-row>
              </v-col>

              <!-- CAMPO COR  -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <v-text-field
                    v-model="color"
                    label="Cor"
                  ></v-text-field>
                </v-row>
                <v-color-picker
                  v-model="color"
                  :swatches="swatches"
                  :show-swatches="showSwatches"
                  mode="hexa"
                  hide-inputs
                  hide-canvas
                  hide-sliders
                >
                </v-color-picker>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12">
                <v-row justify="end">
                  <v-btn
                    v-if="this.formData.id == undefined"
                    :disabled="!valid || loading"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callCreate()"
                  >
                    Salvar
                  </v-btn>
                  <v-btn
                    v-if="this.formData.id != undefined"
                    :disabled="!valid || loading"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callUpdate()"
                  >
                    Editar
                  </v-btn>
                </v-row>
              </v-col>

              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { DrivesManager } from "../../manager/drives-manager";
const drivesManager = new DrivesManager();

export default {
  name: "DriveForm",

  props: {
    formData: {
      type: Object,
      require: false,
    },
    showForm: {
      type: Boolean,
    },
  },

  data() {
    return {
      // VALIDAÇÃO
      valid: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },

      color: "#0077C2FF",
      showSwatches: true,
      swatches: [
        ["#b61827", "#ef5350"],
        ["#0077C2", "#42a5f5"],
        ["#338a3e", "#66bb6a"],
        ["#c79a00", "#ffca28"],
        ["#000000", "#616161"],
      ],
    };
  },

  computed: {
    loading: () => drivesManager.state.loading,
  },

  watch: {
    color(pValue, pOldValue) {
      if (pOldValue == null) {
        this.color = "#0077C2FF";
      }
      this.formData.color = pValue;
    },
    formData(pValue) {
      if (!!pValue.color) {
        this.color = pValue.color;
        return;
      }
    },
  },

  methods: {
    // UTILIZADA PARA FECHAR O FORMULARIO. ELA RECEBE UM PARAMETRO
    // QUE VALIDA SE EXITE NESCESSIDADE DE ATUALIZAR A TABELA OU NAO
    toggleForm(pHasUpdate) {
      this.$refs.refForm.resetValidation();
      this.$emit("emitClose", pHasUpdate);
    },

    // USADO PARA LIMPAR O FORMULARIO
    callClearForm() {
      this.$refs.refForm.reset();
    },

    // CRIA UM NOVO ITEM
    async callCreate() {
      this.formData.color = this.color;
      if (!this.$refs.refForm.validate()) return;
      var response = await drivesManager.create(this.formData);
      if (response.ok) {
        this.toggleForm(true);
      }
    },

    // ATUALIZA UM ITEM
    async callUpdate() {
      if (!this.$refs.refForm.validate()) return;
      var response = await drivesManager.update(this.formData);
      if (response.ok) {
        this.toggleForm(true);
      }
    },
  },
};
</script>

<style scoped>
</style>s