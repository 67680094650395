import store from '@/store'
import axios from 'axios';

/*
https://vuex.vuejs.org/api/#subscribe
Um método nativo do store
Subscribe = se inscrever
Inscreva-se para armazenar mutações. 
O handler é chamado após cada mutação e recebe o descritor de mutação e o estado pós-mutação como argumentos.
*/
store.subscribe((mutation) => {

  // console.log('-- STORE | SUBSCRIBE --');
  // console.log(mutation);
  // console.log(mutation.type);
  // console.log(mutation.payload);

  if (mutation.type == 'auth/SET_TOKEN') {

    // console.log('Mutação auth/SET_TOKEN acionada')
    if (mutation.payload) {
      // console.log('Configurar o token na header do axios');
      axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`
      // console.log('Gravar o token no local storage');
      localStorage.setItem('token', mutation.payload);
    } else {
      console.alert('Mutação auth/SET_TOKEN acionada com token vazio')
    }

  }

  if (mutation.type == 'auth/CLEAR') {
    // console.log('Mutação auth/CLEAR acionada');
    // console.log('Remover o token da header do axios');
    axios.defaults.headers.common['Authorization'] = null;
    // console.log('Remover o token do local storage');
    localStorage.removeItem('token');
  }

  if (mutation.type == 'auth/SET_USER') {

    // console.log('Mutação auth/SET_USER acionada')
    if (mutation.payload) {
      localStorage.setItem('theme_color', mutation.payload.theme_color);
      localStorage.setItem('theme_dark', mutation.payload.theme_dark);
      localStorage.setItem('theme_font_color', mutation.payload.theme_font_color);
    } 

  }

})