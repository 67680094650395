<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      overlay-opacity="0.8"
      style="width: 100%; z-index: 5000"
      persistent
      max-width="400px"
      open-delay="5000"
    >
      <v-card>
        <v-card-text>
          <!-- TOPO FORMULARIO  -->
          <div class="d-flex justify-end"></div>
          <v-card-actions>
            <v-row class="my-4 d-flex justify-space-between align-center">
              <div class="d-flex display-1 align-center">
                <span>
                  {{ showInvite ? "Convidar" : "Compartilhar" }}
                </span>
              </div>
              <v-btn fab rounded text tile x-small @click="toggleForm(false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>

          <v-form
            ref="refForm"
            v-model="valid"
            lazy-validation
            onSubmit="return false;"
          >
            <v-row>
              <!-- CAMPO NOME   -->
              <v-col v-show="showAlert" cols="12">
                <v-row class="ma-1">
                  <v-alert dense outlined :type="messageAlert.color">
                    {{ messageAlert.message }}
                  </v-alert>
                </v-row>
              </v-col>

              <!-- CAMPO NOME   -->
              <v-col cols="12">
                <v-row class="ma-1">
                  <v-text-field
                    v-model="formData.email"
                    autofocus
                    label="Email"
                    :rules="rules.email"
                    maxlength="30"
                    required
                  ></v-text-field>
                </v-row>
              </v-col>

              <!-- CAMPO COLAPSSED   -->
              <v-col v-show="!showInvite" cols="12">
                <v-row class="ma-1">
                  <v-switch
                    v-model="formData.type"
                    label="Permitir edição ?"
                    value="E"
                  ></v-switch>
                </v-row>
              </v-col>

              <v-col v-show="showInvite" cols="12">
                <v-row class="ma-1">
                  <p style="font-weight: 700">
                    O email esta correto. Deseja convidar ?
                  </p>
                </v-row>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12">
                <v-row justify="end">
                  <v-btn
                    v-if="showInvite"
                    :disabled="!valid"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callInviteDocument()"
                  >
                    Convidar
                  </v-btn>
                  <v-btn
                    v-else
                    :disabled="!valid"
                    color="success"
                    class="white--text my-5 mr-3"
                    @click="callSharedDocument()"
                  >
                    Compartilhar
                  </v-btn>
                </v-row>
              </v-col>

              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  
  <script>
import { DocumentsManager } from "@/manager/document-manager";
const documentManager = new DocumentsManager();

import { UserManager } from "@/manager/user-manager";
const userManager = new UserManager();

export default {
  name: "ShareDocument",
  
  props: {
    formData: {
      type: Object,
    },
    showForm: {
      type: Boolean,
    },
  },

  data() {
    return {
      valid: false,

      messageAlert: { message: "", color: "warning" },
      showAlert: false,
      showInvite: false,

      rules: {
        email: [
          (v) => !!v || "Campo obrigatório",
          (v) => /.+@.+\..+/.test(v) || "E-mail precisa ser válido",
        ],
      },
    };
  },

  methods: {
    // SERVICES
    // UTILIZADA PARA FECHAR O FORMULARIO. ELA RECEBE UM PARAMETRO
    // QUE VALIDA SE EXITE NESCESSIDADE DE ATUALIZAR A TABELA OU NAO
    toggleForm(pHasUpdate) {
      this.$refs.refForm.resetValidation();
      this.$emit("emitClose", pHasUpdate);
    },

    async callSharedDocument() {
      if (!this.formData.type) {
        // console.log(this.formData);
        this.formData.type = "V";
      }
      let response = await documentManager.shareDocument(this.formData);
      // console.log(response);

      if (!response.ok && response.message) {
        this.messageAlert.color = "warning";
        this.messageAlert.message = response.message;
        this.showAlert = true;
        this.showInvite = true;
      } else if (response.ok) {
        this.messageAlert.message = "";
        this.messageAlert.color = "warning";
        this.showAlert = false;
        this.toggleShareDocument();
      }
    },

    async callInviteDocument() {
      let hasInite = true;
      let response = await userManager.inviteUser(this.formData);
      // console.log(response);
      if (hasInite) {
        this.showInvite = false;
        this.messageAlert.message = response.message;
        this.messageAlert.color = "success";
      }
    },

    // -----------------------------------------------------------------------
    toggleShareDocument() {
      this.showAlert = false;
      this.$emit("emitToggleShareDocument", false);
    },
    resetValidation() {
      this.$refs.documentForm.resetValidation();
    },

    async shareDocument() {
      if (!this.selectedSwitch) {
        this.item.type = "V";
      }
      this.item.document_id = this.documentId;
      let hasShare = false;
      //  await documentsUserManager.shareDocument(this.item);
      this.messageAlert.message = false;
      // documentsUserManager.state.messageInvite;
      if (
        !hasShare &&
        this.messageAlert.message ===
          "Verifique o email e caso o usuário não seja cadastrado convide-o para compartilhar o documento."
      ) {
        this.messageAlert.color = "warning";
        this.showAlert = true;
        this.showInvite = true;
      } else if (!hasShare) {
        this.messageAlert.color = "warning";
        this.showAlert = true;
        this.showInvite = false;
      } else if (hasShare) {
        this.messageAlert.message = "";
        this.messageAlert.color = "warning";
        this.showAlert = false;
        this.toggleShareDocument();
      }
    },
  },
};
</script>
  
  