<template>
  <div>
    <v-app-bar
      :color="colorApp"
      app
      flat
      ref="appBar"
      class="text-white"
      style="min-width: 320px"
    >
      <v-icon
        :style="{ color: fontColorApp }"
        @click="$emit('emitToggleNav')"
      >mdi-menu</v-icon>

      <v-spacer></v-spacer>

      <h5
        class="mr-5 font-weight-thin d-none d-sm-flex"
        :style="{ color: fontColorApp }"
      >
        {{ isAuthenticated ? user.name : "" }}
      </h5>

      <MegaModal
        class="mr-1"
        v-if="isAuthenticated"
        :show="megaModal"
        @emitCloseOverlay="megaModal = false"
      ></MegaModal>

      <v-btn
        class="mr-1"
        v-show="isAuthenticated"
        icon
        to="/hot-list"
      >
        <v-icon :style="{ color: fontColorApp }">mdi-fire</v-icon>
      </v-btn>

      <v-btn
        class="mr-1 d-none d-sm-flex"
        v-show="isAuthenticated"
        icon
        to="/perfil"
      >
        <v-icon :style="{ color: fontColorApp }">mdi-account-circle</v-icon>
      </v-btn>

      <v-btn
        class="mr-1 d-none d-sm-flex"
        v-if="isAuthenticated"
        icon
        to="/login"
      >
        <v-icon :style="{ color: fontColorApp }">mdi-logout</v-icon>
      </v-btn>
      <v-btn
        class="mr-1 d-none d-sm-flex"
        v-else
        icon
        to="/login"
      >
        <v-icon :style="{ color: fontColorApp }">mdi-login</v-icon>
      </v-btn>

      <!-- <v-btn class="mr-1" v-show="isAuthenticated" icon @click="swapExtraNav()">
      <v-icon v-if="!enableExtraNav" :style="{ color: fontColorApp}">mdi-plus</v-icon>
      <v-icon v-if="enableExtraNav" :style="{ color: fontColorApp}">mdi-minus</v-icon>
    </v-btn> -->

    </v-app-bar>
  </div>
</template>

<script>
import ExtraNav from "./ExtraNav.vue";
import MegaModal from "./MegaModal.vue";

import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "HeaderPrivate",
  components: {
    MegaModal,
    ExtraNav,
  },
  data() {
    return {
      megaModal: false,
      enableExtraNav: false,
      timeDiff: null,
    };
  },

  computed: {
    ...mapGetters({
      // carregar o módulo de autenticação e o usuário logado
      user: "auth/user",
      isAuthenticated: "auth/isAuthenticated",
    }),
    colorApp() {
      // Esta condição está no Nav também.
      let themeColor = "";
      if (this.user) {
        if (this.user.theme_dark != "D") {
          themeColor = this.user.theme_color;
        }
      } else {
        if (localStorage.getItem("theme_dark") != "D") {
          themeColor = localStorage.getItem("theme_color");
        }
      }
      return themeColor;
    },
    fontColorApp() {
      // if (this.user) {
      //   return this.user.theme_color;
      // }
      return this.user
        ? this.user.theme_font_color
        : localStorage.getItem("theme_font_color");
      return null;
    },
  },
  watch: {
    isAuthenticated() {
      this.changeDark();
    },

    // $route(to, from) {
    //   console.log(to);
    //   if (to.name == "quick-diary") {
    //     console.log(to);
    //     this.megaModal = true;
    //   } else {
    //     this.megaModal = false;
    //   }
    // },
  },
  methods: {
    swapExtraNav() {
      this.enableExtraNav = !this.enableExtraNav;
    },
    changeDark() {
      const now = moment(); // Obtém o horário atual
      const sixPm = moment().set({ hour: 0, minute: 0, second: 0 }); // Define as 6:00 PM de hoje
      const diff = now.diff(sixPm); // Calcula a diferença em milissegundos
      const duration = moment.duration(diff); // Converte a diferença em uma duração
      const hours = duration.hours();
      // const minutes = duration.minutes();
      // console.log(this.user.theme_dark);
      // console.log(hours);
      this.timeDiff = hours;

      let themeDark = "";
      if (this.user) {
        themeDark = this.user.theme_dark;
        // console.log('está conectado');
      } else {
        themeDark = localStorage.getItem("theme_dark");
        // console.log('está desconectado');
      }
      // console.log(themeDark);

      if (
        (themeDark == "H" && (this.timeDiff < 8 || this.timeDiff >= 18)) ||
        themeDark == "D"
      ) {
        // console.log('tema escuro');
        this.$vuetify.theme.dark = true;
      } else {
        // console.log('tema claro');
        this.$vuetify.theme.dark = false;
      }
      setTimeout(this.changeDark, 60000); // 1 minuto
    },
  },
};
</script>
