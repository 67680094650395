<template>
  <div class="pa-4">
    <v-row>
      <div class="text-h5 text-sm-h4">Cadastro</div>
      <v-spacer></v-spacer>
      <!-- <v-icon>mdi-plus-circle</v-icon> -->
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" sm="6" class="pa-0">
        <v-card>
          <div v-if="!isAuthenticated">
            <div v-if="userRegistered">
              <v-container>
                <v-icon style="font-size: 8em" class="mb-4 mt-2" color="green">
                  mdi-checkbox-marked-circle-auto-outline
                </v-icon>
                <br />
                <h1 class="mb-4">Oi {{ user.name }},</h1>
                <p>
                  Seu cadastro foi realizado com sucesso! <br />
                  <br />
                  Para acessar o portal verifique seu email
                  <b>{{ user.email }}</b> e valide o seu cadastro.<br />
                  <br />
                  Até breve 😊!!!
                </p>
              </v-container>
            </div>
            <div v-else>
              <v-container>
                <v-form
                  ref="registerForm"
                  v-model="valid"
                  lazy-validation
                  onSubmit="return false;"
                >
                  <v-row>
                    <!-- CAMPO NOME  -->
                    <v-col cols="12">
                      <v-text-field
                        v-model="formData.name"
                        label="Nome"
                        maxlength="30"
                        :rules="rules.name"
                        required
                      ></v-text-field>
                    </v-col>

                    <!-- CAMPO EMAIL -->
                    <v-col cols="12">
                      <v-text-field
                        v-model="formData.email"
                        :rules="rules.email"
                        label="E-mail"
                        required
                      ></v-text-field>
                    </v-col>

                    <!-- CAMPO DE SENHA -->
                    <v-col cols="12">
                      <v-text-field
                        v-model="formData.password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="showPassword ? 'text' : 'password'"
                        name="input-10-1"
                        label="Senha"
                        hint="Minimo de 8 caracteres"
                        counter
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </v-col>

                    <!-- CAMPO CONFIRMAÇÂO DE SENHA -->
                    <v-col cols="12">
                      <v-text-field
                        block
                        v-model="formData.password_confirmation"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, passwordMatch]"
                        :type="showPassword ? 'text' : 'password'"
                        name="input-10-1"
                        label="Confimar senha"
                        counter
                        @click:append="showPassword = !showPassword"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-row justify="center">
                        <v-btn
                          rounded
                          :disabled="!valid || loading"
                          :loading="loading"
                          color="primary"
                          class="white--text my-5 mr-3"
                          @click="submit()"
                        >
                          Registrar
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-row class="my-1 align-center" justify="center">
                      <div class="d-flex align-center">
                        Já possui login ?
                        <router-link class="link ma-2" to="/login">
                          Acesse
                        </router-link>
                      </div>
                    </v-row>
                  </v-row>
                </v-form>
              </v-container>
            </div>
          </div>
          <div v-else class="pa-8">
            Você está autenticado
            <v-spacer class="mb-4"></v-spacer>
            <v-btn @click="callLogout" class="success">Logout</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { RegisterManager } from "@/manager/register-manager";
const registerManager = new RegisterManager();

export default {
  name: "Login",

  data: () => ({
    validarLogin: false,
    userRegistered: false,

    user: {
      name: "",
      email: "",
    },
    formData: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },

    rules: {
      email: [
        (v) => !!v || "Campo obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail precisa ser válido",
      ],
      password: [(v) => !!v || "Campo obrigatório"],
      required: (value) => !!value || "Informe uma senha.",
      min: (v) => (v && v.length >= 8) || "Min 8 characters",
      name: [(value) => !!value || "Informe seu nome."],
    },
    valid: false,
    hasAuth: false,
    showPassword: false,
  }),
  computed: {
    ...mapGetters({
      // carregar o módulo de autenticação e se o usuário está logado
      isAuthenticated: "auth/isAuthenticated",
    }),
    passwordMatch() {
      return () =>
        this.formData.password === this.formData.password_confirmation ||
        "Suas credenciais não conferem";
    },
    loading: () => registerManager.state.loading,
  },
  methods: {
    async submit() {

      if (!this.$refs.registerForm.validate()) return;
      let response = await registerManager.create(this.formData);

      if (response.ok) {
        this.userRegistered = true;
        this.user.name = response.data.name;
        this.user.email = response.data.email;
      }
    },
  },
};
</script>