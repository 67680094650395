<template>
  <v-snackbar
    :color="color"
    v-model="isActive"
    :vertical="true"
    :timeout="timeLimit"
    top 
    min-width="300"
    style="z-index: 99999 !important"
    >
    <div class="subtitle-1 mb-1" v-html="title"></div>
    <v-divider v-if="message" />
    <div v-if="message" class="body-2 mt-3" v-html="message"></div>

    <template v-slot:action="{ attrs }">
      <v-btn small text v-bind="attrs" @click="isActive = false"> Dispensar </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import  {SnackbarManager}  from '../../manager/snackbar-manager';
const manager = new SnackbarManager();

export default {
  name: 'snackbar',

  computed: {
    isActive: {
      get() {
        return manager.isActive();
      },
      set(value) {
        manager.setIsActive(value);
      },
    },
    title() {
      return manager.title();
    },
    message() {
      return manager.message();
    },
    timeLimit() {
      return manager.timeLimit();
    },
    color() {
      return manager.color();
    },
  },
};
</script>

<style></style>
