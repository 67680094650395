<template>
  <div>
    <v-btn
      block
      outlined
      color="primary"
      @click="submitFiles"
      v-show="files.length > 0"
      :loading="loadingUploading"
    >
      Enviar
      <v-icon right dark> mdi-cloud-upload </v-icon>
    </v-btn>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "UploadButton",

  props: {
    files: {
      type: Array,
      required: true,
    },
    uploadPercentage: {
      type: Number,
      required: true,
    },
    loadingProgess: {
      type: Boolean,
    },
    url:{
      type: String,
      required: false
    }
  },

  computed: {
    _files: {
      get() {
        return this.files;
      },
      set(value) {
        this.$emit("update:files", value);
      },
    },

    _uploadPercentage: {
      get() {
        return this.uploadPercentage;
      },
      set(value) {
        this.$emit("update:uploadPercentage", value);
      },
    },
    _loadingProgess: {
      get() {
        return this.loadingProgess;
      },
      set(value) {
        this.$emit("update:loadingProgess", value);
      },
    },
  },

  data: () => ({ loadingUploading: false }),

  methods: {
    resetForm() {
      this.loadingUploading = false;
     
      this._files = [];
      this._uploadPercentage = 0;
      setTimeout(() => {
        this._loadingProgess = false;
      }, 1000);
    },

    prepareFormData() {
      let formData = new FormData();

      for (var i = 0; i < this._files.length; i++) {
        let file = this._files[i];
        console.log(file);
        formData.append("files[" + i + "]", file);
      }

      formData.append("visibility", 'public');

      return formData;
    },

    async submitFiles() {
      this._uploadPercentage = 0;
      this.loadingUploading = true;
      this._loadingProgess = true;
      // console.log(this.url);
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const onUploadProgress = function (progressEvent) {
        //please remove the timeout before release to productuion
        setTimeout(() => {
          this._uploadPercentage = parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        }, 600);
      }.bind(this);

      try {
        const data = await axios.post(this.url, this.prepareFormData(), {
          headers,
          onUploadProgress,
        });
        this.resetForm();
        this.$emit("emitSuccessUpload", true);
      } catch (error) {
        // console.log(error);
        this.resetForm();
      }
    },
  },
};
</script>

<style></style>
