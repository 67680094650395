/* eslint-disable */
import Vue from 'vue';
import axios from 'axios';
import { SnackbarManager } from './snackbar-manager';
const snackbarManager = new SnackbarManager();
import { GlobalManager } from './global-manager';
const globalManager = new GlobalManager();

const initialState = {
  drives: [],
  loading: false,
  breadcrumbs: [],
  treeView: [],
};

export class DrivesManager {
  state;
  constructor() {
    this.state = Vue.observable(initialState);
  }
  isLoading() {
    return this.state.loading;
  }

  // EXTRAI PRINCIPAIS IONFORMAÇÕES A SEREM UTILIZADAS
  extractData(pResponse) {
    const { data } = pResponse.data;

    // VERIFICA SE É UM OBJETO
    if (!!data?.attributes) {
      return globalManager._reduceResponse(data);
    }

    const list = data.map(item => (globalManager._reduceResponse(item)));
    return (list);
  }

  async breadcrumbs(pDriveId) {
    try {
      globalManager.loading(true);
      this.state.loading = true;

      let response = await axios.get(`/drives/breadcrumbs/${pDriveId}`);
      // console.log("response", response);

      let list = response.data.breadcrumbs
      // console.log("List - Manager", list);

      return list;

    } catch (error) {

      globalManager.printErrors(error);

      return globalManager.returnOk(false);
    } finally {
      globalManager.loading(false);
      this.state.loading = false;
    }
  }

  async list(pDriveId) {

    try {
      globalManager.loading(true);
      this.state.loading = true;

      // VERIFICA SE EXISTE O ID
      if (pDriveId === undefined || pDriveId === null || pDriveId === '') {
        let response = await axios.get(`/drives`);
        // console.log("response", response);

        let list = this.extractData(response);
        // console.log("List - Manager", list);

        return list;
      } else {
        let response = await axios.get(`/drives/folders/${pDriveId}`);
        // console.log("response", response);

        let list = this.extractData(response);
        // console.log("List - Manager", list);

        return list;
      }
    } catch (error) {

      globalManager.printErrors(error);

      return globalManager.returnOk(false);
    } finally {
      globalManager.loading(false);
      this.state.loading = false;
    }
  }

  async create(pFormData) {

    try {

      this.state.loading = true;
      // console.log("create", pFormData)

      await axios.post("/drives", { ...pFormData });

      snackbarManager.setSuccessMessage('Drive adicionado!');

      return globalManager.returnOk(true);

    } catch (error) {

      globalManager.printErrors(error);

      return globalManager.returnOk(false);

    } finally {
      this.state.loading = false;
    }
  }

  async update(pFormData) {

    try {

      this.state.loading = true;
      // console.log(pFormData);

      await axios.put(`/drives/${pFormData.id}`, { ...pFormData });

      snackbarManager.setSuccessMessage('Drive atualizado!');

      return globalManager.returnOk(true);

    } catch (error) {

      globalManager.printErrors(error);

      return globalManager.returnOk(false);

    } finally {
      this.state.loading = false;
    }

  }

  async delete(pDrive) {
    try {

      this.state.loading = true;

      await axios.delete(`/drives/${pDrive.id}`);

      snackbarManager.setSuccessMessage('Drive removido!');

      return globalManager.returnOk(true);

    } catch (error) {

      globalManager.printErrors(error);

      return globalManager.returnOk(false);

    } finally {
      this.state.loading = false;
    }
  }

  async search(pFormData){
    try {

      globalManager.loading(true);
      this.state.loading = true;

      let response = await axios.post(`search/drives-and-notes`, {...pFormData});
      return response.data;

    } catch (error) {

      globalManager.printErrors(error);

      return globalManager.returnOk(false);

    } finally {
      this.state.loading = false;
      globalManager.loading(false);
    }
  }


}
