<template>
  <v-row width="100%">
    <v-card-text class="text-h5 d-flex align-center justify-center">
      <v-icon :style="styleIcon" :size="sizeIcon">{{
        icon ? icon : "mdi-information-outline"
      }}</v-icon>
    </v-card-text>

    <v-card-text
      :style="styleTitle"
      class="text-h5 d-flex align-center justify-center"
    >
      <h1>{{ title }}</h1>
    </v-card-text>
    <v-card-text
      :style="styleDescription"
      class="text-h5 d-flex align-center justify-center"
    >
      {{ description }}
    </v-card-text>
  </v-row>
</template>

<script>
export default {
  name: "Message",

  props: {
    sizeIcon: {
      type: String,
    },
    styleIcon: {
      type: String,
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    styleTitle: {
      type: String,
    },
    description: {
      type: String,
    },
    styleDescription: {
      type: String,
    },
  },
};
</script>