<template>
  <div class="pa-4">
    <!-- TITULO -->
    <v-row>
      <div class="text-h5 text-sm-h4">Editar diário</div>
      <v-spacer></v-spacer>
    </v-row>
    <!-- CONTAINER -->
    <v-row class="">
      <v-col cols="12" sm="6" lg="6" class="pa-1">
        <div id="editor-container">
          <v-textarea
            style="border-color: red !important"
            no-resize
            ref="refText"
            flat
            outlined
            height="500"
            @keyup="callAutoSave()"
            v-model="formData.diary"
            id="editor"
            hide-details
            loading="false"
          />
        </div>
        <v-row class="justify-space-between mt-4 px-1">
          <div>
            <v-btn text tile x-small :loading="loadingAutoSave">
              <v-icon class="mr-2">mdi-cloud-check</v-icon>
            </v-btn>
            <span class="mt-2">{{ statusTextAutoSave }}</span>
          </div>

          <div class="d-flex align-center">
            <v-rating
              v-model="formData.rating"
              background-color="orange lighten-3"
              color="orange"
              dense
            ></v-rating>
          </div>

          <v-btn class="mt-1" text tile x-small @click="callUpdate(false)">
            Salvar <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-row>
      </v-col>

      <v-col v-if="isRemider" cols="3" class="pt-1">
        <v-date-picker @change="callUpdate(true)" full-width v-model="formData.date"></v-date-picker>
      </v-col>

      <v-col v-if="!isRemider" cols="12" sm="6" lg="6" class="pt-0">
        <PreviewMd :formData="converterMd(formData.diary)" />
        <!-- <h4 class="mt-4">Tipos de diário</h4>
				<div v-for="item in diariesTypes" :key="item.id">
					<v-checkbox
						v-model="formData.type"
						:label="item.title"
						color="primary"
						:value="item.key"
						hide-details
					></v-checkbox>
				</div>
				<h4 v-if="formData.type === 'TGT'" class="mt-4">Meta</h4>
				<Select
					v-if="formData.type === 'TGT'"
					:list.sync="targets"
					:formData="formData"
					itemText="title"
					itemValue="id"
					label="Selecione uma meta"
					nodeName="target"
					:itemSelected.sync="formData.target_id"
					:rules="[rules.required]"
				/> -->

        <!-- <div>
					<h4 class="mt-2">Avaliação</h4>
					<v-rating
						v-model="formData.rating"
						background-color="orange lighten-3"
						color="orange"
						dense
					></v-rating>
				</div> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import showdown from "showdown";

import { DiaryManager } from "@/manager/diary-manager";
const diaryManager = new DiaryManager();
import { TargetsManager } from "@/manager/targets-manager";
const targetsManager = new TargetsManager();
import { GlobalManager } from "@/manager/global-manager";
const globalManager = new GlobalManager();

import Select from "@/components/Selects/Select.vue";
import PreviewMd from "@/components/PreviewMd/Preview.vue";

var timeoutAutoSave;
const converter = new showdown.Converter();
converter.setFlavor("github");

export default {
  name: "EditDiary",

  components: {
    Select,
    PreviewMd,
  },

  data() {
    return {
      // VARIAVEIS REFERENTE A DIARIO DO DIA
      formData: {},

      diariesTypes: [],
      targets: [],
      isRemider: false,

      rules: {
        required: (value) => !!value || "Campo obrigatório",
      },

      // CONTROLA AUTO SAVE
      timeoutAutoSave: null,
      statusTextAutoSave: "",
      loadingAutoSave: false,
    };
  },

  computed: {
    loading: () => diaryManager.state.loading,
    loadingTarget: () => targetsManager.state.loading,
  },

  watch: {
    loading(value) {
      globalManager.loading(value);
    },    
  },

  created() {
    this.callListDiariesTypes();
    this.callListTargets();
    this.callDiary();
  },

  methods: {

    async callListDiariesTypes() {
      const data = await diaryManager.listDiariesTypes();
      // console.log(data)
      this.diariesTypes = data.map((objeto) => objeto);
    },

    async callListTargets() {
      const data = await targetsManager.list();
      // console.log(data);
      this.targets = data.map((objeto) => objeto);
    },

    async callDiary() {
      const data = await diaryManager.showDiary(this.$route.params.id);
      if (this.$route.query?.types == "REM") {
        this.isRemider = true;
      }
      this.formData = data;
    },

    async callUpdate(pAutoSave) {
      this.statusTextAutoSave = "Salvando...";
      this.loadingAutoSave = true;

      if (this.formData.type != "TGT") {
        this.formData.target_id = "";
      }

      if (await diaryManager.update(this.formData, pAutoSave)) {
        this.delayedCompletedAutoSave();
      }
    },

    converterMd(pContent) {
      const content = converter.makeHtml(pContent);

      return { content_md: content };
    },

    // CONTROLA DELAY DO KEYUP PARA EFETUAR O AUTOSAVE
    callAutoSave() {
      this.clearMessage();
      timeoutAutoSave = setTimeout(() => {
        this.callUpdate(true);
      }, 5000);
    },

    // CONTROLA MENSAGENS DE STATUS AUTOSAVE
    delayedCompletedAutoSave() {
      this.loadingAutoSave = false;
      this.statusTextAutoSave = "Alterações salvas.";
      setTimeout(() => {
        this.statusTextAutoSave = "";
      }, 1000);
      this.clearMessage();
    },

    // AUXILIAR QUE RESETA CONTADOR DE DELAY DO KEYUP
    clearMessage() {
      clearTimeout(timeoutAutoSave);
    },
  },
};
</script>
